import React, { Component } from 'react';

class SamplepreviousArrow extends Component {


    render() {

        const { key, onClick,slickPrev} = this.props;
        return (
            
            <div className="slick-arrow2 product-right-slick" onClick={()=>{onClick(1)}}>
                <div className="caret">
                    <a> <i class="fa fa-caret-up" aria-hidden="true"></i></a>
                </div>


            </div>
        );
    }
}

export default SamplepreviousArrow;

