import Api from "./index";
import {getUserToken} from "../utils/storageUtils";

export const getaboutUs_api = (data) => {
    var data = {...data}
    return Api(`/getContent`, data, "POST",'');
};
export const faqList_api = (data) => {
    const token = getUserToken();
    return Api(`/faqList`, data, "POST", token);
};
export const Subscribeusers_api = (data) => {
    // const token = getUserToken();
    return Api(`/subscribeusers`, data, "POST", '');
};
export const video_help_api = (data) => {
    return Api(`/getTutorial`, null, "GET", "");
};

export const Askme_api = (data) => {
    const token = getUserToken();
    return Api(`/askUs`, data, "POST", token);
};

export const testimonial_list_api = (data) => {
    const token = getUserToken();
    return Api(`/getTestimonial`, data, "POST", token);

};

