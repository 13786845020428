import React, { Component } from 'react'
import { getHomeDashboardModules, getHomePageBrands, getHomeSliders, getoffers_home, getservicehomepage } from "../../../actions/index";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import {
    getOffersList_api, getservicehomepage_api
} from "../../../api/home";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Servicecategory from "../../features/theme/servicecategory";
import categories from "../../features/theme/categories";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ServiceHomePageComp1 from '../../pages/ServiceHomePageComp1';
import ServiceHomePageComp2 from '../../pages/ServiceHomePageComp2';
import ServiceHomePageComp3 from '../../pages/ServiceHomePageComp3';
import serchIcon from "../../../assets/images/search_icon.png"
import {  getSearchSuggestionssp_api } from '../../../api/product';


class ServiceProviderMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboardData: {},
            isLoading: false,
            searchSuggestion: [],
            activeOption: -1,
            showOptions: false

        };
    }


    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        // get all home page sliders & brands
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(this.handleGeoLocation, this.handleGeoLocationError);
          } else {
            console.log("Geolocation is not supported in this browser.");
            localStorage.removeItem("pincode")
          }
        this.props.getservicehomepage();
        this.getSlider()
    }

    handleGeoLocation = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
    
        this.reverseGeocode(latitude, longitude);
      }
    
      handleGeoLocationError = (error) => {
        console.error("Error getting geolocation: " + error.message);
      }
    getofferlist = async () => {

        try {
            this.setState({ loaderForPlaceOrder: true });
            let apiParams = {
                language_id: 3
            };
            
            // console.log(apiParams);
            let offerlist = await getOffersList_api(apiParams);
            if (offerlist.success) {

            } else {
                toast.error(offerlist.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

 
      
     reverseGeocode = (latitude, longitude)  => {
        // You would typically use a service or library for reverse geocoding.
        // Here's a simplified example using the OpenCage Geocoder API.
        const apiKey = "YOUR_API_KEY"; // Get your API key from OpenCage or another geocoding service.
        const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${"9c495ce19a6b4bd1b6ba8a5adace0248"}`;
        
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            console.log("user", data)
            const pincode = data.results[0].components.postcode; // Assuming the pincode is available in the response
            console.log("User's Pincode: " + pincode);
            localStorage.setItem("pincode", pincode)
          })
          .catch(error => {
            console.error("Error in reverse geocoding: " + error);
          });
      };
    getSlider = async () => {

        try {
            this.setState({ loaderForPlaceOrder: true, isLoading: true });
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let offerlist = await getservicehomepage_api(apiParams);
            this.setState({ dashboardData: offerlist.result.data })
            console.log(offerlist, "service data")

        } catch (error) {
            console.log(error);
            toast.error(error.message);
        } finally {
            this.setState({ isLoading: false });
        }

    };

    onChange = async (e) => {
        if (e.currentTarget.value.length > 0) {
            let apiParams = {
                search: e.currentTarget.value,
                cat_id: this.state.cat_id
            };
            const list = await getSearchSuggestionssp_api(apiParams);
            console.log(list, "search")
            var options = [];

            if (list.status) {
                this.setState({ searchSuggestion: list.result.data })
            } else {
                this.setState({ searchSuggestion: [] })
            }

            if (list.status) {
                if ((list && list.data && list.data.suggest && list.data.suggest.product && list.data.suggest.product.length > 0)) {
                    options = [...list.data.suggest.product[0].options]
                } else {
                    options = []
                }
                this.setState({
                    activeOption: -1,
                    filteredOptions: options,
                    showOptions: true,

                });
            }
        }
        else {
            this.setState({ showOptions: false });
        }


    };


    render() {
        const { dashboardModulesnew } = this.props;
        const { service } = this.props.service;
        const { dashboardData, showOptions } = this.state


        let url;
        const settings = {
            dots: true,
            autoplay: true,
            infinite: true,
            lazyLoad: true,
            speed: 3000,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        if (this.state.isLoading) {
            return <div className="row w-100">
                <div
                    className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found">
                    <div className="loading-cls" />
                </div>
            </div>
        }
        return (
            <>{
                dashboardData.slider ?
                    <div>
                        {/*Home Slider*/}
                        <section className='p-0 '>
                            <div className='banner_wrapper '>
                                <div>
                                    <div className="banner_txt seatch_div">SERVICE ON-DEMAND</div>
                                    <div className="input-group banner-input-group">
                                        <input type="text" className="form-control banner-input" onChange={this.onChange} placeholder="Search Here…" onBlur={() => {
                                            setTimeout(() => {
                                                this.setState({ showOptions: false })
                                                this.setState({
                                                    searchSuggestion: []
                                                })
                                            }, 500)
                                        }
                                        } aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2"> <img src={serchIcon} alt="serchIcon" /></span>
                                        </div>


                                        {
                                            this.state.searchSuggestion.length === 0 && showOptions ?
                                                <>
                                                    <div className='searchlist mt-3 w-100'>
                                                        <p>Service not available</p>
                                                    </div>
                                                </>
                                                : <></>
                                        }
                                        {this.state.searchSuggestion.length > 0 && true ?
                                            <>
                                                <div className='searchlist mt-3 w-100'>
                                                    {
                                                        this.state.searchSuggestion.slice(0, (10)).map((item, index) => {
                                                            let url = item.parent_id ? `/service-providertype/${item.id}` : `/sub-services/` + item.service_name + `/` + item.speciality_id + `/${item.id}`
                                                            return (
                                                                <>
                                                                    <p><Link onClick={() => {
                                                                        this.setState({ searchSuggestion: [] });
                                                                    }} to={url} className='list-item' key={index}>{item.service_name}</Link></p>
                                                                </>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </div>
                                            </>
                                            : <> </>}
                                    </div>

                                </div>
                            </div>
                        </section>
                 
                        {/*Home Section End*/}

                        <section>
                            <Servicecategory />
                        </section>

                        <section>
                        <Slider className='slide-1 home-slider' {...settings}>
                                {dashboardData.slider.map((slider, i) => {
                                    // if (slider.offer_id !== 0 && slider.category_id === 0)
                                    //     url =
                                    //         `${process.env.PUBLIC_URL}/offer/` +
                                    //         slider.offer_id +
                                    //         `/products`;
                                    // else if (slider.category_id !== 0) {
                                    //     url = `category/${slider.category_id}/products`;
                                    // } else {
                                    // }
                                    if (slider.linked_too === "speciality") {
                                        url = `/services/${slider.name}/${slider.speciality_id}/0`;
                                    } else if(slider.linked_too === "subservice") {
                                        url = `service-providertype/${slider.service_or_speciality.id}`
                                    } else if(slider.linked_too === "service") {
                                        url = `/sub-services/${slider.name}/${slider.service_or_speciality.speciality_id}/${slider.service_or_speciality.id}`

                                    }
                                    return (
                                        <div key={i}>
                                            <Link to={url}>
                                                <div
                                                    className='home text-center'
                                                    style={{
                                                        backgroundImage: `url(${slider.image_url})`,
                                                    }}
                                                >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='slider-contain'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </section> 
                        
                        {/* <section> */}
                        {/* <div className='banner-jobsec'> */}
                        {/* <div className=''>
                                    <h1 className='jobsec-txt'>FROM SMALL TOUCH-UPS TO FULL <br />
                                        PROPERTY REFURBISHMENT</h1>
                                        <h4 className="job-sm-txt">We Make a Neat Job of It All! </h4>
                                </div> */}
                        {/* </div> */}
                        {/* <ServiceHomePageComp1 servicelist={dashboardData.sp_dashboard} /> */}
                        {/* </section> */}
                        {/* Section 1 Component */}
                        <ServiceHomePageComp1 servicelist={dashboardData.sp_dashboard} position={"Top"} />

                                    
                        <section className='p-0 mt-2 '>
                            <Slider className='slide-1 home-slider' {...settings}>
                                {dashboardData.banner.filter((item) => item.position == 1).map((item, i) => {

                                    let url;
                                    if(item.linked_too === "subservice") {
                                        url = `service-providertype/${item.sub_service_id}`
                                    }else if(item.linked_too === "service") {
                                        url = `/sub-services/${item.data.service_name}/${item.data.speciality_id}/${item.data.id}`
                                    } else if(item.linked_too === "speciality") {
                                        url = `/services/${item.data.name}/${item.speciality_id}/0`
                                    }
                                    return (
                                        <div key={i}>
                                            <Link to={url}>
                                                <div
                                                    className='home text-center'
                                                    style={{
                                                        backgroundImage: `url(${item.banner_image_url})`,
                                                    }}
                                                >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='slider-contain'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </section>
                        {/* <div className='banner-jobsec'></div> */}
                        <ServiceHomePageComp1 servicelist={dashboardData.sp_dashboard} position={"Center"} />
                        <section className='p-0 '>
                            <Slider className='slide-1 home-slider' {...settings}>
                                {dashboardData.banner.filter((item) => item.position == 2).map((item, i) => {

                                    let url;
                                    if(item.linked_too === "subservice") {
                                        url = `service-providertype/${item.sub_service_id}`
                                    }else if(item.linked_too === "service") {
                                        url = `/sub-services/${item.data.service_name}/${item.speciality_id}/${item.data.id}`
                                    } else if(item.linked_too === "speciality") {
                                        url = `/services/${item.data.name}/${item.speciality_id}/0`
                                    }
                                    return (
                                        <div key={i}>
                                            <Link to={url}>
                                                <div
                                                    className='home text-center'
                                                    style={{
                                                        backgroundImage: `url(${item.banner_image_url})`,
                                                    }}
                                                >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='slider-contain'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </section>
                        <ServiceHomePageComp1 servicelist={dashboardData.sp_dashboard} position={"Bottom"} />
              
                        <section className='p-0 '>
                            <Slider className='slide-1 home-slider' {...settings}>
                                {dashboardData.banner.filter((item) => item.position == 3).map((item, i) => {

                                    let url;
                                    if(item.linked_too === "subservice") {
                                        url = `service-providertype/${item.sub_service_id}`
                                    }else if(item.linked_too === "service") {
                                        url = `/sub-services/${item.data.service_name}/${item.data.speciality_id}/${item.data.id}`
                                    } else if(item.linked_too === "speciality") {
                                        url = `/services/${item.data.name}/${item.speciality_id}/0`
                                    }
                                    return (
                                        <div key={i}>
                                            <Link to={url}>
                                                <div
                                                    className='home text-center'
                                                    style={{
                                                        backgroundImage: `url(${item.banner_image_url})`,
                                                    }}
                                                >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='slider-contain'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </section>

                        {/* END Section 1 Component */}



                    </div> : ""
            }</>
        );
    }
}

const mapStateToProps = (state) => ({
    service: state.service
});

export default connect(
    mapStateToProps,
    {
        getservicehomepage
    }
)(ServiceProviderMain);
