import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux"
class TopBar extends Component {
  render() {
    const { translate } = this.props;
    const { match,store_name,store_phone } = this.props;
    const {cartList} = this.props
    return (
        <div className="top-header">
          <div className="container-fluid ">
            <div className="row mx-3">
              <div className="col-lg-6">
                <div className="header-contact">
                  <ul>
                    <li>
                      Welcome to Our <strong>Store {store_name}</strong>
                    </li>
                    <li>
                      <i className="fa fa-phone" aria-hidden="true" />
                      {translate("call_us")} <strong>{store_phone}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 text-right">
                <ul className="header-dropdown nav-css-menu">
                  {/* <li className="mobile-wishlist compare-mobile"><Link to={`${process.env.PUBLIC_URL}/compare`}><i className="fa fa-random" aria-hidden="true"></i>{translate('compare')}</Link></li> */}

                  {/* <li className="mobile-wishlist1">
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                      <i className="fa fa-home " aria-hidden="true" />
                     Home
                    </Link>
                  </li> */}

                  {/* <li className="mobile-wishlist2 ">
                    <Link to={`${process.env.PUBLIC_URL}/categories-list`}>
                      <i className="fa fa-th-large " aria-hidden="true" />
                      Categories
                    </Link>
                  </li> */}


                  {/* <li className="mobile-wishlist3">
                    <Link to={`${process.env.PUBLIC_URL}/cart`}>
                      <i className="fa fa-shopping-cart " aria-hidden="true" />
                      Cart
                    </Link>
                  </li> */}

                  <li className="mobile-wishlist4   ">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/neworders`}>
                      <i className="fa fa-list " aria-hidden="true" />
                      <span>Orders</span>
                    </Link>
                  </li>

                  <li className="mobile-wishlist hide-breadcrumbs">
                    <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                      <i className="fa fa-heart " aria-hidden="true" />
                      <span>Wishlist</span>
                    </Link>
                  </li>

                  <li className="onhover-dropdown mobile-wishlist5">
                    <i className="fa fa-user " aria-hidden="true" />{" "}
                    <span>Profile</span>
                    <ul className="onhover-show-div">
                      {this.props.auth.isLoggedIn ? (
                          <>
                            <li>
                              <Link
                                  to={`${process.env.PUBLIC_URL}/dashboard/account`}
                                  data-lng="en"
                              >
                                Dashboard
                              </Link>
                            </li>


                            <li
                                // className={
                                //   match.path === "/dashboard/profile/edit" ? "" : "active"
                                // }
                            >
                              <Link to={`${process.env.PUBLIC_URL}/dashboard/profile/edit`}>
                                 Profile
                              </Link>
                            </li>
                            <li
                                // className={match.path === "/dashboard/address" ? "" : "active"}
                            >
                              <Link to={`${process.env.PUBLIC_URL}/dashboard/address`}>
                                Address
                              </Link>
                            </li>
                            <li>
                            <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                              {translate("wishlist")}
                            </Link>
                            </li>
                            <li>
                            <Link to={`${process.env.PUBLIC_URL}/create-giftcard`}>
                              {/* {translate("Create GiftCard")} */}
                              {"Create GiftCard"}
                            </Link>
                            </li>
                            <li
                                // className={match.path === "/dashboard/orders" ? "" : "active"}
                            >
                              <Link to={`${process.env.PUBLIC_URL}/dashboard/neworders`}>
                                My Orders
                              </Link>
                            </li>


                            <li>
                          <span
                              style={{
                                color: "#333333",
                              }}
                              onClick={() => this.props.handleLogout()}
                          > Logout
                          </span>
                            </li>
                          </>
                      ) : (
                          <>
                            <li>
                              <Link
                                  to={`${process.env.PUBLIC_URL}/login`}
                                  data-lng="en"
                              > Login
                              </Link>
                            </li>
                            <li>
                              <Link
                                  to={`${process.env.PUBLIC_URL}/register`}
                                  data-lng="en"
                              > Register
                              </Link>
                            </li>
                          </>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartList: state.cartList.cart
})
export default connect(mapStateToProps) (withTranslate(TopBar)); 
