import {
  SET_USER_SIGNUP_SUCCESS,
  LET_USER_LOGOUT,
} from "../constants/ActionTypes";

const initialState = {
  userData: JSON.parse(localStorage.getItem("userData")),
  isLoggedIn: localStorage.getItem("token") ? true : false,
};

// console.log("initialState", localStorage.getItem("cartlist"))

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SIGNUP_SUCCESS:
      return { ...state, userData: action.userData, isLoggedIn: true };
    case LET_USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
export default authReducer;
