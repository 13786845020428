import React, { Component } from "react";
import { Link } from "react-router-dom";

class Stars extends Component {
  render() {
    const { total, active } = this.props;
    let starArray = [];
    for(let i=0; i<total; i++) {
      starArray.push('');
    }
    return (
        <div className="d-inline-block">
        {
          starArray.map((item, index) => {
              if(index < active) {
                return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#FF8C02"> 
                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              } else {
                return   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#606060"> 
                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              }
          })
          }
        </div>
    );
  }
}

export default Stars;
