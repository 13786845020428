import React, { Component } from "react";
import Geosuggest from "react-geosuggest";

class EditAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      country_code: "+91",
      mobile_no: "",
      user_name: "",
      supervision_country_code: "+91",
      supervision_name: "",
      supervision_mobile_no: "",
      landmark: "",
      address_latitude: "",
      address_longitude: "",
      address_1: "",
      address_2: "",
      city_name: "",
      state_id: "",
      country_id: "",
      pin_code: "",
      is_default: 1,
      address_type: 1,
      isDataLoaded: false,
      flat_no: "",
      gst_number:"",
      company_name:"",
    };
  }

  componentDidMount() {
    if (this.props.editAddress) {
      this.setEditData();
      console.log(this.props.editAddress);
    }
  }

  setEditData = () => {
    this.setState((prevState) => ({
      ...prevState,
      ...this.props.editAddress,
      isDataLoaded: true,
    }));
  };

  handleCountryChange = (e) => {
    this.setState({
      country_code: e.target.value,
    });
  };

  handleSuperVisorCountryChange = (e) => {
    this.setState({
      supervision_country_code: e.target.value,
    });
  };

  getLanLngForAddress = (e) => {
    if (e !== undefined) {
      let results = e.gmaps.hasOwnProperty("address_components");

      if (results) {
        var city = "";
        var state = "";
        var country = "";
        var postal_code = "";
        var address_2 = "";

        let results = e.gmaps.address_components;

        // console.log(results);

        for (var i = 0; i < results.length; i++) {
          if (results[i].types[0] === "country") {
            country = results[i].long_name;
          }
          if (results[i].types[0] === "administrative_area_level_1") {
            state = results[i].long_name;
          }
          if (results[i].types[0] === "locality") {
            city = results[i].long_name;
          }
          if (results[i].types[0] === "postal_code") {
            postal_code = results[i].long_name;
          }
          if (results[i].types[0] === "sublocality_level_1") {
            address_2 = results[i].long_name;
          }
        }

        this.setState({
          address_latitude: e.location.lat.toString(),
          address_longitude: e.location.lng.toString(),
          address_1: e.label,
          address_2: address_2,
          city_name: city,
          state_id: state,
          country_id: country,
          pin_code: postal_code,
        });
      }
    } else {
      this.setState({
        city_name: "",
        state_id: "",
        country_id: "",
        pin_code: "",
      });
    }
  };

  handleEditAddress = (e) => {
    e.preventDefault();
    // Edit address api
    this.props.editAddressApi(this.state);
  };

  render() {
    const {
      mobile_no,
      user_name,
      supervision_name,
      supervision_mobile_no,
      landmark,
      country_id,
      state_id,
      city_name,
      address_1,
      address_2,
      pin_code,
      address_type,
      address_longitude,
      address_latitude,
      flat_no,
      gst_number,
      company_name,

    } = this.state;
    const { loaderForAddress } = this.props;
    return (
      <div className="col-sm-12">
        <div className="dashboard-right register-page">
          <div className="theme-card border-0">
            <form className="theme-form">
              <div className="form-row">
              <div className="col-md-2">
                                    <label htmlFor="fname">
                                    Flat/Bunlows/Plot No.<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fname"
                                        placeholder="Flat No."
                                        value={flat_no}
                                        required=""
                                        onChange={(e) => {
                                            this.setState({
                                                flat_no: e.target.value,
                                            });
                                        }}
                                    />

                                </div>
                <div className="col-md-10">
                  <label htmlFor="building">
                    Address
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  {(address_longitude == "" && address_latitude =="" && address_1.length > 0)?<span className="text-danger"> enter valid address</span>:""}
                  <Geosuggest
                    placeholder={"Enter address"}
                    initialValue={address_1}
                    onSuggestSelect={this.getLanLngForAddress}
                    onChange={(e) => {
                      this.setState({
                        address_1: e,
                      });
                    }}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="fname">
                    Full Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    placeholder="First Name"
                    value={user_name}
                    required=""
                    onChange={(e) => {
                      this.setState({
                        user_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Phone Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobileNo"
                      placeholder="Enter phone number"
                      value={mobile_no}
                      maxLength="10"
                      required
                      onChange={(e) =>
                        this.setState({
                          mobile_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                                    <label htmlFor="company_name">
                                        Company Name (Optional)
                                        {/* <span style={{color: "red"}}>*</span> */}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company_name"
                                        placeholder="Company Name"
                                        value={company_name}
                                        required=""
                                        onChange={(e) =>
                                            this.setState({company_name: e.target.value})
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="gst_number">
                                            Gst Number (Optional)
                                            {/* <span style={{color: "red"}}>*</span> */}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="gst_number"
                                            placeholder="Enter GST Number"
                                            value={gst_number}
                                            required
                                            onChange={(e) =>
                                                this.setState({
                                                    gst_number: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="superVisorName">
                    Supervisor Name
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="superVisorName"
                    placeholder="Supervisor Name"
                    value={supervision_name}
                    required=""
                    onChange={(e) =>
                      this.setState({ supervision_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="mobileNo">
                      Supervisor Phone Number
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobileNo"
                      placeholder="Enter phone number"
                      value={supervision_mobile_no}
                      maxLength="10"
                      required
                      onChange={(e) =>
                        this.setState({
                          supervision_mobile_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="locality">
                    Colony/Street/Locality
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="locality"
                    placeholder="Colony/Street/Locality"
                    value={address_2}
                    required=""
                    onChange={(e) =>
                      this.setState({ address_2: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="landmark">
                    Landmark
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="landmark"
                    placeholder="Landmark"
                    value={landmark}
                    required=""
                    onChange={(e) =>
                      this.setState({ landmark: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="country">
                    Country<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    placeholder="Country"
                    value={country_id}
                    required=""
                    readOnly
                    onChange={(e) =>
                      this.setState({ country_id: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="state">
                    State
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="State"
                    value={state_id}
                    required=""
                    readOnly
                    onChange={(e) =>
                      this.setState({ state_id: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="city">
                    City
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="City"
                    value={city_name}
                    required=""
                    readOnly
                    onChange={(e) =>
                      this.setState({ city_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="postalCode">
                    Postal Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    placeholder="Postal Code"
                    value={pin_code}
                    required=""
                    onChange={(e) =>
                      this.setState({ pin_code: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6" style={{lineHeight: '40px'}}>
                  <button type="button" onClick={(e) => {
                    this.setState({
                      address_type: parseInt(1),
                    });
                  }}
                          className={ address_type ==1 ?"btn text-white bg-primary-new mr-1 w-48":"btn mr-1 w-48"} style={{borderRadius: "15px",width:100}}> Home
                  </button>
                  <button type="button" onClick={(e) => {
                    this.setState({
                      address_type: parseInt(2),
                    });
                  }}
                          className={ address_type ==2 ?"btn text-white bg-primary-new mr-1 w-49":"btn  mr-1 w-49"} style={{borderRadius: "15px",width:100}}>Office
                  </button>
                  <button type="button" onClick={(e) => {
                    this.setState({
                      address_type: parseInt(3),
                    });
                  }}
                          className={ address_type ==3 ?"btn text-white  bg-primary-new mr-1 address-w-100":"btn mr-1 address-w-100"} style={{borderRadius: "15px",width:100}}> Site
                  </button>
                </div>
                {this.state.isDataLoaded && (
                  <div className="col-md-6">
                    <label htmlFor="isDefault">&nbsp;</label>
                    <br />
                    <input
                      type="checkbox"
                      id="isDefault"
                      className="mt-3"
                      // defaultChecked={
                      //   parseInt(this.state.is_default) === 1 ? true : false
                      // }
                      checked={
                        parseInt(this.state.is_default) === 1 ? true : false
                      }
                      onChange={(e) =>
                        this.setState({ is_default: e.target.checked ? 1 : 0 })
                      }
                    />
                    &nbsp; Set As Default Address
                  </div>
                )}
              </div>
              <div>
                <button
                  className="btn btn-solid"
                  onClick={(e) => this.handleEditAddress(e)}
                  disabled={
                    loaderForAddress ||
                    mobile_no.length < 9 ||
                    user_name.length === 0 ||
                    // supervision_name.length === 0 ||
                    // supervision_mobile_no.length < 9 ||
                    landmark.length === 0 ||
                    country_id.length === 0 ||
                    state_id.length === 0 ||
                    city_name.length === 0 ||
                    address_1.length === 0 ||
                    address_2.length === 0 ||
                    address_latitude.length === 0 ||
                    address_latitude.length === 0 ||
                    pin_code.length === 0
                  }
                >
                  EDIT NOW &nbsp;{" "}
                  {this.props.loaderForAddress && (
                    <i className="fa fa-spinner ml-3 fa-pulse fa-2x" />
                  )}
                </button>
                <button
                  className="btn btn-solid ml-3"
                  onClick={(e) => this.props.showEditPage(e)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAddress;
