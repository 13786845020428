import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../services";
import ProductListItem from "./product-list-item";
import ProductBox from "../../layouts/common/ProductBox";

class DashboardProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = { limit: 10, hasMoreItems: true, page: 1 };
  }

  // componentWillMount() {
  //   this.fetchMoreItems();
  // }

  fetchMoreItems = () => {
    /*if (this.state.limit >= this.props.dashboardProducts.length) {
      this.setState({ hasMoreItems: false });
      return;
    }*/
    // a fake async api call
    setTimeout(() => {
      this.setState({
        limit: this.state.limit + 10,
      });
    }, 500);
  };

  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      dashboardProducts,
      fetchDashboardProductLoader,
      productList
    } = this.props;
      
    console.log(this.props,"this.props")

    return (
      <div className={(productList.length == 0 ) ? "col-12 text-center":"col-12 psm0"}>

        <div className="product-wrapper-grid">
          <div className="container-fluid psm0" id="listing">

            {(productList.length > 0 ) ? (
                    <InfiniteScroll
                        dataLength={this.state.limit}
                        next={()=>{
                          this.props.loadMore();
                          this.fetchMoreItems()
                        }}
                        hasMore={this.state.hasMoreItems}
                        loader={""}
                    >
                      <div className="row">
                        <div className="col-12">
                          {this.props.gridView ? 
                          <div className="d-flex flex-wrap w-100">
                            {productList.map((product, index) => (
                                    <div
                                        className="setproducts"
                                      /*className={`${
                              this.props.colSize === 3
                              ? "col-xl-3 col-md-6 col-grid-box"
                              : "col-lg-" + this.props.colSize
                            }`}*/
                              key={index}
                              >
                              <ProductBox
                              gridView={this.props.gridView}
                              product={product}
                              symbol={symbol}
                              onAddToCompareClicked={() => addToCompare(product)}
                              onAddToWishlistClicked={() => addToWishlist(product)}
                              onAddToCartClicked={addToCart}
                              key={index}
                              auth={this.props.auth}
                              isAdjustImageBox={true}
                              />
                              </div>
                              ))}
                          </div>
                          : 
                          <div className="d-flex flex-wrap w-100">
                            {productList.map((product, index) => {
                              console.log(product,"product");
                              return(
                              <div className="setproducts">
                                <div className="" key={index}>
                                    <ProductBox
                                      gridView={this.props.gridView}
                                      product={product}
                                      symbol={symbol}
                                      onAddToCompareClicked={() => addToCompare(product)}
                                      onAddToWishlistClicked={() => addToWishlist(product)}
                                      onAddToCartClicked={addToCart}
                                      key={index}
                                      auth={this.props.auth}
                                      isAdjustImageBox={true}
                                      />
                                </div>
                              </div>
                            )})}
                          </div>
                            }
                        </div>
                      </div>

                    </InfiniteScroll>



            ) : (
              fetchDashboardProductLoader === "noData" && (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/empty-search.jpg`}
                      className="img-fluid mb-4"
                    />
                    <h3>
                      Sorry! Couldn't find the product you were looking For!!!{" "}
                    </h3>
                    <p>
                      Please check if you have misspelt something or try
                      searching with other words.
                    </p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      className="btn btn-solid btnMt"
                    >
                      continue shopping
                    </Link>
                  </div>
                </div>
              )
            )}

          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  productList: state.data.dashboardProducts,
  symbol: state.data.symbol,
  dashboardProducts: state.data.dashboardProducts,
  fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare }
)(DashboardProductListing);
