import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {video_help_api} from "../../api/pages";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class HelpAndSupport extends Component {


    render() {
        const {store_address,store_phone,store_email,store_name,instagram_link,youtube_link,twitter_link,facebook_link,footer_text} = this.props.settings;
        return (
            <div>
                <Breadcrumb title={'Help And Support'}/>


                {/*Dashboard section*/}
                <section className="faq-section section-b-space help_suppoer">
                    <div className="container">
                        <div className="row">

                                <div className="col-sm-12 col-md-6 col-lg-6 mt-1">
                                    <div className="hs_icone borders_set m-md-3">
                                        <i className="fa fa-phone fa-5x mt-5"></i>
                                        <p className="hs_title">Contact Us</p>
                                        <hr width="40%"/>
                                        <p className="details-hs" >{store_phone}</p>
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 mt-1">
                                    <div className="hs_icone borders_set m-md-3">
                                        <i className="fa fa-envelope-o fa-5x mt-5"></i>
                                        <p className="hs_title">Email</p>
                                        <hr width="40%"/>
                                        <a href={"mailto:"+store_email}>
                                        <p className="details-hs">{store_email}</p>
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="col-sm-12 col-md-6 col-lg-6 offset-md-3 mt-1">
                                    <div className="hs_icone borders_set m-md-3">
                                        <i className="fa fa-comments-o fa-5x mt-5"></i>
                                        <p className="hs_title">Chat</p>
                                        <hr width="40%"/>
                                        <p className="details-hs">Have a question?</p>
                                        <p className="details-hs">Chat Here with our executive</p>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    settings : state.settings,
});

export default connect(
    mapStateToProps,
    {}
)(HelpAndSupport);