
import React from 'react';
import OverlayTxt from './layout/OverlayTxt'

const BannerTxt = () => {
  return (
    <div className="container-fluid py-4 overlay slider-contain">
      <table className="bnrtxt p-4">
        <tr  >
            <td className=" bnrhvr"  >
              <OverlayTxt imgsrc="./banner/1.png"/> 
            </td>
            <td className="p-2 bnrhvr" id="spn2" rowSpan="2">
              <OverlayTxt  imgsrc="./banner/3.png" />
            </td>
            <td className=" bnrhvr">
              <OverlayTxt  imgsrc="./banner/2.png"/>
            </td>
        </tr>
        <tr>
          <td className=" bnrhvr">
            <OverlayTxt  imgsrc="./banner/4.png"/>
            </td>
            <td className=" bnrhvr">
            <OverlayTxt  imgsrc="./banner/5.png"/>
            </td>
        </tr>
      </table>
    </div>
  );
}

export default BannerTxt;