import React, {Component} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom'
import ProductBox from "../common/ProductBox";
import ReactStars from "react-rating-stars-component";
import {addToCart_api, addToWishlist_api} from "../../../api/product";
import {toast} from "react-toastify";


class MultiSliderItem extends Component {
    render() {
        const {
            items,
            symbol,
            NoOfProducts,
            onAddToCartClicked,
            onAddToWishlistClicked,
            onAddToCompareClicked
        } = this.props;
      const   addToCartApi = async (item, qty) => {
            if (this.props.auth.isLoggedIn) {
                try {
                    this.setState({ addToCartApiCalled: true });
                    let apiParams = {
                        product_id: item.id,
                        user_id: this.props.auth.userData.id,
                        quantity: qty,
                        option_val: [],
                        is_retail: this.declareOrderType(item, qty) === "Retail" ? 1 : 2,
                    };
                    let cartData = await addToCart_api(apiParams);
                    this.setState({ addToCartApiCalled: false });
                    // console.log(cartData);
                    if (cartData.success) {
                        this.props.onAddToCartClicked(item, qty);
                    } else {
                        toast.error(cartData.message);
                    }
                } catch (error) {
                    this.setState({ addToCartApiCalled: false });
                    console.log(error);
                    toast.error(error.message);
                }
            } else {
                this.props.onAddToCartClicked(item, qty);
            }
        };

      const  addToWishList = async (item) => {
            if (this.props.auth.isLoggedIn) {
                try {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id")
                            ? item.product_id
                            : item.id,
                    };
                    let apiData = await addToWishlist_api(apiParams);
                    console.log(apiData);
                    if (apiData.success) {
                        this.props.onAddToWishlistClicked();
                    } else {
                        toast.error(apiData.message);
                    }
                } catch (error) {
                    console.log(error);
                    toast.error(error.message);
                }
            } else {
                toast.info("Please login to continue");
            }
        };

        const  addToCart = (item, qty) => {
            let data = JSON.parse(JSON.stringify(item));
            if (data.product_option && data.product_option.length > 0) {
                let requiredOptions = data.product_option
                    .filter((opt) => opt.required == 1)
                    .filter((abc) => abc.selected_options.length === 0);
                if (requiredOptions.length > 0) {
                    toast.error("Please select the mandatory options");
                } else {
                    this.addToCartApi(item, qty);
                }
            } else {
                this.addToCartApi(item, qty);
            }
        };
        return (
            <div className="offer-slider slide-1">


                {
                    (items && items.product_section && items.product_section.length > 0) ? items.product_section.map((dashboardModule, i) => {
                        return dashboardModule.dashboard_id == (this.props.list_id ? this.props.list_id : 6) && dashboardModule.products && dashboardModule.products.length > 0 ?
                            (
                                dashboardModule.products.map((product, i) => (
                                        <div key={i} >

                                            <div key={i} id="specialMedia" className="media">
                                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`}>
                                                    <img className="img-fluids"
                                                         onError={this.addDefaultSrc}
                                                         src={product.product_image}
                                                         alt=''
                                                    />
                                                </Link>
                                                <div className="media-body align-self-center">

                                                    <ReactStars
                                                        value={product.avg_rate}
                                                        count={5}
                                                        size={14}
                                                        activeColor="#ffd700"
                                                        edit={false}
                                                    />

                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`}>
                                                        <h6>{product.product_name}</h6></Link>


                                                    <h4>
                                                        {symbol}
                                                        {product.special_price !== ""
                                                            ? product.special_price
                                                            : product.product_price}
                                                        &nbsp;
                                                        {/* {product.price-(product.price*product.discount/100)} */}
                                                        {product.special_price !== "" && (
                                                            <del>
                  <span className="money">
                    {symbol}
                      {product.product_price}

                  </span>
                                                            </del>
                                                        )}
                                                    </h4>

                                                    <hr id="sphr"/>
                                                    <div className="cart-info" id="specialBox">
                                                        {/*<a title="Add to cart" onClick={()=>{addToCart(product,1)}}>
                                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                                        </a>
                                                        <a href="javascript:void(0)" title="Add to Wishlist"
                                                           onClick={() => addToWishList(product)}>
                                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                                        </a>*/}
                                                        {/*<a
                                                            href="javascript:void(0)"
                                                            data-toggle="modal"
                                                            data-target="#quick-view"
                                                            title="Quick View"
                                                            onClick={this.onOpenModal}
                                                        ><i className="fa fa-search" aria-hidden="true"></i></a>*/}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )) : ""
                    }) : ""

                }


                {

                }
            </div>
        )
    }

}

export default MultiSliderItem;
