import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getBestSeller } from "../../services";
import { getLatestProducts_api } from "../../api/home";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";

class DashboardNewProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderForNewProductsData: false,
      products: [],
    };
  }

  componentDidMount() {
    this.setNewData();
  }

  setNewData = async () => {
    try {
      this.setState({ loaderForNewProductsData: true });
      const apiData = await getLatestProducts_api();
      // console.log(apiData);
      this.setState({
        loaderForNewProductsData: false,
        products: apiData.data,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForNewProductsData: false });
    }
  };

  render() {
    const { items, symbol } = this.props;

    var arrays = [];
    while (items.length > 0) {
      arrays.push(items.splice(0, 3));
    }

    return (
      <div className="theme-card">
        <h5 className="title-border">new product</h5>
        {this.state.products.length > 0 && (
          <Slider className="offer-slider slide-1">
            <div>
              {this.state.products.map((product, i) => (
                <div className="media" key={i}>
                  <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                    <img
                      className="img-fluid"
                      src={product.product_image}
                      alt=""
                      style={{
                        minWidth: "110px",
                      }}
                    />
                  </Link>
                  <div className="media-body align-self-center">
                    <div className="rating">
                      <ReactStars
                        value={product.avg_rate}
                        count={5}
                        onChange={(rate) => console.log(rate)}
                        size={14}
                        activeColor="#ffd700"
                        edit={false}
                      />
                    </div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                    >
                      <h6>{product.product_name}</h6>
                    </Link>
                    <h4>
                      {symbol}
                      {product.special_price !== ""
                        ? product.special_price
                        : product.product_price}
                      &nbsp;
                      {/* {product.price-(product.price*product.discount/100)} */}
                      {product.special_price !== "" && (
                        <del>
                          <span className="money">
                            {symbol}
                            {product.product_price}
                          </span>
                        </del>
                      )}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </Slider>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    items: getBestSeller(state.data.products),
    symbol: state.data.symbol,
  };
}

export default connect(
  mapStateToProps,
  null
)(DashboardNewProduct);
