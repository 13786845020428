import React, {Component, useEffect, useState} from 'react';
import Slider from "react-slick"

import Breadcrumb from "../../common/breadcrumb";
import {Slider6, Slider4, Slider7} from "../../../services/script"
import axios from 'axios';
import {Link} from 'react-router-dom';

const ElementCategory1 = (props) => {
    const [subCategories, setSubCategories] = useState([])
    const categoryID = props.categoryID
    const  viewlisttitle = props.viewlisttitle


    const getSubCategories = () => {
        axios.get("/getSidebarNLevelCategory")
            .then(function (response) {
                setSubCategories(response.data.data)
            })
            .catch(function (err) {
                console.log(err.data)
            })
    }
    useEffect(() => {
        getSubCategories()
    }, [categoryID])
    return (
        <>
            <div>

                {/* <Breadcrumb parent={'Elements'} title={'Category'}/> */}
                {/*Category One*/}
                <Slider {...Slider6} className='slide-4 category-m no-arrow'>

                    {subCategories && subCategories
                        .filter((categories) => categories.id == categoryID)
                        .map((subCate) =>
                            subCate.subCat.map((val) => (
                                <div className='category-block'>
                                    <Link to={"/category/" + val.id + "/products"}>
                                        <div className='category-image'>
                                            <img
                                                src={val.category_image}
                                                alt=''
                                            />
                                        </div>
                                    </Link>
                                    <div className='category-details'>
                                        <Link to={"/category/" + val.id + "/products"}>
                                            <h5>{val.category_name}</h5>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        )}
                </Slider>
            </div>
        </>
    );
}

export default ElementCategory1

