import React from 'react';
import arrow_pointing_left from '../../../assets/icone/icons8-arrow_pointing_left.png';
const Navigation_bar = (props) => {
    const  viewlisttitle = props.viewlisttitle
    return (
        <>

                <div className="row d-none-mobile_unset text-center shadow-sm py-2 mb-3 bg-grey homepage_navigation_index">
                    <div className="col-1 ">
                        <h6 className="d-inline pointer " onClick={() => window.history.back()}>
                            <br/>
                            <img src={arrow_pointing_left} alt=""/>
                            <br/>

                        </h6>
                    </div>
                    <div className="col-10 ">
                        <br/>
                        <h6 className="h4_title_filtes_mobile  centeredasdasdas">  {viewlisttitle} </h6>
                        <br/>

                    </div>
                </div>
        </>
    );
}

export default Navigation_bar

