import React, { Component } from 'react';


export default class ImageZoom extends Component {
    render() {
        const {image} = this.props;

        return (
            <div className="image_zoom_cls-0">
                <img className="img-fluid" src={`${image}`}   />
            </div>
        );
    }
}