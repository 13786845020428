import React, { Component } from "react";
import Link from "react-router-dom/Link";
import {getSingleOrder_api, setCancelledOrder_api, setReturnOrders_api} from "../../api/product";
import {
  declareProductPrice,
  declareOrderType,
  declareAvailability,
  checkAndRedirectLogin,
} from "../../utils/helper";
import { toast } from "react-toastify";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from "moment";
import Modal from "react-responsive-modal";
import ApiConstants from "../../api/ApiConstants";



class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderForOrderDetail: false,
      orderData: null,
      rangeValue: 0,
      reason_text: "",
      popup: 0,
    };
  }

  componentDidMount() {
    if (this.props.viewOrder) {
      this.getOrderDetail();
    }
  }

  getOrderDetail = async () => {
    try {
      this.setState({loaderForOrderDetail: true});
      let apiParams = {
        user_id: this.props.auth.userData.id,
        id: this.props.viewOrder.order_details_id,
      };
      const address = await getSingleOrder_api(apiParams);
      // console.log(address);
      this.setState({
        loaderForOrderDetail: false,
        orderData: address.data,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({loaderForOrderDetail: false});
    }
  };


  cancelandretunorders = async (e) => {
    try {
      let apiParams = {
        user_id: this.props.auth.userData.id,
        order_detail_id: this.props.viewOrder.order_details_id,
        cancel_reason_text: this.state.reason_text
      };

      if(this.state.popup == 1)
        var rs = await setCancelledOrder_api(apiParams);
      if(this.state.popup == 2)
        var rs = await setReturnOrders_api(apiParams);
      if(this.state.popup == 0){
        this.setState({
          reason_text: "",
          popup:0,
        });
        return false;
      }

      if (rs.success) {
        toast.success(rs.message);
        this.setState({
          reason_text: "",
          popup:0,
        });
      }
      else{
        console.log("asd")
      }
      window.location.reload()

    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({loaderForOrderDetail: false});
    }
  };

  onCloseModal = (id) => {
    this.setState({ popup: id });
  };


 Orderstatus (status){
    if([1,2,3,8,9,10,11,12].includes(parseInt(status))) {
        return <h5 className="btn btn-danger mx-auto w-100" onClick={()=>{this.onCloseModal(1)}} > Cancel Order</h5>
    }
    else if(parseInt(status) == 5 || parseInt(status) == 7 ) {
      return <h5 className="btn btn-danger mx-auto w-100 " onClick={()=>{this.onCloseModal(2)}} > Return Order  </h5>
    }
}

  Orderstatus_text (status,statusnmae){
      return <strong className=""> Order Status: <b className="texrt">{statusnmae}</b>  </strong>
  }

  render() {
    const {loaderForOrderDetail, orderData} = this.state;
    let total = 0;
    let coupon_discount = orderData ? orderData.total_coupon_price : 0;
    let tax_amount = orderData ? orderData.total_tax_price : 0;
    return (
        <>
          <div className="col-xl-3 col-lg-3 col-md-12 ">
            <div className="row border pt-2 min_height_500px_detail">
              {orderData && !loaderForOrderDetail && (
                  <>
              <div className="col-6">
                <b>Order id:</b><br/>
                <strong className="theme_text_set">{orderData.order_id}</strong>
              </div>
              <div className="col-6 mt-2">
                <b>QTY:</b><strong className="border p-1 pl-0 ">{orderData.product_details.order_quantity}</strong>
              </div>
                <div className="col-3 mt-4">
                  <Link to={`${process.env.PUBLIC_URL}/${orderData.product_details.product_slug}/p/${orderData.product_id}`}>
                     <img src={orderData.product_details.product_image} className="img-thumbnail-details"  alt="" />
                  </Link>
                </div>
                <div className="col-9  mt-4">
                  <strong  className="mb-4"><b>{orderData.product_details.product_name}</b></strong><br/>

                  {
                    (orderData && orderData.option_data && orderData.option_data.length > 0) ?

                        orderData.option_data.map((opt, i) => {

                          return <strong className="d-block">
                            {(opt.option_name) ? opt.option_name + ": "+opt.option_value_name : ""}

                          </strong>

                        })
                        : ""
                  }




                  {/*<strong className="">Size: L</strong> <br/>*/}

                  <strong className="">Order Type: <span className={orderData.is_retail == 1 ?"check___Retail":"check___Wholesale"}>{orderData.is_retail == 1 ?"Retail":"Wholesale"}</span></strong>
                  {this.Orderstatus_text(orderData.product_details.order_status_id,orderData.product_details.order_status_name)}
                </div>
                <div className="col-5 mt-4">
                  <h5 className="theme_text_set cp font-weight-bold" onClick={(e) => this.props.showOrderDetailPage(e)} > BACK </h5>
                </div>
              <div className="col-5 mt-4">
                <h5><b>Price:</b><strong className=" p-1 pl-0 theme_text_set"><b>{this.props.symbol}{orderData.total_product_price}</b></strong></h5>
              </div>
                    <div className="col-12">

                    {this.Orderstatus(orderData.product_details.order_status_id)}

                    </div>

                  </>
                )}
            </div>
          </div>

          <div className="col-xl-9 col-lg-9 col-md-12 order-sec">
            {orderData && !loaderForOrderDetail && (
                <>
                  <div>


                    {(orderData.product_detail && orderData.product_detail.length > 0) ? orderData.product_detail.map((item, index) => {
                      total = total + parseInt(item.product_price_with_tax);
                      return (
                          <div key={index} className="row blog-media">
                            <div className="container order-box-sec1">
                              <h3 className="sec-title">Order Details</h3>
                              <div className="row">
                                <div className="col-6">
                                  <span><h4>Order Date</h4></span>
                                  <strong>{moment(item.product_order_time).format(
                                      "YYYY-MM-DD"
                                  )}</strong>
                                </div>
                                <div className="col-6">
                                  <span><h4>Expected Delivery Date</h4></span>
                                  <strong>{moment(item.product_order_time).add(parseInt(10), 'd').format("YYYY-MM-DD")}</strong>
                                </div>
                              </div>
                              <hr/>
                            </div>
                            {(orderData && orderData.product_details && orderData.product_details.slot_details && orderData.product_details.slot_details.length === 0 ) ?
                            <div className="container order-box-sec3">
                              <div className="row">
                                <h3 className="sec-title col-9" >Payment Details</h3>

                                <h4 className=" col-3" id="invoice"
                                    onClick={() => window.open(ApiConstants.BASE_URL+"/sellerorder/generateInvoice/" + orderData.id, "_blank")}>Invoice</h4>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <span><h4 id="sec-title">Payment Method</h4></span>
                                  <span><p id="method-detail"> {orderData.payment_type == 1 ? "COD" : "Online Payment"}</p></span>
                                </div>
                                <div className="col-6">
                                  <span><h4 id="sec-title">Payment Status</h4></span>
                                  <span><p id="status-detail">{item.order_status_name}</p></span>
                                </div>
                              </div>
                              <hr/>
                            </div>:""}

                            <div className="container order-box-sec2">
                              <h3 className="sec-title">Delivery Details</h3>
                              <div className="row">
                                <div className="col-6">
                                  <span><h4 id="sec2-title2">Billing Address</h4></span>
                                  <span><strong>{orderData.billing_user_name}</strong></span><br/>
                                  <p> {orderData.billing_address_1}{" "}</p>
                                  <strong className="sec2-phone">Phone
                                    Number: <b>{orderData.billing_mobile_no}</b></strong>
                                </div>
                                <div className="col-6">
                                  <span><h4 id="sec2-title2">Shipping Address</h4></span>
                                  <span><strong>{orderData.cust_name}</strong></span><br/>
                                  <p>{orderData.address_1}</p>
                                  <strong className="sec2-phone">Phone
                                    Number: <b>{orderData.billing_mobile_no}</b></strong>
                                </div>
                              </div>
                              <hr/>
                            </div>

                            {
                              (orderData && orderData.product_details && orderData.product_details.slot_details && orderData.product_details.slot_details.length >0 ) ?
                                  orderData.product_details.slot_details.map((val,kay)=>{
                                        return <div className="container order-box-sec3">
                                          <div className="row">
                                            <h3 className="sec-title col-9" id="slot">Delivery Slot {kay+1} Details</h3>
                                            <h4 className=" col-3" id="invoice"
                                                onClick={() => window.open(ApiConstants.BASE_URL+"/sellerorder/generateInvoice/" + orderData.id, "_blank")}>Invoice</h4>
                                          </div>
                                          <div className="row">
                                            <div className="col-6">
                                              <span><h4 id="sec2-title2">Payment Method</h4></span>
                                              <span><p
                                                  id="method-detail"> {val.payment_type == 1 ? "COD" : "Online Payment"}</p></span>

                                              <span><h4 id="sec2-title2">Quantity</h4></span>
                                              <span><p id="method-detail">{val.slot_quantity}</p></span><br/>

                                            </div>
                                            <div className="col-6">
                                              <span><h4 id="sec2-title2">Payment Status</h4></span>
                                              <span><p id="status-detail">{val.slotdetails.name}</p></span>

                                              <span><h4 id="sec2-title2">Delivery Date</h4></span>
                                              <span><p id="method-detail">{moment(val.delivery_date).format("YYYY-MM-DD")}</p></span><br/>
                                            </div>
                                            <div className="container order-tracking">
                                              <h3 className="sec-title">Order Tracking</h3><br/>
                                              <RangeSlider    value={1} min={1} max={4} />
                                              <ul className="range-steps">
                                                <li><strong>
                                                  Order Placed
                                                  <p>{moment(val.delivery_date).format("YYYY-MM-DD")}</p>
                                                </strong></li>
                                                <li><strong>
                                                  Order Packed
                                                  <p style={{opacity:0}}>{moment(val.delivery_date).format("YYYY-MM-DD")}</p>
                                                </strong></li>
                                                <li><strong>
                                                  Order Shipped
                                                    <p style={{opacity:0}}>{moment(val.delivery_date).format("YYYY-MM-DD")}</p>

                                                </strong></li>
                                                <li><strong>
                                                  Order Delivered
                                                    <p style={{opacity:0}}>{moment(val.delivery_date).format("YYYY-MM-DD")}</p>

                                                </strong></li>
                                              </ul>
                                            </div>
                                          </div>

                                          <hr/>
                                        </div>
                                      })
                                  :
                                  <div className="container order-box-sec3">
                                    <div className="row">
                                      <div className="container order-tracking">
                                        <h3 className="sec-title">Order Tracking </h3>
                                        <div className="hide-slider">
                                          <br/>
                                          <RangeSlider    value={1} min={1} max={4} />
                                        </div>
                                        <ul className="range-steps">
                                          <li><strong>
                                            Order Placed
                                            <p>{moment(item.product_order_time.delivery_date).format("YYYY-MM-DD")}</p>
                                          </strong></li>
                                          <li><strong>
                                            Order Packed
                                            <p style={{opacity:0}}>{moment(item.product_order_time.delivery_date).format("YYYY-MM-DD")}</p>
                                          </strong></li>
                                          <li><strong>
                                            Order Shipped
                                            <p style={{opacity:0}}>{moment(item.product_order_time.delivery_date).format("YYYY-MM-DD")}</p>

                                          </strong></li>
                                          <li><strong>
                                            Order Delivered
                                            <p style={{opacity:0}}>{moment(item.product_order_time.delivery_date).format("YYYY-MM-DD")}</p>

                                          </strong></li>
                                        </ul>
                                      </div>
                                    </div>

                                    <hr/>
                                  </div>

                            }





                            {/* <div className="col-xl-4">
                      <div className="blog-left">

                        <Link
                          to={`${process.env.PUBLIC_URL}/product/${
                            item.product_id
                          }`}
                        > */}
                            {/* <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/blog/1.jpg`}
                          className="img-fluid"
                          alt=""
                        /> */}
                            {/* <img
                            src={item.product_image}
                            className="img-fluid"
                            alt={item.product_name}
                            style={{
                              maxHeight: "200px",
                            }}
                          /> */}
                            {/* </Link>
                      </div>
                    </div> */}
                            {/* <div className="col-xl-8">
                      <div
                        className="blog-right"
                        style={{
                          alignItems: "unset",
                          justifyContent: "unset",
                        }}
                      >
                        <div>
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/${
                              item.product_id
                            }`}
                          >
                            <h6>{item.product_name}</h6>
                          </Link>
                          <h4> */}
                            {/*{this.props.symbol} {item.product_sp_price}*/}
                            {/* {this.props.symbol} {item.product_price_without_tax}
                          </h4>
                          <ul className="post-social">
                            <li>
                              <i className="fa fa-star" /> {item.avg_rate}
                            </li>
                            <li>
                              Seller Name : {item.seller_details.seller_name}{" "}
                            </li>
                          </ul>
                          <p>
                            Tax amount: {this.props.symbol}
                            {item.product_tax_price}
                          </p>
                          <p className="mt-3">
                            Quantity: {item.order_quantity}
                          </p>
                          <p className="mt-3">
                            Status: {item.order_status_name}
                          </p>
                        </div>
                      </div>
                    </div> */}
                          </div>
                      );
                    }) : ""}
                  </div>
                  <div className="row dashboard">
                    <div className="col-xl-12 box">
                      {/* <div className="box-title">
                  <h3
                    style={{
                      color: "#444444",
                      fontWeight: "700",
                      fontSize: "24PX",
                    }}
                  >
                    Payment information
                  </h3>
                </div> */}
                      {/* <div
                  className="box-content"
                  style={{
                    color: "#777777",
                  }}
                >
                  <span>
                    <strong>Payment Mehod:</strong>{" "}
                    {orderData.payment_type == 1 ? "COD" : "Online Payment"}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    <strong>Billing Address:</strong>{" "}
                    {orderData.billing_address_1}{" "}
                  </span>{" "}
                  <br />
                </div> */}
                    </div>
                    {/* <div className="col-xl-12 box">
                <div className="box-title">
                  <h3
                    style={{
                      color: "#444444",
                      fontWeight: "700",
                      fontSize: "24PX",
                    }}
                  >
                    Delivery address
                  </h3>
                </div>
                <div
                  className="box-content"
                  style={{
                    color: "#777777",
                  }}
                >
                  <span>
                    <strong>Delivery Address:</strong> {orderData.address_1}{" "}
                  </span>{" "}
                  <br />
                </div>
              </div> */}
                    <div className="col-xl-6 box">
                      <div className="">
                        <h3
                            style={{
                              color: "#444444",
                              fontWeight: "700",
                              fontSize: "24PX",
                            }}
                        >
                          Order Summary
                        </h3>
                      </div>
                      <div
                          className="box-content"
                          style={{
                            color: "#777777",
                          }}
                      >
                        <div>
                          <div
                              className="p-2"
                              // style={{ border: "1px solid #ced4da" }}
                          >

                            <table
                                style={{
                                  // tableLayout: "fixed",
                                  width: "100%",
                                }}
                            >
                              <tbody>
                              <tr className="setLineHeight">
                                <th>Grand total excl. taxes</th>
                                <td style={{textAlign: "right"}}>
                                  {this.props.symbol} {parseFloat(orderData.product_price_without_tax) * parseFloat(orderData.product_details.order_quantity)}
                                </td>
                              </tr>
                            {/*  <tr className="setLineHeight">
                                <td>Taxes</td>
                                <td style={{textAlign: "right"}}>
                                  {this.props.symbol} {parseInt(orderData.product_details.product_tax_price)}
                                </td>
                              </tr>*/}

                              <tr className="setLineHeight">
                                <td>Shiping Price</td>
                                <td style={{textAlign: "right"}}>
                                  {this.props.symbol} {orderData.product_ship_price}
                                </td>
                              </tr>

                              {/*<tr className="setLineHeight">
                                <td>SubTotal</td>
                                <td style={{textAlign: "right"}}>
                                  {this.props.symbol} {orderData.product_details.total_product_price}
                                </td>
                              </tr>*/}
                              <tr className="setLineHeight">
                                <td>Coupon Discount</td>
                                <td style={{textAlign: "right"}}>
                                  {this.props.symbol} {coupon_discount}
                                </td>
                              </tr>
                              <tr className="setLineHeight" id="grand-total">
                                <th className="w-50">Grand Total:</th>
                                <td className="w-50" style={{textAlign: "right"}}>
                                  {this.props.symbol}{" "}
                                  {parseFloat(orderData.total_product_price)}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* <div className="payment-box mt-3">
                      <button
                        type="button"
                        className="btn-solid btn"
                        onClick={(e) => this.props.showOrderDetailPage(e)}
                      >
                        BACK
                      </button>
                      {
                        (orderData.order_details_id > 0)?
                      <button
                          type="button"
                          className="btn-solid btn btn-classic mx-2"
                          onClick={()=> window.open(ApiConstants.BASE_URL+"/sellerorder/generateInvoice/"+orderData.order_details_id, "_blank")}
                      >
                        Download Invoice
                      </button>:""}
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* // Modal for delivery slots */}

                  <Modal
                      open={this.state.popup}
                      onClose={()=>{this.onCloseModal(false)}}
                      center
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'customcancelmodel',
                      }}
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                      <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                          <h5 className="modal-title">Reason for {this.state.popup == 1?"Cancellation":"Return"}</h5>
                        </div>
                        <div className="modal-body ">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="w-100">
                                <form>
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Reason  </label>

                                    <textarea placeholder="Reason .." className="form-control" id="exampleFormControlTextarea1"
                                              onChange={(e)=>{    this.setState({ reason_text: e.target.value });}}
                                              rows="5"></textarea>
                                    <span> minimum 20 letters requirement</span>
                                  </div>
                                </form>

                                <button className="btn btn-danger" disabled={this.state.reason_text.replace(/\n/g, "").replace(/\s/g,'').length <= 19} onClick={(e)=>{this.cancelandretunorders(e)}}> Submit </button>

                              </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Modal>
                </>
            )}

            {loaderForOrderDetail && (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <div className="loading-cls"/>
                  </div>
                </div>
            )}
          </div>
        </>

    );
  }
}

export default OrderDetail;


