import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'

function Collapse(props) {
    const [isOpen, setIsOpen] = useState(false);
    function click() {
        alert("Collaps clicked")
    }




    return (
        <div className="collaps" style={{zIndex:1}}>
            <li className="row" onClick={() => setIsOpen(!isOpen)} style={{zIndex:1}}>
                <div className="col-10 "><h6 className="title-text text-capitalize">{props.ListTopic}</h6></div>
                <div className="col-2 ">
                    {isOpen?<i className="fa fa-angle-up collaps-arrow  float-right fa-2x" aria-hidden="true"></i>:<i className="fa fa-angle-down collaps-arrow float-right fa-2x" aria-hidden="true"></i>}
                </div>
                {isOpen && <div className="col-12" style={{zIndex:1}}>
                    <div className="border-top-dotted">{props.children}</div>
                   </div>}

            </li>



            {/* <li className="row">
                    <div className="col-6">Shop Policy</div>
                    <div className="col-6"><button><img src="./ Product Details/extras/Path Copy 2.png" alt="" className="src" /></button></div>

                </li>
                <li className="row">
                    <div className="col-6">Product Policy</div>
                    <div className="col-6"><button><img src="./ Product Details/extras/Path Copy 2.png" alt="" className="src" /></button></div>

                </li>
                <li className="row">
                    <div className="col-6">Reviews & Ratings</div>
                    <div className="col-6"><button><img src="./ Product Details/extras/Path Copy 2.png" alt="" className="src" /></button></div>

                </li> */}


        </div>
    )
}



export default Collapse;

