import Api from "./index";
import {convertObjectToQuerystring} from "../utils/helper";
import {getUserData, getUserToken} from "../utils/storageUtils";
import Imagesapi from "./images";

// GET HOME PAGE BRANDS
export const getProductDetail_api = (data) => {
    const token = getUserToken();
    return Api(`/getProductDetails`, data, "POST", token);
};

export const getConfigureProductDetails_api = (data) => {
    const token = getUserToken();
    return Api(`/getConfigureProductDetails`, data, "POST", token);
};

export const getAllDashboardProductFilters_api = (data) => {
    const token = getUserToken();
    return Api(`/getFilters`, data, "POST", token);
    // var data = {...data,'user_d'}
    return Api(`/getProductFilters`, data, "POST", token);
};

export const getAllDashboardProducts_api = (data) => {
    const token = getUserToken();

    if (getUserData() && getUserData().id) {
        data.user_id = getUserData() ? getUserData().id : '';
        data.is_guest_user = 0;
    }
    data.is_guest_user = 1;

    data.is_allow_location = 0;
    data.allow_location_address = localStorage.getItem('get_ip');
    if (localStorage.getItem('is_active')  == 1)
    {
        data.is_allow_location = 1;
        data.allow_location_address =  localStorage.getItem('get_address');
        localStorage.setItem('is_active', 0)
    }

    data.page_limit = 15;
    return Api(`/v1/getProductV2`, data, "POST", '');
};


export const addToCart_api = (data, qty) => {
    const token = getUserToken();
    return Api(`/addCart`, data, "POST", token);
}


export const applyCouponCode_api = (data) => {
    const token = getUserToken();
    return Api(`/addCoupon`, data, "POST", token);
};

export const listWishlist_api = (data) => {
    const token = getUserToken();
    return Api(`/listWhislist`, data, "POST", token);
};

export const addToWishlist_api = (data) => {
    const token = getUserToken();

    return Api(`/addWhislist`, data, "POST", token);
};

export const deleteWishlist_api = (data) => {
    const token = getUserToken();
    return Api(`/deleteWhislist`, data, "POST", token);
};


export const removeproductwishlist_api = (data) => {
    const token = getUserToken();
    return Api(`/removeProductWishList`, data, "POST", token);
};

export const removeAndAddToCart_api = (data) => {
    const token = getUserToken();
    return Api(`/removeWishAddCart`, data, "POST", token);
};

export const userPlaceOrder_api = (data) => {
    const token = getUserToken();
    return Api(`/v1/addOrderV2`, data, "POST", token);
};

export const userPayumoney_api = (data) => {
    const token = getUserToken();
    return Api(`/v1/payumoneyorders`, data, "POST", token);
};


export const getAllOrders_api = (data) => {
    const token = getUserToken();
    return Api(`/v1/orderList`, data, "POST", token);
};

export const getSingleOrder_api = (data) => {
    const token = getUserToken();
    var data = {...data}
    return Api(`/v1/orderDetails`, data, "POST", token);
};


export const applyGiftCard_api = (data) => {
    const token = getUserToken();
    return Api(`/applyGiftCard`, data, "POST", token);
};

export const getCouponList_api = (data) => {
    const token = getUserToken();
    return Api(`/listCoupon`, data, "POST", token);
};

export const getSearchSuggestions_api = (data) => {
    const token = getUserToken();
    return Api(`/getAutoSearchSuggestion`, data, "POST", token);
};
export const getSearchSuggestionssp_api = (data) => {
    const token = getUserToken();
    return Api(`/v1/sp/search`, data, "POST", token);
};
export const getServiceMenu = (data) => {
    const token = getUserToken();
    return Api(`/v1/sp/specialservice`, {}, "POST", token);
};

export const setCancelledOrder_api = (data) => {
    const token = getUserToken();
    return Imagesapi(`/cancelledOrder`, data, "POST", token);
};

export const setReturnOrders_api = (data) => {
    const token = getUserToken();
    return Imagesapi(`/returnOrders`, data, "POST", token);
};

export const checkpincode_api = (data) => {
    const token = getUserToken();
    return Api(`/isShipping`, data, "POST", token);
};


export const setReview_api = (data) => {
    const token = getUserToken();
    return Api(`/addRating`, data, "POST", token);
};

export const getOfferData_api = (data) => {
    // return Api('/offerProductList',data,"POST")
    const token = getUserToken();

    if (getUserData() && getUserData().id) {
        data.user_id = getUserData() ? getUserData().id : '';
        data.is_guest_user = 0;
    }
    data.is_guest_user = 1;

    data.is_allow_location = 0;
    data.allow_location_address = localStorage.getItem('get_ip');
    if (localStorage.getItem('is_active')  == 1)
    {
        data.is_allow_location = 1;
        data.allow_location_address =  localStorage.getItem('get_address');
        localStorage.setItem('is_active', 0)
    }
    if(!data.page_limit) {
        data.page_limit = 15;
    }
    return Api(`/v1/getProductV2`, data, "POST", '');
}