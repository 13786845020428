import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { checkAndRedirectLogin } from "../../utils/helper";
import { toast } from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import { Helmet } from "react-helmet";
import { getAllOrders_api } from "../../api/product";
import OrderDetail from "./OrderDetail";
import moment from "moment";
import { Link } from "react-router-dom";

class ListOrdersExtra extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaderForOrders: false,
            orders: [],
            isShowOrderDetailPage: false,
            viewOrder: null,
            showlistof: 1,
        };
    }

    componentDidMount() {
        if (!checkAndRedirectLogin()) {
            toast.info("Please login to continue");
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.setAllOrders(1);
        }
    }

    setAllOrders = async (id) => {
        try {
            this.setState({
                orders: [],
            });
            this.setState({ loaderForOrders: true });
            let apiParams = {
                user_id: this.props.auth.userData.id,
                filter_by: id,//1 = This Month, 2 = Past Month, 3 = 6 Last Month,  4 = Last Year
                language_id: 1,
            };
            const address = await getAllOrders_api(apiParams);
            // console.log(address);
            this.setState({
                loaderForOrders: false,
                orders: address.data,
            });
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            this.setState({ loaderForOrders: false });
        }
    };

    showOrderDetailPage = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isShowOrderDetailPage: !prevState.isShowOrderDetailPage,
        }));
    };

    handleViewClick = (e, item) => {
        e.persist();
        this.setState(
            {
                viewOrder: item,
            },
            () => {
                this.showOrderDetailPage(e);
            }
        );
    };

    render() {
        const { isLoggedIn } = this.props.auth;
        const {
            isShowOrderDetailPage,
            loaderForOrders,
            orders,
            viewOrder,
        } = this.state;

        return (
            <div>
                <Helmet>
                    <title>My Orders | </title>
                    <meta name="description" content="" />
                </Helmet>

                <Breadcrumb title={"My Orders"} />

                {/*Dashboard section*/}
                <section className="section-b-space blog-page">
                    <div className="container">
                        <div className="row">
                            {isLoggedIn && <DahsboardSidebar {...this.props} />}
                            {isLoggedIn && !isShowOrderDetailPage && (
                                <div className="col-lg-9">
                                    <div className="dashboard dashboard-right register-page">
                                        <div className="theme-card">
                                            <form className="theme-form">

                                                <div className="form-group row">
                                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Order time:</label>
                                                    <div className="col-sm-10">
                                                        <select className="custom-select mb-2"
                                                            onChange={(e) => this.setAllOrders(e.target.value)}>
                                                            <option value="1">This Month</option>
                                                            <option value="2">Past Month</option>
                                                            <option value="3">Last Month</option>
                                                            <option value="4">Last Year</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="row check-out">
                                                <div className="container">
                                                    <table className="table order-table table-responsive-md">
                                                        <thead>
                                                            <tr className="table-head">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col" className="order-product-td">Product</th>
                                                                <th scope="col">Order Date</th>
                                                                <th scope="col">E.T.A</th>
                                                                <th scope="col">Price</th>
                                                                <th scope="col">Quantity</th>
                                                                <th scope="col">Subtotal</th>
                                                                {/* <th scope="col">View</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orders.map((item, index) => {
                                                                return (
                                                                    <tr key={index} className="tbl-row">

                                                                        <td id="order-id">
                                                                            <strong>{item.order_id}</strong>
                                                                        </td>
                                                                        <td className="order-product-td row">
                                                                        {/* <div className=""> */}
                                                                            <div className="col-3">
                                                                            <Link
                                                                                to={`${process.env.PUBLIC_URL}/product/${item.id
                                                                                    }`}
                                                                            >
                                                                                <img src={item.product_details.product_image} id="my-order-img"  alt="" />
                                                                            </Link>
                                                                            </div>
                                                                            <div className="col-8 product-data">
                                                                            <strong  className="mb-4">{item.product_details.product_name}</strong><br/><br/>
                                                                            <strong className="">Color: {item.product_details.color}</strong><br/>
                                                                            <strong className="">Size: L</strong> <br/>
                                                                            <strong className="" >Order Type: <span id="order-type">{item.product_details.order_type_text}</span></strong>
                                                                            </div>
                                                                        {/* </div> */}

                                                                        </td>
                                                                        <td id="order-date">
                                                                            <strong>
                                                                                {moment(item.created_at).format(
                                                                                    "YYYY-MM-DD"
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td id="eta-date">
                                                                            <strong>
                                                                                {moment(item.created_at).format(
                                                                                    "YYYY-MM-DD"
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong id="order-price">
                                                                                {this.props.symbol}
                                                                                {item.total_amount}
                                                                            </strong>{" "}
                                                                        </td>
                                                                        <td id="order-qty">
                                                                            <strong>

                                                                                {item.product_details.order_quantity}
                                                                            </strong>{" "}
                                                                        </td>
                                                                        <td id="order-subtotal">
                                                                            <strong>
                                                                                {this.props.symbol}
                                                                                {item.product_details.total_product_price}
                                                                            </strong>{" "}
                                                                        </td>
                                                                        <td id="order-view">
                                                                        <div className="box pull-right">
                                                                            <a
                                                                                href="javascript:"
                                                                                onClick={(e) =>
                                                                                    this.handleViewClick(e, item)
                                                                                }
                                                                            >
                                                                                View
                                                                            </a>{" "}
                                                                        </div>
                                                                        </td>
                                                                    </tr>);
                                                            })}

                                                            {/* <div
                                                                    className="row"
                                                                    key={index}
                                                                    style={{
                                                                        lineHeight: "25px",
                                                                    }}
                                                                >
                                                                    <div className="col-1">
                                                                        <span>#{index + 1}</span>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        <span>Order ID: </span>
                                                                        <strong>{item.order_id}</strong> <br/>
                                                                        <span>Addess: </span>
                                                                        <strong>{item.address_1}</strong> <br/>
                                                                        <span>Total Discount:</span>{" "}
                                                                        <strong>
                                                                            {this.props.symbol}
                                                                            {item.total_coupon_price}
                                                                        </strong>{" "}
                                                                        <br/>
                                                                        <span>Total Amount:</span>{" "}
                                                                        <strong>
                                                                            {this.props.symbol}
                                                                            {item.total_amount}
                                                                        </strong>{" "}
                                                                        <br/>
                                                                        <span>Order Status:</span>{" "}
                                                                        <strong>
                                                                            {item.product_item_count > 0
                                                                                ? item.product_details.order_status_name
                                                                                : "Pending"}
                                                                        </strong>
                                                                        <br/>
                                                                        <span>Date:</span>{" "}
                                                                        <strong>
                                                                            {moment(item.created_at).format(
                                                                                "YYYY-MM-DD"
                                                                            )}
                                                                        </strong>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="box pull-right">
                                                                            <a
                                                                                href="javascript:"
                                                                                onClick={(e) =>
                                                                                    this.handleViewClick(e, item)
                                                                                }
                                                                            >
                                                                                View
                                                                            </a>{" "}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            {/* );
                                                        })} */}


                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {this.state.loaderForOrders && (
                                        <div className="row">
                                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                                <div className="loading-cls" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Add New Address Page */}
                            {isLoggedIn && isShowOrderDetailPage && (
                                <OrderDetail
                                    viewOrder={viewOrder}
                                    showOrderDetailPage={this.showOrderDetailPage}
                                    loaderForOrders={loaderForOrders}
                                    auth={this.props.auth}
                                    symbol={this.props.symbol}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    {}
)(ListOrdersExtra);
