import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { checkAndRedirectLogin } from "../../utils/helper";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import { Helmet } from "react-helmet";
import { getAllOrders_api } from "../../api/product";
import OrderDetail from "./OrderDetail";
import moment from "moment";
import { Link } from "react-router-dom";
import NewOrderDetails from "./NewOrderDetails";

class ListOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaderForOrders: false,
            orders: [],
            isShowOrderDetailPage: false,
            viewOrder: null,
            showlistof: 1,
            listingTitle: "My Orders",
            detailsTitle: "Order Details",
            showBack: true

        };
    }

    componentDidMount() {
        if (!checkAndRedirectLogin()) {
            toast.info("Please login to continue");
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.setAllOrders(1);
        }
    }

    setAllOrders = async (id) => {

        try {
            this.setState({
                orders: [],
            });
            this.setState({ loaderForOrders: true });
            let apiParams = {
                user_id: this.props.auth.userData.id,
                filter_by: id,//1 = This Month, 2 = Past Month, 3 = 6 Last Month,  4 = Last Year
                language_id: 1,
            };
            const address = await getAllOrders_api(apiParams);
            // console.log(address);
            this.setState({
                loaderForOrders: false,
                orders: address.data,
            });

        } catch (error) {
            console.log(error);
            toast.error(error.message);
            this.setState({ loaderForOrders: false });
        }
    };

    showOrderDetailPage = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isShowOrderDetailPage: !prevState.isShowOrderDetailPage,
            showBack: !prevState.showBack
        }));
    };

    handleViewClick = (e, item) => {
        e.persist();
        this.setState(
            {
                viewOrder: item,
            },
            () => {
                this.showOrderDetailPage(e);
            }
        );
    };

    render() {
        const { isLoggedIn } = this.props.auth;
        const {
            isShowOrderDetailPage,
            loaderForOrders,
            orders,
            viewOrder,
        } = this.state;

        const now = new Date().getUTCFullYear();
        const years = Array(now - 2020).fill('').map((v, idx) => now - idx);

        return (
            <div>
                <Helmet>
                    <title>My Orders | </title>
                    <meta name="description" content="" />
                </Helmet>

                <Breadcrumb showBack={this.state.showBack} title={!this.state.isShowOrderDetailPage ? this.state.listingTitle : this.state.detailsTitle} />

                {/*Dashboard section*/}
                <section className="section-b-space blog-page order-list-page " style={{ paddingTop: "0px" }}>

                    {isLoggedIn && !isShowOrderDetailPage && (
                        <div className="container-fluid cart-d-none-mobile">
                            <div className="row">
                                <div className="col-6">
                                    <span className='font-weight-bold float-left'>My Orders</span>
                                </div>
                                <div className="col-6">

                                    <select className='form-control font-weight-bold' style={{ borderRadius: "8px" }} onChange={(e) => this.setAllOrders(e.target.value)}>
                                        <option value='1'>This Month</option>
                                        <option value='2'>Past Month</option>
                                        <option value='3'>Last Month</option>
                                        {/*<option value='4'>Last Year</option>*/}
                                        {
                                            years.map(i=>{
                                                return <option value={i}> {i}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {orders.map((item, index) => {
                                    return (
                                        <div className="col-12" onClick={(e) =>
                                            this.handleViewClick(e, item)
                                        }>
                                            <div className="container">
                                                <div className="row my-orders">
                                                    <div className="col-4 text-center">
                                                        <Link to={`${process.env.PUBLIC_URL}/${item.product_details.product_slug}/p/${item.product_id}`}>
                                                            <img src={item.product_details.product_image} id="my-order-img" width="100px" height="100px" alt="" />
                                                        </Link>
                                                        <strong className="order-list-list-id"> <span className="badge bg-primary-new text-white mt-3" style={{ borderRadius: "1rem" }}>{item.order_id}</span> </strong>
                                                    </div>
                                                    <div className="col-8">
                                                        <ul>
                                                            <li>
                                                                <strong className="my-orders-product-name "><b>{item.product_details.product_name}</b></strong>
                                                            </li>
                                                            <li>
                                                                <strong className="" style={{ fontSize: "smaller" }}>
                                                                    Quantity :
                                                                </strong><strong className="set_color_dark_1">{item.product_details.order_quantity}</strong>
                                                            </li>

                                                            <li>
                                                                <strong id="order-price" style={{ fontSize: "smaller" }}>
                                                                    {this.props.symbol}
                                                                    {item.product_details.product_main_price}
                                                                </strong>
                                                            </li>
                                                            <li>
                                                                <strong className="" style={{ fontSize: "smaller" }}>Order Date </strong>
                                                                <strong className="set_color_dark_1">
                                                                    {moment(item.product_details.product_order_time).format("YYYY-MM-DD")}
                                                                </strong>
                                                            </li>
                                                            <li>
                                                                <strong className="" style={{ fontSize: "smaller" }}> Grand Total </strong>
                                                                <strong className="set_color_dark_1">
                                                                    {this.props.symbol} {item.total_product_price}
                                                                </strong>
                                                            </li>
                                                            <li>
                                                                <strong className="" style={{ fontSize: "smaller" }}>
                                                                    EDD
                                                                </strong>  <strong className="set_color_dark_1">{moment().add(parseInt(item.product_details.delivery_time), 'd').format("YYYY-MM-DD")}</strong> <br />

                                                            </li>
                                                            <li>
                                                                <strong className="" style={{ fontSize: "smaller" }}>
                                                                    Order Status
                                                                </strong>
                                                                <strong className="font-weight-bold" style={{ color: "#ff9500" }}> In-Progress</strong>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    <div className="container-fluid">
                        <div className="row order-detail-res">
                            {isLoggedIn && !isShowOrderDetailPage && (
                                <div className="col-lg-12 col-sm-12 col-md-12 cart-d-none">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-8 col-lg-10 myorders-sort-res align-self-center">
                                            <span className='font-weight-bold float-right'>SORT ORDERS:</span>
                                        </div>
                                        <div className='col-sm-4 col-md-4 col-lg-2 myorders-sort-val-res' style={{ paddingLeft: "0" }}>
                                            <select className='form-control font-weight-bold' style={{ color: "#2472f0" }} onChange={(e) => this.setAllOrders(e.target.value)}>
                                                <option value='1'>This Month</option>
                                                <option value='2'>Past Month</option>
                                                <option value='3'>Last Month</option>
                                                {/*<option value='4'>Last Year</option>*/}
                                                {
                                                    years.map(i=>{
                                                        return <option value={i}>{i}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-12 px-md-5 px-sm-1">
                                            <table className="table myorders-table-res" style={{ marginTop: "2rem", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)" }}>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th scope='col'>ORDER ID</th>
                                                        <th scope='col'>PRODUCT IMAGE</th>
                                                        <th scope='col'>PRODUCT DETAILS</th>
                                                        <th scope='col'>ORDER DATE</th>
                                                        <th scope='col'>ESTIMATED DELIVERY</th>
                                                        <th scope='col'>PRICE</th>
                                                        <th scope='col'>QUANTITY</th>
                                                        <th scope='col'>GRAND TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.map((item, index) => {
                                                        return (
                                                            <tr key={index} className="tbl-row" style={{ cursor: "pointer" }} onClick={(e) =>
                                                                this.handleViewClick(e, item)
                                                            }>

                                                                <td id="order-id" className="myorders-table-valign">
                                                                    <strong className="order-list-list-id">{item.order_id}</strong>
                                                                </td>
                                                                <td className="myorders-table-valign">
                                                                    {/* <div className=""> */}
                                                                    <div className="">
                                                                        <Link to={`${process.env.PUBLIC_URL}/${item.product_details.product_slug}/p/${item.product_id}`}>
                                                                            <img src={item.product_details.product_image} id="my-order-img" width="100px" height="100px" alt="" />
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                <td className='myorders-table-valign'>
                                                                    <div className="">
                                                                        <strong className=""><b>{item.product_details.product_name}</b></strong><br />

                                                                        {
                                                                            (item && item.option_data && item.option_data.length > 0) ?

                                                                                item.option_data.map((opt, i) => {

                                                                                    return <strong className="d-block order-list-list-optoin ">
                                                                                        {(opt.option_name) ? opt.option_name + ": " + opt.option_value_name : ""}
                                                                                    </strong>

                                                                                })
                                                                                : ""
                                                                        }

                                                                        {/*<strong className="">Size: L</strong> <br/>*/}
                                                                        <strong className="order-list-list-optoin">Order Type: <span className={'order-list-list-optoin check___' + item.product_details.order_type_text}>{item.product_details.order_type_text}</span></strong>
                                                                    </div>
                                                                    {/* </div> */}

                                                                </td>
                                                                <td id="order-date" className='myorders-table-valign'>
                                                                    <strong className="set_color_dark_1">
                                                                        {moment(item.product_details.product_order_time).format(
                                                                            "YYYY-MM-DD"
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                                <td id="eta-date" className='myorders-table-valign'>
                                                                    <strong className="set_color_dark_1">
                                                                        {item.delivery_date}
                                                                    </strong>
                                                                </td>
                                                                <td className='myorders-table-valign'>
                                                                    <strong id="order-price">
                                                                        {this.props.symbol}
                                                                        {item.product_details.product_main_price}
                                                                    </strong>{" "}
                                                                </td>
                                                                <td id="order-qty" className='myorders-table-valign'>
                                                                    <strong className="set_color_dark_1">

                                                                        {item.product_details.order_quantity}
                                                                    </strong>{" "}
                                                                </td>
                                                                <td id="order-subtotal" className='myorders-table-valign'>
                                                                    <strong className="text-success">
                                                                        {this.props.symbol}
                                                                        {item.total_amount}
                                                                    </strong>{" "}
                                                                </td>


                                                            </tr>);
                                                    })}

                                                    {/* <div
                                                                    className="row"
                                                                    key={index}
                                                                    style={{
                                                                        lineHeight: "25px",
                                                                    }}
                                                                >
                                                                    <div className="col-1">
                                                                        <span>#{index + 1}</span>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        <span>Order ID: </span>
                                                                        <strong>{item.order_id}</strong> <br/>
                                                                        <span>Addess: </span>
                                                                        <strong>{item.address_1}</strong> <br/>
                                                                        <span>Total Discount:</span>{" "}
                                                                        <strong>
                                                                            {this.props.symbol}
                                                                            {item.total_coupon_price}
                                                                        </strong>{" "}
                                                                        <br/>
                                                                        <span>Total Amount:</span>{" "}
                                                                        <strong>
                                                                            {this.props.symbol}
                                                                            {item.total_amount}
                                                                        </strong>{" "}
                                                                        <br/>
                                                                        <span>Order Status:</span>{" "}
                                                                        <strong>
                                                                            {item.product_item_count > 0
                                                                                ? item.product_details.order_status_name
                                                                                : "Pending"}
                                                                        </strong>
                                                                        <br/>
                                                                        <span>Date:</span>{" "}
                                                                        <strong>
                                                                            {moment(item.created_at).format(
                                                                                "YYYY-MM-DD"
                                                                            )}
                                                                        </strong>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="box pull-right">
                                                                            <a
                                                                                href="javascript:"
                                                                                onClick={(e) =>
                                                                                    this.handleViewClick(e, item)
                                                                                }
                                                                            >
                                                                                View
                                                                            </a>{" "}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                    {/* );
                                                        })} */}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {this.state.loaderForOrders && (
                                        <div className="row">
                                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                                <div className="loading-cls" />
                                                <p></p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Add New Address Page */}
                            {isLoggedIn && isShowOrderDetailPage && (
                                <NewOrderDetails
                                    viewOrder={viewOrder}
                                    showOrderDetailPage={this.showOrderDetailPage}
                                    loaderForOrders={loaderForOrders}
                                    auth={this.props.auth}
                                    symbol={this.props.symbol}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    symbol: state.data.symbol,
});

export default withRouter(connect(mapStateToProps, {})(ListOrders));