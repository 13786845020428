import React from "react";
import {Link} from 'react-router-dom';


const Banner3 = (props) => {
        const {dashboardModulesnew} = props


        function setlink(bannerdetails) {

            if (bannerdetails.link_banner_to == 0) {
                return `${process.env.PUBLIC_URL}/product/` + bannerdetails.product_id;
            } else if (bannerdetails.link_banner_to == 1) {
                return `${process.env.PUBLIC_URL}/category/` + bannerdetails.category_id + `/products`
            } else if (bannerdetails.link_banner_to == 2) {
                return `${process.env.PUBLIC_URL}/offer/` + bannerdetails.offer_id + `/products`
            } else {
                return `${process.env.PUBLIC_URL}`;
            }

            return "";
        }

        return (
            <div>
                {(dashboardModulesnew && dashboardModulesnew.banners_bottom) ?
                    <>
                        {(dashboardModulesnew.banners_bottom.banner_position === 2 ?
                            <>
                                <Link to={setlink(dashboardModulesnew.banners_bottom)}>

                                    <div className="d-block w-100 banner mb-3" id="bnrimg">
                                        <img className="d-block w-100 home-banner"
                                             src={dashboardModulesnew.banners_bottom.banner_image_url} alt=""
                                             srcSet=""/>
                                    </div>

                                </Link>
                            </>
                            : "")}
                    </>
                    : ""}
            </div>
        );
    }
;

export default Banner3;


