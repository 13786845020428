import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { connect } from "react-redux";
import { getAboutUspage } from "../../../actions/index";

class PrivacyPolicy extends Component {


  componentDidMount() {
    this.props.getAboutUspage({ content_type: 12 });


  }

  render() {
    return (
      <>
        {this.props.aboutUs.page_content ? (
          <>
            <div>
              {window.location.href.search("sp") <= 1 && (
                <>
                  {this.props.aboutUs ? <Breadcrumb title={this.props.aboutUs.page_name} />: <Breadcrumb title={"About Us"} />}
                </>
              )}
              <section className="about-page  section-b-space">
                <div className="container-fluid cutCont">
                  <div className="row">
                    <div className="col-sm-12">
                      {this.props.aboutUs.page_content ? (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.aboutUs.page_content,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutUs: state.pages.aboutUs,
});

const mapDispatchToProps = { getAboutUspage };

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
