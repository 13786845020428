import React from 'react'
import giftbox from "../../access/icone/giftbox.png"
import Breadcrumb from "../common/breadcrumb";

const ListGiftCard = () => {
    return (
      <div>
        <Breadcrumb title={"GIFTCARDS"} />
        <div className='container'>
          <div className='row mt-3 mb-3'>
            <div className='col-12'>
              <strong className='theme_text_set'>GIFTCARDS</strong>
              <button
                className='btn btn-solid float-right'
                style={{ borderRadius: "2rem" }}
              >
                CREATE A GIFTCARD
              </button>
            </div>
            <div className='col-6' style={{backgroundColor: "#2472f0", borderRadius: "10px"}}>
              <div className='row'>
                <div className='col-3'>
                  <img src={giftbox} width='150' height='150' />
                </div>
                <div className='col-9'>
                  <div className='row '>
                    <div className='col-3'>
                      <div
                        className='form-group'
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 className="text-white font-weight-bold pt-3" for='Student'>Occation</h6>
                        <small name='Student' className="text-white font-weight-bold" id='Student'>
                          Diwali Festival
                        </small>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div
                        className='form-group'
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 className="text-white font-weight-bold pt-3" for='Student'>Reciepient</h6>
                        <small className="text-white font-weight-bold" name='Student' id='Student'>
                          abc@xyz.com
                        </small>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div
                        className='form-group float-right'
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h2 className="text-white pt-1">₹ 50,000.00</h2>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div
                        className='form-group'
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 for='Student' className="text-white font-weight-bold">Giftcard Details</h6>
                        <p className="text-white ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ListGiftCard
