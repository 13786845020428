import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { connect } from "react-redux";
import { getAboutUspage } from "../../../actions/index";

class ContactUs extends Component {


  componentDidMount() {
    this.props.getAboutUspage({ content_type: 35 });

    try {
    //   if (window.location.href.search("sp") >= 1) {
    //     document
    //       .getElementById("footer_id")
    //       .setAttribute("style", "display:none");
    //     document
    //       .getElementById("header_id")
    //       .setAttribute("style", "display:none");
    //     document
    //       .getElementById("breadcrumb_id")
    //       .setAttribute("style", "display:none");
    //   }
    } catch (err) {}
  }

  render() {
console.log(this.props.aboutUs, 'Contact render' );

    return (
      <>
        {this.props.aboutUs ? (
          <>
            <div>
              {window.location.href.search("sp") <= 1 && (
                <>
                  {this.props.aboutUs ? <Breadcrumb title={this.props.aboutUs.page_name} />: <Breadcrumb title={"About Us"} />}
                </>
              )}
              <section className="about-page  section-b-space">
                <div className="container-fluid cutCont">
                  <div className="row">
                    <div className="col-sm-12">
                      {this.props.aboutUs.page_content ? (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.aboutUs.page_content,
                            }}
                          />
                        </>
                      ) : (
                        <> </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
            <h3>Page Not Found</h3>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutUs: state.pages.aboutUs,
});

const mapDispatchToProps = { getAboutUspage };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);