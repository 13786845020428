import React from 'react';
import Rating from '../rating';

const TxtWrap = (props) => {
  return (
    <div className="TxtWrap ">
      <table className="tw text-right">
        <tr className="card-image p-2">
          <td rowSpan="2" className="p-2"> <img src={props.card} alt="" className="src" />   </td>
        </tr>
        <tr className="card-image p-2">
          <td className="p-2"> <Rating /> Slim Fit Cotton Shirt<p>400$</p><hr id="txtw" />
            <td id="icons_"> <ul className="wishlist2">
              <li><a title="Add to Wishlist">
                <i class="fa fa-shopping-cart" id="cart-icon" aria-hidden="true"></i></a>
              </li>
              <li><a title="Add to Wishlist">
                <i class="fa fa-heart" aria-hidden="true"></i></a>
              </li>
              <li><a title="Quick View"><i class="fa fa-search" aria-hidden="true"></i></a>
              </li>
              <li><a title="Compare"><i class="fa fa-refresh" aria-hidden="true"></i></a>
              </li>
            </ul></td>

          </td>
        </tr>

      </table>
      {/* <Banner3 src="./section 8/1.png" /> */}


    </div>
  );
}

export default TxtWrap;