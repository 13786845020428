import React, { Component } from "react";
import { connect } from "react-redux";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";

import { getBrands, getColors, getMinMaxPrice } from "../../../services";
import {
  filterBrand,
  filterColor,
  filterPrice,
  getHomePageBrands,
} from "../../../actions";

class DashboardProductFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
    };
  }

  componentDidMount() {
    if (this.props.brands.length === 0) {
      this.props.getHomePageBrands();
    }
  }

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  clickBrandHendle(event, brands) {
    var index = brands.indexOf(event.target.value);
    if (event.target.checked) brands.push(event.target.value);
    // push in array checked value
    else brands.splice(index, 1); // removed in array unchecked value

    this.props.filterBrand(brands);
  }

  colorHandle(event, color) {
    var elems = document.querySelectorAll(".color-selector ul li");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    this.props.filterColor(color);
  }

  render() {
    const filteredBrands = this.props.filters.brand;
    const {
      fetchDashboardProductFilterLoader,
      dashboardProductFilters,
      dashboardProducts,
    } = this.props;
    // console.log(filteredBrands);
    return (
      <div className="collection-filter-block">
        {/*brand filter start*/}
        <div className="collection-mobile-back">
          <span className="filter-back" onClick={(e) => this.closeFilter(e)}>
            <i className="fa fa-angle-left" aria-hidden="true" /> back
          </span>
        </div>
        {!fetchDashboardProductFilterLoader &&
        dashboardProductFilters.length > 0 &&
        dashboardProducts.length > 0
          ? dashboardProductFilters.map((item, i) => {
              return (
                <SlideToggle
                  key={i}
                  render={({ onToggle, setCollapsibleElement }) => (
                    <div className="collection-collapse-block">
                      <h3 className="collapse-block-title" onClick={onToggle}>
                        {item.filter_group_name}
                      </h3>
                      <div
                        className="collection-collapse-block-content"
                        ref={setCollapsibleElement}
                      >
                        <div className="collection-brand-filter">
                          {item.filters.map((filter, index) => {
                            return (
                              <div
                                className="custom-control custom-checkbox collection-filter-checkbox"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    this.props.sidebarFilterClicked(
                                      e,
                                      filteredBrands
                                    )
                                  }
                                  value={filter.filter_id}
                                  defaultChecked={
                                    filteredBrands.includes(
                                      filter.filter_id.toString()
                                    )
                                      ? true
                                      : false
                                  }
                                  className="custom-control-input"
                                  id={filter.filter_description}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={filter.filter_description}
                                >
                                  {filter.filter_description}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                />
              );
            })
          : null}

        {this.props.fetchDashboardProductFilterLoader && (
          <div className="loading-cls mt-4" />
        )}

        {/*color filter start here*/}
        {/* <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block">
              <h3 className="collapse-block-title" onClick={onToggle}>
                colors
              </h3>
              <div
                className="collection-collapse-block-content"
                ref={setCollapsibleElement}
              >
                <div className="color-selector">
                  <ul>
                    {this.props.colors.map((color, index) => {
                      return (
                        <li
                          className={color}
                          title={color}
                          onClick={(e) => this.colorHandle(e, color)}
                          key={index}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </SlideToggle> */}

        {/*price filter start here */}
        {/* <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={onToggle}>
                price
              </h3>
              <div
                className="collection-collapse-block-content block-price-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter">
                  <div className="custom-control custom-checkbox collection-filter-checkbox">
                    <InputRange
                      maxValue={this.props.prices.max}
                      minValue={this.props.prices.min}
                      value={this.props.filters.value}
                      onChange={(value) => this.props.filterPrice({ value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </SlideToggle> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // brands: getBrands(state.data.products),
  brands: state.home.brands,
  colors: getColors(state.data.products),
  prices: getMinMaxPrice(state.data.products),
  filters: state.filters,
  dashboardProducts: state.data.dashboardProducts,
  dashboardProductFilters: state.data.dashboardProductFilters,
  fetchDashboardProductFilterLoader:
    state.data.fetchDashboardProductFilterLoader,
});

export default connect(
  mapStateToProps,
  { filterBrand, filterColor, filterPrice, getHomePageBrands }
)(DashboardProductFilter);
