import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/DashboardNewProduct";
import Filter from "./common/DashboardProductFilter";
import FilterBar from "./common/DashboardFilterBar";
import ProductListing from "./common/DashboardProductListing";
import ElementCategory1 from "../../components/features/theme/element-category1"
import CategoriesLists from "../../components/features/theme/subcategories"
import StickyBox from "react-sticky-box";
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts, getAllSettings,
} from "../../actions";

class SubCategoriesList extends Component {
    state = {
        layoutColumns: 3,
        page: 1,
        showBack: true
    };

    componentDidMount() {
        // reset product list

        let apiParams = {
            category_id: this.props.match.params.id,
            language_id: 1,
            page: this.state.page,
        };
        this.props.getAllDashboardProducts(apiParams);

    }

    render() {

        const {
            store_name,
        } = {...this.props.settings}
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>{store_name}</title>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb showBack={this.state.showBack} title={"categories / "+this.props.__list_name}/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="col">
                                    <h3 style={{textAlign:"center", color:"#000", fontWeight:"600", padding:"20px"}}>Categories</h3>
                                </div> */}
                                {/* <div className="col-12">
                                    <div className="title1 section-t-space">
                                            <h3 className="title-inner1 title-inner1 font-size-text-30 mb-5" 
                                            // style={{textAlign:"center", color:"#000", fontWeight:"600", padding:"20px"}}
                                            >SHOP BY CATEGORIES</h3>
                                    </div>
                                </div> */}

                                <div className="col-12">
                                    <CategoriesLists categoryID={this.props.match.params.id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
    settings: state.settings,
    productCount: state.data.dashboardProducts.length
});

export default connect(
    mapStateToProps,
    {
        getAllDashboardProducts,
        filterSort,
        getAllDashboardProductFilters,
        filterBrand,
        resetFilterAndfSort,
        resetDashboardProducts,
        getAllSettings
    }
)(SubCategoriesList);
