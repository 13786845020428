import React,{Component} from 'react'
import BrandDiv from './BrandDiv'
import ItemList from './ItemList'
import { useSelector,useDispatch } from 'react-redux';
import Slider from "react-slick";
import { connect } from 'react-redux';


class Tab extends Component {
    componentDidMount() {
    }

    render() {
        const {dashboardModules} = this.props;
        var  props = this.props;

        return (
            <div className="container-fluid tab mt-5 ">

                {/*<nav>
                <div class="nav nav-tabs Tab_div" id="nav-tab" role="tablist">
                    <a class="nav-link tabLink active " id={"nav-"+(props.tabId1)+"-tab"} data-toggle="tab" href={"#nav-"+(props.tabId1)} role="tab" aria-controls={"nav-"+(props.tabId1)}  aria-selected="true">{props.slide1}</a>
                    <a class="nav-link tabLink" id={"nav-"+(props.tabId2)+"-tab"} data-toggle="tab" href={"#nav-"+(props.tabId2)} role="tab" aria-controls={"nav-"+(props.tabId2)}  aria-selected="false">{props.slide2}</a>
                    <a class="nav-link tabLink" id={"nav-"+(props.tabId3)+"-tab"} data-toggle="tab" href={"#nav-"+(props.tabId3)} role="tab" aria-controls={"nav-"+(props.tabId3)} aria-selected="false">{props.slide3}</a>
                </div>
                
            </nav>*/}
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active " id={"nav-" + (props.tabId1)} role="tabpanel"
                         aria-labelledby={"nav-" + (props.tabId1) + "-tab"}>
                        {
                            (dashboardModules && dashboardModules.product_section && dashboardModules.product_section.length > 0) ? dashboardModules.product_section.map((dashboardModule, i) => {
                                return dashboardModule.dashboard_id == props.dashboard_id ?
                                    <ItemList data={dashboardModule.products}/> : ''
                            }) : ""
                        }
                    </div>
                    {/*<div class="tab-pane fade" id={"nav-"+(props.tabId2)} role="tabpanel" aria-labelledby={"nav-"+(props.tabId2)+"-tab"}>
                      <ItemList />
                </div>
                <div class="tab-pane fade" id={"nav-"+(props.tabId3)} role="tabpanel" aria-labelledby={"nav-"+(props.tabId3)+"-tab"}>
                    <ItemList />
                </div>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dashboardModules: state.home.dashboardModulesnew,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tab);
