import React, {Component} from 'react';
import Slider from 'react-slick';
import {Link} from "react-router-dom";

import Breadcrumb from "../common/breadcrumb";
import {connect} from "react-redux";
import {fetchOffers, getoffers_home} from "../../actions";
import {Slider3} from "../../services/script";

class lookbook extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchOffers()
    }

    render() {
        var check3index = function check3index(i) {
            return !(i % 3)
        };
        var newarray = this.props.offerlist && this.props.offerlist.filter((item) => item.position == "center");
        console.log("offer", newarray)
        var offerdata = [];

        function ddd() {
            var data = [];
            newarray.map(function (offer, i) {

                if (check3index(i + 1)) {
                    offerdata.push({...offer});
                } else {
                    data.push({...offer});
                    if (data.length == 2) {
                        offerdata.push([...data]);
                        data = [];
                    }
                }
            });
        }

 

        return (
            <>
                {
                this.props.offerlist && this.props.offerlist.filter((item) => item.position == this.props.position).length > 0 ?
                <>
                <div className="container offers-wrp">
                        <div className="row">
                            <div className="col">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1 title-inner1 font-size-text-30 mb-md-5 mb-sm-1">Offers</h2>
                                </div>
                            </div>
                            <div className="col-md-12 margin-top-buttom-20" style={{paddingBottom: "20px"}}>
                                <Slider {...Slider3} className="slider3 no-arrow">
                                    {this.props.offerlist.filter((item) => item.position == this.props.position).map((data, i) => {
                                        return <div className='margin-top-buttom-20'>
                                            <div className="col-md-12">
                                                <Link to={'/offer/' + data.id + '/products'}>
                                                    <div className="classic-effect">
                                                        <img src={data.offer_banner}
                                                             className="img-fluid offerimages" alt=""/>
                                                        <span></span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </> : <></> }

                {/* {(this.props.offerlist && this.props.offerlist.length >= 1 && this.props.offerlist.filter(word => word.position == this.props.position).length >= 1) ?
                    <div className="container offers-wrp">
                        <div className="row">
                            <div className="col">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1 title-inner1 font-size-text-30 mb-md-5 mb-sm-1">Offers</h2>
                                </div>
                            </div>

                            <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                <Slider {...Slider3} className="slider3 no-arrow">

                                    {this.props.offerlist.filter(word => word.position == this.props.position).map((data, i) => {
                                        return <div>
                                            <div className="col-md-12">
                                                <Link to={'/offer/' + data.id + '/products'}>
                                                    <div className="classic-effect">
                                                        <img src={data.offer_banner}
                                                             className="img-fluid offerimages" alt=""/>
                                                        <span></span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                    : ""
                } */}
            </>
        )
    }
}


const
    mapStateToProps = (state, ownProps) => {
        return {
            offerlist: state.home.offerlist,
        };
    };

export default connect(
    mapStateToProps, {
        fetchOffers
    }
)

(
    lookbook
)
;