import React from 'react';
import { Link } from 'react-router-dom';

const Products = () => {
    return (
        <div className="pro container-fluid my-4 ">
            <table className="pro-cat container">
                <tr className="cat1">
                    <td><a><img class="icon_ fab" src="./icon/2.png" alt="" /></a>
                    <p>Winter jacket</p></td>
                    <td><a href=""><img class="icon_ fab" src="./icon/1.png" alt="" /></a><p>Boots</p></td>
                    <td><a href=""><img class="icon_ fab" src="./icon/3.png" alt="" /></a><p>Vest</p></td>
                    <td><Link to="/t-shirt"><img class="icon_ fab" src="./icon/4.png" alt="" /></Link><p>T-shirts</p></td>
                    <td><a href=""><img class="icon_ fab" src="./icon/5.png" alt="" /></a><p>soccer-boots</p></td>
                    <td><a href=""><img class="icon_ fab" src="./icon/6.png" alt="" /></a><p>Shorts</p></td>
                </tr><br/>
                <tr className="cat1">
                    <td><a><img class="icon_ fab" src="./icon/7.png" alt="" /></a><p>Sandels</p></td>
                    <td><a><img class="icon_ fab" src="./icon/8.png" alt="" /></a><p>Socks</p></td>
                    <td><a><img class="icon_ fab" src="./icon/9.png" alt="" /></a><p>Skirt</p></td>
                    <td><a><img class="icon_ fab" src="./icon/1.png" alt="" /></a><p>Boots</p></td>
                    <td><a><img class="icon_ fab" src="./icon/2.png" alt="" /></a><p>Winter jacket</p></td>
                    <td><a><img class="icon_ fab" src="./icon/3.png" alt="" /></a><p>Vest</p></td>
                </tr>
            </table><br/>
        </div>
    );
}

export default Products;