import React, { Component } from 'react'
import Breadcrumb from "../common/breadcrumb";
import {toast} from "react-toastify"
import { connect } from "react-redux"
import axios from 'axios';
import { getUserToken } from "../../utils/storageUtils"
// import {createGiftCard_api} from "../../api/auth"
class CreateGiftCard extends Component {
  constructor(props) {
                       super(props);
                       this.state = {
                         userName: this.props.auth.userData.name,
                         loader: false
                       };
                       this.onInputchange = this.onInputchange.bind(this);
                     }
                
                  //    CreateGiftCard = async () => {
                  //      try {
                  //       let apiParams = new URLSearchParams()
                  //       apiParams.append("user_name", this.state.userName)
                  //       apiParams.append("user_email", this.state.email)
                  //       apiParams.append("gift_amount", this.state.amount)
                  //       apiParams.append("occasion_val", this.state.occasion)
                  //       apiParams.append("user_message", this.state.details)

                  //       let giftCard = await createGiftCard_api(apiParams);
                  //       if (giftCard.success) {
                  //         toast.success(giftCard.message)
                  //       } else {
                  //           toast.error(giftCard.message);
                  //       }
                  //   } catch (error) {
                  //       console.log(error);
                  //       toast.error(error.message);
                  //   }
                  // };
                  
                  CreateGiftCard = async () => {
                    if(this.state.occasion === ""){
                      toast.error("Occasion is Required")
                    }
                    if(this.state.amount === ""){
                      toast.error("Amount is Required")
                    }
                    if(this.state.details === ""){
                      toast.error("Details is Required")
                    }
                    if(this.state.email === ""){
                      toast.error("Email is Required")
                    }
                    
                    if((this.state.email && this.state.amount && this.state.occasion && this.state.details) !== "" ){
                      this.setState({loader: true})
                       const headers = {
                         'Content-Type': 'application/x-www-form-urlencoded',
                         'Authorization': getUserToken()
                     }
                        let apiParams = new URLSearchParams()
                        apiParams.append("user_name", this.state.userName)
                        if(this.state.email !== undefined)
                        apiParams.append("user_email", this.state.email)
                        if(this.state.amount !== undefined)
                        apiParams.append("gift_amount", this.state.amount)
                        if(this.state.occasion !== undefined)
                        apiParams.append("occasion_val", this.state.occasion)
                        if(this.state.details !== undefined)
                        apiParams.append("user_message", this.state.details)
   
                        await axios
                          .post("/addGiftCard", apiParams, { headers: headers})
                          .then(function(response) {
                            if (response.data.success === true) {
                              toast.success(response.data.message);
                            }
                            else {
                              toast.error(response.data.message)
                              this.setState({loader: false})
                            }
                          })
                          .catch(function(error) {
                            // handle error
                            console.log(error);
                           });
                           this.setState({loader: false, occasion: "", amount: "", details: "", email: ""})
                    }
                 };

              onInputchange(event) {
                this.setState({
                  [event.target.name]: event.target.value
                });
              }
            
                 render() {
                   return (
                     <div>
                       <Breadcrumb title={"CREATE GIFTCARD"} />
                       <div className='container'>
                         <div className='row'>
                           <div className='col pt-3'>
                             <h5>CREATE GIFTCARD</h5>
                           </div>
                         </div>
                         <div className='pb-3'>
                           <div className='form-row'>
                             <div className='form-group col-md-6'>
                               <label>Occasion</label>
                               <input
                                 type='text'
                                 className='form-control'
                                 name="occasion"
                                 value={this.state.occasion}
                                 placeholder='For which occasion you want to create this card?'
                                 onChange={this.onInputchange}
                               />
                             </div>
                             <div className='form-group col-md-6'>
                               <label>Gift Amount</label>
                               <input
                                 type='text'
                                 className='form-control'
                                 name="amount"
                                 value={this.state.amount}
                                 placeholder='Type amount of giftcard'
                                 onChange={this.onInputchange}
                               />
                             </div>

                             <div className='form-group col-md-6'>
                               <label>Giftcard Details</label>
                               <textarea
                                 className='form-control'
                                 placeholder='Details of giftcard'
                                 name="details"
                                 value={this.state.details}
                                 onChange={this.onInputchange}
                               >
                                 {" "}
                               </textarea>
                             </div>
                             <div className='form-group col-md-6'>
                               <label>Reciepient</label>
                               <input
                                 type='email'
                                 className='form-control'
                                 name="email"
                                 value={this.state.email}
                                 placeholder='Email of reciepient'
                                 onChange={this.onInputchange}
                               />
                             </div>
                           </div>
                           <button onClick={this.CreateGiftCard} type="button" className='btn btn-solid'>
                             CREATE
                           </button>
                         </div>
                       </div>
                       {this.state.loader && (
                                        <div className="row">
                                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                                <div className="loading-cls" />
                                                <p></p>
                                            </div>
                                        </div>
                                    )}
                     </div>
                   );
                 }
               }

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps) (CreateGiftCard); 
