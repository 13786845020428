import React, { Component } from "react";
import { connect } from 'react-redux';
// import '../components/nav.css';
import { useSelector,useDispatch } from 'react-redux';

import axios from 'axios';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {
    getHomePageBrands,
    getHomeSliders,
    getHomeDashboardModules,
} from "../../actions/index";


class Carousel extends Component {
        componentDidMount() {
            this.props.getHomeSliders();
        }

        render() {
            const {sliders} = this.props;
            const settings = {
                dots: false,
                infinite: true,
                fade: true,
                lazyLoad: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
            };

            let url;

            return (
                // <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">

                <Slider className="" {...settings}>
                    {(sliders && sliders.length > 0) ? sliders.map((slider, i) => {
                        /* if (slider.category_id !== 0) {
                             url = `category/${slider.category_id}/products`;
                         } else {
                             url = `product/${slider.product_id}`;
                         }*/
                        return (
                            <div key={i}>
                                <div
                                    className="home text-center homepageslider"
                                    style={{
                                        backgroundImage: `url(${slider.slider_image})`,
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <div className="slider-contain">
                                                    {/*<div>
                                                 <h4>welcome to fashion</h4>
                                                <h2
                                                    style={{
                                                        color: "white",
                                                    }}
                                                >
                                                    {slider.slider_name}
                                                </h2>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/${url}`}
                                                    className="btn btn-solid"
                                                >
                                                    shop now
                                                </Link>
                                            </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }) : ""}
                </Slider>

                // </div>
            );
        }
    }

const mapStateToProps = state => ({
    sliders: state.home.sliders,
});

const mapDispatchToProps = { getHomeSliders };

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);



{/* <div className="menubar text-center">
        <ul className="Item-list  text-center pt-2" >
          <li id="m4" className="px-4 nav-item">
            <a href="" class="">HOME</a> </li>

          <li id="m4 " className=" px-4 nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="http://google.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> CATEGORIES </a>
              <ul class="dropdown-menu  ">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
              </ul>
          </li>

          <li id="m4" className=" px-4 dropdown">
          <a class="nav-link dropdown-toggle" href="http://google.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> BRANDS </a>
            <ul class="dropdown-menu">
              <li><a href="#"  class="dropdown-item" >Service C1</a></li>
            </ul>
          </li>

          <li id="m4" className=" px-4 nav-item"> BEST SELLING</li>
          <li id="m4" className=" px-4 nav-item"> TESTINOMALS</li>
          <li id="m4" className=" px-4 nav-item"> BLOG</li>
        </ul>
      </div> */}
