import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Modal from "react-responsive-modal";
import Breadcrumb from "../common/breadcrumb";
import {getProductCartTotal} from "../../services";
import {
    applyCouponDiscount,
    decrementQty,
    incrementQty,
    removeFromCart,
    resetTheCart,
    setCartdata,
    setCartdatafull,
    updateWithDeliverySLots,
} from "../../actions";
import {
    declareAvailability,
    declareOrderType,
    declareProductPrice,
    declareWishlistAvailability
} from "../../utils/helper";
import {cartDelete_api, getCartList_api, updateCart_api, getAllAddress_api, addDeliverySlots_api} from "../../api/auth";
import {applyCouponCode_api, getCouponList_api, addToWishlist_api, removeproductwishlist_api} from "../../api/product";
import {toast} from "react-toastify";
import moment from "moment";

class ProductCartComponentNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openDeliverySLotsModal: false,
            openMobileDeliverySLotsModal: false,
            singleProduct: null,
            rows: [],
            rowss: [],
            remainSlot: 0,
            loaderForCartList: false,
            couponCode: "",
            loaderForCouponCode: false,
            is_promo_code_model: false,
            list_cupone: [],
            is_wishlist: [],
            not_is_wishlist: []
        };
    }

    componentDidMount() {
        if (this.props.auth.isLoggedIn) {
            this.setCartData();
            this.getAllAddress();
         
        } else {

        }
    }

    getAllAddress = async () => {
        const addressList = await getAllAddress_api()
        const isDefaultAddress = addressList.data.some(value => value.is_default == 1) 
        if(!isDefaultAddress){
            toast.warn("Please select Default Address")
        }
    }

    setCartData = async () => {
        try {
            this.setState({loaderForCartList: true});
            // get cart list
            let apiParams = {
                user_id: this.props.auth.userData.id,
                code: this.state.couponCode,
                // language_id: 1,
            };
            let apiData = await getCartList_api(apiParams);
            // console.log(apiData);
            if (apiData.success) {
                let data = JSON.parse(JSON.stringify(apiData.data));
                for (let index = 0; index < data.length; index++) {
                    for (const x in data[index].product_option) {
                        data[index].product_option[x].selected_options = [];
                    }
                    data[index].delivery_slots = [];
                }
                // set the cart data
                this.props.setCartdata(data);
                this.props.applyCouponDiscount(apiData.total_coupon_price ? apiData.total_coupon_price : 0);
                this.props.setCartdatafull(JSON.parse(JSON.stringify(apiData)));
            } else {
                toast.error(apiData.message);
            }
            this.setState({loaderForCartList: false});
        } catch (error) {
            this.setState({loaderForCartList: false});
            console.log(error);
            toast.error(error.message);
        }
    };

    onCloseModal = () => {
        this.setState({openDeliverySLotsModal: false, singleProduct: null});
        this.setState({openMobileDeliverySLotsModal: false, singleProduct: null});
    };

    PromoCodeModal = async (value) => {

        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                // language_id: 3,
                // product_id:5,
                // category_id:2,
                user_id: (this.props.auth && this.props.auth.userData && this.props.auth.userData.id) ? this.props.auth.userData.id : 0,
            };
            // console.log(apiParams);
            let couponlist = await getCouponList_api(apiParams);
            if (couponlist.success) {
                this.setState({
                    list_cupone: couponlist.data,
                });
                this.setState({is_promo_code_model: value});

            } else {
                toast.error(couponlist.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }


    };

    incrementQty = async (item, qty) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({loaderForCartList: true});
                // add quantity
                let apiParams = {
                    cart_id: item.cart_id,
                    user_id: this.props.auth.userData.id,
                    quantity: qty + 1,
                    type: "add",
                };
                let apiData = await updateCart_api(apiParams);
                console.log(apiData);
                if (apiData.success) {
                    this.setState({loaderForCartList: false});
                    // toast.success("Cart has been updated successfully");
                    this.props.incrementQty(item, qty);
                    this.setCartData();
                } else {
                    this.setState({loaderForCartList: false});
                    toast.error(apiData.message);
                }
            } catch (error) {
                this.setState({loaderForCartList: false});
                console.log(error);
                toast.error(error.message);
            }
        } else {
            // user is not logged in
            this.props.incrementQty(item, qty);
        }
    };

    decrementQty = async (item) => {
        if (this.props.auth.isLoggedIn) {
            try {
                if (item.cart_quantity > 1) {
                    this.setState({loaderForCartList: true});
                    // decrement qty
                    let apiParams = {
                        cart_id: item.cart_id,
                        user_id: this.props.auth.userData.id,
                        quantity: item.cart_quantity - 1,
                    };
                    let apiData = await updateCart_api(apiParams);
                    console.log(apiData);
                    if (apiData.success) {
                        this.setState({loaderForCartList: false});
                        // toast.success("Cart has been updated succrefully");
                        this.props.decrementQty(item, item.cart_quantity - 1);
                        this.setCartData();
                    } else {
                        this.setState({loaderForCartList: false});
                        toast.error(apiData.message);
                    }
                }
            } catch (error) {
                this.setState({loaderForCartList: false});
                console.log(error);
                toast.error(error.message);
            }
        } else {
            // user is not logged in
            if (item.cart_quantity - 1)
                this.props.decrementQty(item, item.cart_quantity - 1);
        }
    };

    removeFromCart = async (item) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({loaderForCartList: true});
                // decrement qty
                let apiParams = {
                    cart_id: item.cart_id,
                    user_id: this.props.auth.userData.id,
                };
                let apiData = await cartDelete_api(apiParams);
                if (apiData.success) {
                    this.setState({loaderForCartList: false});
                    // toast.success("Cart has been updated successfully.");
                    this.setCartData();
                    this.props.removeFromCart(item.id);
                    this.setCartData();
                } else {
                    toast.error(apiData.message);
                }
            } catch (error) {
                this.setState({loaderForCartList: false});
                console.log(error);
                toast.error(error.message);
            }
        } else {
            this.props.removeFromCart(item.id);
        }
    };

    handleClearCart = async (item) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({loaderForCartList: true});
                // decrement qty
                let apiParams = {
                    user_id: this.props.auth.userData.id,
                };
                let apiData = await cartDelete_api(apiParams);
                if (apiData.success) {
                    this.setState({loaderForCartList: false});
                    toast.success(apiData.message);
                    this.props.resetTheCart();
                } else {
                    toast.error(apiData.message);
                }
            } catch (error) {
                this.setState({loaderForCartList: false});
                console.log(error);
                toast.error(error.message);
            }
        } else {
            this.props.resetTheCart();
        }
    };

    handleChange = (idx) => (e) => {
        const {name, value} = e.target;

        if (value >= 0 || name == 'date') {
            const rows = [...this.state.rows];
            rows[idx] = {
                ...rows[idx],
                [name]: value,
            };

            this.setState(
                {
                    rows,
                },
                () => {
                    const rows = this.state.rows;
                    let total = 0;
                    for (let index = 0; index < rows.length; index++) {
                        total += parseInt(rows[index].qty !=="" ? rows[index].qty : 0);
                    }
                    let remainSlot = parseInt(this.state.singleProduct.cart_quantity - total);
                    this.setState({
                        remainSlot: remainSlot > 0 ? remainSlot : 0,
                    });
                }
            );
        }
    };

    handleAddRow = () => {
        let rows = this.state.rows;
        if (rows.length > 0) {
            if (rows[rows.length - 1].qty === 0) {
                return false;
            }
        }
        const item = {
            qty: 0,
            date: "",
        };
        this.setState({
            rows: [...this.state.rows, item],
        });
    };

    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows];
        rows.splice(idx, 1);
        this.setState({rows}, () => {
            const rows = this.state.rows;
            let total = 0;
            for (let index = 0; index < rows.length; index++) {
                total += parseInt(rows[index].qty);
            }
            let remainSlot = parseInt(this.state.singleProduct.cart_quantity - total);
            this.setState({
                remainSlot: remainSlot > 0 ? remainSlot : 0,
            });
        });
    };

    handleUpdateDeliverySlot = async(item) => {


        const rows = [...this.state.rows];
        let total = 0;
        let checkIsValid = false;
        const today = moment().format("YYYY-MM-DD");
        for (let index = 0; index < rows.length; index++) {
            total += parseInt(rows[index].qty);

            if (rows[index].qty < this.state.singleProduct.min_slot_qty_for_schedule_delivery) {
                toast.error(
                    "minimum slot quantity is " + this.state.singleProduct.min_slot_qty_for_schedule_delivery
                );
                return false;
            }

            if (
                moment(rows[index].date).format("YYYY-MM-DD") < today ||
                rows[index].date === ""
            ) {
                checkIsValid = true;
            }
        }
        if (checkIsValid) {
            toast.error("Invalid date found");
            return false;
        }
        if (total !== this.state.singleProduct.cart_quantity) {
            toast.error(
                "Your slots quantity is not matched with actual quantity of order"
            );
            return false;
        }

        let apiParams = {
            cart_id: this.state.singleProduct.cart_id,
            product_slot: JSON.stringify(rows)
        }
        const addDeliverySlots = await addDeliverySlots_api(apiParams)

        if(addDeliverySlots.success){
            toast.success("Delivery slots updated");
            this.setState({
                openDeliverySLotsModal: false,
                openMobileDeliverySLotsModal: false,
            })
    
            let indexOfCurrentProduct = this.props.cartItems.findIndex(
                (product) => product.id === this.state.singleProduct.id
            );
    
            if (indexOfCurrentProduct !== -1) {
                this.props.cartItems[
                    indexOfCurrentProduct
                    ].delivery_slots = this.state.rows;
            }
            this.props.updateWithDeliverySLots(
                this.props.cartItems[indexOfCurrentProduct]
            );
            this.setCartData()
        }


    };

    handleDeleteDeliverySlot = async(id) => {
        const rows = [];
        let apiParams = {
            cart_id: id,
            product_slot: JSON.stringify(rows)
        }
        const addDeliverySlots = await addDeliverySlots_api(apiParams)
        if(addDeliverySlots.success){
            toast.success("Slots Removed Successfully")
            this.setCartData()
        }else {
            toast.error(addDeliverySlots.message)
        }
    }

    declareTaxesOfProduct = () => {
        const {cartItems} = this.props;
        var total = 0;
        var taxTypesSum;
        for (var i = 0; i < cartItems.length; i++) {
            taxTypesSum = 0;
            // if (declareOrderType(cartItems[i]) === "Retail") {
            //   if (cartItems[i].tax_price.length > 0) {
            //     for (var j = 0; j < cartItems[i].tax_price.length; j++) {
            //       if (cartItems[i].tax_price[j].tax_amount)
            //         taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // } else {
            //   if (cartItems[i].wholesale_tax_price.length > 0) {
            //     for (var k = 0; k < cartItems[i].wholesale_tax_price.length; k++) {
            //       if (cartItems[i].tax_price[k].tax_amount)
            //         taxTypesSum += parseInt(
            //           cartItems[i].wholesale_tax_price[k].tax_amount
            //         );
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // }

            if (cartItems[i].tax_price.length > 0) {
                for (var j = 0; j < cartItems[i].tax_price.length; j++) {
                    if (cartItems[i].tax_price[j].tax_amount)
                        taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
                }
                total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            }
        }
        return total;
    };

    handleDeliverySlotsModal = (item) => {
       
        let total = 0;
        for (let index = 0; index < item.delivery_slots.length; index++) {
            total += parseInt(item.delivery_slots[index].qty);
        }
        let remainSlot = parseInt(item.cart_quantity - total);
        this.setState({
            openDeliverySLotsModal: true,
            openMobileDeliverySLotsModal: false,
            singleProduct: item,
            remainSlot: remainSlot > 0 ? remainSlot : 0,
            rows: item.delivery_slots,
        });
    };
    handleMobileDeliverySlotsModal = (item) => {
     
        let total = 0;
        for (let index = 0; index < item.delivery_slots.length; index++) {
            total += parseInt(item.delivery_slots[index].qty);
        }
        let remainSlot = parseInt(item.cart_quantity - total);
        this.setState({
            openDeliverySLotsModal: false,
            openMobileDeliverySLotsModal: true,
            singleProduct: item,
            remainSlot: remainSlot > 0 ? remainSlot : 0,
            rows: item.delivery_slots,
        });
    };

    handleApplyCouponCode = async (e, code) => {
        // e.preventDefault();

        if (code !== "") {
            try {
                this.setState({loaderForCouponCode: true});
                let apiParams = {
                    code: code ? code : this.state.couponCode,
                    user_id: this.props.auth.userData.id,
                    language_id: 1,
                };
                let couponApiData = await applyCouponCode_api(apiParams);
                this.setState({loaderForCouponCode: false});
                console.log(couponApiData);
                if (couponApiData.success && couponApiData.total_coupon_price > 0) {
                    // this.setState({couponCode: ""});
                    let totalDiscount = 0;
                    if (couponApiData.data.length > 0) {
                        for (let index = 0; index < couponApiData.data.length; index++) {
                            totalDiscount += parseFloat(
                                couponApiData.data[index].coupon_price
                            );
                        }
                    }
                    console.log(totalDiscount);
                    if (totalDiscount > 0) {
                        // dispatch coupon code action
                        this.setState({couponCode: code});
                        localStorage.setItem('code_set',code);
                        this.props.applyCouponDiscount(totalDiscount);
                        toast.success("Promo code applied successfully");
                    }
                } else {
                    if(couponApiData.total_coupon_price == 0) {
                        toast.error("this coupon code is not valid")
                    }else {
                        toast.error(couponApiData.message);
                    }
                    this.setState({couponCode: ""});
                }
            } catch (error) {
                console.log(error);
                this.setState({couponCode: ""});
                toast.error(error.message);
                this.setState({loaderForCouponCode: false});
            }
        }
    };

    addToWishList = async (item, is_already_in = 0) => {
        
        this.setCartData();
        if (this.props.auth.isLoggedIn) {
            try {
                if (is_already_in == 1) {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id")
                            ? item.product_id
                            : item.id,
                    };
                    
                    let apiData = await removeproductwishlist_api(apiParams);
                    if (apiData.success) {
                        this.setState({is_wishlist: []})
                        var wishlist_ids = this.state.not_is_wishlist.concat(apiParams.product_id);
                        this.setState({not_is_wishlist: wishlist_ids})
                            let apiParamsParams = {
                                user_id: this.props.auth.userData.id,
                                code: this.state.couponCode,
                                // language_id: 1,
                            };
                            let apiDatagetCartList_api = await getCartList_api(apiParamsParams);
                            toast.success(apiData.message);
                    } else {
                        toast.error(apiData.message);
                    }
                } else {
                    console.log(apiData,"123");
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id")
                            ? item.product_id
                            : item.id,
                    };
                    let apiData = await addToWishlist_api(apiParams);
                    if (apiData.success) {
                        var wishlist_ids = this.state.is_wishlist.concat(apiParams.product_id);
                        this.setState({is_wishlist: wishlist_ids})
                        this.setState({not_is_wishlist: []})
                        this.setCartData();
                        let apiParamsParams = {
                            user_id: this.props.auth.userData.id,
                            code: this.state.couponCode,
                        };
                        let apiDatagetCartList_api = await getCartList_api(apiParamsParams);
                        toast.success(apiData.message);
                    } else {
                        toast.error(apiData.message);
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
            }
            
        } else {
            toast.info("Please login to continue");
            setTimeout(() => {
                window.location.href = "/login"
            }, 3000)

        }
    };


    render() {
        const {
            cartItems,
            symbol,
            total,
            coupon_discount,
            cartLoader,
            cart_full,
            cart
        } = this.props;
        console.log("this.cartitems", cartItems)
        const {min_order_amount, max_order_amount, low_stock_quantity} = this.props.settings;
        const {singleProduct, rows, remainSlot, loaderForCartList} = this.state;
        const cartlist = JSON.parse(localStorage.getItem('cartlist'), true)
        var {is_wishlist, not_is_wishlist, couponCode} = this.state;      
        return (
          <div>
            {/*SEO Support*/}
            <Helmet>
              <title> Cart </title>
              <meta name='description' />
            </Helmet>
            {/*SEO Support End */}
            <div className="cart-d-none">
              <Breadcrumb title={"Cart"} />
            </div>
            {cartItems.length > 0 && !cartLoader && !loaderForCartList ? (
              <section className='cart-section section-b-space paddingTop60'>
                <div className='container-fluid'>
                  <div className='row mx-sm-3 mx-sm-1'>
                    <div className='col-sm-9 mb-3'>
                      <table className='table cart-table table-responsive-md cart-d-none'>
                        <thead>
                          <tr className='table-head'>
                            <th scope='col' className='text-left'>
                                Product Details
                            </th>
                            <th scope='col'>price</th>
                            <th scope='col'>quantity</th>
                            <th scope='col'>action</th>
                            <th scope='col'>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody className='cart-table-res'>
                          {cartItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className='text-left align-top'>
                                  <div className='d-flex'>
                                    <div>
                                      <div className='small_image_90 d-inline-block border-wishlist'  
                                    >
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}
                                        >
                                          <img
                                            className='w-100 small_image_borders'
                                            src={item.product_image}
                                            alt=''
                                            // onClick={() =>
                                            //     { 
                                            //         item.is_in_wishlist === 0 ?   
                                            //         this.addToWishList(item) :
                                            //          this.addToWishList(item,1) }
                                            //     }
                                          />
                                        </Link>

                                        <div className="padding-l-10 c-pointer">
                                            <i className={ `fa fa-heart ${item.is_in_wishlist === 0 ? 'unactive-wishlist-icon' : 'active-wishlist-icon'}`} aria-hidden='true'></i>
                                                <span className={item.is_in_wishlist === 0 ? 'cart-add-wishlist-btn' : 'cart-remove-wishlist'}
                                                  onClick={() =>
                                                    { item.is_in_wishlist === 0 ?  this.addToWishList(item) : this.addToWishList(item,1) } }>
                                            {item.is_in_wishlist === 0 ? 'Add To Wishlist' : 'Remove To Wishlist'}</span>
                                        </div>

                                        {/* {item.is_in_wishlist == 1 ?    <div className='w-100 small_image_borders_added text-danger mt-1 p-1 '>
                                          <a title='Add to Wishlist'>
                                            <span className='text-right'>
                                                <i style={{color: "#2472f0"}} className={"fa fa-heart fa-lg " } aria-hidden="true">
                                                    {" "}
                                                    <strong
                                                    className='Added-to-Whishlist'
                                                    style={{ marginLeft: "4px",color: "#2472f0" }}
                                                    >
                                                    Add to Wishlist
                                                    </strong>    
                                                </i>
                                            </span>
                                          </a>
                                        </div> :   <div className='w-100 small_image_borders  mt-1 p-1'>
                                          <a title='Add to Wishlist'
                                            className="cart-wishlist-btn"
                                            onClick={() =>
                                              { 
                                                item.is_in_wishlist === 0 ?   
                                                this.addToWishList(item) :
                                                this.addToWishList(item,1) }
                                              }>
                        
                                                <i className={ `fa fa-heart ${item.is_in_wishlist === 0 ? 'unactive-wishlist-icon' : 'active-wishlist-icon'}`} aria-hidden='true'></i>
                                                <span className={item.is_in_wishlist === 0 ? 'cart-add-wishlist-btn' : 'cart-remove-wishlist'}>
                                            {item.is_in_wishlist === 0 ? 'Add To Wishlist' : 'Remove To Wishlist'}</span>
                                          </a>
                                        </div> } */}
                                     
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        className='d-inline-block  w-80 style_text_name pl'
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}
                                        >
                                          <b className='style_text_name cart-text-res'>
                                            {item.product_fullname
                                              ? item.product_fullname.replace(
                                                  "(Child)",
                                                  ""
                                                )
                                              : ""}
                                          </b>
                                        </Link>

                                        <div>
                                          <b>Availability: </b>
                                          <samp
                                            className={
                                              "check___" +
                                              declareAvailability(item)
                                            }
                                          >
                                            {declareAvailability(item)}
                                          </samp>
                                        </div>

                                        {item &&
                                        item.option_data &&
                                        item.option_data.length > 0
                                          ? item.option_data.map((opt, i) => {
                                              return (
                                                <div>
                                                  <b>
                                                    {opt.option_name
                                                      ? opt.option_name
                                                      : ""}
                                                    :{" "}
                                                  </b>
                                                  <samp>
                                                    {opt.option_name
                                                      ? opt.option_value_name
                                                      : ""}
                                                  </samp>
                                                </div>
                                              );
                                            })
                                          : ""}
                                        <div>
                                          <b>Order Type: </b>
                                          <span
                                            className={
                                              "check___" +
                                              declareOrderType(item)
                                            }
                                          >
                                            {" "}
                                            {declareOrderType(item)}{" "}
                                          </span>
                                        </div>
                                        {item.product_id_with_distance && <div>
                                          <b>Distance: </b>
                                          <span>
                                            {" "}
                                            {
                                              item.product_id_with_distance
                                            } KM{" "}
                                          </span>
                                        </div>}
                                          {
                                              ( item.ship_price != 0 && item.ship_price)?<div>
                                                  <b>Shipping Price: </b>
                                                  <span>
                                                  {symbol} {
                                                      item.ship_price
                                                  }
                                          </span>
                                              </div>:"Shipping Price: "
                                          }

                                        
                                        {item.coupon_price !== 0 && item.coupon_price ?
                                            <div>
                                                <span className="text-success font-weight-bold">You have saved {symbol + parseFloat(item.coupon_price).toFixed(2)}</span>
                                            </div> : ""
                                        }

                                        {/* Deliver Slots div */}
                                        <div>
                                          {item.product_slot && JSON.parse(item.product_slot).length > 0 && (
                                            <hr className='mb-0' />
                                          )}
                                          {item.product_slot && JSON.parse(item.product_slot).length > 0 &&
                                            JSON.parse(item.product_slot)
                                              .filter((fltr) => fltr.qty > 0)
                                              .map((opt, i) => {
                                                return (
                                                  <span key={i}>
                                                    <br />
                                                    <span>
                                                      <strong>Slot:</strong>
                                                      {i + 1}{" "}
                                                      <strong>Quantity:</strong>
                                                      {opt.qty}{" "}
                                                      <strong>Date:</strong>
                                                      {opt.date}{" "}
                                                    </span>
                                                  </span>
                                                );
                                            })}
                                                {item.product_slot && JSON.parse(item.product_slot).length > 0 ? 
                                                <span className="cursor-pointer" onClick={()=> this.handleDeleteDeliverySlot(item.cart_id)}>
                                                    <i className="fa fa-trash fa-lg text-danger" style={{fontSize:"24px"}}></i>
                                                </span> : ""
                                            }
                                        </div>

                                        <div className='mobile-cart-content row'>
                                          <div className='col-xs-3'>
                                            <div className='qty-box'>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  name='quantity'
                                                  className='form-control input-number'
                                                  defaultValue={
                                                    item.cart_quantity
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-xs-3'>
                                            <h2 className='td-color'>
                                              {symbol}
                                              {/* {item.product_price} */}
                                              {declareProductPrice(item)}
                                            </h2>
                                          </div>
                                          <div className='col-xs-3'>
                                            <h2 className='td-color'>
                                              <span
                                                className='icon'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.removeFromCart(item);
                                                }}
                                              >
                                                <i className='icon-close' />
                                              </span>
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h2 className='td-color'>
                                    {symbol}
                                    {/* {item.product_price} */}
                                    {declareProductPrice(item).replace(
                                      /\.0$/,
                                      ""
                                    )}{" "}
                                    {item.unit_name != ""
                                      ? "/" + item.unit_name
                                      : ""}
                                  </h2>
                                </td>
                                <td style={{ width: 150 }}>
                                  <div className='qty-box mb-2'>
                                    <div className='input-group'>
                                      {/* <span className='input-group-prepend'>
                                        <button
                                          type='button'
                                          className='btn quantity-left-minus d-none'
                                          onClick={() =>
                                            this.decrementQty(item)
                                          }
                                          data-type='minus'
                                          data-field=''
                                        >
                                          <i className='fa fa-angle-left' />
                                        </button>
                                      </span> */}
                                      <input
                                        type='text'
                                        name='quantity'
                                        value={item.cart_quantity}
                                        readOnly={true}
                                        className='form-control'
                                      />
                                      {/* <span className='input-group-prepend'>
                                        <button
                                          className='btn quantity-right-plus d-none'
                                          onClick={() => {
                                            if (
                                              item.cart_quantity <=
                                              item.max_quantity
                                            ) {
                                              this.incrementQty(
                                                item,
                                                item.cart_quantity
                                              );
                                            } else {
                                              toast.success(
                                                "max quantity to buy this item is " +
                                                  item.max_quantity +
                                                  "."
                                              );
                                            }
                                          }}
                                          data-type='plus'
                                          disabled={
                                            item.cart_quantity >= item.stock
                                              ? true
                                              : false
                                          }
                                        >
                                          <i className='fa fa-angle-right' />
                                        </button>
                                      </span> */}
                                    </div>
                                  </div>    

                                  {(item.product_val_type === 2 ||
                                    item.product_val_type === 3) &&
                                  item.is_pre_order === 0 &&
                                  item.cart_quantity >=
                                    item.product_min_wholesale_quantity ? (

                                    <span
                                      className='c-pointer-with-color font-weight-bold schedule_order_btn'
                                      onClick={(e) => {
                                        this.handleDeliverySlotsModal(item);
                                      }}
                                    >
                                      Schedule Order
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <span
                                    className='icon'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.removeFromCart(item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className='fa fa-times fa-3x text-danger' />
                                  </span>
                                </td>
                                <td>
                                  <h2 className=''>
                                    {symbol}
                                    {/* {item.sum} */}
                                    {item.cart_quantity *
                                      declareProductPrice(item)}
                                  </h2>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                        {cartItems.map((item, index) => {
                            return (
                                <>
                      <div className="row cart-d-none-mobile cartproduct-wrp">
                        <div className="col-4">
                          <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`} >
                            <img
                              className='small_image_80'
                              src={item.product_image}
                              alt=''
                            />
                          </Link>
                          <div>
                          <div className="row">


                                <div className="col">
                                  <div className="input-group m-automobile" style={{width: 120}}>


                                      <span className='input-group-prepend'>
                                        <button
                                            type='button'
                                            className='btn quantity-left-minus'
                                            onClick={() =>
                                                this.decrementQty(item)
                                            }
                                            data-type='minus'
                                            data-field=''>
                                          <i className='fa fa-angle-left'/>
                                        </button>
                                      </span>
                                      <input
                                          type='text'
                                          name='quantity'
                                          value={item.cart_quantity}
                                          readOnly={true}
                                          className='form-control input-number text-center'
                                      />
                                      <span className='input-group-prepend'>
                                        <button
                                            className='btn quantity-right-plus'
                                            onClick={() =>
                                                this.incrementQty(
                                                    item,
                                                    item.cart_quantity
                                                )
                                            }
                                            data-type='plus'
                                            disabled={
                                                item.cart_quantity >= item.stock
                                                    ? true
                                                    : false
                                            }
                                        >
                                          <i className='fa fa-angle-right'/>
                                        </button>

                                      </span>

                                  </div>
                                  {(item.product_val_type === 2 ||
                                                            item.product_val_type === 3 || item.product_val_type === 1) &&
                                                        item.is_pre_order === 0 &&
                                                        item.cart_quantity >=
                                                        item.product_min_wholesale_quantity ? (
                                                            <span
                                                                className='c-pointer-with-color font-weight-bold'
                                                                onClick={(e) => {
                                                                    this.handleMobileDeliverySlotsModal(item);
                                                                }}
                                                            >
                                      Schedule Order
                                    </span>
                                                        ) : (
                                                            ""
                                                        )}
                                </div>

                            </div>
                          </div>
                        </div>
                        <div className="col-8 cart-title-set">

                        <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
                          <b className='cart-text-res'>
                            {item.product_name}
                          </b>
                        </Link>

                            <div><b> Availability:  </b><samp className={"check___" + declareAvailability(item)}>
                                {declareAvailability(item)}
                            </samp></div>

                          {item && item.option_data && item.option_data.length > 0 ? item.option_data.map((opt, i) => {
                                return (<div><b>{opt.option_name? opt.option_name:""}:</b><samp>{opt.option_name?opt.option_value_name: ""}</samp></div>);
                              })
                            : ""}
                            <div><b>Order Type: </b><samp className={"check___" + declareOrderType(item)}> {declareOrderType(item)} </samp></div>
                            <div><b>Distance: </b><span > {parseFloat(item.product_id_with_distance).toFixed(2)} KM </span></div>
                            {
                                ( item.ship_price != 0)?<div>
                                    <b>Shipping Price: </b>
                                    <span>
                                                  {symbol}{
                                        item.ship_price
                                    }
                                          </span>
                                </div>:"Shipping Price: Free"
                            }

                            <div className="">
                                <a href="javascript:void(0)" title="Add to Wishlist"
                                   onClick={() => this.addToWishList(item)}>
                                    <div className="row">
                                        <div className='col-12'>
                                            <i
                                                className={((item.is_in_wishlist == 1 && (!not_is_wishlist.includes(item.id))) || is_wishlist.includes(item.id)) ? "fa fa-heart fa-lg d-inline-block text-danger" : "fa fa-heart fa-lg d-inline-block"}
                                                // class='fa fa-heart-o fa-lg d-inline-block ml-3'
                                                aria-hidden='true'
                                            ><label className="font-size-text-14 theme_text_set" style={{marginLeft: "4px"}}>Add to Wishlist</label></i>
                                        </div>

                                    </div>
                                </a>
                            </div>

                          <div className='col-xs-3'>
                              <span
                                className='icon float-right text-danger'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.removeFromCart(item);
                                }}
                                style={{fontSize: "1.5rem"}}
                              >
                                <i className='fa fa-times' />
                              </span>
                              <h5 className="fdsfsfsdfds">
                                {symbol} {item.cart_quantity * declareProductPrice(item)}
                              </h5>
                            </div>

                        </div>
                      </div>
                      {/*<hr className="cart-d-none-mobile" />*/}
                      </>
                      )})}
                      {/*<table className="table cart-table table-responsive-md">*/}
                      {/*    <tfoot>*/}
                      {/*    <tr>*/}
                      {/*        <td>total price : <h2> {symbol} {total}{" "}</h2></td>*/}
                      {/*    </tr>*/}
                      {/*    </tfoot>*/}
                      {/*</table>*/}
                    </div>
                    <div className='col-sm-3 promocode-wrp'>
                      <h4 className='font-weight-bold'>PROMO CODE</h4>
                      <span className='text-muted'>
                        {" "}
                        Enter coupon code if you have any{" "}
                      </span>
                      <br/>
                      <button style={{color: "#2472f0", backgroundColor: "#fff"}}
                        className='font-weight-bold my-2'
                        onClick={() => {
                          this.PromoCodeModal(true);
                        }}
                      >
                        {this.state.couponCode
                          ? "Use Promo code:" + this.state.couponCode
                          : "Add Promo code"}
                      </button>
                      {this.state.couponCode ? this.state.list_cupone &&
                                this.state.list_cupone.length > 0 ? (
                                  this.state.list_cupone.filter((couponList) => couponList.code === this.state.couponCode).map((val, i) => {
                                    return (
                                      <div className="container">
                                         <div className="row p-15" style={{backgroundColor: "rgba(4, 123, 213, 0.1)", marginBottom: "0.5rem"}}>
                                            <div className="d-flex flex-column w-100">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div>
                                                        <input type="radio" name="c1" checked={this.state.couponCode === val.code ? true : false} />
                                                        <span class="badge badge-secondary ml-2">{val.code}</span>
                                                    </div>
                                                    <h4 className="theme_text_set">{val.type === "P" ? val.discount + "%" : symbol + " " + val.discount}</h4>
                                                </div>
                                                <div className="d-flex justify-content-between">    
                                                <span>{val.name}</span>
                                                <button className="font-weight-bold text-danger"
                                                    onClick={(e) => {
                                                        this.setState({couponCode: ""});
                                                            setTimeout(() => { this.setCartData(); }, 200); }}> {"Remove"}
                                                        </button>
                                                </div>
                                            </div>
                                         </div> 
                                            {/* <div className="row" style={{backgroundColor: "rgba(4, 123, 213, 0.1)", marginBottom: "0.5rem"}}>
                                                <div className="col-md-1 my-auto">
                                                    <input type="radio" name="c1" checked={this.state.couponCode === val.code ? true : false} />
                                                    <span class="badge badge-secondary">{val.code}</span>
                                                </div>
                                                        <div className="col-md-6 my-auto text-center">
                                                            <h4 className="theme_text_set">{val.type === "P"
                                                                ? val.discount + "%"
                                                                : symbol + " " + val.discount}</h4>
                                                        </div>
                                                        <div className="col-md-4 my-auto">
                                                            <span>{val.name}</span>
                                                        </div>
                                                        <div className="col-md my-auto" style={{textAlign: "right"}}>
                                                            <button className="font-weight-bold text-danger"
                                                                    onClick={(e) => {
                                                                        this.setState({couponCode: ""});
                                                                        setTimeout(() => {
                                                                            this.setCartData();
                                                                        }, 200);
                                                                    }}>
                                                                {"Remove"}
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                </div>);
                                        })
                                    ) : ("") : ""
                                    }
                                    <form
                                        className='d-none'
                                        onSubmit={(e) => this.handleApplyCouponCode(e)}
                                    >
                                        <div className='form-group '>
                                            <div className='input-group mt-2'>
                                                <input
                                                    type='text'
                                                    className='form-control coupon'
                                                    placeholder='e.g. FIRST50'
                                                    value={this.state.couponCode}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            couponCode: e.target.value,
                                                        })
                                                    }
                                                />
                                                <span className='input-group-append'>
                                            <button
                                                className='btn btn-solid'
                                                disabled={
                                                    this.state.loaderForCouponCode ||
                                                    this.state.couponCode === ""
                                                }
                                            >
                                            Apply
                                                {this.state.loaderForCouponCode && (
                                                    <i className='fa fa-spinner ml-3 fa-pulse'/>
                                                )}
                                            </button>
                                            </span>
                                            </div>
                                        </div>
                                    </form>
                                    <div
                                        className='p-2 table-border-width'
                                        style={{border: "1px solid #ced4da"}}
                                    >
                                        <h4 className='font-weight-bold my-2'>
                                            PAYMENT SUMMARY
                                        </h4>
                                        <table
                                            className='payment_summary_table'
                                            style={{
                                                // tableLayout: "fixed",
                                                width: "100%",
                                            }}
                                        >
                                            {this.props.auth.isLoggedIn ? (
                                                <tbody>
                                                {/*<tr>
                                                <th  className="py-2" >SubTotal</th>
                                                <td  className="py-2" style={{textAlign: "right"}}>
                                                    {symbol} {(cart_full&&cart_full.total)?parseFloat(cart_full.total):0}
                                                </td>
                                            </tr>*/}
                                                <tr>
                                                    <th className='py-2'>
                                                        Subtotal
                                                    </th>
                                                    <td
                                                        className='py-2 d-block pr-4'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        
                                                        {cart_full && cart_full.total? cart_full.total.toFixed(2): 0}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                <th  className="py-2" >Taxes</th>
                                                <td  className="py-2"  style={{textAlign: "right"}}>
                                                    {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                                </td>
                                            </tr>*/}

                                                <tr>
                                                    <th className='py-2'>Coupon Discount</th>
                                                    <td
                                                        className='py-2 text-danger d-block pr-4'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        {cart_full && cart_full.total_coupon_price
                                                            ? cart_full.total_coupon_price
                                                            : 0}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='py-2'>Shipping Price</th>
                                                    <td
                                                        className='py-2 text-danger d-block pr-4'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        {cart_full && cart_full.total_ship_price
                                                            ? cart_full.total_ship_price
                                                            : 0}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='py-2 w-70'>
                                                        Total
                                                    </th>
                                                    <td
                                                        className='py-2 w-30 theme_text_ligblue cart-display-block pr-4'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        {cart_full && cart_full.total
                                                            ? (parseFloat(cart_full.total_ship_price) + parseFloat(cart_full.total) - parseFloat(cart_full.total_coupon_price)).toFixed(2)
                                                            : 0}
                                                    </td>
                                                </tr>

                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {/*<tr>
                                                <th  className="py-2" >SubTotal</th>
                                                <td  className="py-2" style={{textAlign: "right"}}>
                                                    {symbol} {(cart_full&&cart_full.total)?parseFloat(cart_full.total):0}
                                                </td>
                                            </tr>*/}
                                                <tr>
                                                    <th className='py-2'>
                                                        Grand total excl. taxes
                                                    </th>
                                                    <td
                                                        className='py-2 d-block'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol} {total}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                <th  className="py-2" >Taxes</th>
                                                <td  className="py-2"  style={{textAlign: "right"}}>
                                                    {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                                </td>
                                            </tr>*/}

                                                <tr>
                                                    <th className='py-2'>Coupon Discount</th>
                                                    <td
                                                        className='py-2 text-danger d-block'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol} {coupon_discount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='py-2'>Shipping Price</th>
                                                    <td
                                                        className='py-2 text-danger d-block'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        {cart_full && cart_full.total_ship_price
                                                            ? cart_full.total_ship_price
                                                            : 0}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className='py-2 w-70'>
                                                        Grand total inc. taxes
                                                    </th>
                                                    <td
                                                        className='py-2 w-30 theme_text_ligblue cart-display-block'
                                                        style={{textAlign: "right"}}
                                                    >
                                                        {symbol}{" "}
                                                        {parseFloat(total).toFixed(2) - parseFloat(coupon_discount)}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            )}
                                        </table>


                                        <div className="">
                                        {/* <Link to={`${process.env.PUBLIC_URL}/checkout`} className='btn btn-solid' style={{width: "100%"}}>
                                            Proceed to checkout
                                        </Link> */}

                                            {this.props.auth.isLoggedIn ? <>
                                                    {cart_full &&
                                                    cart_full.total &&
                                                    parseFloat(cart_full.total) >=
                                                    parseFloat(min_order_amount) &&
                                                    parseFloat(cart_full.total) <=
                                                    parseFloat(max_order_amount) &&
                                                    cart_full.orderrestriction == "No" ? (
                                                        <div
                                                            colSpan={2}
                                                            class='text-left cart-display-block'
                                                        >
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/checkout`}
                                                                className='btn btn-solid'
                                                                style={{width: "100%"}}
                                                            >
                                                                Proceed to checkout
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            colSpan={2}
                                                            class='text-left cart-display-block'
                                                        >
                                                            <button
                                                                disabled={true}
                                                                className='btn btn-solid'
                                                            >
                                                                Proceed to checkout
                                                            </button>
                                                        </div>
                                                    )}</>:
                                                <div
                                                    colSpan={2}
                                                    class='text-left cart-display-block'
                                                >
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/login`}
                                                        className='btn btn-solid  w-100'
                                                    >
                                                        Login
                                                    </Link>
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-2 btn-wrp'>
                                <div className='col-12 col-md-9 col-lg-9 d-flex justify-content-end'>
                                    {/* <Link
                        to={`${process.env.PUBLIC_URL}`}
                        className='btn btn-solid bg-color_green mx-2 '
                      >
                        Update cart
                      </Link> */}

                                    <span
                                        className='btn btn-solid c-pointer bg-color_orange f-width'
                                        onClick={this.handleClearCart}
                                    >
                                            Clear Cart
                                            </span>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : cartLoader || loaderForCartList ? (
                    <div className='row'>
                        <div className='col-sm-12 text-center section-b-space mt-5 no-found mb-5'>
                            <div className='loading-cls'/>
                        </div>
                    </div>
                ) : (
                    <section className='cart-section section-b-space'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div>
                                        <div className='col-sm-12 empty-cart-cls text-center'>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                                className='img-fluid mb-4'
                                                alt=''
                                            />
                                            <h3>
                                                <strong>Your Cart is Empty</strong>
                                            </h3>
                                            <h4>Explore more shortlist some items.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <Modal open={this.state.openMobileDeliverySLotsModal} onClose={this.onCloseModal} center>
                    <div className="modal-body cart-d-none-mobile">
                            <div className="row">
                                <div className="col-sm-12">
                                    {rows.map((item, idx) => (
                                        <div className="row">
                                         <div className="col-12">
                                            <strong className="font-weight-bold">Delivery Slot {idx + 1}</strong>
                                         </div>
                                         <div className="col-5">
                                             <div className="form-group">
                                             <label for="qty">Quantity</label>
                                                <input
                                                    id="qty"
                                                    type='number'
                                                    name='qty'
                                                    value={rows[idx].qty}
                                                    onChange={this.handleChange(idx)}
                                                    className='form-control'/>
                                             </div>
                                         </div>
                                         <div className="col-5">
                                             <div className="form-group">
                                                <label for="date">Date</label>
                                                <input
                                                    id="date"
                                                    type='date'
                                                    name='date'
                                                    value={rows[idx].date}
                                                    onChange={this.handleChange(idx)}
                                                    className='form-control'
                                                    min={moment()
                                                        .local()
                                                        .format("YYYY-MM-DD")} />
                                             </div>
                                         </div>
                                         <div className="col-2">
                                            <div className="form-group">
                                                <label for="del">Action</label>
                                                <span className='icon' id="del" onClick={this.handleRemoveSpecificRow(idx)}
                                                style={{cursor: "pointer", color: "red"}} >
                                                        <i className='fa fa-times fa-2x'/>
                                                </span>
                                            </div>
                                         </div>
                                     </div>
                                    ))}
                                </div>
                                    <div className='col-sm-12'>
                        {remainSlot > 0 ?
                          <span onClick={this.handleAddRow}>
                            <span className='c-pointer-with-color font-weight-bold'>
                              <i className='fa fa-plus'/> Add Delivery Slots{" "}
                            </span>
                            ({remainSlot} left)
                          </span> : ""
                        }
                                                <br/>
                                                {this.props.cartItems.map((item) => {
                                        return <button
                                                    className='btn btn-solid mt-2'
                                                    onClick={this.handleUpdateDeliverySlot}
                                                >
                                                    UPDATE
                                                </button>
                                            })}
                                            </div>
                            </div>
                    </div>
                </Modal>

                {/* // Modal for delivery slots */}
                <Modal
                    open={this.state.openDeliverySLotsModal}
                    onClose={this.onCloseModal}
                >
                    <div className="cart-schedule-modal">
                        <div className='modal-dialog modal-lg' role='document'>
                            <div className='modal-content quick-view-modal'>
                                <div className='modal-header pt0cs'>
                                    <h5 className='modal-title'>Set Delivery Slots</h5>
                                </div>
                                <div className='modal-body'>
                                    <div className='row'>
                                        <div className='col-sm-12 mb-3 mb0cs'>
                                            {/* <table className="table cart-table table-responsive-md"> */}
                                            <div className="tabscroll">
                                                <table className='table'>
                                                    <thead>
                                                    <tr className='table-head'>
                                                        <th scope='col'>Delivery Slot</th>
                                                        <th scope='col'>Quantity</th>
                                                        <th scope='col'>Date</th>
                                                        <th scope='col'>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {rows.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td className="font-weight-bold"> Delivery
                                                                Slot {idx + 1}</td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    name='qty'
                                                                    value={rows[idx].qty}
                                                                    onChange={this.handleChange(idx)}
                                                                    className='form-control'
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='date'
                                                                    name='date'
                                                                    value={rows[idx].date}
                                                                    onChange={this.handleChange(idx)}
                                                                    className='form-control'
                                                                    min={moment()
                                                                        .local()
                                                                        .format("YYYY-MM-DD")}
                                                                />
                                                            </td>
                                                            <td>
                                    <span
                                        className='icon'
                                        onClick={this.handleRemoveSpecificRow(idx)}
                                        style={{cursor: "pointer", color: "red"}}
                                    >
                                        <i className='fa fa-times fa-2x'/>
                                    </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='col-sm-12'>
                                                {remainSlot > 0 ?  
                          <span onClick={this.handleAddRow}>
                            <span className='c-pointer-with-color font-weight-bold'>
                              <i className='fa fa-plus'/> Add Delivery Slots{" "}
                            </span>
                            ({remainSlot} left)
                          </span>
                          : ""}
                                                <br/>
                                                <button
                                                    className='btn btn-solid mt-2 mt20cs'
                                                    onClick={this.handleUpdateDeliverySlot}
                                                >
                                                    UPDATE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.is_promo_code_model}
                    onClose={() => this.PromoCodeModal(false)}
                >
                    <div className='cart-modal'>
                        <div className='modal-dialog modal-xl' role='document'>
                            <div className='modal-content quick-view-modal'>
                                <div className=''>
                                    <div className='row'>
                                        <div className='mb-3'>
                                            <h4 className='modal-title'>Enter Coupon Code</h4>
                                        </div>
                                        <div class='input-group mb-3'>
                                            <input
                                                type='text'
                                                class='form-control'
                                                placeholder="DSFSDF45435DFGFD"
                                                aria-label="coupon"
                                                onChange={(e) => this.setState({couponCode: e.target.value})}
                                            />
                                            <div class='input-group-append'>
                                                <button
                                                    class='btn btn-outline-secondary'
                                                    type='button'
                                                    style={{
                                                        backgroundColor: "#2472f0",
                                                        color: "#fff"
                                                    }}
                                                    onClick={(e) => {
                                                        this.PromoCodeModal(false);

                                                        this.handleApplyCouponCode(
                                                            e,
                                                            this.state.couponCode
                                                        );
                                                        setTimeout(() => {
                                                            this.setCartData();
                                                        }, 200);
                                                    }}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='hr-or'></div>
                                <div className='modal-body'>
                                    <div className='row'>
                                        <div className='mb-3'>
                                            <h5>Coupons For You</h5>
                                        </div>
                                        <div className="col-md-12 coupon-code">

                                        
                                        {this.state.list_cupone &&
                                        this.state.list_cupone.length > 0 ? (
                                            this.state.list_cupone.map((val, i) => {
                                                return (
                                                    <div className="col-md-12">
                                                        <div className="row" style={{
                                                            backgroundColor: "rgba(4, 123, 213, 0.1)",
                                                            height: "70px",
                                                            marginBottom: "1.5rem"
                                                        }}>
                                                            <div className="col-md-1 my-auto">
                                                                <input type="radio" name="c1"
                                                                       checked={this.state.couponCode === val.code ? true : false}
                                                                       onClick={(e) => {
                                                                           this.setState({
                                                                               couponCode: val.code,
                                                                           });
                                                                           this.PromoCodeModal(false);

                                                                           this.handleApplyCouponCode(
                                                                               e,
                                                                               val.code
                                                                           );
                                                                           setTimeout(() => {
                                                                               this.setCartData();
                                                                           }, 200);
                                                                       }}/>
                                                            </div>
                                                            <div className="col-md-3 my-auto">
                                                                <h5><span
                                                                    class="badge badge-secondary">{val.code}</span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-md-3 my-auto">
                                                                <strong
                                                                    className="theme_text_set">{val.type === 'P' ? "Percentage" : "Fixed"}</strong>
                                                            </div>
                                                            <div className="col-md-2 my-auto">
                                                                <h4 className="theme_text_set">{val.type === "P"
                                                                    ? val.discount + "%"
                                                                    : symbol + " " + val.discount}</h4>
                                                            </div>
                                                            <div className="col-md-3 my-auto">
                                                                <span>{val.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="col-md-12">
                                                <div className="col-md-12 my-auto">
                                                    <h5 className='text-center'>
                                                        No Coupon found
                                                    </h5>
                                                </div>
                                            </div>
                                        )}
</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const
    mapStateToProps = (state) => ({
        cartItems: state.cartList.cart,
        cart_full: state.cartList.cart_full,
        cartLoader: state.cartList.cartLoader,
        symbol: state.data.symbol,
        total: getProductCartTotal(state.cartList.cart),
        coupon_discount: state.cartList.coupon_discount,
        auth: state.auth,
        settings: state.settings,
        cart: state.cartList
    });

export default connect(
    mapStateToProps,
    {
        removeFromCart,
        incrementQty,
        decrementQty,
        updateWithDeliverySLots,
        applyCouponDiscount,
        setCartdata,
        setCartdatafull,
        resetTheCart,
    }
)

(
    ProductCartComponentNew
)
;
