import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script";
import Slider from "react-slick";
import { Slider6 } from "../../services/script";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { getAboutUspage, fetchAboutus, setAboutus } from "../../actions/index";

class aboutUs extends Component {


  componentDidMount() {
    this.props.getAboutUspage({ content_type: 29 });
  }

  render() {
    return (
      <>
        {this.props.aboutUs.page_content ? (
          <>
            {" "}
            <div>
              {window.location.href.search("sp") <= 1 ? (
                <>
                  {" "}
                  {this.props.aboutUs ? (
                    <>
                      <Breadcrumb title={this.props.aboutUs.page_name} />
                    </>
                  ) : (
                    <>
                      <Breadcrumb title={"About Us"} />
                    </>
                  )}{" "}
                </>
              ) : (
                ""
              )}
              <section className="about-page  section-b-space">
                <div className="container-fluid cutCont">
                  <div className="row">
                    {/* <div className="col-lg-12">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/company.jpg`} className="img-fluid w-100" alt=""/>
                                </div>
                            </div> */}
                    <div className="col-sm-12">
                      {this.props.aboutUs.page_content ? (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.aboutUs.page_content,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutUs: state.pages.aboutUs,
});

const mapDispatchToProps = { getAboutUspage };

export default connect(mapStateToProps, mapDispatchToProps)(aboutUs);
