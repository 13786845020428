import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { checkAndRedirectLogin } from "../../utils/helper";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import { Helmet } from "react-helmet";
import { getAllOrders_api } from "../../api/product";
import OrderDetail from "./OrderDetail";
import moment from "moment";
import { Link } from "react-router-dom";
import NewOrderDetails from "./NewOrderDetails";

class NewListOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderForOrders: false,
      orders: [],
      isShowOrderDetailPage: false,
      viewOrder: null,
      showlistof: 1,
      listingTitle: "My Orders",
      detailsTitle: "Order Details",
      showBack: true,
    };
  }

  componentDidMount() {
    if (!checkAndRedirectLogin()) {
      toast.info("Please login to continue");
      this.props.history.push({
        pathname: "/login",
      });
    } else {
      this.setAllOrders(1);
    }
  }

  setAllOrders = async (id) => {
    try {
      this.setState({
        orders: [],
      });
      this.setState({ loaderForOrders: true });
      let apiParams = {
        user_id: this.props.auth.userData.id,
        filter_by: id, //1 = This Month, 2 = Past Month, 3 = 6 Last Month,  4 = Last Year
        language_id: 1,
      };
      const address = await getAllOrders_api(apiParams);
      // console.log(address);
      this.setState({
        loaderForOrders: false,
        orders: address.data,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForOrders: false });
    }
  };

  showOrderDetailPage = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isShowOrderDetailPage: !prevState.isShowOrderDetailPage,
      showBack: !prevState.showBack,
    }));
  };

  handleViewClick = (e, item) => {
    e.persist();
    this.setState(
      {
        viewOrder: item,
      },
      () => {
        this.showOrderDetailPage(e);
      }
    );
  };

  render() {
    const { isLoggedIn } = this.props.auth;
    const {
      isShowOrderDetailPage,
      loaderForOrders,
      orders,
      viewOrder,
    } = this.state;

    const now = new Date().getUTCFullYear();
    const years = Array(now - 2020)
      .fill("")
      .map((v, idx) => now - idx);

    return (
      <div>
        <Helmet>
          <title>My Orders | </title>
          <meta name='description' content='' />
        </Helmet>

        <Breadcrumb
          showBack={this.state.showBack}
          showOrderDetailsBack={this.state.isShowOrderDetailPage}
          showOrderDetailPage={this.showOrderDetailPage}
          title={
            !this.state.isShowOrderDetailPage
              ? this.state.listingTitle
              : this.state.detailsTitle
          }
        />

        {/*Dashboard section*/}
        <section
          className='section-b-space blog-page order-list-page '
          style={{ paddingTop: "0px" }}
        >
          <div className='container-fluid'>
            <div className='row order-detail-res'>
              {isLoggedIn && !isShowOrderDetailPage && (
                <div className='col-lg-12 col-sm-12 col-md-12 cart-d-none'>
                  <div className='row margintest'>
                    <div className='col-sm-4 col-md-8 col-lg-10 myorders-sort-res align-self-center'>
                      <span className='font-weight-bold float-right'>
                        SORT ORDERS:
                      </span>
                    </div>
                    <div
                      className='col-sm-4 col-md-4 col-lg-2 myorders-sort-val-res'
                      style={{ paddingLeft: "0" }}
                    >
                      <select
                        className='form-control font-weight-bold'
                        style={{ color: "#2472f0" }}
                        onChange={(e) => this.setAllOrders(e.target.value)}
                      >
                        <option value='1'>This Month</option>
                        <option value='2'>Past Month</option>
                        <option value='3'>Last 6 Months</option>
                        {years.map((i) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                    </div>
                    <div className='col-sm-12 px-md-5 px-sm-1 mt-3 paddingOrder'>
                      {orders.map((item, index) => {
                        return (
                          <div className='container-fluid d-flex justify-content-around'>
                            <div className='outerBox'>
                              <div className='innerBox'>
                                <div className='orderGrid'>
                                  <div className='orderGridItems'>
                                    <div className='orderRow'>
                                      <div className='orderCol orderSpan'>
                                        <div className='orderRow orderInnerFontTop'>
                                          <span className='orderInnerSpanTop'>
                                            {/* {item.order_status === null ? "Order Placed" : item.order_status} */}
                                            Order Id
                                          </span>
                                        </div>
                                        <div className='orderRow orderInnerFontBase'>
                                          <span className='orderInnerSpanBase'>
                                            {/* {moment(item.order_time).format(
                                              "DD MMMM YYYY"
                                            )} */}
                                            {item.order_id}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='orderRow'>
                                      <div className='orderCol orderSpan'>
                                        <div className='orderRow orderInnerFontTop'>
                                          <span className='orderInnerSpanTop'>
                                            No. Of Products
                                          </span>
                                        </div>
                                        <div className='orderRow orderInnerFontBase'>
                                          <span className='orderInnerSpanBase'>
                                          {/* {this.props.symbol}{item.total_amount.toFixed(2)} */}
                                            {item.group_by && item.group_by.length}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='orderRow'>
                                      <div className='orderCol orderSpan'>
                                        <div className='orderRow orderInnerFontTop'>
                                          <span className='orderInnerSpanTop'>
                                            Grand Total
                                          </span>
                                        </div>
                                        <div className='orderRow orderInnerFontBase'>
                                          <span className='orderInnerSpanBaseShip'>
                                            {/* {item.cust_name} */}
                                            {this.props.symbol}{item.total_amount.toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="orderRow">
                                        <div className="orderCol orderSpan">
                                            <div className="orderRow orderInnerFontTop">
                                                <span className="orderInnerSpanTop">
                                                    Order Date
                                                </span>
                                            </div>
                                            <div className="orderRow orderInnerFontBase">
                                                <span className="orderInnerSpanBaseShip">
                                                    {/* {moment(item.delivery_date).format("DD MMMM YYYY")} */}
                                                    {moment(item.order_time).format("DD MMMM YYYY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='orderGridRight'>
                                      <div className="d-flex">
                                        <div className="col-md">
                                          {
                                              item.order_status ? <>
                                                <button className="btn btn-light2">
                                            <a href={`https://beta.infrawheel.com/admin/sellerorder/generateInvoice/${item.id}`} target={"_blank"} style={{color: "grey"}}>INVOICE</a>
                                          </button></>: <></>
                                          }
                                        
                                        </div>
                                        <div className="col-md">
                                          <Link to={`${process.env.PUBLIC_URL}/dashboard/neworderdetials/${item.id}`}>
                                            <button className="btn btn-solid2">
                                              ORDER DETAILS
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                      {/* <div className='orderRow orderInnerFontTop'>
                                        <span className='orderInnerSpanTop'>
                                          Order #
                                        </span>
                                        <span className='orderInnerSpanTop'>
                                          {item.order_id}
                                        </span>
                                      </div>
                                      <div className='orderRow orderInnerFontBase'>
                                        <ul className='orderUl'>
                                          <div className='orderUlFont' 
                                            onClick={(e) => this.handleViewClick(e, item)}>
                                            View Order Details
                                          </div>
                                          &nbsp;&nbsp;&nbsp;
                                          <span className='orderInnerFontBase orderUlFont'>
                                            Invoice
                                          </span>
                                        </ul>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {item.group_by && item.group_by.map((orderItems, index) => {
  
                                  return (
                                    <div className={index === 0 ? 'shipmentBox mt-5' : "shipmentBox"} >
                                        <div className='shipmentBoxInner'>
                                        <div className='shipmentInnerDiv'>
                                            <div className='orderRow shipmentInner'>
                                              <div >
                                            

                                            <div className='d-flex justify-content-between align-items-center row'>
                                                <div className="orderImg col-md-2">
                                                  <Link to={`${process.env.PUBLIC_URL}/${orderItems.product_details.product_slug}/p/${orderItems.product_id}`}>
                                                    <img
                                                        alt=""
                                                        src={orderItems.product_details.product_image}
                                                        className=''
                                                        style={{boxShadow: "0 0 3px black"}}
                                                        width={90} height={90}
                                                    />
                                                  </Link>
                                                </div>
                                              {/* <div className=" col-md-10"> */}
                                                <div className='col-md-2'>
                                                <div className='orderRow'>
                                                    <span className='orderUlFont font-size-text-16'>
                                                        {orderItems.product_details.product_name}
                                                    </span>
                                                    {
                                                                            (orderItems && orderItems.option_data && orderItems.option_data.length > 0) ?

                                                                            orderItems.option_data.map((opt, i) => {

                                                                                    return <strong className="d-block order-list-list-optoin ">
                                                                                        {(opt.option_name) ? opt.option_name + ": " + opt.option_value_name : ""}
                                                                                    </strong>

                                                                                })
                                                                                : ""
                                                                        }
                                                                        <strong className="d-block order-list-list-optoin">Order Type: <span className={'order-list-list-optoin check___' + orderItems.product_details.order_type_text}>{orderItems.product_details.order_type_text}</span></strong>
                                                                        <strong className="d-block order-list-list-optoin">
                                                                          Shipping Price: <span className={'order-list-list-optoin'}>
                                                                          {orderItems.product_details.product_ship_price !=0?(this.props.symbol+" "+orderItems.product_details.product_ship_price):"Free"}
                                                                        </span>
                                                                        </strong>
                                                                        <strong className="d-block order-list-list-optoin">
                                                                           <span className={'order-list-list-optoin'}>
                                                                          <span className="text_bold">{ moment(orderItems.delivery_date).format("DD MMMM YYYY")}</span>  (Estimated Delivery)
                                                                        </span>
                                                                        </strong>
                                                                        


                                                                        {/* <strong className="d-block order-list-list-optoin">Quantity: 
                                                                        <b style={{color: "#000000"}}>{orderItems.product_details.order_quantity}</b>
                                                                        </strong> */}
                                                    {/* <div className='orderRow'>
                                                                        <span className='orderInnerFontTop'>
                                                                            Return window closed on 26-Mar-2020
                                                                        </span>
                                                                    </div> */}
                                                    {/* <div className='orderRow shipmentButtonPadding'>
                                                    <span className='shipmentButton'>
                                                        <span className='shipmentButtonInner'>
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/${orderItems.product_details.product_slug}/p/${orderItems.product_id}`}
                                                            className='shipmentButtonLink'
                                                        >
                                                            <div className='shipmentButtonInnerDiv'>
                                                            <i className='reorderIcon'></i>
                                                            Buy it again
                                                            </div>
                                                        </Link>
                                                        </span>
                                                    </span>
                                                    </div> */}
                                                </div>
                                                </div>

                                                <div className='col-md-2 text-center my-auto'>
                                                  <strong className='d-block text-uppercase'>Quantity</strong>
                                                  <span className='d-block'>{orderItems.product_details.order_quantity}</span>
                                                </div>
                 <div className='col-md-2 text-center my-auto'>
                                                  <strong className='d-block text-uppercase'>Unit Price</strong>
                                                  <span className='d-block'>{this.props.symbol}{(parseFloat(orderItems.product_details.product_main_price)).toFixed(2)}/{orderItems.product_details.unit_name}</span>
                                                </div>
                                                <div className='col-md-2 text-center my-auto'>
                                                  <strong className='d-block text-uppercase'>Price</strong>

                                                  <span className='d-block'>{this.props.symbol}{(parseFloat(orderItems.product_details.product_price_total)).toFixed(2)}</span>
                                                  {/*{parseFloat(orderItems.total_coupon_price)}*/}

                                                </div>
                               
                                                <div className='col-md-2 text-center my-auto'>
                                                  <strong className='d-block text-uppercase'>Order Status</strong>
                                                  <span className='d-block'>{orderItems.product_details.order_status_name}</span>
                                                </div>
                                              </div>     
                                            </div>
                                            {/* </div> */}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                  )
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {this.state.loaderForOrders && (
                    <div className='row'>
                      <div className='col-sm-12 text-center section-b-space mt-5 no-found'>
                        <div className='loading-cls' />
                        <p></p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Add New Address Page */}
              {isLoggedIn && isShowOrderDetailPage && (
                <NewOrderDetails
                  viewOrder={viewOrder}
                  showOrderDetailPage={this.showOrderDetailPage}
                  loaderForOrders={loaderForOrders}
                  auth={this.props.auth}
                  symbol={this.props.symbol}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  symbol: state.data.symbol,
});

export default withRouter(connect(mapStateToProps, {})(NewListOrder));
