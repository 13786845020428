import React, {Component} from 'react'
import Breadcrumb from "../common/breadcrumb";
import {connect} from "react-redux"
import Slider from "react-slick";
import banner from "../../assets/images/download/banner.png"
import app1 from "../../assets/images/download/buttons/apple.png"
import app2 from "../../assets/images/download/buttons/google.png"
import icone1 from "../../assets/images/download/icons/image-24px.png"
import icone2 from "../../assets/images/download/icons/edit.png"
import icone3 from "../../assets/images/download/icons/key.png"
import icone4 from "../../assets/images/download/icons/web-asset-24px.png"
import Group3 from "../../assets/images/download/screenshots/Group3.png"
import Group4 from "../../assets/images/download/screenshots/Group4.png"
import Group6 from "../../assets/images/download/screenshots/Group6.png"
import Group7 from "../../assets/images/download/screenshots/Group7.png"
import lastsection from "../../assets/images/download/lastsection.png"

class DownloadApp extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            loader: false
        };
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {

        const productSlider = {
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 3000,
            customPaging: "20px",
            responsive: [

                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        const {android_app_link, ios_app_link} = this.props.settings;

        return (
            <div className="become-vendor">
                <Breadcrumb title={"Download App"}/>
                <section className="section-b-space vetCont">
                    <div className='container-fluid vendCont'>
                        <div className="row">
                            <div className='col-sm-12 '>
                                <h4 className="mx-4">Download App</h4>
                            </div>
                        </div>

                        <div className='row '>
                            <div className='col-sm-12 pt-3'>
                                <img src={banner} className="downloadapp_banners" alt=""/>
                            </div>

                            <div className='col-sm-12 '>

                                <h6 className="downloadapp_app_title mt-5 mb-1">Sed Ut Perspiciatis Unde Omnis Iste Natus
                                    Error Sit Voluptatem Accusantium Doloremque Laudantium</h6>
                                <p></p>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                    laudantium,
                                </p>
                                <p>
                                    On the other hand, we denounce with righteous indignation and dislike men who are so
                                    beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire,
                                    that they cannot foresee the pain and trouble that are bound to ensue; and equal blame
                                    belongs to those who fail in their duty through weakness of will, which is the same as
                                    saying through shrinking from toil and pain. These cases are perfectly simple and easy
                                    to distinguish. In a free hour, when our power of choice is untrammelled and when
                                    nothing prevents our being able to do what we like best, every pleasure is to be
                                    welcomed and every pain avoided. But in certain circumstances and owing to the claims of
                                    duty or the obligations of business it will frequently occur that pleasures have to be
                                    repudiated and annoyances accepted. The wise man therefore always holds in these matters
                                    to this principle of selection: he rejects pleasures to secure other greater pleasures,
                                    or else he endures pains to avoid worse pains.
                                </p>
                            </div>
                            <div className='col-sm-12'>
                                <div className="d-flex justify-content-start downloadapp_img_s">
                                    <a target="_blank" href={ios_app_link} className="servMarTop mr-md-5 mt-md-5 mr-2 mt-2">
                                        <img src={app1} className="downloadapp_app" alt=""/>
                                    </a>
                                    <a target="_blank" href={android_app_link} className="servMarTop mr-md-5 mt-md-5 mr-2 mt-2">
                                        <img src={app2} className="downloadapp_app" alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-sm-12 appFe'>
                                <h6 className="downloadapp_app_title mt-5 mb-1">App Features</h6>
                                <hr/>
                            </div>
                            <div className='col-sm-12 col-lg-3 pb-4'>
                                <div className="w-100">
                                    <img src={icone1} className="downloadapp_app_icone" alt=""/>
                                    <p className="downloadapp_app_icone_title capsTi">LOWEST COST</p>
                                    <p className="downloadapp_app_icone_title_P">In pariatur conubia exercitationem Cillum,
                                        rhoncus! Aspernatur hendrerit! Morbi pariatur tempora platea volutpat?</p>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-3 pb-4'>
                                <div className="w-100">
                                    <img src={icone2} className="downloadapp_app_icone" alt=""/>
                                    <p className="downloadapp_app_icone_title capsTi">HIGH GROWTH RATE</p>
                                    <p className="downloadapp_app_icone_title_P">In pariatur conubia exercitationem Cillum,
                                        rhoncus! Aspernatur hendrerit! Morbi pariatur tempora platea volutpat?</p>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-3 pb-4'>
                                <div className="w-100">
                                    <img src={icone3} className="downloadapp_app_icone" alt=""/>
                                    <p className="downloadapp_app_icone_title capsTi">DEDICATED PICKUP</p>
                                    <p className="downloadapp_app_icone_title_P">In pariatur conubia exercitationem Cillum,
                                        rhoncus! Aspernatur hendrerit! Morbi pariatur tempora platea volutpat?</p>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-3 pb-4'>
                                <div className="w-100">
                                    <img src={icone4} className="downloadapp_app_icone" alt=""/>
                                    <p className="downloadapp_app_icone_title capsTi">MOST APPROACHABLE</p>
                                    <p className="downloadapp_app_icone_title_P">In pariatur conubia exercitationem Cillum,
                                        rhoncus! Aspernatur hendrerit! Morbi pariatur tempora platea volutpat?</p>
                                </div>
                            </div>
                            <div className='col-sm-12 appFe'>

                                <div className="d-flex justify-content-between alignEnd w-100">
                                    <h6 className="downloadapp_app_title mt-5">APP SCREENSHOTS</h6>
                                    <div className=" mt-5 mb-0 marginBot">
                                        <button className="button bg-white" onClick={this.previous}>
                                            <i className="fa fa-angle-left fa-2x"></i>
                                        </button>
                                        <button className="button bg-white" onClick={this.next}>
                                            <i className="fa fa-angle-right fa-2x"></i>
                                        </button>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div className='col-sm-12 downloadapp_app_img_shadow'>
                                <Slider {...productSlider} ref={c => (this.slider = c)} className="product-m no-arrow">
                                    {
                                        [Group3, Group4, Group6, Group7, Group3, Group4, Group6, Group7].map((images) => (

                                            <div>
                                                <img src={images} className="downloadapp_img" alt=""/>
                                            </div>

                                        ))}
                                </Slider>

                            </div>
                            <div className='bottomSide row'>
                                <div className='col-sm-6'>
                                    <h4 className="downloadapp_content_title my-3">DOWNLOAD APP NOW</h4>
                                    <p className="downloadapp_content">On the other hand, we denounce with righteous indignation
                                        and dislike men who are so beguiled and demoralized by the charms of pleasure of the
                                        moment, so blinded by desire, that they cannot foresee the pain and trouble that are
                                        bound to ensue; and equal blame belongs to those who fail in their duty through weakness
                                        of will, which is the same as saying through shrinking from toil and pain. These cases
                                        are perfectly simple and easy to distinguish. In a free hour, when our power of choice
                                        is untrammelled and when nothing prevents our being able to do what we like best, every
                                        pleasure is to be welcomed and every pain avoided. But in certain circumstances and
                                        owing to the claims of duty or the obligations of business it will frequently occur that
                                        pleasures have to be repudiated and annoyances accepted. The wise man therefore always
                                        holds in these matters to this principle of selection: he rejects pleasures to secure
                                        other greater pleasures, or else he endures pains to avoid worse pains.</p>
                                    <div className="d-flex justify-content-start downloadapp_img_s">
                                        <div className="d-flex justify-content-start downloadapp_img_s">
                                            <a target="_blank" href={ios_app_link} className="servMarTop mr-md-5 mt-md-5 mr-2 mt-2">
                                                <img src={app1} className="downloadapp_app" alt=""/>
                                            </a>
                                            <a target="_blank" href={android_app_link} className="servMarTop mr-md-5 mt-md-5 mr-2 mt-2">
                                                <img src={app2} className="downloadapp_app" alt=""/>
                                            </a>
                                        </div>
                                        `
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <img src={lastsection} className="downloadapp_lastsection_" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.loader && (
                    <div className="row">
                        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                            <div className="loading-cls"/>
                            <p></p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    footer_category: state.home.footer_category,
    settings: state.settings,
});

export default connect(
    mapStateToProps,
    {}
)(DownloadApp);