import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {video_help_api} from "../../api/pages";
import {toast} from "react-toastify";
import { Link } from "react-router-dom";
class VideoHelp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            video_list: [],
            show: 0,
        };
    }

    componentDidMount() {
        this.VideoHelplist()
    }

    FAQlistshow(index) {
        this.setState({show: index});
    }

    VideoHelplist = async () => {
        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };

            let video_list = await video_help_api(apiParams);
            console.table("video_list",video_list,video_list.data);
            if (!video_list.success) {
                this.setState({
                    video_list: video_list.data,
                });
            } else {
                toast.error(video_list.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    render() {
        console.log(this.state.video_list,"video_listvideo_list");
        return (
            <div>
                <Breadcrumb title={'Video Help'}/>


                {/*Dashboard section*/}
                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6 className="font-size-text-20 my-3">Video Tutorial</h6>
                            </div>

                            <div className="col-sm-12">
                                <div className="row gx-5 gy-5">
                                    {
                                       this.state.video_list && this.state.video_list.map((data,index)=>(
                                            data.status === 1 ? 
                                            <div className="col-sm-12 col-md-6 col-lg-6 video_help">
                                                <Link to={`${process.env.PUBLIC_URL}/video-help/${data.id}`} >
                                                        <div className="card bf-img-viddeo m-30">

                                                        <iframe src={data.link}
                                                                    allow='autoplay; encrypted-media'
                                                                    allowFullScreen
                                                                    title='video'
                                                                    height={400}
                                                            />

                                                                {/* <div className="card-body video-tutorial-box">
                                                                    <img src={`${data.thumbnail}`} alt="" className="home text-center w-100 videothumbnail"/> 
                                                                </div> */}
                                                                <div className="card-footer bf-img-viddeo-footer bg-theme-blue">
                                                                    <div className="p-2">
                                                                    <h6 className="my-0 text-white d-block video-name">{data.name}</h6>
                                                                        <h6 className="my-0 text-white d-block video-info mt-2">{data.description.slice(0,40)}...</h6>
                                                                    </div>
                                                                </div>
                                                        </div> 
                                                </Link>
                                            </div> 
                                        : <></>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default VideoHelp