
import React, { Component } from 'react';
import Slider from 'react-slick';
import SampleNextArrow from './SampleNextArrow';
import SamplepreviousArrow from './SamplepreviousArrow';


class SmallImages extends Component {
    constructor (props) {
        super(props);
        this.state = {
            is_clicable:'none'
        }
        this.next = this.next.bind(this);
    }
    next(i =0) {
        if(i == 1)
        {
            this.slider2.slickPrev();
        }
        else
        {
            this.slider2.slickNext();
        }
    }

    componentDidMount() {
       setTimeout(()=>{
            this.setState({
                is_clicable: 'unset'
            });
        },1)
    }

    render() {
        const { item, settings  } = this.props;
        const { is_clicable  } = this.state;

        var productsnav = settings;

        return (

                <div className="col-12 p-0 product-details-d-none" style={ {pointerEvents: is_clicable}}>
                    {/*<SamplepreviousArrow onClick={this.next}/>*/}

                    <Slider {...productsnav} asNavFor={this.props.navOne} ref={slider => (this.slider2 = slider)} className="slider-nav">
                        <div style={ {pointerEvents: is_clicable,cursor:"pointer"}} key={999} >
                            <img src={`${item.product_image}`} key={999} alt=""  className="img-fluid media" />
                        </div>
                        {(item && item.product_images && item.product_images.length > 0)?item.product_images.map((vari, index) =>
                            <div style={ {pointerEvents: is_clicable,cursor:"pointer"}} key={index}>
                                <img src={`${vari.images}`} key={index} alt=""  className="img-fluid media"  />
                            </div>
                        ):""}
                        {
                            (item && item.product_images && item.product_images.length == 0)?
                                [1,2,3].map((vari, index) =>
                                    <div style={ {pointerEvents: is_clicable,cursor:"pointer"}} key={index} >
                                        <img src={`${item.product_image}`} key={index} alt=""  className="img-fluid media" />
                                    </div>
                                ):""
                        }
                        {
                            item.product_video?
                                (<div style={ {pointerEvents: is_clicable,cursor:"pointer"}} key={9999}>
                                    <img src='/images/video-thumb.png' key={9999} alt=""  className="img-fluid media" />
                                </div>):""
                        }
                    </Slider>
                    {/*<SampleNextArrow onClick={this.next}/>*/}

                 </div>

        );
    }
}

export default SmallImages;