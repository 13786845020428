import React from 'react';
import Hovericon from './hovericon';
import Rating from './rating';
import { Link } from 'react-router-dom';


const Li = (props) => {
  
  function Clicked(){
      alert("item is added to cart");
     
  };

  function ProductListLi(params) {
   
    return(
    <li id="p1" className="p-2">
        
        {/* top icons */}
        <div className="imgset" id="parent">
          <div className="new">
            <img src="./icon/New.png" id="new" alt="" srcset="" /><br/>
            <p id="sale" style={{color:"gray",fontFamily:"Titillium Web', sans-serif"}}>ON SALE</p>
          </div>

          {/* main */}
          <div className="pro-img">
          <Link to={'/product/'+props.id} id="openDetail"><img src={props.path_} alt="" srcset="" className="media p-2 " /></Link>
            <button className="addtocart" type="button" id="addToCart">Add to Cart</button><br />
            <Hovericon onClick={Clicked}/> 
          </div>

          <div className="itemicon">
          <a href=""><img src="./Section 1/search.png" id="searchicon" alt="" srcset="" /><br/></a>
          <a href=""><img src={props.heartPath} id="hearticon" alt="" srcset="" /></a>
          </div>
        </div>

    {/* Bottom  data */}
        <Rating />
        <a href="" id="pro-name">{props.title}</a><br />
        {/*<p id="price-color">{props.price}
          <del className="px-2" style={{ color: "gray", fontSize: "15px" }}>{props.delprice}</del>
          <div className="siz">
            <a href="https://www.flipkart.com/"><img src="product_images\size\s.png" class="sizeli" alt="" srcset="" /></a>
            <a href="https://www.flipkart.com/"><img src="product_images\size\m.png" class="sizeli" alt="" srcset="" /></a>
            <a><img src="product_images\size\l.png" class="size" alt="" srcset="" /></a>
            <a><img src="product_images\size\xl.png" class="size" alt="" srcset="" /></a>
            
          </div>
        </p>*/}
      

  </li>);
    

  }
  return (
    <div className="Item_list cart container-fluid">
      {ProductListLi()}
    
    </div>
  );
}
export default Li;
