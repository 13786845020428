import ApiConstants from "./ApiConstants";

export default function api(path, params, method, token, fcmToken = null) {
  let options;
  
  if(["/removeWishAddCart"].includes(path)){
    options = {
      headers: {
        ...(token && { Authorization: token }),
        ...(fcmToken && { FcmToken: fcmToken }),
      },
      method: method,
      ...(params && { body: params }),
    };
  }else{
    options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
        ...(fcmToken && { FcmToken: fcmToken }),
      },
      method: method,
      ...(params && { body: JSON.stringify(params) }),
    };
  }
  

  //   return fetch(ApiConstants.BASE_URL + path, options)
  //     .then(resp => resp.json())
  //     .then(json => {
  //       console.log('json : ', json);
  //       return json;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  return new Promise((resolve, reject) => {
    fetch(`${ApiConstants.BASE_URL}${path}`, options)
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        } else if (response.status === 401) {
          if (path !== "/user/logout") {
            // Router.push(`/login${Router.asPath !== '/login' && Router.asPath !== '/' ? `?return=${Router.asPath}` : ''}`)
          }
        } else {
          return reject(response.json);
        }
      })
      .catch((error) => {
        reject({
          networkError: error.message,
        });
      });
  });
}
