import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  
  goBack = () => {
    window.history.back()
}
  render() {
    const { title, parent, showBack, showOrderDetailsBack, showOrderDetailPage, goBack, handleBackClick } = this.props;
  
    return (
        <div className="breadcrumb-section" id="breadcrumb_id">
          <div className="container-fluid">
            <div className="row mx-md-5 mx-sm-1">
              {(showBack && (goBack == undefined || goBack == false)) ? 
              <div className="col-md col-sm">
                <div className="page-title">
                  <div onClick={this.goBack} style={{cursor: "pointer"}}>
                    <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                  </div>
                </div>
              </div> : ""
              }
              {goBack ? 
              <div className="col-md col-sm">
                <div className="page-title">
                  <div onClick={handleBackClick} style={{cursor: "pointer"}}>
                    <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                  </div>
                </div>
              </div> : ""
              }
              {showOrderDetailsBack ? 
              <div className="col-md col-sm">
                <div className="page-title">
                  <div onClick={(e)=> showOrderDetailPage(e)} style={{cursor: "pointer"}}>
                    <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                  </div>
                </div>
              </div> : ""
              }
              <div className="col-md-5 col-sm-11">
                <div className="page-title">
                  <h2>{title}</h2>
                </div>
              </div>
              <div className="col-md-6 col-sm-11">
                <nav aria-label="breadcrumb" className="theme-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                    {parent?<li className="breadcrumb-item" aria-current="page">{parent}</li>:''}
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

    );
  }
}

export default Breadcrumb;
