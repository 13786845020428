import React from 'react'
import Breadcrumb from '../common/breadcrumb'

const NewMyOrders = () => {
    return (
      <div>
        <Breadcrumb title={"MY ORDERS"} />
        <section className='section-b-space blog-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 col-sm-8 col-md-12'>
                <div className='row'>
                  <div className='col-sm-4 col-md-8 col-lg-10 myorders-sort-res align-self-center'>
                    <span
                      htmlFor='staticEmail'
                      className='font-weight-bold float-right'
                      // style={{border: "#fff"}}
                    >
                      SORT ORDERS:
                    </span>
                  </div>
                  <div className='col-sm-4 col-md-4 col-lg-2 myorders-sort-val-res' style={{ paddingLeft: "0" }}>
                    <select className='form-control'>
                      <option value='1'>This Month</option>
                      <option value='2'>Past Month</option>
                      <option value='3'>Last 6 Months</option>
                      <option value='4'>Last Year</option>
                    </select>
                  </div>
                </div>
                <table
                  className='table myorders-table-res'
                  style={{
                    marginTop: "2rem",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>ORDER ID</th>
                      <th scope='col'>PRODUCT IMAGE</th>
                      <th scope='col'>PRODUCT DETAILS</th>
                      <th scope='col'>ORDER DATE</th>
                      <th scope='col'>ESTIMATED DELIVERY</th>
                      <th scope='col'>PRICE</th>
                      <th scope='col'>QUANTITY</th>
                      <th scope='col'>GRAND TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className='myorders-table-valign' scope='row'>
                        KHJG435FDG
                      </th>
                      <td className='myorders-table-valign'>
                        <img
                          src='https://i.picsum.photos/id/485/200/300.jpg?hmac=Kv8DZbgB5jppYcdfZdMVu2LM3XAIt-3fvR8VcmrLYhw'
                          height='100'
                          width='100'
                        />
                      </td>
                      <td className='myorders-table-valign'>
                        <strong>CCI PPC Cement</strong> <br/>
                        <strong>Size: L</strong> <br/> 
                        <strong>Order type: Retail</strong> <br/> 
                        <strong>Type: OPC</strong> <br/> 
                        <strong>Grade: 53 Grade</strong> 
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        07-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        10-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold theme_text_set'>
                        <i class='fa fa-inr' aria-hidden='true'></i>650
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        150
                      </td>
                      <td className='myorders-table-valign font-weight-bold text-success'>
                        <i class='fa fa-inr' aria-hidden='true'></i>98,000
                      </td>
                    </tr>
                    <tr>
                      <th className='myorders-table-valign' scope='row'>
                        KHJG435FDG
                      </th>
                      <td className='myorders-table-valign'>
                        <img
                          src='https://i.picsum.photos/id/485/200/300.jpg?hmac=Kv8DZbgB5jppYcdfZdMVu2LM3XAIt-3fvR8VcmrLYhw'
                          height='100'
                          width='100'
                        />
                      </td>
                      <td className='myorders-table-valign'>
                        <strong>CCI PPC Cement</strong> <br/>
                        <strong>Size: L</strong> <br/> 
                        <strong>Order type: Retail</strong> <br/> 
                        <strong>Type: OPC</strong> <br/> 
                        <strong>Grade: 53 Grade</strong>
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        07-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        10-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold theme_text_set'>
                        <i class='fa fa-inr' aria-hidden='true'></i>650
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        150
                      </td>
                      <td className='myorders-table-valign font-weight-bold text-success'>
                        <i class='fa fa-inr' aria-hidden='true'></i>98,000
                      </td>
                    </tr>
                    <tr>
                      <th className='myorders-table-valign' scope='row'>
                        KHJG435FDG
                      </th>
                      <td className='myorders-table-valign'>
                        <img
                          src='https://i.picsum.photos/id/485/200/300.jpg?hmac=Kv8DZbgB5jppYcdfZdMVu2LM3XAIt-3fvR8VcmrLYhw'
                          height='100'
                          width='100'
                        />
                      </td>
                      <td className='myorders-table-valign'>
                        <strong>CCI PPC Cement</strong> <br/>
                        <strong>Size: L</strong> <br/> 
                        <strong>Order type: Retail</strong> <br/> 
                        <strong>Type: OPC</strong> <br/> 
                        <strong>Grade: 53 Grade</strong>
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        07-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        10-09-2021
                      </td>
                      <td className='myorders-table-valign font-weight-bold theme_text_set'>
                        <i class='fa fa-inr' aria-hidden='true'></i>650
                      </td>
                      <td className='myorders-table-valign font-weight-bold'>
                        150
                      </td>
                      <td className='myorders-table-valign font-weight-bold text-success'>
                        <i class='fa fa-inr' aria-hidden='true'></i>98,000
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default NewMyOrders
