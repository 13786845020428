import React from 'react';

const Fashion = (props) => {
    return (
        <div className="Fashion container d-flex justify-content-center">
                <li id="p2" className="faslist"><img src={props.path_} alt="" srcset="" /><br />
                    <a id="combo" className="pt-4" style={{cursor:"pointer"}}>Combo Deals</a>
                    <h4 id="fas pt-2">FASHION</h4>
                    <p>Lorem ipsum dolor sit amet<br/> consectetur, adipisicing elit. </p>
                    <a href=""><button type="button" class="btn btn-danger">Shop Now</button></a> 
                </li>
    </div>
  );
}

export default Fashion;