
import React from 'react';
import { Link } from 'react-router-dom';


function New_deals(props) {
    return (
        <div className=" row New_deals container-fluid text-center mt-4" >
            <div className="col-4 " id="info">  
               <p className="text-center " >{props.deal}</p>
               <hr id="bottom_bar"/></div>
            


            <div className="col-2 "><h5 className="viewAll"><Link to="/new-products" >view all</Link></h5></div>
        </div>

    );
}

export default New_deals;