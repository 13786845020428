import ApiConstants from "./ApiConstants";

export default function Imagesapi(path, params, method, token, fcmToken = null) {
    let options;
    options = {
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            ...(token && {Authorization: token}),
            ...(fcmToken && {FcmToken: fcmToken}),
        },
        method: method,
        body: params,
    };
    return new Promise((resolve, reject) => {

        fetch(`${ApiConstants.BASE_URL}${path}`, options)

            .then((response) => {
                return new Promise((resolve) =>
                    response.json().then((json) =>
                        resolve({
                            status: response.status,
                            ok: response.ok,
                            json,
                        })
                    )
                );
            })
            .then((response) => {
                if (response.ok) {
                    return resolve(response.json);
                }  else {
                    return reject(response.json);
                }
            })
            .catch((error) => {
                    reject({
                        networkError: error.message,
                    })
                }
            );
    });
}
