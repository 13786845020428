import React, {Component, useEffect, useState} from 'react';
import Slider from "react-slick"

import Breadcrumb from "../../common/breadcrumb";
import {Slider6, Slider4, Slider7} from "../../../services/script"
import axios from 'axios';
import {Link} from 'react-router-dom';
import all_categories from "../../../access/icone/group-40.webp";

const categories = (props) => {
    const [subCategories, setSubCategories] = useState([])
    const categoryID = props.categoryID

    const getSubCategories = () => {
        axios.get("/getSidebarNLevelCategory")
            .then(function (response) {
                setSubCategories(response.data.data)
            })
            .catch(function (err) {
                console.log(err.data)
            })
    }
    useEffect(() => {
        getSubCategories()
    }, [categoryID])
    return (
        <>
            <div className="element-category display-mob">
                <div className="parent-category">

                    {subCategories && subCategories.map((value) => (
                            (value.subCat.length >= 1) ?
                                <div className="child-category category-block mt-5 mb-3">
                                    <Link to={"/sub-categories-list/" + value.id}>
                                        <div className="category-image">
                                            <img src={value.category_image} alt=""/></div>
                                    </Link>
                                    <div className="category-details">
                                        <Link to={"/sub-categories-list/" + value.id}>
                                            <h5>{value.category_name}</h5>
                                        </Link>
                                    </div>
                                </div> : <div className="child-category category-block mt-5 mb-3">
                                    <Link to={"category/"+value.id+"/products"}>
                                        <div className="category-image">
                                            <img src={value.category_image} alt=""/></div>
                                    </Link>
                                    <div className="category-details">
                                        <Link to={"category/"+value.id+"/products"}>
                                            <h5>{value.category_name}</h5>
                                        </Link>
                                    </div>
                                </div>

                        )
                    )}
                </div>
            </div>
        </>
    );
}

export default categories

