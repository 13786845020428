import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import CategoriesLists from "../../components/features/theme/categories"
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts, getAllSettings,
} from "../../actions";

class CategoriesList extends Component {
    constructor(props){
        super(props)
        this.state = {
            showBack: true
        }
    }

    render() {

        const {
            store_name,
        } = {...this.props.settings}
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>{store_name} | Collection of Products</title>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb showBack={this.state.showBack} title="Categories"/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cart-d-none-mobile">
                                    <div className="title1 section-t-space">
                                        <h3 className="title-inner1 title-inner1 font-size-text-30" 
                                        // style={{textAlign:"center", color:"#000", fontWeight:"600", padding:"20px"}}
                                        >SHOP BY CATEGORIES</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <CategoriesLists categoryID={this.props.match.params.id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
    settings: state.settings,
});

export default connect(
    mapStateToProps,
    {
        getAllSettings
    }
)(CategoriesList);
