import React, { Component } from 'react'
import Breadcrumb from '../common/breadcrumb'
import {getserviceportfoliodetails_api} from "../../api/home"
import { toast } from 'react-toastify'
import telephone from "../../access/icone/telephone.webp"
import provider_details from "../../access/icone/provider_details.png"

export default class SerivcePortfolioDetails extends Component {
    state = {
        portfolioDetails: [],
    }
    
    componentDidMount() {
      this.servicePortfolioDetails()
    }

    servicePortfolioDetails = async () => {

      try {
          // this.setState({loaderForPlaceOrder: true});
          let apiParams = {
              language_id: 3,
              portfolio_id: this.props.match.params.id
          };
          // console.log(apiParams);
          let servicePortfolioDetails = await getserviceportfoliodetails_api(apiParams);
          if (servicePortfolioDetails.success) {
              this.setState({
                portfolioDetails: servicePortfolioDetails.data,
              });
          } else {
              toast.error(servicePortfolioDetails.message);
          }
      } catch (error) {
          console.log(error);
          toast.error(error.message);
      }

    };
    render() {
        return (
          <div className='portFolioDetail'>
            <Breadcrumb title={"PORTFOLIO DETAILS"} />
            {this.state.portfolioDetails.length !== 0 ?
            <> 
             <div className="cart-d-none">
              <img
                className='service-provider-detail-img'
                src={this.state.portfolioDetails.speciality_icon && this.state.portfolioDetails.speciality_icon ? this.state.portfolioDetails.speciality_icon : provider_details}
              />
              <div className='container-fluid portFolioDetailUp'>
                <div className=' service-provider-portfolio-details'>
                  <div className='row'>
                    <div className='col-md-4 sideInPa'>
                      <div className='service-provider-portfolio-divider text-center service-provider-portfolio-v-align'>
                        <div className="row">
                          <div className="col-12 pb-1">
                            <h5 className='theme_text_set'>Speciality</h5>
                            <h4 className="pb-3" style={{color: "#000"}}>{this.state.portfolioDetails.speciality_name}</h4>
                          </div>
                          <div className="col-12">
                            <h5 className='theme_text_set'>Service</h5>
                            <h4 style={{color: "#000"}}>{this.state.portfolioDetails.service_name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4 sideInPa sideInPaBorder service-provider-portfolio-divider text-center service-provider-portfolio-v-align'>
                      <div className="row">
                        <div className="col-12 pb-3">
                          <h5 className='theme_text_set'>Start Date</h5>
                          <h4 style={{color: "#000"}}>{this.state.portfolioDetails.start_date_work}</h4>
                        </div>
                        <div className="col-12">
                          <h5 className='theme_text_set'>End Date</h5>
                          <h4 style={{color: "#000"}}>{this.state.portfolioDetails.end_date_work}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4 text-center service-provider-portfolio-v-align sideInPa'>
                      <h5 className='theme_text_set'>ADDRESS</h5>
                      <div style={{ width: "60%" }}>
                        <h4 style={{color: "#000"}}>{this.state.portfolioDetails.address}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container-fluid portFolioDetailUp portTop'>
                <div className='row' style={{ marginRight: "0" }}>
                  <div
                    className='col-md-12 float-left portTopIN'
                    style={{ paddingTop: "10rem" }}
                  >
                    <h4 className='font-weight-bold' style={{color: "#000"}}>DETAILS OF WORK</h4>
                    <p>
                      {this.state.portfolioDetails.short_details_work}
                    </p>
                  </div>
                  <div
                    className='col-md-12 text-center btnaround'
                    style={{ paddingBottom: "3rem", paddingTop: "5rem" }}
                  >
                    <button
                      type='button'
                      className='btn btn-success btn-padding'
                    >
                      <span style={{padding: "0.9rem"}}>
                        <img src={telephone} className="float-left pr-1" /> Call Now
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid cart-d-none-mobile portMob">
              <div className="row">
                <div className="col">
                <div className="row">
                  <div className="col-12 mobImg">
                      <img src={this.state.portfolioDetails.speciality_icon} width="350" height="200" style={{borderRadius: "10px"}} />
                  </div>
                  <div className="container-fluid mobijn">
                    <div className="row">
                      <div className="col-6 pt-3">
                        <strong className="theme_text_set">
                            Speciality
                        </strong> <br/>
                        <span>{this.state.portfolioDetails.speciality_name}</span>
                      </div>
                      <div className="col-6 pt-3">
                        <strong className="theme_text_set">
                            Service
                        </strong> <br/>
                        <span>{this.state.portfolioDetails.service_name}</span>
                      </div>
                      <div className="col-6 pt-3">
                        <strong className="theme_text_set">
                            Start Date
                        </strong> <br/>
                        <span>{this.state.portfolioDetails.start_date_work}</span>
                      </div>
                      <div className="col-6 pt-3">
                        <strong className="theme_text_set">
                            End Date
                        </strong> <br/>
                        <span>{this.state.portfolioDetails.end_date_work}</span>
                      </div>
                      <div className="col-12 pt-3">
                        <strong className="theme_text_set">
                            Address
                        </strong> <br/>
                        <p>{this.state.portfolioDetails.address}</p>
                        <hr/>
                      </div>
                      <div className="col-12">
                        <strong className="theme_text_set det">
                            Details of Work
                        </strong> <br/>
                        <p className='detCont'>{this.state.portfolioDetails.short_details_work}</p>
                      </div>
                      <div className="col-6 pt-3 pb-3 mobBtnin">
                        <button className="btn btn-solid btn-green">
                          <img src={telephone} className="float-left" /> Call Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            </>: ""}
            

          </div>
        );
    }
}
