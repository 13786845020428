import React, { Component } from "react";
import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";
import * as storageUtils from "../../../../utils/storageUtils";
import {checksinglevendo} from "../../../../utils/helper";

import {toast} from "react-toastify";

class HomeSideBar extends Component {
  closeNav() {
    var closemyslide = document.getElementById("mySidenav");

    document.documentElement.classList.remove("cm_overflow");

    if (closemyslide) closemyslide.classList.remove("open-side");
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub1"))
      event.target.nextElementSibling.classList.remove("opensub1");
    else {
      document.querySelectorAll(".opensub1").forEach(function(value) {
        value.classList.remove("opensub1");
      });
      event.target.nextElementSibling.classList.add("opensub1");
    }
  };
  handleSubTwoMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub2"))
      event.target.nextElementSibling.classList.remove("opensub2");
    else {
      document.querySelectorAll(".opensub2").forEach(function(value) {
        value.classList.remove("opensub2");
      });
      event.target.nextElementSibling.classList.add("opensub2");
    }
  };
  handleSubThreeMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub3"))
      event.target.nextElementSibling.classList.remove("opensub3");
    else {
      document.querySelectorAll(".opensub3").forEach(function(value) {
        value.classList.remove("opensub3");
      });
      event.target.nextElementSibling.classList.add("opensub3");
    }
  };
  handleSubFourMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub4"))
      event.target.nextElementSibling.classList.remove("opensub4");
    else {
      document.querySelectorAll(".opensub4").forEach(function(value) {
        value.classList.remove("opensub4");
      });
      event.target.nextElementSibling.classList.add("opensub4");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensidesubmenu"))
      event.target.nextElementSibling.classList.remove("opensidesubmenu");
    else {
      event.target.nextElementSibling.classList.add("opensidesubmenu");
    }
  };

  handleLogout = () => {
    storageUtils.unsetUserData();
    storageUtils.unsetUserToken();
    this.props.setUserLogout();

    toast.success("You have logged out successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };



  render() {
    const { sideBarMenus } = this.props;

    return (
      <div id="mySidenav" className="sidenav">
        <a
          href="javascript:void(0)"
          className="sidebar-overlay"
          onClick={this.closeNav}
        />
        <nav>
          <a onClick={this.closeNav}>
            <div className="sidebar-back text-left menu-boder-with-margin">
               <i className="fa fa-times  font-weight-normal font-size-text-15" aria-hidden="true"></i>
            </div>
          </a>
          <ul id="sub-menu" className="sidebar-menu ">
            {/* {sideBarMenus.map((m, i) => (
              <li key={i}>
                <Link
                  to={`${process.env.PUBLIC_URL}/category/${m.id}/products`}
                  onClick={(e) =>
                    m.subCat.length > 0 ? this.handleSubmenu(e) : null
                  }
                >
                  {m.category_name}
                  {m.subCat.length > 0 && <span className="sub-arrow" />}
                </Link>
                {m.subCat.length > 0 && (
                  <SubMenu
                    s={m.subCat}
                    handleSubTwoMenu={(e) => this.handleSubTwoMenu(e)}
                  />
                )}
              </li>
            ))} */}
            <li>
              <Link onClick={this.closeNav} to="/" className="font-weight-bold font-size-text-15">
                 Home
              </Link>
            </li>
            <li>
              <Link  to={`${process.env.PUBLIC_URL}/categories-list`} className="font-weight-bold font-size-text-15" onClick={(e) => {this.handleSubmenu(e);this.closeNav()}}>
                 Shop By Category
                <span className="sub-arrow" />
              </Link>
              <ul>
              {sideBarMenus.map((m, i) => (
              <li key={i}>
                <Link
                  className="font-weight-bold font-size-text-12"
                  to={m.subCat.length >= 1 ?`${process.env.PUBLIC_URL}/sub-categories-list/${m.id}`:`${process.env.PUBLIC_URL}/category/${m.id}/products`}
                  onClick={(e) =>
                    m.subCat.length > 0 ? this.handleSubTwoMenu(e) : null
                  }
                >
                  {m.category_name}
                  {m.subCat.length > 0 && <span className="sub-arrow" />}
                </Link>
                {m.subCat.length > 0 && (
                  <SubMenu
                    s={m.subCat}
                    handleSubTwoMenu={(e) => this.handleSubTwoMenu(e)}
                  />
                )}
              </li>
            ))}
              </ul>
            </li>
            {checksinglevendo() == true?
            <li>
              <Link onClick={this.closeNav} className="font-weight-bold font-size-text-15" to={`${process.env.PUBLIC_URL}/service-provider-home`}>
                 Contact Service Provider
              </Link>
            </li>
                : ""}
            {/*<li>
              <Link className="font-weight-bold font-size-text-15" to={`${process.env.PUBLIC_URL}/seller-registration`}>
                 Become a Seller
              </Link>
            </li>*/}
            {checksinglevendo() == true?
            <li>
              <Link onClick={this.closeNav}className="font-weight-bold font-size-text-15" onClick={this.closeNav} to="how-to-become-a-service-provider">
                 Become a Service Provider
              </Link>
            </li>
                :""}
            {checksinglevendo() == true?
            <li>
              <Link className="font-weight-bold font-size-text-15" to={`${process.env.PUBLIC_URL}/seller-registration`} onClick={this.closeNav}>
                Sell on Infrawheel
              </Link>
            </li>
                :""}
            {checksinglevendo() == true?
            <li>

              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/services`}>
                Services 
              </Link>
            </li>:""}
            {checksinglevendo() == true?
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/service-provider-home`}>
                Service Provider Home 
              </Link>
            </li>:""}
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/pages/contact`}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/download-app`}>
                Download App
              </Link>
            </li>
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/pages/about-us`}>
                About Us
              </Link>
            </li>
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/pages/terms-and-conditions`}>
                 Terms Of Use
              </Link>
            </li>
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/help-and-support`}>
                Help & Support
              </Link>
            </li>
            <li>
              <Link className="font-weight-bold font-size-text-15" onClick={this.closeNav} to={`${process.env.PUBLIC_URL}/video-help`}>
                Video tutorial
              </Link>
            </li>
            {this.props.auth.isLoggedIn ?
            <li>
              <Link className="font-weight-bold font-size-text-15 asdd" to="#" onClick={(e) => {e.preventDefault(); this.handleLogout()}}>
                  Logout
              </Link>
            </li>:""
            }
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps) (withTranslate(HomeSideBar));
