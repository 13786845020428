import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../services";
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import {getserviceListById_api, getservicelist_api} from "../../../api/home"
import {toast} from "react-toastify";
// import nodata from "../../access/icone/nodata.jpg"
import { withRouter } from 'react-router-dom';
import nodata from "../../../access/icone/nodata.jpg"

class ServiceProductListing extends Component {
  constructor(props){
    super(props)
    this.state = {
      serviceList: [],
      isLoading: false
    }
}

componentDidMount() {
  if(this.props.dashboardId && this.props.dashboardId){
      this.serviceListWithId();
  }else{
    this.serviceList();
  }
}
componentDidUpdate(prevProps, prevState) {
  if (this.props.dashboardId !== prevProps.dashboardId) {
    if(this.props.dashboardId && this.props.dashboardId){
        this.serviceListWithId();
    }else{
      this.serviceList();
    }
  }
}

serviceListWithId = async () => {

  try {

    this.setState({
      isLoading: true
    })


      let apiParams = {
        parent_ids: this.props.subService || "0",
          speciality_id: this.props.dashboardId
      };
      
      // console.log(apiParams);
      let serviceList = await getserviceListById_api(apiParams);
      // console.log("serviceList",serviceList)

      if (serviceList.status) {
        if(serviceList.result.data.length) {

          if(false) {
            this.setState({
              serviceList: serviceList.result.data[parseInt(this.props.subService)].services,
            });
          }else {
            // console.log("sub sevice")
            this.setState({
              serviceList: serviceList.result.data,
            });
          }
        }else toast.error("No Service Availbale")
      } else toast.error(serviceList.message);
  } catch (error) {
      console.log(error);
      toast.error(error.message);
  } finally  {
    this.setState({
      isLoading: false
    })
  }

};

serviceList = async () => {

  try {
    this.setState({
      isLoading: true
    })
      let apiParams = {
          language_id: 3,
      };
      // console.log(apiParams);
      let serviceList = await getservicelist_api(apiParams);
      if (serviceList.success) {
          this.setState({
            serviceList: serviceList.data,
          });
      } else {
          toast.error(serviceList.message);
      }
  } catch (error) {
      console.log(error);
      toast.error(error.message);
  } finally {
    this.setState({
      isLoading: false
    })
  }

};


  render() {
    if(this.state.isLoading) {

      return <div className="row w-100">
      <div
          className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found ">
          <div className="loading-cls"/>
      </div>
  </div>
    }

    
    return (
      <div className='col-12 psm0 contLIst'>
        <div className='product-wrapper-grid'>
          <div className='container-fluid psm0 servListCont' id='listing'>
            {!this.state.isLoading && this.state.serviceList.length === 0 ? <div className='d-flex justify-content-center'>
                            <img src={nodata}  height={400} width={400}/>
                        </div> :
            <InfiniteScroll
              dataLength={this.state.limit}
              // next={()=>{
              //   this.props.loadMore();
              //   this.fetchMoreItems()
              // }}
              hasMore={this.state.hasMoreItems}
              loader={""}
            >
              <div className='row servprolist'> 
                <div className='col-12'>
                  <div
                    className='d-flex flex-wrap w-100   serListPt'
                    style={{ paddingTop: "20px" }}
                  >
                    {
                        (this.props.subService||0) == 0?
                      this.state.serviceList.map((serviceList, index) => 
                          { return <div className='setproducts p-2 ' key={index}>
                            
                        <div className='product-box product-wrap my-3'>
                          <div className='img-wrapper'>
                            <div className='front'> 
                              <Link
                                to={ this.props.subService == undefined ? `/sub-services/${serviceList.service_name}/${serviceList.speciality_id}/${serviceList.id}` :`${process.env.PUBLIC_URL}/service-providertype/${serviceList.id}`}
                              >
                                <div
                                  className={"img-fluid"}
                                  style={{
                                    backgroundImage: `url('${ this.props.subService == undefined ? serviceList.image_url:serviceList.image_url }')`,
                                  }}
                                ></div>
                                <div className="product-detail">
                                  <div>                                
                                  { this.props.subService == undefined ?<h6 className="pt-4">{serviceList.service_name}</h6>:<h6 className="pt-4">{serviceList.sub_service_name} </h6>}
                                  </div>
                              </div>
                              </Link>
                            </div> 
                          </div>
                        </div>
                          </div> }                   
                      ) : this.state.serviceList.map((service) => 

                      (service.id == this.props.subService) &&
                        service.services.map((serviceList, index) =>
                          { return <div className='setproducts p-2 ' key={index}>
                            
                        <div className='product-box product-wrap my-3'>
                          <div className='img-wrapper'>
                            <div className='front'> 
                              <Link
                                to={ this.props.subService == undefined ? `/sub-services/${serviceList.service_name}/${serviceList.speciality_id}/${serviceList.id}` :`${process.env.PUBLIC_URL}/service-providertype/${serviceList.id}`}
                              >
                                <div
                                  className={"img-fluid"}
                                  style={{
                                    backgroundImage: `url('${ this.props.subService == undefined ? service.image_url:serviceList.image_url }')`,
                                  }}
                                ></div>
                                <div className="product-detail">
                                  <div>                                
                                  { this.props.subService == undefined ?<h6 className="pt-4">{serviceList.service_name}</h6>:<h6 className="pt-4">{serviceList.sub_service_name} </h6>}
                                  </div>
                              </div>
                              </Link>
                            </div> 
                          </div>
                        </div>
                          </div> }                
                      ))}

                  </div>
                </div>
              </div>
            </InfiniteScroll>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: getVisibleproducts(state.data, state.filters),
  symbol: state.data.symbol,
  dashboardProducts: state.data.dashboardProducts,
  fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare,
})(withRouter(ServiceProductListing));
