import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getBestSeller,
  getMensWear,
  getWomensWear,
} from "../../../services/index";
import { addToCart, addToCompare, addToWishlist } from "../../../actions/index";
import ProductBox from "./ProductBox";
import { Link } from "react-router-dom";

class SpecialProducts extends Component {
  render() {
    const {
      bestSeller,
      mensWear,
      womensWear,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
      dashboardModules,
      is_special_product,
      show_count,
      classname,
    } = this.props;
    const { items, type } = this.props;

    const removewishlist = () => {
      alert("remove");
    };

    return (
      <>
        <div className="">
          {items && items.length > 0
            ? items.map((item, key) => {
                return item && item.products && item.products.length >= 1 ? (
                  <>
                    <div className="container">
                      <div className="title1 section-t-space row">
                        <div className="col-8 col-lg-12">
                          <h2 className="title-inner1 font-size-text-30 ">
                            {item.section_title}
                          </h2>
                        </div>

                        <div className="col-4 my-auto col-lg-12">
                          {type == "fix" ? (
                            ""
                          ) : (
                            <Link
                              to={{
                                pathname:`${process.env.PUBLIC_URL}/dashboard/${item.dashboard_id}/products`,
                                state:{
                                    products: item.products,
                                    section_title: item.section_title,
                                  },
                               
                            }}
                            >
                              <h6 className="font-weight-bold font-size-text-22">
                                view all
                              </h6>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <section className="section-b-space">
                      <div className="container-fluid ">
                        <div className="row mx-md-2 mx-xl-5" id={classname}>
                          <div className="d-flex flex-wrap w-100 justify-content-start box_res_mob">
                            {item && item.products && item.products.length > 0
                              ? item.products
                                  .sort((a, b) => {
                                    return b.avg_rate - a.avg_rate;
                                  })
                                  .slice(0, show_count ? show_count : 4)
                                  .map((product, index) => (
                                    <div
                                      className={
                                        is_special_product == 1
                                          ? "setproducts3"
                                          : "setproducts"
                                      }
                                      key={index}
                                    >
                                      <ProductBox
                                        is_special_product={is_special_product}
                                        product={product}
                                        symbol={symbol}
                                        onAddToCompareClicked={() =>
                                          addToCompare(product)
                                        }
                                        onAddToWishlistClicked={() =>
                                          addToWishlist(product)
                                        }
                                        onRemoveWishlist={() =>
                                          removewishlist(product)
                                        }
                                        onAddToCartClicked={addToCart}
                                        key={index}
                                        auth={this.props.auth}
                                        isAdjustImageBox={true}
                                        className={classname}
                                      />
                                    </div>
                                  ))
                              : ""}
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  ""
                );
              })
            : ""}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bestSeller: getBestSeller(state.data.products),
  mensWear: getMensWear(state.data.products),
  womensWear: getWomensWear(state.data.products),
  symbol: state.data.symbol,
  dashboardModules: state.home.dashboardModulesnew,
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare,
})(SpecialProducts);
