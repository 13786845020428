import Api from "./index";
import Imagesapi from "./images";

import { getUserToken } from "../utils/storageUtils";

// Auth API's
export const letUserSignIn_Api = (data) => {
  return Api(`/signin`, data, "POST", "");
};
export const letUserSignUp_Api = (data) => {
  return Api(`/signup`, data, "POST", "");
};
export const letCheckMobileNo_Api = (data) => {
  return Api(`/checkMobileNo`, data, "POST", "");
};
export const letCheckMobileEmail_Api = (data) => {
  return Api(`/checkMobileEmail`, data, "POST", "");
};
export const vendorRegister_Api = (data) => {
  return Imagesapi(`/vendor_register`, data, "POST", "");
}

// Auth routes
export const editProfile_api = (data) => {
  const token = getUserToken();
  return Api(`/editProfile`, data, "POST", token);
};
export const getAllAddress_api = () => {
  const token = getUserToken();
  return Api(`/listAddress`, null, "GET", token);
};
export const addNewAddress_api = (data) => {
  const token = getUserToken();
  return Api(`/addAddress`, data, "POST", token);
};
export const editAddress_api = (data) => {
  const token = getUserToken();
  return Api(`/editAddress`, data, "POST", token);
};
export const deleteAddress_api = (data) => {
  const token = getUserToken();
  return Api(`/deleteAddress`, data, "POST", token);
};
export const setAsDefaultAddress_api = (data) => {
  const token = getUserToken();
  return Api(`/isDefaultAddress`, data, "POST", token);
};
export const getCartList_api = (data) => {
  const token = getUserToken();
  return Api(`/v1/cartList`, data, "POST", token);
};

export const addDeliverySlots_api = (data) => {
  const token = getUserToken();
  return Api(`/addslotcart`, data, "POST", token);
};
export const updateCart_api = (data) => {
  const token = getUserToken();
  return Api(`/updateCart`, data, "POST", token);
};

export const cartDelete_api = (data) => {
  const token = getUserToken();
  return Api(`/cartDelete`, data, "POST", token);
};

export const getProfileapi = (data) => {
  const token = getUserToken();
  return Api(`/getProfile`, data, "GET", token);
};

export const editProfileImage_api = (data) => {
  const token = getUserToken();
  return Imagesapi(`/editProfileImage`, data, "POST", token);
};
