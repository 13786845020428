import shop from "../api/shop";
import {
    getHomePageBrands_api,
    getHomeFooterCats_api,
    getHomeHeaderMainMenu_api,
    getHomeSlider_api,
    getHomeSidebarCategories_api,
    getHomeDashboardModules_api, getOffersList_api,
    getservicehomepage_api, getproductpositionlist_api
} from "../api/home";
import {
    getProductDetail_api,
    getAllDashboardProducts_api,
    getAllDashboardProductFilters_api, getConfigureProductDetails_api,
    getOfferData_api
} from "../api/product";
import {getAllSettings_api} from "../api/settings";
import {
    getaboutUs_api,
} from "../api/pages";
import {addToCart_api} from "../api/product";
import * as types from "../constants/ActionTypes";
import store from "../store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {SET_SETTINGS} from "../constants/ActionTypes";

// Home page actions
export const fetchHomeHeaderMainMenu = () => ({
    type: types.GET_HOME_MAIN_MENU,
});
export const setHomeHeaderMainMenu = (mainMenu) => ({
    type: types.SET_HOME_MAIN_MENU,
    mainMenu,
});
export const getHomeHeaderMainMenu = () => async (dispatch) => {
    dispatch(fetchHomeHeaderMainMenu());
    try {
        let rs = await getHomeHeaderMainMenu_api();
        dispatch(setHomeHeaderMainMenu(rs));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchHomeBrands = () => ({
    type: types.GET_HOME_BRANDS,
});
export const setHomeBrands = (brands) => ({
    type: types.SET_HOME_BRANDS,
    brands,
});
export const getHomePageBrands = () => async (dispatch) => {
    dispatch(fetchHomeBrands());
    try {
        let rs = await getHomePageBrands_api();
        dispatch(setHomeBrands(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchHomeFooterCats = () => ({
    type: types.GET_HOME_FOOTER_CATEGORY,
});
export const setHomeFooterCats = (footer_category) => ({
    type: types.SET_HOME_FOOTER_CATEGORY,
    footer_category,
});
export const getHomeFooterCats = () => async (dispatch) => {
    dispatch(fetchHomeFooterCats());
    try {
        let rs = await getHomeFooterCats_api();
        dispatch(setHomeFooterCats(rs));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchHomeSlider = () => ({
    type: types.GET_HOME_SLIDER,
});
export const setHomeSlider = (sliders) => ({
    type: types.SET_HOME_SLIDER,
    sliders,
});
export const getHomeSliders = () => async (dispatch) => {
    dispatch(fetchHomeSlider());
    try {
        let rs = await getHomeSlider_api();
        dispatch(setHomeSlider(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchHomeSidebarCategories = () => ({
    type: types.GET_HOME_SIDEBAR_CATEGORIES,
});
export const setHomeSidebarCategories = (sideBarMenus) => ({
    type: types.SET_HOME_SIDEBAR_CATEGORIES,
    sideBarMenus,
});
export const getHomeSidebarCategories = () => async (dispatch) => {
    dispatch(fetchHomeSidebarCategories());
    try {
        let rs = await getHomeSidebarCategories_api();
        dispatch(setHomeSidebarCategories(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchHomeDashboardModules = () => ({
    type: types.GET_HOME_DASHBOARD_MODULES,
});
export const setHomeDashboardModules = (dashboardModules) => ({
    type: types.SET_HOME_DASHBOARD_MODULES,
    dashboardModules,
});

export const fetchHomeDashboardModules_NEW = () => ({
    type: types.GET_HOME_DASHBOARD_MODULES_NEW,
});
export const setHomeDashboardModules_NEW = (dashboardModules) => ({
    type: types.SET_HOME_DASHBOARD_MODULES_NEW,
    dashboardModules,
});

export const getHomeDashboardModules = () => async (dispatch) => {
    dispatch(fetchHomeDashboardModules());
    try {
        let rs = await getHomeDashboardModules_api();
        // console.log(rs,"rs")
        // if (rs.success) {
        //     let data = JSON.parse(JSON.stringify(rs.data.product_section));
        //     dispatch(setHomeDashboardModules_NEW(rs.data));

        //     for (const x in data) {
        //         for (let index = 0; index < data[x].products.length; index++) {
        //             for (
        //                 let indexTwo = 0;
        //                 indexTwo < data[x].products[index].product_option.length;
        //                 indexTwo++
        //             ) {
        //                 data[x].products[index].product_option[
        //                     indexTwo
        //                     ].selected_options = [];
        //             }
        //             data[x].products[index].delivery_slots = [];
        //         }
        //     }
        //     dispatch(setHomeDashboardModules(data));
        // }
    } catch (error) {
        console.log(error);
        // toast.error(error.networkError);
    }
};

// Product detail
export const getHomeDashboardProduct = () => ({
    type: types.GET_PRODUCT_HOME_PAGE_PRODUCT,
});
export const setHomeDashboardProduct = (data) => ({
    type: types.SET_PRODUCT_HOME_PAGE_PRODUCT,
    data,
});
export const getProductPositionList = () => async (dispatch) => {
    dispatch(fetchHomeDashboardModules());
    try {
        if(store.getState().auth.isLoggedIn){
            const userid = store.getState().auth.userData.id;
            let top = await getproductpositionlist_api({ position : 'top', userid});
           if (top.success) {
               dispatch(setHomeDashboardProduct({product_top:top.data}));
           }
           let center = await getproductpositionlist_api({ position : 'Center', userid });
           if (center.success) {
               dispatch(setHomeDashboardProduct({product_center:center.data}));
           }
           let bottom = await getproductpositionlist_api({ position : 'bottom', userid});
           console.log(bottom, "bottom product")
           if (bottom.success) {
               dispatch(setHomeDashboardProduct({product_bottom:bottom.data}));
           } 
            // console.log(top, center , bottom, "<<=== when user logged it product res")
        }else{
            let top = await getproductpositionlist_api({ position : 'top'});
            if (top.success) {
                dispatch(setHomeDashboardProduct({product_top:top.data}));
            }
            let center = await getproductpositionlist_api({ position : 'Center' });
            if (center.success) {
                dispatch(setHomeDashboardProduct({product_center:center.data}));
            }
            let bottom = await getproductpositionlist_api({ position : 'bottom'});
            if (bottom.success) {
                dispatch(setHomeDashboardProduct({product_bottom:bottom.data}));
            } 
            // console.log(top, center , bottom, "<<=== when user NOT_LOGGED it product res")
        }

    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};


// Product detail
export const fetchProductDetail = () => ({
    type: types.GET_PRODUCT_DETAIL,
});
export const setProductDetail = (singleProduct) => ({
    type: types.SET_PRODUCT_DETAIL,
    singleProduct,
});
export const getProductDetail = (data) => async (dispatch) => {
    dispatch(fetchProductDetail());
    dispatch(setProductDetail([]));
    try {
        let rs = await getProductDetail_api(data);
        if (rs.success) {
            let data = JSON.parse(JSON.stringify(rs.data));
            for (const x in data.product_option) {
                data.product_option[x].selected_options = [];
            }
            for (const y in data.bought_product) {
                for (const z in data.bought_product[y].product_option) {
                    data.bought_product[y].product_option[z].selected_options = [];
                }
            }
            data.delivery_slots = [];
            dispatch(setProductDetail(data));
        } else {
            toast.info(rs.message);
            dispatch({
                type: types.SET_PRODUCT_DETAIL_ERROR,
            });
        }
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};


export const getConfigureProductDetail = (data) => async (dispatch) => {
    dispatch(fetchProductDetail());
    dispatch(setProductDetail([]));
    try {
        let rs = await getConfigureProductDetails_api(data);
        if (rs.success) {
            let data = JSON.parse(JSON.stringify(rs.data));
            for (const x in data.product_option) {
                data.product_option[x].selected_options = [];
            }
            for (const y in data.bought_product) {
                for (const z in data.bought_product[y].product_option) {
                    data.bought_product[y].product_option[z].selected_options = [];
                }
            }
            data.delivery_slots = [];
            dispatch(setProductDetail(data));
        } else {
            console.log(rs)
            toast.info(rs.message);
            dispatch({
                type: types.SET_PRODUCT_DETAIL_ERROR,
            });
        }
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};


export const updateProductOptions = (data) => async (dispatch) => {
    dispatch(setProductDetail(data));
};

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN,
});
export const receiveProducts = (products) => ({
    type: types.RECEIVE_PRODUCTS,
    products,
});
export const getAllProducts = () => (dispatch) => {
    dispatch(fetchProductsBegin());
    shop.getProducts((products) => {
        dispatch(receiveProducts(products));
        return products;
    });
};
export const fetchSingleProduct = (productId) => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId,
});

// Dashboard products filters
export const fetchDashboardProductFiltersBegin = () => ({
    type: types.GET_DASHBOARD_PRODUCTS_FILTER,
});
export const receiveDashboardProductFilters = (dashboardProductFilters) => ({
    type: types.SET_DASHBOARD_PRODUCTS_FILTER,
    dashboardProductFilters,
});
export const getAllDashboardProductFilters = (data) => async (dispatch) => {
    dispatch(fetchDashboardProductFiltersBegin());
    let rs = await getAllDashboardProductFilters_api(data);
    if (rs.success) {
        // dispatch(receiveDashboardProductFilters(rs.data));
    } else {
        toast.info(rs.message);
        dispatch({
            type: types.SET_DASHBOARD_PRODUCTS_FILTER_ERROR,
        });
    }
};
// Dashboard products fetch
export const resetDashboardProducts = () => ({
    type: types.RESET_DASHBOARD_PRODUCTS,
});

export const fetchDashboardProductsBegin = () => ({
    type: types.GET_DASHBOARD_PRODUCTS,
});
export const receiveDashboardProducts = (dashboardProducts) => ({
    type: types.SET_DASHBOARD_PRODUCTS,
    dashboardProducts,
});

export const setproductlistname = (value) => ({
    type: types.SET_PRODUCT_LIST_NAME,
    value,
});

export const setbrandlistname = (value) => ({
    type: types.SET_BRAND_LIST_NAME,
    value,
});

export const getAllDashboardProducts = (data) => async (dispatch) => {
    dispatch(fetchDashboardProductsBegin());
    try {
        let rs = await getAllDashboardProducts_api(data);
        if (rs.success) {
            dispatch(receiveDashboardProducts(rs.data));
            dispatch(receiveDashboardProductFilters(rs.filter_data));
            dispatch(setproductlistname(rs.product_list_name));
            dispatch(setbrandlistname(rs.brand_name));
        } else {
            toast.info(rs.message);
            dispatch({
                type: types.SET_DASHBOARD_PRODUCTS_ERROR,
            });
        }
    } catch (err) {
        toast.info(err.message);
        dispatch({
            type: types.SET_DASHBOARD_PRODUCTS_ERROR,
        });
    }
};

// SignUp
export const setUserSignUpSuccess = (userData) => ({
    type: types.SET_USER_SIGNUP_SUCCESS,
    userData,
});
// LET_USER_LOGOUT
export const setUserLogout = () => ({
    type: types.LET_USER_LOGOUT,
});

export const setCartLoader = () => ({
    type: types.FETCH_CART_DATA,
});
export const setCartdata = (data) => (dispatch) => {
    // console.log(data);
    dispatch(setCartLoader());
    setTimeout(() => {
        dispatch({
            type: types.SET_CART_DATA,
            data,
        });
    }, 1500);
};

export const setCartdatafull = (data) => (dispatch) => {
    dispatch(setCartLoader());
    setTimeout(() => {
        dispatch({
            type: types.SET_CART_DATA_FULL,
            data,
        });
    }, 1500);
};


//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => async (dispatch) => {
    toast.success("Item Added to Cart.");
    dispatch(addToCartUnsafe(product, qty));
    // try {
    //   let rs = await AddToCart_api();
    // } catch (err) {
    //   toast.info(err.message);
    //   // dispatch({
    //   //   type: types.SET_DASHBOARD_PRODUCTS_ERROR,
    //   // });
    // }
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty,
});
export const removeFromCart = (product_id) => (dispatch) => {
    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id,
    });
};
export const incrementQty = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (product, qty) => (dispatch) => {
    toast.warn("Item Decrement Qty to Cart");

    dispatch({
        type: types.DECREMENT_QTY,
        product, qty
    });
};
export const updateWithDeliverySLots = (product) => (dispatch) => {
    dispatch({
        type: types.UPDATE_WITH_DELIVERY_SLOTS,
        product,
    });
};
export const resetTheCart = () => (dispatch) => {
    dispatch({
        type: types.RESET_THE_CART,
    });
};
export const applyCouponDiscount = (coupon_discount) => ({
    type: types.APPLY_COUPON_DATA,
    coupon_discount,
});

// order
export const placeOrder = (data) => async (dispatch) => {
    try {
        let rs = await getAllDashboardProducts_api(data);
        if (rs.success) {
            dispatch(receiveDashboardProducts(rs.data));
        } else {
            toast.info(rs.message);
            dispatch({
                type: types.SET_DASHBOARD_PRODUCTS_ERROR,
            });
        }
    } catch (err) {
        toast.info(err.message);
        dispatch({
            type: types.SET_DASHBOARD_PRODUCTS_ERROR,
        });
    }
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product));
};


export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id,
    });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product,
});
export const removeFromCompare = (product_id) => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id,
});

// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand,
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color,
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value,
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by,
});
export const resetFilterAndfSort = () => ({
    type: types.RESET_FILTER_AND_SORTING,
});

// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol,
});


//Pages

export const fetchAboutus = () => ({
    type: types.GET_ABOUTUS_PAGE,
});

export const setAboutus = (aboutUs) => ({
    type: types.SET_ABOUTUS_PAGE,
    aboutUs,
});

export const getAboutUspage = (data) => async (dispatch) => {
    dispatch(fetchAboutus());
    try {
        let rs = await getaboutUs_api(data);
        dispatch(setAboutus(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};

export const AllSettings = () => ({
    type: types.GET_SETTINGS,
});
export const setAllSettings = (settings) => ({
    type: types.SET_SETTINGS,
    settings,
});

export const getAllSettings = (data) => async (dispatch) => {
    try {
        let rs = await getAllSettings_api(data);
        localStorage.setItem("setting", JSON.stringify(rs.data));
        dispatch(setAllSettings(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};



export const fetchOffers = () => ({
    type: types.GET_OFFERS,
});
export const setOffers = (offerlist) => ({
    type: types.SET_OFFERS,
    offerlist,
});
export const getoffers_home = () => async (dispatch) => {
    dispatch(fetchOffers());

    try {
        let apiParams = {
            language_id: 3
        };
        let rs = await getOffersList_api(apiParams);
        dispatch(setOffers(rs.data));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};
export const fetchService = () => ({
    type: types.GET_SERVICE,
});
export const setservice = (service) => ({
    type: types.SET_SERVICE,
    service,
});

export const getservicehomepage = () => async (dispatch) => {
    try {
        let apiParams = {
            language_id: 3
        };
        let rs = await getservicehomepage_api(apiParams);
        console.log(rs, "service")
        dispatch(setservice(rs.data.result.data.slider));
    } catch (error) {
        console.log(error);
        toast.error(error.networkError);
    }
};

export const getOfferData = async(data)=>{
    try {
        console.log("first")
        let rs = await getOfferData_api(data);
        console.log(rs, "res22")
        return rs
      
    } catch(err){
        toast.info(err.message);
    }        
};
