import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ItemList from './ItemList.js';

const LoadMore = (props) => {
    // const [items, setItems] = useState([]);
    // const [visible, setVisible] = useState(4);

    // useEffect(() => {
    //     fetch('https://fakestoreapi.com/products')
    //         .then(res => res.json())
    //         .then(json => setItems(json));
    // }, [])
    // function LoadMoreItems() {
    //     setVisible((prevValue) => prevValue + 4);
    // }
    // function APIload(params) {
    //     return (
    //         <div className=" row container d-flex justify-content-center " id="prodivmaster">

    //             <ul className="row Item-list text-left pro-items" id="proout">
    //                 <div className="row LoadItems" id="pros">
    //                     {items.slice(0, visible).map((item) => (
    //                         <ul className="Item-list pro-items"> 
    //                     <Li title={item.title} price={item.price + `₹`} delprice={item.price + `₹`} path_={item.image} heartPath={item.image} /><br /></ul>

    //                     ))}
    //                 </div>

    //             </ul>
                


    //         </div>


    //     )

    // }
    const [counter,setcounter] = useState(0);
    function isClicked(params) {
    
    if(counter==0){
      return <ItemList />
    }
    
        // var newC = (setcounter(counter+1));
        console.log(counter)
       
    }

    return (
        <div className="load d-flex justify-content-center py-2">
             <button id="load" onClick={props.LoadClick} className="center m-auto">Load More</button>
        </div>
    )
}



export default LoadMore;
