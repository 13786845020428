
import React, { useState, useEffect } from 'react';
import Li from '../li.js';
import LoadMore from './LoadMore';

const ItemList = (props) => {
    console.log('propsss',props)

    const [items, setItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [visible, setVisible] = useState(4);


    useEffect(() => {
        fetch('https://fakestoreapi.com/products')
            .then(res => res.json())
            .then(json => setItems(json))
            .then(setProducts(props.data))
    }, [])
    function LoadMoreItems() {
        setVisible((prevValue) => prevValue + 4);
    }

    const productItem = [
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "520 ₹",
            title: " Casual Wear",
            delprice: "690 ₹",
            path: "./product_images/4.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "600 ₹",
            title: " Casual Wear",
            delprice: "790 ₹",
            path: "./product_images/2.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "520 ₹",
            title: " Casual Wear",
            delprice: "690 ₹",
            path: "./product_images/4.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "600 ₹",
            title: " Casual Wear",
            delprice: "790 ₹",
            path: "./product_images/2.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "520 ₹",
            title: " Casual Wear",
            delprice: "690 ₹",
            path: "./product_images/4.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "600 ₹",
            title: " Casual Wear",
            delprice: "790 ₹",
            path: "./product_images/2.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "520 ₹",
            title: " Casual Wear",
            delprice: "690 ₹",
            path: "./product_images/4.png",
            heartPath: "./Section 1/Path.png"
        },
        {
            price: "500 ₹",
            title: " Casual Wear",
            delprice: "590 ₹",
            path: "./product_images/1.png",
            heartPath: "./Section 1/heart_selected.png"
        },
        {
            price: "600 ₹",
            title: " Casual Wear",
            delprice: "790 ₹",
            path: "./product_images/2.png",
            heartPath: "./Section 1/Path.png"
        }
    ]

    function LoadList(params) {
        return (
            <>
                <div className="row container d-flex justify-content-center ">
                    <ul className="Item-list  text-left pro-items mx-4 proulmaster" >
                      {(products && products.length >0)?products.map(productItem => (
                            <Li  id={productItem.id} title={productItem.product_name} price={productItem.product_price} delprice={productItem.wholesale_price} path_={productItem.product_image} heartPath={productItem.product_image} />
                        )):""}
                    </ul>
                </div>
            </>

        )

    }
    function APIload(params) {
        return (
            <div className=" row container d-flex justify-content-center " id="prodivmaster">

                <ul className="row Item-list text-left pro-items" id="proout">
                    <div className="row LoadItems" id="pros">

                        {items.slice(0, visible).map((item) => (
                            <ul className="Item-list pro-items"> 
                            <Li  id={item.id} title={item.title} price={item.price + `₹`} delprice={item.price + `₹`} path_={item.image} heartPath={item.image} /><br /></ul>

                        ))}
                    </div>

                </ul>
                


            </div>


        )

    }
    function ContainerSlide(params) {
        if (props.className) {
            return (
                // <div>
                //     <table className="container">
                //         <tr>
                //             {LoadList()}
                //         </tr>
                //         <tr>
                //             {LoadList()}
                //         </tr>
                //         <tr>
                //             <LoadMore LoadClick={LoadList()} />
                //         </tr>
                //     </table>
                // </div>
                alert(props.className)
            )
        }
    }

    return (


        <table className="container mainProContainer">
            <tr >
                {LoadList()}
            </tr>
            <tr id="second_loadlist">
                {LoadList()}
            </tr>
            <tr>
                <LoadMore LoadClick={APIload} />
            </tr>
        </table>


    );
}

export default ItemList;


{/* 
    <Li price="500 ₹" delprice="590₹" path_="./product_images/1.png" heartPath="./Section 1/heart_selected.png" />
    <Li price="500 ₹" delprice="690₹" path_="./product_images/4.png" heartPath="./Section 1/Path.png" />
    <Li price="300 ₹" delprice="490₹" path_="./product_images/1.png" heartPath="./Section 1/heart_selected.png" />
    <Li price="400 ₹" delprice="590₹" path_="./product_images/2.png" heartPath="./Section 1/Path.png" /> */}