import React, {Component} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";
import {Link} from "react-router-dom";

import {
    getHomePageBrands,
    getHomeSliders,
    getHomeDashboardModules,
} from "../../../actions/index";

import {
    getOffersList_api
} from "../../../api/home";

// Import custom components
import HomeDashboardModules from "./HomeDashboardModules";
import SpecialProducts from "../common/products";
import BlogSection from "../common/blogsection";
// import Instagram from "../common/instagram";
import BrandsLogo from "./BrandsLogo";
import {
    svgFreeShipping,
    svgservice,
    svgoffer,
} from "../../../services/script";
import {faqList_api} from "../../../api/pages";
import {toast} from "react-toastify";

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            offerlist: [],
        };
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        // get all home page sliders & brands
        this.props.getHomeSliders();
        this.props.getHomePageBrands();
        this.props.getHomeDashboardModules();
        this.getofferlist()
    }

    getofferlist = async () => {

        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let offerlist = await getOffersList_api(apiParams);
            if (offerlist.success) {
                this.setState({
                    offerlist: offerlist.data,
                });
            } else {
                toast.error(offerlist.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };


    render() {
        const {sliders} = this.props;

        let url;
        return (
            <div>
                <Helmet>
                    <title></title>
                    <meta name="description" content=""/>
                </Helmet>
                {/*Home Slider*/}
                <section className="p-0 small-slider">
                    <Slider className="slide-1 home-slider">
                        {sliders.map((slider, i) => {
                            if (slider.category_id !== 0) {
                                url = `category/${slider.category_id}/products`;
                            } else {
                                url = `product/${slider.product_id}`;
                            }
                            return (
                                <div key={i}>
                                    <div
                                        className="home text-center"
                                        style={{
                                            backgroundImage: `url(${slider.slider_image})`,
                                        }}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="slider-contain">
                                                        <div>
                                                            {/* <h4>welcome to fashion</h4> */}
                                                            <h2
                                                                style={{
                                                                    color: "white",
                                                                }}
                                                            >
                                                                {slider.slider_name}
                                                            </h2>
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/${url}`}
                                                                className="btn btn-solid"
                                                            >
                                                                shop now
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </section>
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0">
                    <div className="container">
                        <div className="row partition2">
                            <div className="col-md-6">
                                <Link to="/">
                                    <div className="collection-banner p-right text-center">
                                        <img
                                            src={`${
                                                process.env.PUBLIC_URL
                                            }/assets/images/electrical-banner.png`}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        {/* <div className="contain-banner">
                      <div>
                        <h4>save 30%</h4>
                        <h2>men</h2>
                      </div>
                    </div> */}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/">
                                    <div className="collection-banner p-right text-center">
                                        <img
                                            src={`${
                                                process.env.PUBLIC_URL
                                            }/assets/images/plumbing-banner.png`}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        {/* <div className="contain-banner">
                      <div>
                        <h4>save 60%</h4>
                        <h2>women</h2>
                      </div>
                    </div> */}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/*collection banner end*/}

                <HomeDashboardModules type={"women"} {...this.props} />

                {/*Parallax banner*/}
                <section className="p-0 d-none">
                    <div className="full-banner parallax-banner1 parallax text-center p-left">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="banner-contain">
                                        <h2>2018</h2>
                                        <h3>fashion trends</h3>
                                        <h4>special offer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Parallax banner End*/}



                {/*service layout*/}
                <div className="container">
                    <section className="service border-section small-section ">
                        <div className="row">
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>
                                    <div className="media-body">
                                        <h4>free shipping</h4>
                                        <p>free shipping world wide</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgservice}}/>
                                    <div className="media-body">
                                        <h4>24 X 7 service</h4>
                                        <p>online service for new customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgoffer}}/>
                                    <div className="media-body">
                                        <h4>festival offer</h4>
                                        <p>new online special festival offer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/*logo section*/}
                <BrandsLogo {...this.props} />
                {/*logo section end*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    brands: state.home.brands,
    sliders: state.home.sliders,
    dashboardModules: state.home.dashboardModules,
});

export default connect(
    mapStateToProps,
    {
        getHomePageBrands,
        getHomeSliders,
        getHomeDashboardModules,
    }
)(Home);
