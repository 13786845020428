import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTrendingCollection } from "../../../services/index";
import { Product4, Product5 } from "../../../services/script";
import { addToCart, addToWishlist, addToCompare } from "../../../actions/index";
import ProductBox from "../common/ProductBox";

class HomeDashboardModules extends Component {
  render() {
    const {
      items,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
      type,
      dashboardModules,
    } = this.props;

    var properties;
    if (type === "kids") {
      properties = Product5;
    } else {
      properties = Product4;
    }

    return (
      <>
        {dashboardModules.map(
          (section, i) =>
            section.products.length > 0 && (
              <div
                key={i}
                // style={{
                //     backgroundImage: `url(${section.section_background_image})`
                // }}
              >
                {/*Paragraph*/}
                <section className="p-t-0">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div
                          className="title1 section-t-space"
                          style={{
                            paddingTop: `${i === 0 ? "70" : "35"}px`,
                          }}
                        >
                          {/* <h4>special offer</h4> */}
                          <h2 className="title-inner1">
                            {section.section_title}
                            <Link
                              to={`${process.env.PUBLIC_URL}/dashboard/${
                                section.dashboard_id
                              }/products`}
                              className="text-muted pull-right"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              view all
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/*Paragraph End*/}
                <section className="section-b-space p-t-0">
                  <div className="container">
                    <div className="row homepage">
                      {/* <div className="col-md-12"> */}
                      {/* <Slider
                          {...properties}
                          className="product-4 product-m no-arrow"
                        > */}
                      {section.products.map((product, index) => (
                        <div key={index} className="col-md-3">
                          <ProductBox
                            product={product}
                            symbol={symbol}
                            onAddToCompareClicked={() => addToCompare(product)}
                            onAddToWishlistClicked={() =>
                              addToWishlist(product)
                            }
                            onAddToCartClicked={() => addToCart(product, 1)}
                            key={index}
                            auth={this.props.auth}
                          />
                        </div>
                      ))}
                      {/* </Slider> */}
                      {/* </div> */}
                    </div>
                  </div>
                </section>
              </div>
            )
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: getTrendingCollection(state.data.products, ownProps.type),
  symbol: state.data.symbol,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare }
)(HomeDashboardModules);
