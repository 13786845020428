import React from 'react'

const ShopWithUs = (props) => {
    
    return (
        
        <div className="ticklist ">
            <div className="row tick">
            <img className="pl-2 col-1" src="assets/images/extras/tick.png" alt="" srcset=""/>
           <div className="col-10 listContent" >
           {props.listContent}</div>
             </div>
        </div>
           
        
    )
}



export default ShopWithUs;
