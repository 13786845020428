import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import Breadcrumb from "../common/breadcrumb";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import { letUserSignIn_Api, letCheckMobileNo_Api } from "../../api/auth";
import { setUserSignUpSuccess } from "../../actions";
import * as storageUtils from "../../utils/storageUtils";
import {offlinecartpages} from "../../utils/helper";
import countryData from "./countries.json";
import { Helmet } from "react-helmet";
// import config from "config";

class UserLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      country_code: "+91",
      mobile_no: "",
      google_id: "",
      facebook_id: "",
      isDisplayOtp: false,
      isSentOtpLoaderEnable: false,
      isSubmitOtp: false,
      is_sign_up: 1,
      device_token: "webToken",
      device_type: "WEB",
      input_active : true
    };
  }

  componentDidMount() {
   
    if (this.props.auth.isLoggedIn) {
      this.props.history.push({
        pathname: "/dashboard/account",
      });
    }

    if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
    
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",{
        size: "invisible",
        callback: function(response) {
          this.submitPhoneNumberAuth();
        }
      });
  }

  handleChange = (otp) => this.setState({ otp });

  handleCountryChange = (e) => {
    // console.log(e.target.value);
    this.setState({
      country_code: e.target.value,
    });
  };

  submitPhoneNumberAuth = async () => {
    return new Promise((resolve, reject) => {
      try {
        // We are using the test phone numbers we created before
        // var phoneNumber = document.getElementById("phoneNumber").value;
        var phoneNumber = this.state.country_code + this.state.mobile_no;
        var appVerifier = window.recaptchaVerifier;

        // console.log(phoneNumber);
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function(confirmationResult) {
            // console.log("confirmationResult ", confirmationResult);
            window.confirmationResult = confirmationResult;
            resolve(confirmationResult);
          })
          .catch(function(error) {
            console.log(error);
            toast.error(error.message);
            reject(error.message);
          });
      } catch (error) {
        console.log(error);
        reject(error.message);
      }
    });
  };



  getOtpClick = async (e) => {
    e.preventDefault();
    if (this.state.mobile_no !== "") {
      this.setState({ isSentOtpLoaderEnable: true, is_sign_up: 1 });
      try {
        const checkMobileExist = await letCheckMobileNo_Api({
          ...this.state,
          country_code: this.state.country_code.replace("+", ""),
        });

          if(checkMobileExist.useractive){
            if (checkMobileExist.success) {
              const rs = await this.submitPhoneNumberAuth();
              // console.log("RS ", rs);
              this.setState({
                isDisplayOtp: true,
                isSentOtpLoaderEnable: false,
                input_active : false
              });
              toast.success("OTP has been sent to the given number");

            } else {
              this.setState({
                isSentOtpLoaderEnable: false,
              });
              toast.error("Phone number is not registered with us");
            }
          }else{
            storageUtils.unsetUserData();
            storageUtils.unsetUserToken();
            toast.error("Your Account Deactivate plz Contact Administrator");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }

    
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        this.setState({ isSentOtpLoaderEnable: false });
      }
    } else {
      toast.error("Please enter mobile number");
    }
  };

  submitPhoneNumberAuthCode = (e) => {
    return new Promise((resolve, reject) => {
      try {
        // We are using the test code we created before
        // var code = document.getElementById("code").value;
        // console.log(this.state.otp);
        var code = this.state.otp;
        window.confirmationResult
          .confirm(code)
          .then(function(result) {
            var user = result.user;
            // console.log("=============");
            // console.log(user);
            resolve(user);
          })
          .catch(function(error) {
            console.log(error);
            reject(error.message);
          });
      } catch (error) {
        console.log(error);
        reject(error.message);
      }
    });
  };

  handleUserProvidedCode = async (e) => {
    e.preventDefault();
    try {
      this.setState({ isSubmitOtp: true });
      const user = await this.submitPhoneNumberAuthCode();
      // console.log("USER =>", user);
      // toast.success("phone number has been successfully verified");
      try {
        const userData = await letUserSignIn_Api({
          ...this.state,
          country_code: this.state.country_code.replace("+", ""),
          // mobile_no: "9106680279"
        });

        
        if (userData.success) {
          // Set token and login to app.
          storageUtils.setUserData(userData.data);
          storageUtils.setUserToken(userData.token);
              offlinecartpages();

          this.props.setUserSignUpSuccess(userData.data);

          this.setState({
            isSubmitOtp: false,
            otp: "",
            mobile_no: "",
            isDisplayOtp: false,
          });
          toast.success(userData.message);

          this.props.history.push({
            pathname: "/",
          });
        } else {
          this.setState({
            isSubmitOtp: false,
            // isDisplayOtp: false,
          });
          toast.error(userData.message);
        }
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
        this.setState({ isSubmitOtp: false });
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      this.setState({ isSubmitOtp: false });
    }
  };

  // responseGoogleSuccess = async (response) => {
  //   console.log(response);
  //   let user = response.profileObj;
  //   this.setState(
  //     {
  //       is_sign_up: 2,
  //       google_id: user.googleId,
  //     },
  //     async () => {
  //       try {
  //         const userData = await letUserSignIn_Api({
  //           ...this.state,
  //           country_code: this.state.country_code.replace("+", ""),
  //         });

  //         if (userData.success) {
  //           // Set token and login to app.
  //           storageUtils.setUserData(userData.data);
  //           storageUtils.setUserToken(userData.token);
  //             offlinecartpages();

  //           this.props.setUserSignUpSuccess(userData.data);

  //           this.setState({
  //             isSubmitOtp: false,
  //             otp: "",
  //             mobile_no: "",
  //             isDisplayOtp: false,
  //           });
  //           toast.success(userData.message);

  //           this.props.history.push({
  //             pathname: "/",
  //           });
  //         } else {
  //           this.setState({
  //             isSubmitOtp: false,
  //             // isDisplayOtp: false,
  //           });
  //           toast.error(userData.message);
  //         }
  //       } catch (error) {
  //         console.log(error.message);
  //         toast.error(error.message);
  //       }
  //     }
  //   );
  // };

  // responseGoogleError = (response) => {

  //   if(response && response.error == "idpiframe_initialization_failed")
  //   {
  //     console.log("Error ", response);
  //     toast.error(response.details);
  //   }
  //   else if(response && response.error != "popup_closed_by_user")
  //   {
  //     console.log("Error ", response);
  //     toast.error("Oops! something went wrong");
  //   }
  // };

  // responseFromFacebook = async (response) => {
  //   console.log("responseFromFacebook ", response);
  //   if (response.hasOwnProperty("id") && response.hasOwnProperty("name")) {
  //     this.setState(
  //       {
  //         is_sign_up: 3,
  //         facebook_id: response.id,
  //       },
  //       async () => {
  //         try {
  //           const userData = await letUserSignIn_Api({
  //             ...this.state,
  //             country_code: this.state.country_code.replace("+", ""),
  //           });

  //           if (userData.success) {
  //             // Set token and login to app.
  //             storageUtils.setUserData(userData.data);
  //             storageUtils.setUserToken(userData.token);
  //             offlinecartpages();

  //             this.props.setUserSignUpSuccess(userData.data);

  //             this.setState({
  //               isSubmitOtp: false,
  //               otp: "",
  //               mobile_no: "",
  //               isDisplayOtp: false,
  //             });
  //             toast.success(userData.message);

  //             this.props.history.push({
  //               pathname: "/",
  //             });
  //           } else {
  //             this.setState({
  //               isSubmitOtp: false,
  //               // isDisplayOtp: false,
  //             });
  //             toast.error(userData.message);
  //           }
  //         } catch (error) {
  //           console.log(error.message);
  //           toast.error(error.message);
  //         }
  //       }
  //     );
  //   } else {
  //     // toast.error("Oops! something went wrong");
  //   }
  // };

  onChangeMobile = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ mobile_no: e.target.value });
    }
  };

  render() {
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Login </title>
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Login"} />

        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>Login</h3>
                <div className="theme-card">
                  <form className="theme-form">
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label htmlFor="email">Phone Number</label>
                          <select
                            className="form-control"
                            onChange={(e) => this.handleCountryChange(e)}
                            style={{
                              height: "54px",
                            }}
                            defaultValue={this.state.country_code}
                          >
                            {countryData.map((item, i) => {
                              return (
                                <option key={i} value={item.dial_code}>
                                  {item.name}&nbsp;{item.dial_code}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-8 login-num-res">
                        <div className="form-group">
                          <label htmlFor="mobileNo">&nbsp;</label>
                          {
                            this.state.input_active ? <>  <input
                            type="tel"
                            className="form-control"
                            id="mobileNo"
                            placeholder="Enter phone number"
                            maxLength="10"
                            value={this.state.mobile_no}
                            required
                            onChange={this.onChangeMobile}
                          /></> : 
                          <><input
                          type="tel"
                          className="form-control"
                          id="mobileNo"
                          placeholder="Enter phone number"
                          maxLength="10"
                          value={this.state.mobile_no}
                          required
                          disabled
                          onChange={this.onChangeMobile}
                        /></>
                          }
                        
                        </div>
                      </div>
                    </div>

                    <div className={this.state.isDisplayOtp ? "d-none" : ""}>
                      <button
                        className="btn btn-solid"
                        onClick={(e) => this.getOtpClick(e)}
                        disabled={
                          this.state.isSentOtpLoaderEnable
                          // ||
                          // this.state.mobile_no.length < 9
                        }
                      >
                        GET OTP &nbsp;{" "}
                        {this.state.isSentOtpLoaderEnable && (
                          <i className="fa fa-spinner ml-3 fa-pulse fa-2x" />
                        )}
                      </button>
                      {/* Add a container for reCaptcha */}
                      <div id="recaptcha-container" />
                    </div>
                    <div
                      className={` OTP_IN form-group${
                        !this.state.isDisplayOtp ? " d-none" : "" 
                      }`}
                    >
                      <label htmlFor="review">Enter OTP</label>
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={6}
                        separator={<span>&nbsp;</span>}
                        isInputNum={true}
                        // disabledStyle={true}
                        containerStyle={{
                          justifyContent: "space-between",
                        }}
                        inputStyle={{
                          width: "40px",
                          border: "1px solid #dddddd",
                          fontSize: ""
                        }}
                      />
                    </div>
                    <div className={!this.state.isDisplayOtp ? "d-none" : ""}>
                      <button
                        className="btn btn-solid"
                        onClick={(e) => this.handleUserProvidedCode(e)}
                        disabled={this.state.isSubmitOtp}
                      >
                        Login &nbsp;
                        {this.state.isSubmitOtp && (
                          <i className="fa fa-spinner ml-3 fa-pulse fa-2x" />
                        )}
                      </button>
                    </div>
                  </form>
                  {/* <div className="csLine mt-3">
                    <h2>
                      <span>OR</span>
                    </h2>
                  </div>
                  <div className="row d-flex justify-content-between login_soc_buttons">
                    <div className="col">
                    <FacebookLogin
                      appId={firebaseConfig.facebook_appId}
                      fields="name,email,picture"
                      callback={this.responseFromFacebook}
                      cssClass="fbBtnCls"
                      icon="fa-facebook"
                      textButton="&nbsp; &nbsp;SignIn With Facebook"
                    />
                    </div>
                    <div className="col">
                    <GoogleLogin
                        clientId={firebaseConfig.clientId}
                      render={renderProps => (
                        <button className="fbBtnCls" onClick={renderProps.onClick}><i className="fa fa-google"></i>&nbsp;&nbsp;SignIn With Google</button>
                      )}
                      buttonText="SignIn With Google"
                      onSuccess={this.responseGoogleSuccess}
                      onFailure={this.responseGoogleError}
                      cookiePolicy={"single_host_origin"}
                    />
                    </div>

                  </div> */}

                </div>
              </div>
              <div className="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div className="theme-card authentication-right">
                  <h6 className="title-font">Create an account</h6>
                  <p>
                    Sign up for a free account at our store. Registration is
                    quick and easy. It allows you to be able to order from our
                    shop. To start shopping click register.
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/register`}
                    data-lng="en"
                    className="btn btn-solid"
                  >
                    Create an Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div
          style={{
            marginTop: "300px",
            marginBottom: "300px",
          }}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {
    setUserSignUpSuccess,
  }
)(UserLogin);
