import React, { Component } from 'react'
import {Link} from "react-router-dom"
import {toast} from "react-toastify"
import Breadcrumb from '../common/breadcrumb'
import {getspecialitylist_api} from "../../api/home"

export default class SpecialityListing extends Component {
    state = {
        specialityList: [],
        isLoading: false
    }
    
    componentDidMount() {
        this.specialityList()
    }

    specialityList = async () => {

        try {
          this.setState({
            isLoading: true
          })
            let specialityList = await getspecialitylist_api();

            console.log("specialityList",specialityList);
            if (specialityList.status) {
                this.setState({
                    specialityList: specialityList.result.data,
                });
            } else {
                toast.error(specialityList.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        } finally {
          this.setState({
            isLoading: false
          })
        }
  
      };
    render() {
      if(this.state.isLoading) {

        return <div className="row w-100">
        <div
            className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found ">
            <div className="loading-cls"/>
        </div>
    </div>
      }
        return (
          <div>
              <Breadcrumb title={"ALL SPECIALITIES"} />
              
            <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cart-d-none-mobile">
                                    <div className="title1 section-t-space">
                                        <h3 className="title-inner1 title-inner1 font-size-text-30" 
                                        // style={{textAlign:"center", color:"#000", fontWeight:"600", padding:"20px"}}
                                        >SHOP BY CATEGORIES</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                <div className="element-category display-mob">                
                  <div className='parent-category'>
                    {this.state.specialityList &&
                      this.state.specialityList.length &&
                      this.state.specialityList.map((list) => {
                        return (
                          <div className='child-category category-block mt-5 mb-3'>
                            <Link to={`/services/${list.name}/${list.id}/0`}>
                              <div className='category-image sp-image'>
                                <img src={list.image_url} alt='' />
                              </div>
                            </Link>
                            <div className='category-details'>
                              <Link to={`/services/${list.name}/${list.id}/0`}>
                                <h5>{list.name}</h5>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              {/* <section className='section-b-space' id='catSec'>
                <div className='element-category' style={{display: "block"}}>
                <div className="element-category display-mob">                
                  <div className='parent-category'>
                    {this.state.specialityList &&
                      this.state.specialityList.length &&
                      this.state.specialityList.map((list) => {
                        return (
                          <div className='child-category category-block'>
                            <Link to={`/services/${list.name}/${list.id}/0`}>
                              <div className='category-image'>
                                <img src={list.image_url} alt='' />
                              </div>
                            </Link>
                            <div className='category-details'>
                              <Link to={`/services/${list.name}/${list.id}/0`}>
                                <h5>{list.name}</h5>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  </div>
                </div>
              </section> */}
          </div>
        );
    }
}
