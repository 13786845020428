import React from 'react';

const Hovericon = (props) => {
    
    return (
        <>
            <div class="hovericons">
                <ul className="wishlist ">
                    <li><a title="Add to Wishlist " onClick={props.onClick} >
                        <i class="fa fa-shopping-cart" id="cart-icon" aria-hidden="true"></i></a>
                    </li>
                    <li><a title="Add to Wishlist ">
                        <i class="fa fa-heart" aria-hidden="true"></i></a>
                    </li>
                    <li><a title="Quick View "><i class="fa fa-search" aria-hidden="true"></i></a>
                    </li>
                    <li><a title="Compare "><i class="fa fa-refresh" aria-hidden="true"></i></a>
                    </li>

                </ul>
            </div>



        </>
    );
}

export default Hovericon;

