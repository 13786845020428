import React from 'react'

const OverlayTxt = (props) => {
    return (
        <div className="imgwrap">
            <img className="img_img" src={props.imgsrc} alt="" srcset="" />
            <p class="img_desc">
                <p id="sat">SHOPPING SATURDAY.</p> FLAT 30% OFF<br />
                <button type="button" class="btn btn-danger">Shop Now!</button>
            </p>
        </div>
    )
}

export default OverlayTxt
