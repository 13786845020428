import React, {Component} from 'react';
import Slider from "react-slick"
import Breadcrumb from "../../common/breadcrumb";
import {Slider6, Slider4, Slider15} from "../../../services/script"
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {getHomeDashboardModules, getHomePageBrands, getHomeSliders, getservicehomepage} from "../../../actions";
// import all_categories from '../../../access/icone/group-40.webp';
import all_speciality from '../../../access/icone/sp_all.webp';
import { getspecialitylist_api } from '../../../api/home';
import { toast } from 'react-toastify';
class servicecategory extends Component {
  state = {
    specialityList: [],
}
componentDidMount() {
    this.specialityList()
}
specialityList = async () => {
    try {
        let specialityList = await getspecialitylist_api();
        console.log("specialityList",specialityList);
        if (specialityList.status) {
            this.setState({
                specialityList: specialityList.result.data,
            });
        } else {
            toast.error(specialityList.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
  };
    render() {
        const {service} = this.props.service;
        return (
          <>
              <div>
              {/*Category One*/}
              <div className="container p-0">
                  <section className="section-b-space psm0 " id="catSec">

                      <Slider {...Slider15} className="slide-6 no-arrow mobShow">
                          <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6  mb-2 ">
                              <div className="category-block">
                                  <Link to={"/speciality-listing"}>
                                      <div className="sp-image">
                                          <img src={all_speciality} alt=""/></div>
                                  </Link>
                                  <div className="category-details">
                                      <Link to={"/speciality-listing"}>
                                          <h5>ALL SPECIALITIES</h5>
                                      </Link>
                                  </div>
                              </div>
                          </div>
                          {
                             this.state.specialityList &&
                              this.state.specialityList.length &&
                              this.state.specialityList.map((list, index) => {

                                  return <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6  mb-2 ">
                                      <div className="category-block">
                                      <Link to={`/services/${list.name}/${list.id}/0`}>
                              <div className='category-image sp-image'>
                                <img src={list.image_url} alt='' />
                              </div>
                            </Link>
                            <div className='category-details'>
                              <Link to={`/services/${list.name}/${list.id}/0`}>
                                <h5>{list.name}</h5>
                              </Link>
                            </div>
                                      </div>
                                  </div>

                              }) 
                          }
                      </Slider>


                  

                  </section>
              </div>
          </div>
          </>
        );
    }
}
const mapStateToProps = (state) => ({
    service: state.service
});
export default connect(
    mapStateToProps,
    {
        getservicehomepage
    }
)(servicecategory);