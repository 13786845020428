import React, {Component} from "react";
import {Link} from "react-router-dom"
import Slider from 'react-slick';
import "../common/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

// import custom Components
import RelatedProduct from "../common/related-product"
import Breadcrumb from "../common/breadcrumb";
import Stars from "../common/stars";
import DetailsWithPrice from "../../components/products/common/product/details-price2";
import {
    addToCart,
    addToCartUnsafe,
    addToWishlist,
    getConfigureProductDetail,
    getProductDetail,
    updateProductOptions
} from '../../actions'
import ImageZoom from '../../components/products/common/product/image-zoom'
import SmallImages from '../../components/products/common/product/small-image'
import {addToCart_api, addToWishlist_api, removeproductwishlist_api} from "../../api/product";
import {toast} from "react-toastify";
import Video from "../products/common/product/video";
import Navigation_bar from "../features/theme/navigation_bar";

//import SampleNextArrow from "../products/common/product/SampleNextArrow"

class ProductDetail extends Component {

    constructor() {
        super();
        this.state = {
            vertical: true,
            product_option: [],
            is_wishlist: [],
            not_is_wishlist: [],
            prod_options: [],
            showBack: true,
            pageLoaded: false
        };
    }

    componentWillMount() {


        if (window.innerWidth > 576) {
            this.setState({vertical: true});
        } else {
            this.setState({vertical: false});
        }
    }

    componentDidMount() {
      console.log("first")
        let apiParams = {
            id: this.props.match.params.id,
            user_id: this.props.auth.isLoggedIn ? this.props.auth.userData.id : "",
            // language_id: 1,
            hello: "first"
        };
        this.props.getProductDetail(apiParams) ? this.setState({pageLoaded: true}) : this.setState({pageLoaded: false}) 

    }

    componentDidUpdate(prevProps) {
      console.log("second")

        if (prevProps.match.params.id !== this.props.match.params.id) {
            let apiParams = {
                id: this.props.match.params.id,
                user_id: this.props.auth.isLoggedIn ? this.props.auth.userData.id : "",
                hello:"second"
            };

            this.props.getProductDetail(apiParams) ? this.setState({pageLoaded: true}) : this.setState({pageLoaded: false})
        }
    }


    addToWishListproduct = async (item, is_already_in = 0) => {

        if (this.props.auth.isLoggedIn) {
            try {
                if (is_already_in === 1) {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id") ? item.id : item.id,
                    };
                    let apiData = await removeproductwishlist_api(apiParams);
                    if (apiData.success) {

                        this.setState({is_wishlist: []})
                        var wishlist_ids = this.state.not_is_wishlist.concat(apiParams.product_id);
                        this.setState({not_is_wishlist: wishlist_ids})
                        toast.success("Item Removed from Wishlist");

                    } else {
                        toast.error(apiData.message);
                    }
                } else {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id") ? item.id : item.id,
                    };
                    let apiData = await addToWishlist_api(apiParams);
                    if (apiData.success) {

                        var wishlist_ids = this.state.is_wishlist.concat(apiParams.product_id);
                        this.setState({is_wishlist: wishlist_ids})

                        this.props.addToWishlist(item);
                    } else {
                        toast.error(apiData.message);
                    }
                }
            } catch (error) {
                // console.log(error);
                toast.error(error.message);
            }
        } else {
            toast.info("Please login to continue");
            setTimeout(() => {
                window.location.href = "/login"
            }, 1000)
        }
    };

    declareOrderType = (item, qty) => {
        let wholesale_price =
            item.wholesale_sp_price !== ""
                ? item.wholesale_sp_price
                : item.wholesale_price;

        let orderType = "Retail";
        if (qty >= parseInt(item.product_min_wholesale_quantity) && (item.product_val_type === 2 || item.product_val_type === 3) && parseInt(wholesale_price) !== 0
        ) {
            orderType = "Wholesale";
        }


        return orderType;
    };

    declareOrderTypenew = (item) => {

        let orderType = "Retail";
        if (item.product_val_type == 2) {
            orderType = "Wholesale";
        }
        return orderType;
    };


    set_product_option_onload = () => {

        const item = this.props.item;
        const setoption = this.state.prod_options;

        if (item.product_option && item.product_option.length > 0) {
            item.product_option.map((data, index) => {
                data.product_option_value.map((size, i) => {
                    if (item && item.options_json && item.options_json.length > 2 && JSON.parse(item.options_json).includes(size.option_value_id)) {
                        setoption[index] = size.option_value_id
                    }
                })
            })
        }
        this.setState(setoption);
    }

    set_product_option = (option, key, id) => {

        this.set_product_option_onload()

        const cong_prod = this.state.prod_options
        cong_prod[key] = option

        let apiParams = {
            id: id,
            // language_id: 1,
            option_id: JSON.stringify(cong_prod),
            user_id: this.props.auth.isLoggedIn ? this.props.auth.userData.id : "",
        };

        // this.props.getConfigureProductDetail(apiParams); // set to get Configure Product Detail
        this.props.getProductDetail(apiParams);
    }

    addToCartApi = async (item, qty) => {
        if (this.props.auth.isLoggedIn) {
            if(qty >= item.product_min_quantity){
                try {
                    this.setState({addToCartApiCalled: true});
                    let apiParams = {
                        product_id: item.id,
                        user_id: this.props.auth.userData.id,
                        quantity: qty,
                        option_val: item.options_json,
                        is_retail: this.declareOrderTypenew(item) === "Retail" ? 1 : 2,
                    };
                    let cartData = await addToCart_api(apiParams);
                    this.setState({addToCartApiCalled: false});
                    // console.log(cartData);
                    if (cartData.success) {
                        this.props.addToCart(item, qty);
                    } else {
                        toast.error(cartData.message);
                    }
                } catch (error) {
                    this.setState({addToCartApiCalled: false});
                    console.log(error);
                    toast.error(error.message);
                }
            } else {
                toast.error("Quantity should be greater than min quantity")
            }
        } else {
            this.props.addToCart(item, qty);
        }

    };

    
    render() {
        const {
            symbol,
            item,
            addToCart,
            addToCartUnsafe,
            fetchSingleProductLoader,
            singleProduct,
            addToWishlistClicked,
            isSingleProductFound,

        } = this.props;
        const {
            is_allow_reviews,
            is_allow_guest_review,
            is_auto_approval_review,
            facebook_link,
            twitter_link,
            instagram_link,
            youtube_link
        } = this.props.settings;

        const {is_wishlist, not_is_wishlist} = this.state

        var products = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: false,
        };

        var productsnav = {
            // vertical: this.state.vertical,
            // verticalSwiping: this.state.vertical,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: ".product-right-slick",
            arrows: false,
            // nextArrow: <SampleNextArrow />,
            infinite: true,
            centerMode: false,
            dots: false,
            focusOnSelect: true,
            afterChange: (index) => {
                if (index >= 0) {
                    this.slider1.slickGoTo(index)
                }
            },
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    },
                },
            ],
        };


        return (
          <div>
            {item && "product_name" in item
              ? ""
              : // <div className="loader-wrapper">
                //     <div className="loader"></div>
                // </div>
                ""}

            {item && "product_name" in item ? (
              <Helmet>
                <title>{item.product_name}</title>
                <meta
                  name='description'
                  content={item.product_meta_tag_description}
                />
              </Helmet>
            ) : (
              ""
            )}
            <div className='product-details-d-none'>
              {item && "product_name" in item ? (
                <Breadcrumb
                  showBack={this.state.showBack}
                  title={
                    " Product / " + (item && item.product_name)
                      ? "PRODUCT DETAILS"
                      : ""
                  }
                />
              ) : (
                <Breadcrumb
                  showBack={this.state.showBack}
                  title={" Product / " + ""}
                />
              )}
            </div>

            {/*Section Start*/}
            {item && "product_name" in item ? (
              <section id='detailsection' className="detailSec">
                <div className='collection-wrapper mobt'>
                  <div className='container-fluid'>
                    <div
                      className='row leftImage mx-md-n5 mx-xl-5 product-details-margin-max'
                      id='proDetail'
                    >
                      <div className='col-sm-12'>
                        <Navigation_bar viewlisttitle={item.product_name} />
                      </div>

                      <div className='col-lg-5 leftSideimg'>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <Slider
                              {...products}
                              asNavFor={this.props.navOne}
                              ref={(slider) => (this.slider1 = slider)}
                              className='product-right-slick'
                            >
                              <div key={999}>
                                <ImageZoom image={item.product_image} />
                              </div>

                              {item &&
                              item.product_images &&
                              item.product_images.length > 0
                                ? item.product_images.map((vari, index) => (
                                    <div key={index}>
                                      <ImageZoom image={vari.images} />
                                    </div>
                                  ))
                                : ""}

                              {item &&
                              item.product_images &&
                              item.product_images.length == 0
                                ? [1, 2, 3].map((vari, index) => (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      key={index}
                                    >
                                      <ImageZoom image={item.product_image} />
                                    </div>
                                  ))
                                : ""}
                              {item.product_video ? (
                                <div key={9999}>
                                  <Video image={item.product_video} />
                                </div>
                              ) : (
                                ""
                              )}
                            </Slider>
                          </div>

                          <div className='col-sm-12'>
                            <SmallImages item={item} settings={productsnav} />
                          </div>
                        </div>
                        <div
                          className='border-product product-details-d-none d-block border-set mt-2'
                          id='socialRow'
                        >
                          <h6 className='product-title d-inline'>
                            Share it on social :{" "}
                          </h6>
                          <div className='product-icon d-inline'>
                            <ul className='product-social d-inline'>
                              <li>
                                <a href={facebook_link || ""} target='_blank'>
                                  <i className='fa fa-facebook'></i>
                                </a>
                              </li>
                              <li>
                                <a href={twitter_link || ""} target='_blank'>
                                  <i className='fa fa-twitter'></i>
                                </a>
                              </li>
                              <li>
                                <a href={youtube_link || ""} target='_blank'>
                                  <i className='fa fa-youtube-play'></i>
                                </a>
                              </li>
                              <li>
                                <a href={instagram_link || ""} target='_blank'>
                                  <i className='fa fa-instagram'></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-7 rightSideText'>
                        {/* <DetailsWithPrice
                          symbol={symbol}
                          item={item}
                          navOne={this.state.nav1}
                          addToCartClicked={this.addToCartApi}
                          checktype={this.checktype}
                          set_product_option={this.set_product_option}
                          set_product_option_onload={
                            this.set_product_option_onload
                          }
                          product_option={this.state.product_option}
                          BuynowClicked={addToCartUnsafe}
                          is_allow_reviews={is_allow_reviews}
                          addToWishlistClicked={this.addToWishListproduct}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <>
                <div className='container mt-5 centeMt'>
                  {singleProduct && singleProduct.length < 0 ? (
                    <div className='row'>
                      <div className='col-sm-12 text-center section-b-space mt-5 no-found'>
                        <div className='loading-cls' />
                      </div>
                    </div>
                  ) : null}
                </div>
                {singleProduct.length == undefined ?  
                <div className="d-flex justify-content-center text-center centOut">
                  <div className="centeIN"><Link to={"/"} className="text-3d">404</Link>
                    <Link to={"/"}><span class="caption">Click to Return Home</span> </Link></div>
                </div>
                :
                <div className='container mt-5 centeMt'>
                  <div className='row'>
                  <div className='col-sm-12 text-center section-b-space mt-5 no-found'>
                    <div className='loading-cls' />
                  </div>
                </div>
                </div> 
                }
              </>
            )}
            {/*Section End*/}

            {item && "product_name" in item && (
              <>
                {item.related_product.length >= 5 ? (
                  <div className='youmaylike mx-md-1 mx-lg-5 '>
                    <RelatedProduct
                      is_short={1}
                      showno={5}
                      is_sliders={1}
                      title='YOU MAY ALSO LIKE'
                      items={item.related_product}
                    />
                  </div>
                ) : (
                  ""
                )}

                {item.recent_view_product.length >= 5 ? (
                  <div className='recentlyviewed mx-md-1 mx-lg-5 '>
                    <RelatedProduct
                      is_short={1}
                      showno={100}
                      is_sliders={1}
                      title='RECENTLY VIEWED'
                      items={item.recent_view_product}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {item && "product_name" in item ? (
              <section className='section-b-space blog-detail-page review-page'>
                <div className='mx-sm-1 mx-md-1 mx-lg-5 '>
                  <div className='container-fluid' id='Ratings-' name='Ratings'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='w-100'>
                          <h4 className=' my-3 set_color_dark_1'>Ratings</h4>
                        </div>
                        <div className='w-100'>
                          <div className='review-page-main-box d-block margin-0'>
                            <div className=''>
                              <div className='row justify-content-center'>
                                <div className='col-md-6 col-6 my-auto'>
                                  <h6 className='title1 rating-font'>
                                    {item.check_rated_count &&
                                    item.check_rated_count.average_rating
                                      ? item.check_rated_count.average_rating
                                      : 0}
                                    <i className='fa fa-star co'></i>
                                  </h6>
                                  <h6 className='title2 rating-font1'>
                                    {item.check_rated_count &&
                                    item.check_rated_count.count_rating
                                      ? item.check_rated_count.count_rating
                                      : 0}{" "}
                                    ratings and{" "}
                                  </h6>
                                  <h6 className='title2 rating-font1'>
                                    {item.check_rated_count &&
                                    item.check_rated_count.count_rating
                                      ? item.check_rated_count.count_rating
                                      : 0}{" "}
                                    reviews
                                  </h6>
                                </div>
                                <div className='col-md-6 col-6 box2 rating-boder-left'>
                                  <div className='d-table m-auto'>
                                    <div className='d-table-row'>
                                      <div
                                        className='d-table-cell'
                                        style={{ paddingBottom: "5px" }}
                                      >
                                        <h6 className='star1'>
                                          5 <i className='fa fa-star'></i>
                                        </h6>
                                      </div>
                                      <div className='d-table-cell'>
                                        <div id='myProgress'>
                                          <div
                                            id='myBar'
                                            style={{ width: "100%" }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className='d-table-cell'>
                                        <p className='ml-1'>
                                          {item.check_rated_count &&
                                          item.check_rated_count.star5
                                            ? item.check_rated_count.star5
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className='d-table-row'>
                                      <div
                                        className='d-table-cell'
                                        style={{ paddingBottom: "5px" }}
                                      >
                                        <h6 className='star1'>
                                          4 <i className='fa fa-star'></i>
                                        </h6>
                                      </div>
                                      <div className='d-table-cell'>
                                        <div id='myProgress'>
                                          <div
                                            id='myBar'
                                            style={{ width: "80%" }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className='d-table-cell'>
                                        <p className='ml-1'>
                                          {item.check_rated_count &&
                                          item.check_rated_count.star4
                                            ? item.check_rated_count.star4
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className='d-table-row'>
                                      <div
                                        className='d-table-cell'
                                        style={{ paddingBottom: "5px" }}
                                      >
                                        <h6 className='star1'>
                                          3 <i className='fa fa-star'></i>
                                        </h6>
                                      </div>
                                      <div className='d-table-cell'>
                                        <div id='myProgress'>
                                          <div
                                            id='myBar'
                                            style={{ width: "60%" }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className='d-table-cell'>
                                        <p className='ml-1'>
                                          {item.check_rated_count &&
                                          item.check_rated_count.star3
                                            ? item.check_rated_count.star3
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className='d-table-row'>
                                      <div
                                        className='d-table-cell'
                                        style={{ paddingBottom: "5px" }}
                                      >
                                        <h6 className='star1'>
                                          2 <i className='fa fa-star'></i>
                                        </h6>
                                      </div>
                                      <div className='d-table-cell'>
                                        <div id='myProgress'>
                                          <div
                                            id='myBar'
                                            style={{ width: "40%" }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className='d-table-cell'>
                                        <p className='ml-1'>
                                          {item.check_rated_count &&
                                          item.check_rated_count.star2
                                            ? item.check_rated_count.star2
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className='d-table-row'>
                                      <div className='d-table-cell'>
                                        <h6 className='star1'>
                                          1 <i className='fa fa-star'></i>
                                        </h6>
                                      </div>
                                      <div className='d-table-cell'>
                                        <div id='myProgress'>
                                          <div
                                            id='myBar'
                                            style={{ width: "20%" }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className='d-table-cell'>
                                        <p className='ml-1'>
                                          {item.check_rated_count &&
                                          item.check_rated_count.star1
                                            ? item.check_rated_count.star1
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='w-100'>
                          <h4
                            className=' my-3 set_color_dark_1'
                            id='reviews_section_set'
                          >
                            Reviews
                          </h4>
                        </div>
                        <div className='w-100'>
                          <ul className='comment-section'>
                            {item.customer_review &&
                            item.customer_review.length > 0 ? (
                              item.customer_review.map((data, index) => {
                                return (
                                  <li className='w-100'>
                                    <div className='d-flex flex-row  mb-3'>
                                      <div className='p-1'>
                                        <img
                                          src={data.image}
                                          height={200}
                                          alt={data.name}
                                          className='media'
                                        />
                                      </div>
                                      <div className='p-1'>
                                        <div className='media-body'>
                                          <h6 className='comment-user-name'>
                                            {data.name}
                                            <Stars
                                              total={5}
                                              active={data.rating_count}
                                            ></Stars>
                                          </h6>
                                          <p className='w-100 font-size-text-16'>
                                            {data.rating_comment}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li className='innercard text-center w-100'>
                                <h6 className='text-center'>
                                  No Review Available
                                </h6>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
 
    let
        productId = ownProps.match.params.id;
    return {
        // item: state.data.products.find((el) => el.id == productId),
        item: state.data.singleProduct,
        symbol: state.data.symbol,
        singleProduct: state.data.singleProduct,
        fetchSingleProductLoader: state.data.fetchSingleProductLoader,
        isSingleProductFound: state.data.isSingleProductFound,
        auth: state.auth,
        settings: state.settings,
    };
};

export default connect(
    mapStateToProps,
    {
        addToCart,
        addToWishlist,
        getProductDetail,
        getConfigureProductDetail,
        addToCartUnsafe,
        updateProductOptions,

    }
)(ProductDetail);
