import React, { Component } from 'react';

import {getOffersList_api} from "../../../api/home";
import {toast} from "react-toastify";
import { Link } from "react-router-dom";


class ElementBanner extends Component {
        constructor(props) {
            super(props)
            this.state = {
                offerlist: [],
            };
        }

        componentDidMount() {
            this.getofferlist()
        }

        getofferlist = async () => {

            try {
                this.setState({loaderForPlaceOrder: true});
                let apiParams = {
                    language_id: 3
                };
                // console.log(apiParams);
                let offerlist = await getOffersList_api(apiParams);
                if (offerlist.success) {
                    this.setState({
                        offerlist: offerlist.data,
                    });
                } else {
                    toast.error(offerlist.message);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
            }

        };

    render() {
        var {offerlist} = this.state

        return (
            <div>

                {/*Four Banner*/}
                <section className=" ratio2_1 mb-4">
                    <div className="container-fluid">
                        <div className="row partition4">

                            {
                                (offerlist && offerlist.length>0?offerlist.map((value)=> {
                                    return <div className="col-lg-3 col-md-6">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/offer/${value.id}/products`}
                                        >
                                            <div className="collection-banner p-left">
                                                <div className="img-part">
                                                    <img src={value.offer_banner}
                                                         className="img-fluid blur-up lazyload bg-img offer_banner_image "/>
                                                </div>
                                                <div className="contain-banner banner-4">
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                }):"")
                            }



                        </div>
                    </div>
                </section>


            </div>
        )
    }
}


export default ElementBanner;