import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Modal from 'react-responsive-modal';
import Collapse from '../../../common/Collapse';
import ShopWithUs from '../../../common/ShopWithUs';
import moment from 'moment'

class DetailsWithPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            quantity: 1,
            stock: 'InStock',
            nav3: null,
        }
    }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
    }

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if (this.props.item.product_inventory_remain_stock >= this.state.quantity + 1) {
            this.setState({quantity: this.state.quantity + 1})
        } else {
            this.setState({stock: 'Out of Stock !'})

        }
    }
    changeQty = (e) => {
        this.setState({quantity: parseInt(e.target.value)})
    }

    render() {
        const {
            symbol,
            item,
            addToCartClicked,
            BuynowClicked,
            addToWishlistClicked,
            set_product_option,
            product_option,
            set_product_option_onload,
        } = this.props

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        return (
            <div className=" rtl-text">
                <div className="product-right">
                    <h2> {item.product_name} </h2>
                  <div className="border-product row">
                        <h3 className="product-brand col-md-8 col-sm-12 pt-3">{item.brand_name}</h3>
                        {/* <p className="col-6">{item.price}</p> */}
                        {item.brand_image ?
                            <div className=" col-md-4 col-sm-12 mt-3 "><img style={{height:50,width:50}} className=" img-thumbnail src" src={item.brand_image} alt=""/></div> : ""
                        }
                    </div>
                    <div className="border-product row">
                        {((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type === 2) && (item.product_val_type === 2 || item.product_val_type === 3) ) ?
                        <h3 className="product-price col-md-6 col-sm-12">{symbol}{item.wholesale_sp_price !== "" ? item.wholesale_sp_price : item.wholesale_price}
                            {
                                ((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type === 2) && (item.product_val_type === 2 || item.product_val_type === 3) && item.special_price !== "" && item.special_price !== 0 )?
                                <>{(item.wholesale_sp_price != "" && item.wholesale_sp_price != item.wholesale_price) ? <del>{symbol}{item.wholesale_price}</del>:""}</>
                                    :
                                    <>{(item.special_price != ""  && item.special_price != item.product_price) ? <del>{symbol}{item.product_price}</del>:<>{(item.product_val_type == 2 && item.wholesale_sp_price !=""  && item.wholesale_sp_price != item.wholesale_price)?<del>{symbol}{item.wholesale_price}</del>:""}</>}</>

                            }
                        </h3>
                            :
                        <h3 className="product-price col-md-6 col-sm-12">{symbol}{item.special_price !== "" ? item.special_price : item.product_price}
                            {
                                ((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type === 2) && (item.product_val_type === 2 || item.product_val_type === 3) && item.special_price !== "" && item.special_price !== 0 )?
                                    <>{(item.wholesale_sp_price != "" && item.wholesale_sp_price != item.wholesale_price) ? <del>{symbol}{item.wholesale_price}</del>:""}</>
                                    :
                                    <>{(item.special_price != ""  && item.special_price != item.product_price) ? <del>{symbol}{item.product_price}</del>:""}</>
                            }
                        </h3>
                            }




                        <p className="col-md-6 col-sm-12 free">{item.free_delivery_quantity <= this.state.quantity ? 'Free Delivery' : ""}</p>
                    </div>
                    <div className="border-product row">
                        <h3 className="product-stock col-lg-6 col-md-12 col-sm-12 instock-cls">
                           {(parseInt(this.props.item.product_inventory_remain_stock) > 0) ? this.state.stock : "Out of Stock !"}
                        </h3>


                        <h3 className="product-stock col-lg-6 col-md-12 col-sm-12 instock-cls">{(item.is_pre_order === 1 && moment().isAfter(item.pre_start_delivery_date)) ? "Pre Order" : ""} </h3>

                        {item.product_sku ? <p className="col-md-6 col-sm-12 ">{'SKU:' + item.product_sku}</p> : ""}
                        {item.product_upc ? <p className="col-md-6  col-sm-12">{'UPC:' + item.product_upc}</p> : ""}
                        {item.product_ean ? <p className="col-md-6  col-sm-12">{'EAN:' + item.product_ean}</p> : ""}
                        {item.product_jan ? <p className="col-md-6  col-sm-12">{'JAN:' + item.product_jan}</p> : ""}
                        {item.product_isbn ? <p className="col-md-6 col-sm-12 ">{'ISBN:' + item.product_isbn}</p> : ""}
                        {item.product_mnp ? <p className="col-md-6 col-sm-12 ">{'MAP:' + item.product_mnp}</p> : ""}

                    </div>
                    {(item.product_option && item.product_option.length > 0) ?
                        item.product_option.map((data, index) => {
                            return <div className="border-product row my-2 product-variant-res">
                                <h6 className="product-title size-text col-* ">{data.name}:</h6>
                                {JSON.stringify()}
                                <div className="size-box col-* ">
                                    <ul>
                                        {data.product_option_value.map((size, i) => {
                                            return <li
                                                className={(item.options_json && item.options_json.length > 2 && JSON.parse(item.options_json).includes(size.option_value_id))?"pointer border-bottom border-danger" : "pointer"}
                                                key={i}
                                                onClick={(e) =>{
                                                    if(!(item && item.remove_option_list && item.remove_option_list.toString().includes(size.option_value_id) && size.is_select == false))
                                                    {
                                                        set_product_option(size.option_value_id,index)
                                                    }
                                                } }
                                            >
                                                {
                                                    (item && item.remove_option_list && item.remove_option_list.toString().includes(size.option_value_id) && size.is_select == false)?
                                                        <h6 className="text-danger" style={{cursor: "noDrop"}}>{size.option_name}</h6>:<h5> {size.option_name}</h5>
                                                }


                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        }) : ""
                    }

                    <div className="product-description border-product my-2 d-none">
                        {/* {item.size ? */}
                        <div className="row">
                            <h6 className="product-title size-text col-2">Size:</h6>
                            <div className="size-box col-6">
                                {/* <ul>
                                        {item.size.map((size, i) => {
                                            return <li key={i}><a href="#">{size}</a></li>
                                        })}
                                    </ul> */}
                            </div>
                            <div className="col-4 chart">
                                <h4><a data-toggle="modal"
                                       data-target="#sizemodal" onClick={this.onOpenModal}>size chart</a></h4>
                                <div className="modal fade" id="sizemodal" tabIndex="-1"
                                     role="dialog" aria-labelledby="exampleModalLabel"
                                     aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered"
                                         role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"
                                                    id="exampleModalLabel">Sheer Straight
                                                    Kurta</h5>
                                                <button type="button" className="close"
                                                        data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`}
                                                     alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="border-product row">
                        <h6 className="product-title col-lg-6 col-md-6 col-sm-12  pt-2">Quantity:</h6>
                        <div className="qty-box  col-lg-6 col-md-12 col-sm-12">
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-left-minus" onClick={this.minusQty}
                                            data-type="minus" data-field="">
                                        <i className="fa fa-angle-left"></i>
                                    </button>
                                </span>
                                <input type="text" readOnly={true} name="quantity" value={this.state.quantity}
                                       onChange={this.changeQty}
                                       className="form-control input-number"/>
                                <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-right-plus" onClick={this.plusQty}
                                            data-type="plus" data-field="">
                                        <i className="fa fa-angle-right"></i>
                                    </button>
                                </span>
                            </div>

                        </div>
                        <div className="product-button col-lg-12  mx-md-auto mx-sm-auto  col-lg-5 col-md-12 col-sm-12 pt-2">
                            {parseInt(this.props.item.product_inventory_remain_stock) >= 1?
                            <a className="btn btn-solid" onClick={() => {
                                addToCartClicked(item, this.state.quantity)
                            }}>{(item.is_pre_order === 1 && moment().isBefore(item.pre_start_delivery_date)) ? "Pre Order" : "add to cart"} </a>:
                                <a className="btn btn-solid" onClick={(e) => {e.preventDefault()}}>Currently not available</a>
                            }

                            <p>{(item.is_pre_order === 1 && moment().isBefore(item.pre_start_delivery_date)) ? 'Product live at '+ item.pre_start_delivery_date : ""}</p>

                        </div>
                        <div className="col-lg-12 col-md-12 ">
                        {(item.product_min_quantity >=1 && item.product_val_type == 3 || item.product_val_type == 1) ?
                            <p className="text-primary">Minimum Retail order quantity is {item.product_min_quantity}<br/></p> : ""}
                        {(item.product_min_wholesale_quantity >=1 && item.product_val_type == 2 || item.product_val_type == 3)   ?
                            <p className="text-primary">Minimum Wholesale order quantity is {item.product_min_wholesale_quantity}</p> : ""}

                            {(item.free_delivery_quantity >= 1 )   ?
                                <p className="text-primary">Minimum free Delivery order quantity is {item.free_delivery_quantity}</p> : ""}

                        </div>
                        </div>
                    <div className="border-product row">

                        <h6 className="product-stock col-6">Deliver to : INDIA
                            <br/>E.T.A : {moment().add(parseInt(item.delivery_time), 'd').format("DD-MM-YYYY")}</h6>

                        <p className="col-6 handmade"><img src="assets/images/extras/handmade.png"/></p>
                        <div className="steps">
                            <ul className=" ml-2 steplist">
                                <li><img src="assets/images/extras/placed.png" alt="" srcset=""/></li>
                                <li>
                                    <hr className="dlvPro"/>
                                </li>
                                <li><img src="assets/images/extras/dispatched.png" alt="" srcset=""/></li>
                                <li>
                                    <hr className="dlvPro"/>
                                </li>
                                <li><img src="assets/images/extras/delivered.png" alt="" srcset=""/></li>
                            </ul>
                            <div className="stepNameDiv status-res">
                                <ul className="dates">
                                    <li>Placed
                                        <div className="Pdate"><a src=""><b>{moment().format("MMM D")}</b></a></div>
                                    </li>
                                    <li>Dispatched
                                        <div className="disdate"><a
                                            src=""><b>{moment().add(parseInt(1), 'd').format("MMM D")}</b></a></div>
                                    </li>
                                    <li>Delivered
                                        <div className="dlvdate"><a
                                            src=""><b>{moment().add(parseInt(item.delivery_time), 'd').format("MMM D")}</b></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="border-product buynow">
                        {parseInt(this.props.item.product_inventory_remain_stock) >= 1?
                        <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-danger"
                              onClick={(e) =>{
                                  e.preventDefault();
                                  addToCartClicked(item, this.state.quantity)
                                  setTimeout(()=>{
                                      window.location.href = "/checkout"
                                  },3000)

                              }}>{(item.is_pre_order === 1 && moment().isAfter(item.pre_start_delivery_date)) ? "Pre Order" : "Buy now"}</Link>
                            :
                            <Link  to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-danger"
                                  onClick={(e) =>{e.preventDefault();}}>
                                Currently not available
                            </Link>
                        }
                        <div className="payment">
                            <p className="pt-4">Payment Options</p>
                            <table>
                                <tr id="paymentText">
                                    <td><a><img src="/assets/images/payment/credit-card.png" alt="" sizes="" srcset=""/></a>
                                    </td>
                                    <td id="pinfo">Powered by <b id="paypal">PayPal</b><br/>Secured Checkout</td>
                                    <td id="return">30 Day Return as Original and<br/> Exchange</td>
                                </tr>
                            </table>
                            <ul className="paymentOption">
                                <li><a><img src="/assets/images/payment/Pay Pal.png" alt="" srcset=""/></a></li>
                                <li><a><img src="/assets/images/payment/Mastercard.png" alt="" srcset=""/></a></li>
                                <li><a><img src="/assets/images/payment/Apple Pay.png" alt="" srcset=""/></a></li>
                                <li><a><img src="/assets/images/payment/Amazon.png" alt="" srcset=""/></a></li>
                                <li><a><img src="/assets/images/payment/Mastercard.png" alt="" srcset=""/></a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="border-product ">

                        {(item.product_policy && item.product_policy.length > 0) ?
                            item.product_policy.map((data, index) =>
                                <Collapse ListTopic={data.policy_name}>
                                    <p dangerouslySetInnerHTML={{__html: data.policy_description}}/>
                                </Collapse>
                            ) : ""
                        }

                        {
                            item.product_description != ""?
                                <Collapse ListTopic="Product Details">
                                    <div dangerouslySetInnerHTML={{__html: item.product_description}}/>
                                </Collapse>:""
                        }


                        <Collapse ListTopic="Product Specification">
                            <table className="table table-striped mb-0 ">
                                <tbody>
                                {item.model && (
                                    <tr>
                                        <th>Model :</th>
                                        <td>{item.model}</td>
                                    </tr>
                                )}
                                {item.product_sku && (
                                    <tr>
                                        <th>SKU :</th>
                                        <td>{item.product_sku}</td>
                                    </tr>
                                )}
                                {item.product_upc && (
                                    <tr>
                                        <th>UPC :</th>
                                        <td>{item.product_upc}</td>
                                    </tr>
                                )}
                                {item.product_ean && (
                                    <tr>
                                        <th>EAN :</th>
                                        <td>{item.product_ean}</td>
                                    </tr>
                                )}
                                {item.product_jan && (
                                    <tr>
                                        <th>JAN :</th>
                                        <td>{item.product_jan}</td>
                                    </tr>
                                )}
                                {item.product_isbn && (
                                    <tr>
                                        <th>ISBN :</th>
                                        <td>{item.product_isbn}</td>
                                    </tr>
                                )}
                                {item.product_mnp && (
                                    <tr>
                                        <th>MNP :</th>
                                        <td>{item.product_mnp}</td>
                                    </tr>
                                )}
                                {item.product_location && (
                                    <tr>
                                        <th>Location :</th>
                                        <td>{item.product_location}</td>
                                    </tr>
                                )}
                                {item.brand_name && (
                                    <tr>
                                        <th>Brand :</th>
                                        <td>{item.brand_name}</td>
                                    </tr>
                                )}
                                {item.dimension_name && (item.product_length >= 1 || item.product_width >= 1 || item.product_height>= 1 ) && (
                                    <>
                                        {
                                            item.product_length >=1?
                                                <tr>
                                                    <th>Dimention :</th>
                                                    <td>
                                                        {" "}
                                                        Length: {item.product_length} &nbsp;{" "}
                                                        {item.dimension_name}{" "}
                                                    </td>
                                                </tr>:""
                                        }
                                        {item.product_width >= 1?
                                            <tr>
                                                <th/>
                                                <td>
                                                    {" "}
                                                    Width: {item.product_width} &nbsp;{" "}
                                                    {item.dimension_name}{" "}
                                                </td>
                                            </tr>:""
                                        }
                                        {
                                            item.product_height >= 1?
                                            <tr>
                                                <th/>
                                                <td>
                                                    {" "}
                                                    Height: {item.product_height} &nbsp;{" "}
                                                    {item.dimension_name}{" "}
                                                </td>
                                            </tr>:""
                                        }

                                    </>
                                )}

                                {(item.product_attribute && item.product_attribute.length > 0) ?
                                    item.product_attribute.map((val, i) => {
                                        return <tr>
                                            <th>{val.attribute_name} :</th>
                                            <td>
                                                {val.product_attribute_text}
                                            </td>
                                        </tr>
                                    }) : ''}

                                {item.product_weight > 0 && (
                                    <tr>
                                        <th>Weight :</th>
                                        <td>
                                            {item.product_weight}&nbsp;{item.weight_name}
                                        </td>
                                    </tr>
                                )}
                                {item.seller_details.seller_name && (
                                    <tr>
                                        <th>Seller Name :</th>
                                        <td>
                                            {item.seller_details.seller_name}&nbsp;
                                            {item.weight_name}
                                        </td>
                                    </tr>
                                )}
                                {item.seller_details.store_city && (
                                    <tr>
                                        <th>Seller City :</th>
                                        <td>{item.seller_details.store_city}</td>
                                    </tr>
                                )}
                                {item.seller_details.store_details && (
                                    <tr>
                                        <th>Seller Detail :</th>
                                        <td>{item.seller_details.store_details}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </Collapse>
                        {this.props.is_allow_reviews == 1?
                        <Collapse ListTopic="Review & Ratings">

                            {
                                (item.customer_review && item.customer_review.length > 0 ?
                                    (item.customer_review.map((data, index) => {
                                        return <div className="innercard">
                                            {data.rating_comment}
                                            <p className="buyer">-{data.name}<br/><b id="reviewTime"
                                                                                     className="font-weight-bold">{data.created_at}</b>
                                            </p>
                                            <hr/>
                                        </div>
                                    })) :
                                        <div className="innercard text-center">
                                            <h6>No Review Available</h6>
                                        </div>

                                )
                            }
                        </Collapse>:""}

                    </div>

                    <div className="border-product row">
                        <h3 className="product-stock col-12 ">Why Shop With Us?</h3>
                        <ShopWithUs
                            listContent="Lorem ipsum dolor sit, jhjhkj khguyftrdrsr klkjgdhvdmn amet consectetur adipisicing elit. "/>
                        <ShopWithUs
                            listContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit.amet consectetur adipisicing elit. "/>
                        <ShopWithUs
                            listContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit.amet consectetur adipisicing elit. "/>
                        <ShopWithUs
                            listContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit.amet consectetur adipisicing elit. "/>
                    </div>

                    {/* <div className="border-product" id="socialRow">
                        <h6 className="product-title">share it</h6>
                        <div className="product-icon">
                            <ul className="product-social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i
                                    className="fa fa-facebook"></i></a></li>
                                <li><a href="https://plus.google.com/discover" target="_blank"><i
                                    className="fa fa-google-plus"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i
                                    className="fa fa-instagram"></i></a></li>
                            </ul>
                            <button className="wishlist-btn" onClick={() => addToWishlistClicked(item)}><i
                                className="fa fa-heart"></i><span
                                className="title-font">Add To WishList</span>
                            </button>
                        </div>
                    </div> */}
                    {/* <div className="border-product">
                        <h6 className="product-title">Time Reminder</h6>
                        <div className="timer">
                            <p id="demo">
                                <span>25
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Days</span>
                                </span>
                                <span>22
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Hrs</span>
                                </span>
                                <span>13
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Min</span>
                                </span>
                                <span>57
                                    <span className="timer-cal">Sec</span>
                                </span>
                            </p>
                        </div>
                    </div> */}
                </div>


                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                            </div>
                            <div className="modal-body">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt=""
                                     className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}


export default DetailsWithPrice;