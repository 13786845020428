import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {testimonial_list_api} from "../../api/pages";
import {toast} from "react-toastify";

class Testimonial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            testimonial_list: [],
            show: 0,
        };
    }

    componentDidMount() {
        this.testimoniallist()
    }

    testimoniallist = async () => {
        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let faqList = await testimonial_list_api(apiParams);
            if (faqList.success) {
                this.setState({
                    testimonial_list: faqList.data,
                });
            } else {
                toast.error(faqList.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    render() {


        return (
            <div>
                <Breadcrumb title={'Testimonial'}/>


                {/*Dashboard section*/}
                <section className="faq-section section-b-space testimon">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6 className="font-size-text-20 my-3">Testimonial</h6>
                            </div>
                            <div className="col-sm-12">
                                <div className="row ">
                                    {
                                        this.state.testimonial_list.map(data => {
                                            return <div className="col-sm-12 col-md-6 col-lg-6 Testimonial ">
                                                <div className="borders_set">
                                                <h3 className="text-center name_title">{data.author_name}</h3>
                                                <div className="testimonial">
                                                    <div className="w-100 text-center">
                                                        <img src={data.author_image} alt=""/>
                                                    </div>
                                                    <p className="text-center" >
                                                        <blockquote> {data.short_description} </blockquote>
                                                    </p>
                                                    <div  className="text-center author_company_name"> <p>{data.author_company_name}</p></div>
                                                </div>
                                            </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Testimonial