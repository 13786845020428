import React, {Component} from "react";
import Slider from 'react-slick';
import {Slider16, Slider6} from "../../services/script";
import {useSelector, useDispatch} from "react-redux";
import { connect } from 'react-redux';
import {
    getHomePageBrands,
} from "../../actions/index";


class BrandDiv extends Component {
    componentDidMount() {
        this.props.getHomePageBrands();
    }

    render() {
        const {brands} = this.props;
        console.log('brands',brands)
        return (

            <div className=" item brands">
                <div className="brands bg-light container-fluid">

                    <Slider {...Slider16} className="slide-6 no-arrow">
                        {


                            (brands && brands.length > 0) ? brands.map((brand, i) => {
                                    return <div key={i}>
                                        <div className="logo-block">
                                            <a><img src={brand.manufacture_image} alt={brand.manufacture_name}
                                                    style={{width: 214, height: 130}} className="src "/></a>
                                        </div>
                                    </div>
                                }
                            ) : ""
                        }


                    </Slider>
                    <hr id="kbr"/>
                    <div className="line text-left bg-light">
                        <table className="know bg-light  m-2">

                            <tr id="knowmore">
                                <td id="knowtitle"><b className="px-4">Know It First</b>
                                    <p className="px-4">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error
                                        eligendi,</p>
                                </td>
                                <td id="bar">
                                    <div class="input-group ">
                                        <div class="form-outline ">
                                            <input id="search-input" type="search" id="form1" class="form-control"
                                                   placeholder="Enter your email"/>
                                        </div>
                                        <span className="p-2"></span>
                                        <button id="search-button " type="button" class="btn btn-danger"
                                                style={{borderRadius: "0px"}}>
                                            SUBSCRIBE
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>


            </div>
        )
    }
}


const mapStateToProps = state => ({
    brands: state.home.brands,
});

const mapDispatchToProps = {getHomePageBrands};

export default connect(mapStateToProps, mapDispatchToProps)(BrandDiv);