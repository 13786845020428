import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../services";
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import {getDashboardserviceListById_api, getserviceListById_api, getservicelist_api} from "../../../api/home"
import {toast} from "react-toastify";

class SpProductListing extends Component {
  constructor(props){
    super(props)
    this.state = {
      serviceList: [],
      isLoading:  false
    }
}

componentDidMount() {
  console.log("change")
  if(this.props.dashboardId && this.props.dashboardId){
      console.log("hello")
    this.serviceListWithId();
  }else{
    this.serviceList();
  }
}

serviceListWithId = async () => {

  try {
    //   let apiParams = {
    //     parent_id: [0],
    //       speciality_id: this.props.dashboardId
    //   };
      // console.log(apiParams);
      this.setState({isLoading: true});
      let serviceList = await getDashboardserviceListById_api(this.props.dashboardId);
      console.log("serviceList",serviceList)

      if (serviceList.status) {
        if(serviceList.result.data.length) {

          if(this.props.subService) {
            this.setState({
              serviceList: serviceList.result.data[parseInt(this.props.subService)].services,
            });
          }else {
            console.log("sub sevice")
            this.setState({
              serviceList: serviceList.result.data,
            });
          }
    
         
      
          
        
        }else {
          toast.error("No Service Availbale")
        }
         
      } else {
          toast.error(serviceList.message);
      }
  } catch (error) {
      console.log(error);
      toast.error(error.message);
  } finally {
    this.setState({isLoading: false})
  }

};

serviceList = async () => {

  try {

      // console.log(apiParams);
    this.setState({isLoading: true})
      let serviceList = await getDashboardserviceListById_api(this.props.dashboardId);

      console.log(serviceList, "service listing")

      if (serviceList.status) {
          this.setState({
            serviceList: serviceList.result.data,
          });
      } else {
          toast.error(serviceList.message);
      }
  } catch (error) {
      console.log(error);
      toast.error(error.message);
  } finally {
    this.setState({isLoading: false})
  }

};

  render() {
    console.log(this.state.serviceList, "service listing")
    if(this.state.isLoading) {
        return <div className="row w-100">
        <div
            className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found">
            <div className="loading-cls"/>
        </div>
    </div>
    }
    return (
      <div className='col-12 psm0 contLIst'>
        <div className='product-wrapper-grid'>
          <div className='container-fluid psm0 servListCont' id='listing'>
            <InfiniteScroll
              dataLength={this.state.limit}
              // next={()=>{
              //   this.props.loadMore();
              //   this.fetchMoreItems()
              // }}
              hasMore={this.state.hasMoreItems}
              loader={""}
            >
              <div className='row servprolist'>
                <div className='col-12'>
                  <div
                    className='d-flex flex-wrap w-100   serListPt'
                    style={{ paddingTop: "20px" }}
                  >
                    {this.state.serviceList.length && this.state.serviceList[0].services.map((serviceList, index) => 
                    <div className='setproducts p-2 '>
                      <div className='product-box product-wrap my-3'>
                        <div className='img-wrapper'>
                          <div className='front'>
                            <Link
                              to={ !!serviceList.services ? `/sub-services/${serviceList.service_name}/${serviceList.speciality_id}/${index}` :`${process.env.PUBLIC_URL}/service-providertype/${serviceList.id}`}
                            >
                              <div
                                className={"img-fluid"}
                                style={{
                                  backgroundImage: `url('${serviceList.image_url}')`,
                                }}
                              ></div>
                            </Link>
                          </div>

                          <div className='back h-100 w-100'>
                            <Link
                              to={ !!serviceList.services ? `/sub-services/${serviceList.service_name}/${serviceList.speciality_id}/${index}` :`${process.env.PUBLIC_URL}/service-providertype/${serviceList.id}`}
                            >
                              <img
                                src={serviceList.service_image}
                                className='img-fluid m-auto h-100 w-100 media'
                                alt=''
                              />
                            </Link>
                          </div>

                        </div>

                        <div className="product-detail">
                                <div>
                                    {/* <div className="rating" title={4}>
                                        <ReactStars
                                            value={4}
                                            count={5}
                                            onChange={(rate) => console.log(rate)}
                                            size={14}
                                            activeColor="#ffd700"
                                            edit={false}
                                        />
                                    </div> */}
                                    <Link to={ !!serviceList.services ? `/sub-services/${serviceList.service_name}/${serviceList.speciality_id}/${index}` :`${process.env.PUBLIC_URL}/service-providers/${serviceList.speciality_id}`}>
                                        <h6 className="pt-4">{serviceList.service_name}</h6>
                                    </Link>
                                        {/* <h3 className="theme_text_set font-weight-bold" style={{fontSize: "30px !important"}} >
                                            {"$"}
                                            {"500" !== ""
                                                ? "600"
                                                : "600"}
                                            &nbsp;
                                            {("500" != "" && "500" != "600") ?

                                                <sup style={{fontSize: "14px", textDecorationLine: "line-through", color: "rgba(0, 0, 0, 0.25)"}} className="">{"$"}{"800"}</sup>
                                                : ""}
                                        </h3> */}
                                </div>
                            </div>
                      </div>
                    </div>
                    )}

                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: getVisibleproducts(state.data, state.filters),
  symbol: state.data.symbol,
  dashboardProducts: state.data.dashboardProducts,
  fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare,
})(SpProductListing);
