import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyBSG0_zu5dGtJCF7XOjmNV7zcep5_MIpwo")
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

const AddressAutoComplete = (props) => {

    const [latLng, setLatLng] = useState([])
    const handleChange = address => {
        props.setInputValues({ ...props.inputValues, store_address: address})
      };
     
    const handleSelect = address => {
    geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => setLatLng(latLng))
        .catch(error => console.error('Error', error));
        props.setInputValues({ ...props.inputValues, store_address: address})
    };

    useEffect(() => {
        setAddress()
    }, [latLng])

    const setAddress = () => {
        Geocode.fromLatLng(latLng.lat, latLng.lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              const addressFull = response.results[0].address_components;
              let city, state, country;
              for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                  switch (response.results[0].address_components[i].types[j]) {
                    case "locality":
                      city = response.results[0].address_components[i].long_name;
                      break;
                    case "administrative_area_level_1":
                      state = response.results[0].address_components[i].long_name;
                      break;
                    case "country":
                      country = response.results[0].address_components[i].long_name;
                      break;
                  }
                }
              }
              props.setInputValues({
                ...props.inputValues,
                store_address: address,
                store_city: city,
                store_country: country,
                store_state: state,
                store_colony: addressFull[0].long_name,
                store_landmark: addressFull[1].long_name,
                store_pincode: addressFull[addressFull && addressFull.length - 1].long_name,
                store_lat: latLng.lat,
                store_long: latLng.lng,
                store_service: "store_service"
              });
              console.log(city, state, country);
              console.log(addressFull);
            },
            (error) => {
              console.error(error);
            }
          );
    }
      

    return (
        <>
        <PlacesAutocomplete
        value={props.inputValues.store_address && props.inputValues.store_address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Address ....',
                className: 'location-search-input form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
    )
}

export default AddressAutoComplete
