import React, { Component } from "react";
import { Link } from "react-router-dom";

class SubMenu extends Component {
  render() {
    const { s } = this.props;
    return (
      <ul>
        {s.map((m, i) => (
          <li key={i}>
            <Link
              to={`${process.env.PUBLIC_URL}/category/${m.id}/products`}
              onClick={(e) =>
                this.props.onClick ? this.props.onClick(false) :null
                // e.preventdefault()
                (m.subCat && m.subCat.length) > 0 ? this.props.handleSubTwoMenu(e) : null
            }>
              {m.category_name}
              {(m.subCat && m.subCat.length) > 0 && <span className="sub-arrow" />}
            </Link>
            {(m.subCat && m.subCat.length) > 0 && (
              <SubMenu
                s={m.subCat}
                onClick={this.props.onClick ? this.props.onClick :null}
                handleSubTwoMenu={(e) => this.props.handleSubTwoMenu(e)}
              />
            )}
          </li>
        ))}
      </ul>
    );
  }
}

export default SubMenu;
