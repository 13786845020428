import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {video_help_api} from "../../api/pages";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class HelpAndSupport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            video_list: [],
            show: 0,
        };
    }

    componentDidMount() {
        this.VideoHelplist()
    }

    FAQlistshow(index) {
        this.setState({show: index});
    }

    VideoHelplist = async () => {
        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };

            let video_list = await video_help_api(apiParams);
            console.table("video_list",video_list,video_list.data);
            if (!video_list.success) {
                this.setState({
                    video_list: video_list.data,
                });
            } else {
                toast.error(video_list.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    render() {
const data = this.state.video_list.find(c => c.id == this.props.match.params.id);
        return (
            <div>
                <Breadcrumb title={'Video Help'}/>


                {/*Dashboard section*/}
                <section className="faq-section section-b-space video_help_details">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h6 className="font-size-text-20 my-3">Video Tutorial  </h6>
                            </div>

                            <div className="col-sm-12">
                                {data?
                                <div className="row ">
                                             <div className="col-sm-12 col-md-6 col-lg-6 video_help video_help_details mb-2">
                                                <div className="card bf-img-viddeo" style={{background:`url("https://via.placeholder.com/500")`}}>
                                                        <div className="card-body">
                                                            <iframe src={data.link}
                                                                    frameBorder='0'
                                                                    allow='autoplay; encrypted-media'
                                                                    allowFullScreen
                                                                    title='video'
                                                            />
                                                        </div>
                                                        <div className="card-footer bf-img-viddeo-footer">
                                                            <Link to={`${process.env.PUBLIC_URL}/video-help/${data.id}`} >
                                                            <div className="d-flex justify-content-between">

                                                                <div className="p-2">
                                                                    <h6 className="my-0 text-white d-block">{data.name}</h6>
                                                                    <h6 className="my-0 text-white d-block">{data.description.slice(0,15)}...</h6>
                                                                </div>
                                                            </div>
                                                            </Link>
                                                        </div>
                                                </div>
                                            </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                                        <div className="v-title">{data.name}</div>
                                        <p className="v-content">
                                            {data.description}
                                        </p>
                                    </div>


                                </div>
                                    :""}
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default HelpAndSupport