import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { getAboutUspage } from "../../actions";
import { connect } from "react-redux";
import { Askme_api } from "../../api/pages";
import { toast } from "react-toastify";

class Contact extends Component {
  componentDidMount() {
    this.props.getAboutUspage({ content_type: 33 });

    try {
      if (window.location.href.search("sp") >= 1) {
        document
          .getElementById("footer_id")
          .setAttribute("style", "display:none");
        document
          .getElementById("header_id")
          .setAttribute("style", "display:none");
        document
          .getElementById("breadcrumb_id")
          .setAttribute("style", "display:none");
      }
    } catch (err) {}
  }


  render() {
    return (
      <>
        {this.props.aboutUs.page_content ? (
          <>
            {" "}
            <div>
              {window.location.href.search("sp") <= 1 ? (
                <>
                  {" "}
                  {this.props.aboutUs ? (
                    <>
                      <Breadcrumb title={this.props.aboutUs.page_name} />
                    </>
                  ) : (
                    <>
                      <Breadcrumb title={"About Us"} />
                    </>
                  )}{" "}
                </>
              ) : (
                ""
              )}
              <section className="about-page  section-b-space">
                <div className="container-fluid cutCont">
                  <div className="row">
                    <div className="col-sm-12">
                      {this.props.aboutUs.page_content ? (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.aboutUs.page_content,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutUs: state.pages.aboutUs,
  settings: state.settings,
});

const mapDispatchToProps = { getAboutUspage };

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
