import React, {Component} from 'react';
import Slider from "react-slick"

import Breadcrumb from "../../common/breadcrumb";
import {Slider6, Slider4, Slider15} from "../../../services/script"
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {getHomeDashboardModules, getHomePageBrands, getHomeSliders} from "../../../actions";

import all_categories from '../../../access/icone/category_ic.svg';

class ElementCategory extends Component {
    render() {
        const {category} = this.props; 
        return (
            <div>

                {/*Category One*/}
                <div className="container p-0">
                    <section className="section-b-space psm0 " id="catSec">

                        <Slider {...Slider15} className="slide-6 no-arrow mobShow">
                            <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6  mb-2 ">
                                <div className="category-block">
                                    <Link to={"/categories-list"}>
                                        <div className="category-image">
                                            <img src={all_categories} alt=""/></div>
                                    </Link>
                                    <div className="category-details">
                                        <Link to={"/categories-list"}>
                                            <h5>ALL CATEGORIES</h5>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {
                                (category && category.length) ? category.map((value, index) => {

                                    return <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6  mb-2 ">
                                        <div className="category-block">
                                            <Link to={"/sub-categories-list/" + value.id }>
                                                <div className="category-image">
                                                    <img src={value.category_image} alt=""/></div>
                                            </Link>
                                            <div className="category-details">
                                                <Link to={"/sub-categories-list/" + value.id}>
                                                    <h5>{value.category_name}</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                }) : ''
                            }
                        </Slider>


                        {/*<div className="element-category">
                            <div className="parent-category">
                                 <div className="child-category category-block">
                                     <Link to={"/categories-list"}>
                                    <div className="category-image">
                                        <img className="category_ic" src={all_categories} alt=""/></div>
                                </Link>
                                <div className="category-details">
                                    <Link to={"#"}>
                                        <h5>ALL CATEGORIES das</h5>
                                    </Link>
                                </div>
                            </div>

                                {
                                    (category && category.length) && category.map((value, index) => {

                                        return <div className="child-category category-block">
                                            <Link to={"/category/" + value.id + "/products"}>
                                                <div className="category-image">
                                                    <img src={value.category_image} alt=""/></div>
                                            </Link>
                                            <div className="category-details">
                                                <Link to={"/category/" + value.id + "/products"}>
                                                    <h5>{value.category_name}</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </div>*/}


                    </section>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    category: state.home.sideBarMenus,
});

export default connect(
    mapStateToProps,
    {
        getHomePageBrands,
        getHomeSliders,
        getHomeDashboardModules,
    }
)(ElementCategory);