import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/DashboardNewProduct";
import Filter from "./common/DashboardProductFilter";
import FilterBar from "./common/DashboardFilterBar";
import ProductListing from "./common/DashboardProductListing";
import StickyBox from "react-sticky-box";
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts,
} from "../../actions";
import Navigation_bar from "../features/theme/navigation_bar";

class CategoryProductsCollections extends Component {
    state = {
        layoutColumns: 3,
        page: 1,
        cat_id:"all",

    };

    componentDidMount() {
        // reset product list
        this.props.resetDashboardProducts();
        const query = new URLSearchParams(this.props.location.search);
        const search_val = query.get('q')
        const cat_id = query.get('cat-id')

        this.setState({
            cat_id: cat_id?cat_id:"all",
        });

        let apiParams = {
            search_val:search_val,
            language_id: 1,
            page: this.state.page,
            cat_id: cat_id,
           
        };
        if (this.props.filters.brand.length > 0)
            apiParams.filter_id = this.props.filters.brand;
        if (this.props.filters.sortBy !== "")
            apiParams.sorting_order = this.props.filters.sortBy;
        this.props.getAllDashboardProducts(apiParams);
        this.props.getAllDashboardProductFilters(apiParams);

        // check whether sidebar is open or not
        this.closeNav();
    }

    closeNav() {
        var closemyslide = document.getElementById("mySidenav");
        if (closemyslide) closemyslide.classList.remove("open-side");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            // check whether sidebar is open or not
            this.closeNav();

            // reset product list
            this.props.resetDashboardProducts();

            this.setState({
                page: 1,
            });
            // reset all the filters
            this.props.resetFilterAndfSort();
            let apiParams = {
                brand_id: this.props.match.params.id,
                language_id: 1,
                page: 1,
                cat_id: this.state.cat_id,
            };
            this.props.getAllDashboardProducts(apiParams);
            this.props.getAllDashboardProductFilters(apiParams);
        }
    }

    componentWillUnmount() {
        this.props.resetFilterAndfSort();
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums,
        });
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    };

    filterSortClicked = (e) => {
        const {target} = e;

        // reset product list
        this.props.resetDashboardProducts();

        this.setState(
            {
                page: 1,
            },
            () => {
                this.props.filterSort(target.value);

                const query = new URLSearchParams(this.props.location.search);
                const search_val = query.get('q')
                let apiParams = {
                    search_val:search_val,
                    language_id: 1,
                    page: this.state.page,
                    cat_id: this.state.cat_id,
                };
                if (target.value) apiParams.sorting_order = target.value;
                if (this.props.filters.brand.length > 0)
                    apiParams.filter_id = this.props.filters.brand.toString();
                this.props.getAllDashboardProducts(apiParams);
            }
        );
    };

    sidebarFilterClicked = (e, brands,ind) => {


        const {target} = e;

        // reset product list
        this.props.resetDashboardProducts();

        this.setState(
            {
                page: 1,
            },
            () => {
                var index = brands.indexOf(target.getAttribute('data-value'));

                if (target.getAttribute('data-value'))
                {
                    brands[ind] = target.getAttribute('data-value');
                }
                // push in array checked value
                else brands.splice(index, 1); // removed in array unchecked value

                this.props.filterBrand(brands);

                const query = new URLSearchParams(this.props.location.search);
                const search_val = query.get('q')
                let apiParams = {
                    search_val:search_val,
                    language_id: 1,
                    page: this.state.page,
                    cat_id: this.state.cat_id,
                };

                if (brands.length > 0) apiParams.filter_id = brands.filter(brand => (brand != 0 && brand != " ")).toString();
                if (this.props.filters.sortBy !== "")
                    apiParams.sorting_order = this.props.filters.sortBy;
                this.props.getAllDashboardProducts(apiParams);
            }
        );
    };

    loadMore = () => {
        this.setState(
            {
                page: this.state.page + 1,
            },
            () => {
                let apiParams = {
                    brand_id: this.props.match.params.id,
                    language_id: 1,
                    page: this.state.page,
                    cat_id: this.state.cat_id,
                };
                if (this.props.filters.brand.length > 0)
                    apiParams.filter_id = this.props.filters.brand.filter(val => (val != 0 && val != " ")).toString();
                if (this.props.filters.sortBy !== "")
                    apiParams.sorting_order = this.props.filters.sortBy.filter(val => (val != 0 && val != " ")).toString();

                if (this.props.fetchDashboardProductLoader !== "noData") {
                    this.props.getAllDashboardProducts(apiParams);
                }
            }
        );
    };

    render() {
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title> | Collection of Products</title>
                    <meta
                        name="description"
                        content=""
                    />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={this.props.__list_name}/>

                <section className="section-b-space">

                <div className="collection-wrapper catlis">
                    <div className="container-fluid">
                        <div className="row catlisIn">
                            {/*<div className="col-sm-12">
                                <div className="top-banner-wrapper">
                                    <a href="#"><img
                                        src={`${process.env.PUBLIC_URL}/assets/images/tshirtbanner.png`}
                                        className="w-100" alt=""/></a>
                                </div>
                            </div>*/}
                            <div className="col-sm-12 ">
                                <Navigation_bar  viewlisttitle={this.props.__list_name} />
                            </div>

                            <div className="col-sm-3 col-md-4 col-lg-3 psm0 pt-2 catlisInL">
                                <div className="collection-product-wrapper">
                                    <div className="product-top-filter  mx-md-1 ">

                                        <FilterBar viewlisttitle={this.props.__list_name}
                                                   Filters={this.props.filters.brand}
                                                   onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}
                                                   sidebarFilterClicked={(e, data, index) =>
                                                       this.sidebarFilterClicked(e, data, index)
                                                   }
                                                   onFilterSortClicked={(e) =>
                                                       this.filterSortClicked(e)
                                                   }/>


                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-sm-9 col-md-8 col-lg-9 psm0 pt-2 catlisInR">
                                <ProductListing colSize={this.state.layoutColumns}
                                                loadMore={this.loadMore}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
});

export default connect(
    mapStateToProps,
    {
        getAllDashboardProducts,
        filterSort,
        getAllDashboardProductFilters,
        filterBrand,
        resetFilterAndfSort,
        resetDashboardProducts,
    }
)(CategoryProductsCollections);
