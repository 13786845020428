import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getSearchSuggestions_api} from "../../../api/product";
import {getHomeSidebarCategories_api} from "../../../api/home"
import {Link, withRouter} from "react-router-dom";
import { withTranslate } from 'react-redux-multilingual';
import { connect } from 'react-redux';


export class Search_header extends Component {
    static propTypes = {
        options: PropTypes.instanceOf(Array).isRequired
    };
    state = {
        activeOption: -1,
        filteredOptions: [],
        showOptions: false,
        userInput: '',
        cat_id: "all",
        categories: [],
        searchSuggestion : [],
        isSp: (window.location.pathname.includes("sp") || window.location.pathname.includes("speciality") || window.location.pathname.includes("service"))
    };
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if(window.location.pathname.includes("sp") || window.location.pathname.includes("speciality") || window.location.pathname.includes("service")) {
                this.setState({isSp: true})
            }else {
                this.setState({isSp: false})
            }
        });
      }
      componentWillUnmount() {
          this.unlisten();
      }

    storageChanged(e) {
        if(e.key === 'isLoggedIn') {
            this.setState({isLoggedIn: e.newValue})
        }
    }


    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const search_val = query.get('q')
        const cat_id = query.get('cat-id')
        this.setState({
            userInput: search_val,
            cat_id: cat_id||"all",
        })
        this.getCategoryList()
    }

    getCategoryList = async () => {
        const listCategory = await getHomeSidebarCategories_api()
        this.setState({categories: listCategory.data})
    }

    onChange = async (e) => {
        if(e.currentTarget.value.length>0){
            let apiParams = {
                search: e.currentTarget.value,
                cat_id: this.state.cat_id
            };
            const list = await getSearchSuggestions_api(apiParams);
            console.log(list)
            var options = [];
    
            if(list.status){
                this.setState({searchSuggestion:list.result.data})
            }else{
                this.setState({searchSuggestion:[]})
            }
            
            if (list.status) {
                if ((list && list.data && list.data.suggest && list.data.suggest.product && list.data.suggest.product.length > 0)) {
                    options = [...list.data.suggest.product[0].options]
                } else {
                    options = []
                }
                this.setState({
                    activeOption: -1,
                    filteredOptions: options,
                    showOptions: true,
                    
                });
            }
        }
        else{
            this.setState({ showOptions : false});
        }

       
    }; 

    onClicksearch = (e) => {
        this.setState({
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: e.target.getAttribute('data-search')
        });
        const {query_search_autocomplete } = e.target.elements
        window.open("search?q=" + query_search_autocomplete.value.replaceAll("&", " ")+"&cat-id="+this.state.cat_id, '_self')
    };

    onClick = (e) => {
        this.setState({
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: e.target.getAttribute('data-search')

        });
        window.open("search?q=" + e.target.getAttribute('data-search').replaceAll("&", " ")+"&cat-id="+this.state.cat_id, '_self')
    };

    onKeyDown = (e) => {
        const {activeOption, filteredOptions} = this.state;

        const search = {
            activeOption: 0,
            showOptions: false,
            userInput: ((filteredOptions && filteredOptions[activeOption] !== undefined)) ? filteredOptions[activeOption].text : e.target.getAttribute('data-search')?e.target.getAttribute('data-search'): e.currentTarget.value,
        }

        if (e.keyCode === 13) {
            this.setState({
                ...search
            });
            window.open("search?q=" + search.userInput.replaceAll("&", " ")+"&cat-id="+this.state.cat_id, '_self')

        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            this.setState({activeOption: activeOption - 1});
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                console.log(activeOption);
                return;
            }
            this.setState({activeOption: activeOption + 1});
        }
    };

    
    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
        } = this;

        const {activeOption, filteredOptions, showOptions, userInput} = this.state
        const mainMenu  = this.state.categories;
        let optionList;

        if (showOptions && userInput) {
            if (filteredOptions.length) {
                optionList = (
                    <ul className="options">
                        {filteredOptions.map((optionName, index) => {
                            let className;
                            if (index === activeOption) {
                                className = 'option-active';
                            }
                            return (
                                <li data-search={optionName.text} className={className} key={optionName.text}
                                    onClick={onClick}>
                                        <div dangerouslySetInnerHTML={{__html: optionName.text}} />
                                    <p data-search={optionName.text}>
                                        {optionName && optionName._source && optionName._source.category_name ?
                                            <samp data-search={optionName.text} className="text-primary font-size-text-10 ">{optionName._source.category_name} </samp>
                                            : ""}
                                        {optionName && optionName._source && optionName._source.brand_name ?
                                            <samp data-search={optionName.text} className="text-primary font-size-text-10 d-inline">- {optionName._source.brand_name}</samp>
                                            : ""}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                optionList = (
                    ""
                    // <div className="no-options">
                    //     <em>No Option!</em>
                    // </div>
                );
            }
        }

        return (
            <div className="seatch_div">
               {!this.state.isSp && <form autoComplete="off" className={this.props.class} role="form" 
                onSubmit={(e) => {
                    e.preventDefault()
                        // this.onClicksearch(e);
                }}>
                    <input
                        autoComplete="off"
                        id="query_search_autocomplete"
                        type="search"
                        name="search"
                        placeholder="Search here ..."
                        className="nav-search nav-search-field search-box"
                        aria-expanded="true"

                        onFocus={() => {
                            // this.setState({showOptions: true})
                            // if(this.state.searchSuggestion.length === 0){
                            //     this.setState({showOptions: false})
                            // }else{
                            //     this.setState({showOptions: true})
                            // }
                        }}
                        
                        onBlur={() =>
                            {
                                setTimeout(() => {
                                    this.setState({showOptions: false})
                                    this.setState({
                                        searchSuggestion : []
                                    })
                                }, 500)   
                            }
                        }

                        onChange={(e) => {
                            if(!e.currentTarget.value){
                                this.setState({
                                    searchSuggestion : []
                                })
                            }
                            this.setState({
                                userInput: e.currentTarget.value
                            })
                            this.onChange(e);
                        }}
                        onKeyDown={onKeyDown}
                        value={userInput}
                    />

                    <button type="submit" name="nav-submit-button" className="btn-search disabled"> <i className="fa fa-search"/>
                    </button>
                </form>}
                      
                {
                    this.state.searchSuggestion.length === 0  && showOptions? 
                    <>
                        <div className='searchlist'>
                            <p>Product not available</p>
                        </div>
                    </> 
                    : <></>
                }
                      { this.state.searchSuggestion.length > 0 && showOptions ? 
                      <>
                        <div className='searchlist'>
                        {
                            this.state.searchSuggestion.slice(0,(10)).map((item,index) => {
                                return (
                                    <>
                                        <p><Link onClick={()=>{ this.setState({searchSuggestion : []});
                                        }} to={`${process.env.PUBLIC_URL}/`+item.product_slug+`/p/`+item.id} className='list-item' key={index}>{item.product_name}</Link></p>
                                    </>
                                )
                            }  
                            )
                        }
                         </div>
                        </>
                        : <> </>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    provider: state.settings.provider,
  });
  
  export default withRouter(connect(
    mapStateToProps,
    {
    }
  )(withTranslate(Search_header)));
