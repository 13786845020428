import React, { useEffect, useState } from 'react'
import Breadcrumb from '../common/breadcrumb'
import {getworkportfolio_api} from '../../api/home'
import { toast } from 'react-toastify'

const WorkPortfolio = (props) => {
    const [workPortfolio, setWorkPortfolio] = useState([])

    const getWorkPortfolio = async () => {

        try {
            // this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3,
                service_provider_id: props.match.params.id
            };
            // console.log(apiParams);
            let workPortfolioDetails = await getworkportfolio_api(apiParams);
            if (workPortfolioDetails.success) {
                setWorkPortfolio(workPortfolioDetails.data)
            } else {
                toast.error(workPortfolioDetails.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
  
      };

    useEffect(()=> {
        getWorkPortfolio()
    }, [])
    return (
        <div>
            <Breadcrumb title={"Work Portfolio"} />
                {/* Box-item-one */}
                <div className="container">
                <div className="row">
                {workPortfolio && workPortfolio.map((listing) => 
                    <div className="col-md-3">
                    <div className="service-provider">
                        <div className="service-provider-img">
                            <img src={listing.speciality_icon} />
                        </div>
                        <div className="row" style={{paddingLeft:"0.5rem"}}>
                            <div className="col-md-12">
                                <h6 className='font-weight-bold theme_text_set'>
                                    SPECIALITY
                                </h6>
                                <h5 className='font-weight-bold' style={{paddingBottom: "1rem", color: "#000"}}>
                                    {listing.speciality_name}
                                </h5>
                            </div>
                            <div className="col-md-12">
                                <h6 className='font-weight-bold theme_text_set'>
                                    SERVICE
                                </h6>
                                <h5 className='font-weight-bold' style={{paddingBottom: "1rem", color: "#000"}}>
                                    {listing.service_name}
                                </h5>
                            </div>
                            <div className="col-md-12">
                                <h6 className='font-weight-bold theme_text_set'>
                                    DETAILS OF WORK 
                                </h6>
                                <p className="work-portfolio-details">{listing.short_details_work}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                )}
                </div>
                </div>
        </div>
    )
}

export default WorkPortfolio
