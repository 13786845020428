import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
// import 'react-responsive-modal/styles.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ReactStars from "react-rating-stars-component";
import { addToCart_api, addToWishlist_api, deleteWishlist_api, removeproductwishlist_api } from "../../../api/product";
import image_err from '../../../assets/images/portfolio/8.jpg'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Stars from "../../common/stars";
import { Button } from "react-scroll";
import { getProductPositionList } from "../../../actions/index";

class ProductBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            stock: "InStock",
            quantity: 1,
            image: "",
            addToCartApiCalled: false,
            is_wishlist: [],
            not_is_wishlist: []
        };
    }

    onClickHandle(img) {
        this.setState({ image: img });
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if (
            this.state.quantity > parseInt(this.props.product.product_min_quantity)
        ) {
            if (
                parseInt(this.props.product.product_quantity) >= this.state.quantity
            ) {
                this.setState({ stock: "InStock" });
            }
            this.setState({
                quantity: this.state.quantity - 1,
            });
        }
    };

    plusQty = () => {
        if (parseInt(this.props.product.product_quantity) >= this.state.quantity) {
            this.setState({
                quantity: this.state.quantity + 1,
            });
        } else {
            this.setState({ stock: "Out of Stock !" });
        }
    };

    changeQty = (e) => {
        if (
            parseInt(e.target.value) >=
            parseInt(this.props.product.product_min_quantity)
        ) {
            this.setState({
                quantity: parseInt(e.target.value),
            });
        }
    };

    addToWishList = async (item, is_already_in = 0) => {
        if (this.props.auth.isLoggedIn) {
            try {
                if (is_already_in == 1) {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id") ? item.product_id : item.id,
                        user_id: this.props.auth.userData.id,
                    };

                    let apiData = await removeproductwishlist_api(apiParams);
                    if (apiData.success) {
                        this.setState({ is_wishlist: [] })
                        var wishlist_ids = this.state.not_is_wishlist.concat(apiParams.product_id);
                        this.setState({ not_is_wishlist: wishlist_ids })

                        toast.success("Item Removed from Wishlist");
                    } else {
                        toast.error(apiData.message);
                    }
                } else {
                    let apiParams = {
                        product_id: item.hasOwnProperty("product_id") ? item.product_id : item.id,
                        user_id: this.props.auth.userData.id,
                    };

                    let apiData = await addToWishlist_api(apiParams);
                    if (apiData.success) {
                        var wishlist_ids = this.state.is_wishlist.concat(apiParams.product_id);
                        this.setState({ is_wishlist: wishlist_ids })
                        this.setState({ not_is_wishlist: [] })

                        this.props.onAddToWishlistClicked();

                    } else {
                        toast.error(apiData.message);
                    }
                }
                this.props.getProductPositionList();
            } catch (error) {
                console.log(error);
                toast.error(error.message);
            }
        } else {
            toast.info("Please login to continue");
            setTimeout(() => {
                window.location.href = "/login"
            }, 3000)

        }
    };

    addToCart = (item, qty) => {
        let data = JSON.parse(JSON.stringify(item));
        if (data.product_option && data.product_option.length > 0) {
            let requiredOptions = data.product_option
                .filter((opt) => opt.required == 1)
                .filter((abc) => abc.selected_options.length === 0);
            if (requiredOptions.length > 0) {
                toast.error("Please select the mandatory options");
            } else {
                this.addToCartApi(item, qty);
            }
        } else {

            this.addToCartApi(item, qty);
        }
    };

    onChangeOption(e, item, type = "select") {
        let oldData = item.product_option_value.find(
            (prd) => prd.product_option_value_id == e.target.value
        );
        if (type === "select" || type === "radio") {
            item.selected_options = [];
            item.selected_options.push(oldData);
        } else {
            if (e.target.checked) {
                item.selected_options.push(oldData);
            } else {
                item.selected_options = item.selected_options.filter(
                    (fltr) => fltr.product_option_value_id != e.target.value
                );
            }
        }
        // this.props.updateProductOptions()
    }


    /* addToCartApi = async (item, qty) => {
         if (this.props.auth.isLoggedIn) {
             try {
                 this.setState({addToCartApiCalled: true});
                 let apiParams = {
                     product_id: item.id,
                     user_id: this.props.auth.userData.id,
                     quantity: qty,
                     option_val: [{"product_option_id":1200},{"product_option_id":1201}],
                     is_retail: this.declareOrderType(item, qty) === "Retail" ? 1 : 2,
                 };
                 let cartData = await addToCart_api(apiParams);
                 this.setState({addToCartApiCalled: false});
                 // console.log(cartData);
                 if (cartData.success) {
                     this.props.onAddToCartClicked(item, qty);
                 } else {
                     toast.error(cartData.message);
                 }
             } catch (error) {
                 this.setState({addToCartApiCalled: false});
                 console.log(error);
                 toast.error(error.message);
             }
         } else {
             this.props.onAddToCartClicked(item, qty);
         }
     };*/

    declareOrderType = (item, qty) => {
        let wholesale_price =
            item.wholesale_sp_price !== ""
                ? item.wholesale_sp_price
                : item.wholesale_price;

        let orderType = "Retail";
        if (
            qty >= parseInt(item.product_min_wholesale_quantity) &&
            (item.product_val_type === 2 || item.product_val_type === 3) &&
            parseInt(wholesale_price) !== 0
        ) {
            orderType = "Wholesale";
        }

        return orderType;
    };

    addToCartApi = async (item, qty) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({ addToCartApiCalled: true });
                let apiParams = {
                    product_id: item.id,
                    user_id: this.props.auth.userData.id,
                    quantity: qty,
                    option_val: item.options_json ? JSON.stringify(item.options_json) : "",
                    is_retail: this.declareOrderType(item, qty) === "Retail" ? 1 : 2,
                };
                console.log("apiParams", apiParams)
                let cartData = await addToCart_api(apiParams);
                this.setState({ addToCartApiCalled: false });
                // console.log(cartData);
                if (cartData.success) {
                    this.props.onAddToCartClicked(item, qty);
                } else {
                    toast.error(cartData.message);
                }
            } catch (error) {
                this.setState({ addToCartApiCalled: false });
                console.log(error);
                toast.error(error.message);
            }
        } else {
            this.props.onAddToCartClicked(item, qty);
        }
    };

    addDefaultSrc(ev) {
        ev.target.src = image_err
    }

    render() {
        const {
            product,
            symbol,
            onAddToCartClicked,
            onAddToWishlistClicked,
            onAddToCompareClicked,
            is_special_product,
            classname,
            is_short,
            onRemoveWishlist,
            img_click
        } = this.props;

        var { is_wishlist, not_is_wishlist } = this.state;

        const { history } = this.props;

        let RatingStars = [];
        // for(var i = 0; i < product.rating; i++) {
        for (var i = 0; i < 4; i++) {
            RatingStars.push(<i className="fa fa-star" key={i} />);
        }
        return (
            // <div className="product-box mx-2 my-3" id={classname}>
            <div
                className={(product.product_inventory_remain_stock == 0 || ((product.product_val_type == 1 || product.product_val_type == 3) && product.product_inventory_remain_stock < product.product_min_quantity) || ((product.product_val_type == 2) && product.product_inventory_remain_stock < product.product_min_wholesale_quantity)) && product.is_parent != 1 ? "out_of_stock_text product-box product-wrap mx-1 my-1" : "product-box product-wrap mx-1 my-1"}
                id={classname}>
                {
                    is_special_product === true ? <>
                        <div key={i} id="specialMedia" className="media-fluids">
                            {/* <Link to={`${process.env.PUBLIC_URL}/${product.product_slug}/p/${product.id}`}> */}
                            <LazyLoadImage to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                                {/* <img className="img-fluids"
                                         onError={this.addDefaultSrc}
                                         src={product.product_image}
                                         alt=''
                                    /> */}
                                <LazyLoadImage
                                    alt={this.addDefaultSrc}
                                    onError={this.addDefaultSrc}
                                    effect="blur"
                                    src={product.product_image} />
                            </LazyLoadImage>
                            <img src={product.product_image} alt="123" />
                            {/* </Link> */}
                            <div className="media-body align-self-center">
                                <Stars total={5} active={product.avg_rate}></Stars>
                                <div className="d-inline-block">
                                    <i className="fa fa-star star_color mr-1"></i>
                                    <i className="fa fa-star mr-1"> </i>
                                    <i class="fa fa-star" aria-hidden="false"></i>
                                </div>
                            </div>


                            <div className="media-body align-self-center">


                                <Stars total={5} active={product.avg_rate}></Stars>

                                <Link to={`${process.env.PUBLIC_URL}/${product.product_slug}/p/${product.id}`}>
                                    <h5>{product.product_name}</h5>
                                </Link>

                                {product.product_val_type == 2 ?

                                    <h3 className="theme_text_set font-weight-bold price_text_set_for_all">
                                        {symbol}
                                        {product.wholesale_sp_price !== ""
                                            ? product.wholesale_sp_price
                                            : product.wholesale_price}{product.unit_name && <span className="p_unit_name">/{product.unit_name}</span>}
                                        &nbsp;
                                        <div>
                                        {(product.wholesale_sp_price != "" && product.wholesale_price != product.wholesale_sp_price) ?

                                            <del>
                                                <span className="money">{symbol}{product.wholesale_price}</span>
                                            </del>
                                            : ""}
                                            </div>
                                    </h3> :
                                    <h3 className="theme_text_set font-weight-bold price_text_set_for_all">
                                        {symbol}
                                        {product.special_price !== ""
                                            ? product.special_price
                                            : product.product_price}{product.unit_name && <span className="p_unit_name">/{product.unit_name}</span>}
                                        &nbsp;
                                        <div>
                                        {(product.special_price != "" && product.product_price != product.special_price) ?

                                            <><del>
                                                <span className="money">{symbol}{product.product_price}</span>
                                            </del><span className="discount_percentage">({parseFloat(100 - (product.special_price / product.product_price * 100)).toFixed(2)}% off)</span></>
                                            : ""}
                                            </div>
                                    </h3>

                                }


                                <hr id="sphr" />
                                <div className="cart-info" id="specialBox">
                                    {product.product_inventory_remain_stock != 0 ?
                                        <a title="Add to cart" onClick={() => {
                                            this.addToCart(product, product.product_min_quantity == 0 ? 1 : product.product_min_quantity)
                                        }}>
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                        </a>
                                        : ""}

                                    <a href="javascript:void(0)" title="Add to Wishlist"
                                        onClick={() => this.addToWishList(product, ((product.is_in_wishlist == 1 && (!not_is_wishlist.includes(product.id))) || is_wishlist.includes(product.id)) ? 1 : 0)}>
                                        <i className={((product.is_in_wishlist == 1 && (!not_is_wishlist.includes(product.id))) || is_wishlist.includes(product.id)) ? "fa fa-heart" : "fa fa-heart "}
                                            aria-hidden="true"></i>
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        data-toggle="modal"
                                        data-target="#quick-view"
                                        title="Quick View"
                                        onClick={this.onOpenModal}
                                    ><i className="fa fa-eye" aria-hidden="true"></i></a>

                                </div>
                            </div>
                        </div>
                    </> :
                        <>
                            <div className="img-wrapper">
                                <div className="lable-block">
                                    {product.new == true ? <span className="lable3">new</span> : ""}
                                    {product.sale == true ? (<span className="lable4">on sale</span>) : ("")}
                                    {(product.product_inventory_remain_stock == 0 || ((product.product_val_type == 1 || product.product_val_type == 3) && product.product_inventory_remain_stock < product.product_min_quantity) || ((product.product_val_type == 2) && product.product_inventory_remain_stock < product.product_min_wholesale_quantity)) && product.is_parent != 1 ?
                                        <span className="lable4 text-danger out_of_stock_text">OUT OF STOCK</span> : ""}
                                </div>
                                <div className="front">
                                    {
                                        img_click == false ? <>

                                            <div
                                                onError={this.addDefaultSrc}
                                                className={is_short == 1 ? "img-fluid" : "img-fluid"}
                                                alt={product.product_name}
                                                style={{
                                                    backgroundImage: `url(${product.product_image})`,
                                                }}></div>
                                        </> : <>
                                            <Link to={`${process.env.PUBLIC_URL}/${product.product_slug}/p/${product.id}`}>
                                                <div
                                                    onError={this.addDefaultSrc}
                                                    className={is_short == 1 ? "img-fluid" : "img-fluid"}
                                                    alt={product.product_name}
                                                    style={{
                                                        backgroundImage: `url(${product.product_image})`,
                                                    }}></div>
                                            </Link>
                                        </>
                                    }
                                </div>

                                {(product.special_price != "" && product.product_price != product.special_price) &&
                                    <p className="cart_label">{parseInt(parseFloat(100 - (product.special_price / product.product_price * 100)).toFixed(2))}% OFF</p>
                                }


                                <div className="cart-info cart-wrap opacity1">
                                    <div
                                        // href={`javascript:void(0)`}
                                        data-toggle="modal"
                                        data-target="#quick-view"
                                        title="Quick View"
                                        onClick={this.onOpenModal} >
                                        <i className="fa fa-eye" aria-hidden="true" />
                                    </div>
                                    <div
                                        // href={`javascript:void(0)`}
                                        title="Add to Wishlist"
                                        onClick={() => {
                                            if (product.is_in_wishlist == 1) {
                                                //Remove
                                                this.addToWishList(product, 1)

                                            } else {
                                                //add
                                                this.addToWishList(product, 0)
                                            }
                                        }}>
                                        <i className={(product.is_in_wishlist === 1 ? "fa fa-heart active-wishlist-icon" : "fa fa-heart ")} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="product-detail">
                                <div>
                                    <Stars total={5} active={product.avg_rate}></Stars>
                                    <Link to={`${process.env.PUBLIC_URL}/${product.product_slug}/p/${product.id}`}>
                                        <h6 className="productname_hide product_fonts">{product.product_name}</h6>
                                    </Link>

                                    {product.product_val_type == 2 ?

                                        <h3 className="theme_text_set font-weight-bold product_fonts_price price_text_set_for_all">
                                            {symbol}
                                            {product.wholesale_sp_price !== ""
                                                ? product.wholesale_sp_price.toFixed(2)
                                                : product.wholesale_price.toFixed(2)}{product.unit_name && <span className="p_unit_name">/{product.unit_name}</span>}
                                            &nbsp;
                                            <div>
                                            {(product.wholesale_sp_price != "" && product.wholesale_price != product.wholesale_sp_price) ?

                                                <del>
                                                    <span
                                                        className="money">{symbol}{product.wholesale_price.toFixed(2)}</span>
                                                </del>
                                                : ""}
                                                </div>
                                        </h3> :
                                        <h3 className="theme_text_set font-weight-bold product_fonts_price price_text_set_for_all">
                                            {symbol}
                                            {product.special_price !== ""
                                                ? product.special_price.toFixed(2)
                                                : product.product_price.toFixed(2)}{product.unit_name && <span className="p_unit_name">/ {product.unit_name}</span>}
                                            &nbsp;
                                            <div>
                                                
                                            {(product.special_price != "" && product.product_price != product.special_price) ?

<><del>
                                                    <span
                                                        className="money">{symbol}{product.product_price.toFixed(2)} </span>
                                                </del><span className="discount_percentage">({parseInt(parseFloat(100 - (product.special_price / product.product_price * 100)).toFixed(2))}% off)</span></>
                                                : ""}
                                                </div>
                                        </h3>

                                    }


                                    {/*{!product.variants ?
                                        <ul className="color-variant">
                                            {['S', "M", 'L', 'XL'].map((vari, i) => {
                                                return (
                                                    <li className={vari.color} key={i} title={vari.color}
                                                        onClick={() => this.onClickHandle(vari.images)}>{vari}</li>)
                                            })}
                                        </ul> : ''}*/}
                                </div>
                            </div>
                        </>

                }

                <Modal open={this.state.open} onClose={this.onCloseModal} center
                    classNames={{ modal: 'product__models' }}>
                    <div

                        className="modal-dialog modal-lg modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className="quick-view-img">
                                            <img
                                                onError={this.addDefaultSrc}
                                                src={product.product_image}
                                                className="img-fluid"
                                                alt={product.product_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 rtl-text">
                                        <div className="product-right">
                                            <h2> {product.product_name} </h2>
                                            <h5 className="text-muted mb-3">
                                                Availability:{" "}
                                                {parseInt(product.product_quantity) > 0
                                                    ? "In stock"
                                                    : "Out of stock"}
                                            </h5>
                                            <div className="row">
                                                {(product.special_price !== "" && product.special_price !== 0) || product.product_price != 0 ?
                                                    <div className="col-md-6">
                                                        <h4
                                                            style={{
                                                                fontSize: "18px",
                                                            }}
                                                        >
                                                            {" "}
                                                            Retail Price{" "}
                                                        </h4>
                                                        <h3 className="instock-cls">
                                                            {symbol}
                                                            {product.special_price !== "" &&
                                                                product.special_price !== 0
                                                                ? product.special_price
                                                                : product.product_price}
                                                            {product.special_price !== "" &&
                                                                product.special_price !== 0 && (
                                                                    <span className="ml-2">
                                                                        <del>
                                                                            <br />

                                                                            {(product.special_price != "" && product.special_price != 0 && product.special_price != product.product_price) ?
                                                                                <>
                                                                                    {symbol} {product.product_price}
                                                                                </> : ""}

                                                                        </del>
                                                                    </span>
                                                                )}
                                                        </h3>
                                                    </div> : ""}

                                                {product.wholesale_price > 0 && (
                                                    <div className="col-md-6">
                                                        <h4
                                                            style={{
                                                                fontSize: "18px",
                                                            }}
                                                        >
                                                            {" "}
                                                            Wholesale Price{" "}
                                                        </h4>
                                                        <h3 className="instock-cls">
                                                            {symbol}
                                                            {product.wholesale_sp_price !== "" &&
                                                                product.wholesale_sp_price !== 0
                                                                ? product.wholesale_sp_price
                                                                : product.wholesale_price}
                                                            {product.wholesale_sp_price !== "" &&
                                                                product.wholesale_sp_price !== 0 && (
                                                                    <span className="ml-2">
                                                                        <del>
                                                                            <br />

                                                                            {(product.wholesale_sp_price != "" && product.wholesale_sp_price != 0 && product.wholesale_sp_price != product.wholesale_price) ?
                                                                                <>
                                                                                    {symbol} {product.wholesale_price}
                                                                                </> : ""}
                                                                        </del>
                                                                    </span>
                                                                )}
                                                        </h3>
                                                    </div>
                                                )}

                                            </div>

                                            <div className="product-description border-product">
                                                {/*{product.product_option.length > 0 && product.product_option.map((opt, i) => {
                            return opt.product_option_value.length > 0 ? (
                              <ProductOptions
                                item={opt}
                                key={i}
                                onChangeOption={(e, item, type) =>
                                  this.onChangeOption(e, item, type)
                                }
                              />
                            ) : null;
                          })}*/}
                                                <h6 className="product-title d-none">quantity</h6>
                                                <div className="row d-none">
                                                    <div className="col-md-12">
                                                        <div className="qty-box">
                                                            <div className="input-group">
                                                                <span className="input-group-prepend">
                                                                    <button
                                                                        type="button"
                                                                        className="btn quantity-left-minus"
                                                                        onClick={this.minusQty}
                                                                        data-type="minus"
                                                                        data-field=""
                                                                    >
                                                                        <i className="fa fa-angle-left" />
                                                                    </button>
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={this.state.quantity}
                                                                    onChange={this.changeQty}
                                                                    className="form-control input-number"
                                                                />
                                                                <span className="input-group-prepend">
                                                                    <button
                                                                        type="button"
                                                                        className="btn quantity-right-plus"
                                                                        onClick={this.plusQty}
                                                                        data-type="plus"
                                                                        data-field=""
                                                                    >
                                                                        <i className="fa fa-angle-right" />
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 p-0">

                                                {(product.product_min_quantity >= 1 && (product.product_val_type == 1 || product.product_val_type == 3)) ?
                                                    <div className="w-100 font-size-text-14">Minimum Retail Quantity
                                                        : {product.product_min_quantity}</div> : ""}
                                                {(product.product_min_wholesale_quantity >= 1 && (product.product_val_type == 1 || product.product_val_type == 3)) ?
                                                    <div className="w-100 font-size-text-14">Minimum Wholesale Quantity
                                                        : {product.product_min_wholesale_quantity}</div> : ""}


                                            </div>

                                            <div className="product-buttons">
                                                {/*<button
                                                    className="btn btn-solid"
                                                    onClick={() =>
                                                        this.addToCart(product, this.state.quantity)
                                                    }
                                                    disabled={this.state.addToCartApiCalled}
                                                >
                                                    add to cart
                                                    {this.state.addToCartApiCalled && (
                                                        <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                                    )}
                                                </button>*/}
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/${product.product_slug}/p/${product.id}`}
                                                    className="btn btn-solid ml-0"
                                                >
                                                    VIEW DETAIL
                                                </Link>
                                            </div>

                                            <div className="border-product">
                                                <h6 className="product-title">product details</h6>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.product_description,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps, { getProductPositionList }
)(ProductBox);

