import React, { Component } from 'react'
import ProductListing from "../collection/common/DashboardProductListing";
import ServiceProductListing from '../collection/common/ServiceProductListing';
import {connect} from "react-redux";
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts,
} from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import SpProductListing from '../collection/common/SpProductListing';


class SpDashboardData extends Component {
    state = {
        layoutColumns: 3,
        page: 1,
        dashboardId: this.props.match.params.id,
        serviceName: this.props.match.params.name,

    };
    
    componentDidMount() {
        // check whether sidebar is open or not
        this.closeNav();
    }
    closeNav() {
        var closemyslide = document.getElementById("mySidenav");
        if (closemyslide) closemyslide.classList.remove("open-side");
    }
    render() {
        console.log("dashboard")
        return (
            <div>
                <Breadcrumb title={this.state.serviceName} />
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        <div className="row mx-2 prolistTop">
                            <SpProductListing dashboardId={this.state.dashboardId} colSize={this.state.layoutColumns}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
});

export default connect(
    mapStateToProps,
    {
        getAllDashboardProducts,
        filterSort,
        getAllDashboardProductFilters,
        filterBrand,
        resetFilterAndfSort,
        resetDashboardProducts,
    }
)(SpDashboardData);