import React, { useState, useRef, useEffect,Component } from 'react';
import BannerTxt from './bannerTxt';
import Deal_bar from './layout/deal_bar';
import Fashion from './layout/Fashion';
import ItemList from './layout/ItemList';
import New_deals from './layout/new_deals';
import TxtWrap from './layout/textwrap'
import LoadMore from './layout/LoadMore.js'
import BrandDiv from './layout/BrandDiv';
import Tab from './layout/Tab';
import { connect } from 'react-redux';
import {
    getHomeDashboardModules,
} from "../actions/index";

import axios from "axios";

// const listperload = 8;
// const listArray = [];


class Display extends Component {
    componentDidMount() {
        this.props.getHomeDashboardModules();
    }

    render() {


        return (
            <div className=" d-block w-100  text-center ">

                <div className="item" id="new_arrivals">
                    <New_deals deal="NEW ARRIVALS ON"/>

                    <Tab dashboard_id='5' slide1="New Products" slide2="Featured Products" slide3="Best Sellers" tabId1="new" tabId2="featured" tabId3="best"/>

                </div>


                <div className=" container-fluid p-2  justify-content-center" id="combo_deals">
                    <New_deals deal="COMBO DEALS ON"/>
                    <table className="p-2 table-responsive">
                        <tr className="fashion">
                            <td>
                                <Fashion path_="./fashion/f1.png"/>
                            </td>
                            <td>
                                <Fashion path_="./fashion/f2.png"/>
                            </td>
                            <td>
                                <Fashion path_="./fashion/f3.png"/>
                            </td>
                            <td>
                                <Fashion path_="./fashion/f4.png"/>
                            </td>
                        </tr>
                    </table>
                </div>
                {/* <div className="item">
        <New_deals deal="COMBO DEALS ON" />
        <ul className="fashion" >
          <li><Fashion path_="./fashion/f1.png" /></li>
          <li><Fashion path_="./fashion/f2.png" /></li>
          <li><Fashion path_="./fashion/f3.png" /></li>
          <li><Fashion path_="./fashion/f4.png" /></li>
        </ul>
      </div> */}

                <BannerTxt/>
                <div className="d-block w-100 banner">
                    <img class="d-block w-100" src="/images/banner.png" alt="" srcset=""/>
                </div>


                <div className="item" id="top_collection">
                    <New_deals deal="TOP COLLECTION"/>
                    <Tab dashboard_id='6' slide1="New Products" slide2="Featured Products" slide3="Best Sellers" tabId1="topnew" tabId2="topfeatured" tabId3="topbest"/>
                    <br/>
                </div>

                <div className="d-block w-100 banner slide" id="special_products">
                    <img src="/images/b2.png" id="b2" alt="" srcset=""/>
                    <New_deals deal="SPECIAL PRODUCTS"/>
                    <table className="container-fluid special_table my-4">
                        <tr>
                            <a>
                                <td><TxtWrap card="./card/1.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/2.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/3.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/4.jpeg"/></td>
                            </a>
                        </tr>
                        <tr>
                            <a>
                                <td><TxtWrap card="./card/5.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/6.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/7.jpeg"/></td>
                            </a>
                            <a>
                                <td><TxtWrap card="./card/8.jpeg"/></td>
                            </a>
                        </tr>
                    </table>
                </div>

                <div className="container-fluid banner3">
                    <a><img src="./b3/1.png" alt="" className="src"/></a>
                    <a><img src="./b3/2.png" alt="" className="src"/></a>
                    <a><img src="./b3/3.png" alt="" className="src"/></a>
                    <a><img src="./b3/4.png" alt="" className="src"/></a>
                </div>


                <div className="item container-fluid" id="top_brands">
                    <New_deals deal="TOP BRANDS"/><br/>
                    <BrandDiv/>
                </div>

                <div className="item container-fluid " id="recent">
                    <New_deals deal="RECENTLY VIEWD"/>
                    <div className="recentCont my-4">
                        <ItemList/>
                    </div>


                    {/* <LoadMore  /> */}
                    {/* LoadClick={LoadMoreItems} */}
                </div>

            </div>

        );
    }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {getHomeDashboardModules};

export default connect(mapStateToProps, mapDispatchToProps)(Display);