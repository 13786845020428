import React, { Component } from 'react';

class SampleNextArrow extends Component {

    render() {

        const { key, onClick} = this.props;
        return (
            
            <div className="slick-arrow1 product-right-slick"  onClick={()=>{onClick(0)}}>
                <div className="caret">
                    <a> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                </div>


            </div>
        );
    }
}

export default SampleNextArrow;

