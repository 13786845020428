const firebaseConfig = {

  // todo: old code
  /*apiKey: "AIzaSyBg_jAmqyxDGGvD6g0sSlPIgyQl84ETr5o",
  authDomain: "zubagate-c20d4.firebaseapp.com",
  projectId: "zubagate-c20d4",
  storageBucket: "zubagate-c20d4.appspot.com",
  messagingSenderId: "574860245026",
  appId: "1:574860245026:web:dbc19f591ddacc9eac727b",
  measurementId: "G-WJGRNP4JJK",
  facebook_appId:"302353584599517",
  facebook_Secret:"7cf006abdfc9c9dc6ec2126b602f2d42",
  clientId:"954432691949-6uqkummak31hmh081uno1f4p6oof7emc.apps.googleusercontent.com"  // new  company account*/

  // todo: Infrawheel
  apiKey: "AIzaSyBSG0_zu5dGtJCF7XOjmNV7zcep5_MIpwo",
  authDomain: "infrawheel-ddd9e.firebaseapp.com",
  databaseURL: "https://infrawheel-ddd9e.firebaseio.com",
  projectId: "infrawheel-ddd9e",
  storageBucket: "infrawheel-ddd9e.appspot.com",
  messagingSenderId: "540389952777",
  appId: "1:540389952777:web:0e6dd2d703041052e857d8",
  measurementId: "G-Y8VVCY5P8R",
  clientId:"540389952777-icr1ci4ci8pbhn9gclqs07o5kd19ke8m.apps.googleusercontent.com" // new  company account

  // todo: for linkslik
  /*apiKey: "AIzaSyA34rcnXn51gO2kxNydWEP6w8cXlucXu-8",
  authDomain: "linksilk-e0f3d.firebaseapp.com",
  projectId: "linksilk-e0f3d",
  storageBucket: "linksilk-e0f3d.appspot.com",
  messagingSenderId: "788880648657",
  appId: "1:788880648657:web:ae798f700db70e48f2d8f0",
  measurementId: "G-M43TGR1NT5"
  facebook_appId:"724838841468757"
  facebook_Secret:"5b1fba7a305b6a4e89b08bd752bb0ae7",
  clientId:"540389952777-icr1ci4ci8pbhn9gclqs07o5kd19ke8m.apps.googleusercontent.com" // new  company account
   */
};

export default firebaseConfig;
