import React, {Component} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import {checkAndRedirectLogin} from "../../utils/helper";
import {toast} from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import {Helmet} from "react-helmet";
import {setUserSignUpSuccess} from "../../actions";
import {editProfile_api, editProfileImage_api, getProfileapi} from "../../api/auth";
import * as storageUtils from "../../utils/storageUtils";
import countryData from "./countries.json";

class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: "",
            country_code: "+91",
            mobile_no: "",
            first_name: "",
            name: "",
            last_name: "",
            email: "",
            updateProfileLoader: false,
            mode: "view",
        };
    }

    componentDidMount() {
        if (!checkAndRedirectLogin()) {
            toast.info("Please login to continue");
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            // this.setUserData();
            this.getProfile();
        }
    }

    getProfile = async () => {

        try {

            let userData = await getProfileapi();
            console.log("userData", userData)
            if (userData.success) {
                storageUtils.setUserData(userData.data);
                this.props.setUserSignUpSuccess(userData.data);
                this.setState({...userData.data});
            } else {
                toast.error(userData.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    setUserData = () => {
        this.setState({
            country_code: this.props.auth.userData.country_code,
            mobile_no: this.props.auth.userData.mobile_no,
            first_name: this.props.auth.userData.name,
            name: this.props.auth.userData.name,
            last_name: this.props.auth.userData.last_name,
            email: this.props.auth.userData.email,
        });
    };


    onChange = async (event) => {

        try {

            const files = event.target.files
            const formData = new FormData()
            this.setState({imgs: event.target.files})
            formData.append('image', files[0])

            let userData = await editProfileImage_api(formData);

            if (userData.success) {
                this.getProfile();
            } else {
                toast.error(userData.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    handleCountryChange = (e) => {
        this.setState({
            country_code: e.target.value,
        });
    };

    handleUpdateProfile = async (e) => {
        e.preventDefault();
        try {
            this.setState({updateProfileLoader: true});
            const userData = await editProfile_api({
                name: this.state.name,
                last_name: this.state.last_name,
            });
            this.setState({updateProfileLoader: false, mode: "view"});
            toast.success("Profile has been updated successfully");
            // Set data
            storageUtils.setUserData(userData.data);
            this.props.setUserSignUpSuccess(userData.data);
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            this.setState({updateProfileLoader: false});
        }
    };

    render() {
        const {isLoggedIn, userData} = this.props.auth;
        const {country_code, mobile_no, name, last_name, email, mode, image} = this.state;
        return (
            <div>
                <Helmet>
                    <title>Edit Profile | </title>
                    <meta name="description" content=""/>
                </Helmet>

                <Breadcrumb title={"Edit Profile"}/>

                {/*Dashboard section*/}
                <section className="section-b-space editProfile">
                    <div className="container-fluid">
                        <div className="row mx-md-5 mx-sm-1">
                            <div className="col-md-6 col-6">
                            <h4 className="my_profile">MY PROFILE</h4>
                            </div>
                            <div className="col-md-6 col-6">
                                {(this.state.mode == "view")?
                                    <a
                                        className="edit_profile float-sm-left float-md-right float-right"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({mode: 'edit'})
                                        }}
                                    >
                                        EDIT PROFILE
                                        {this.state.updateProfileLoader && (
                                            <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                        )}
                                    </a>
                                :
                                    <a
                                        className="edit_profile float-sm-left float-md-right float-right"
                                        onClick={(e) => {
                                            e.preventDefault();
                                                this.setState({mode: 'view'})
                                        }}
                                    >
                                        VIEW PROFILE
                                        {this.state.updateProfileLoader && (
                                            <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                        )}
                                    </a>
                                }

                            </div>
                            {/*{isLoggedIn && <DahsboardSidebar {...this.props} />}*/}
                            {isLoggedIn && (
                                <>
                                    {
                                        (mode == 'view') ?
                                            <div className="col-sm-12">
                                                <div className="dashboard-right register-page">
                                                    <div className="theme-card">
                                                        <form className="theme-form">
                                                            <div className="form-row">
                                                                <div className="col-md-6">

                                                                    <label>
                                                                            <img className="img-thumbnail"
                                                                                 style={{width: 100, height: 100}}
                                                                                 src={image}/>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="email">
                                                                        First Name<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        readOnly
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="fname"
                                                                        placeholder="First Name"
                                                                        value={name}
                                                                        required=""
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                first_name: e.target.value,
                                                                                name: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="review">
                                                                        Last Name<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        readOnly
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="lname"
                                                                        placeholder="Last Name"
                                                                        value={last_name}
                                                                        required=""
                                                                        onChange={(e) =>
                                                                            this.setState({last_name: e.target.value})
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="email">
                                                                                    Phone Number
                                                                                    <span
                                                                                        style={{color: "red"}}>*</span>
                                                                                </label>
                                                                                <select
                                                                                    readOnly
                                                                                    className="form-control"
                                                                                    onChange={(e) =>
                                                                                        this.handleCountryChange(e)
                                                                                    }
                                                                                    style={{
                                                                                        height: "54px",
                                                                                    }}
                                                                                    value={"+"+country_code}
                                                                                    disabled
                                                                                >
                                                                                    {countryData.map((item, i) => {
                                                                                        return (
                                                                                            <option key={i}
                                                                                                    value={item.dial_code}>
                                                                                                {item.name}&nbsp;{item.dial_code}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="form-group">
                                                                                <label htmlFor="mobileNo">&nbsp;</label>
                                                                                <input
                                                                                    readOnly
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id="mobileNo"
                                                                                    placeholder="Enter phone number"
                                                                                    value={mobile_no}
                                                                                    maxLength="10"
                                                                                    required
                                                                                    onChange={(e) =>
                                                                                        this.setState({
                                                                                            mobile_no: e.target.value,
                                                                                        })
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="email">
                                                                        email<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="email"
                                                                        placeholder="Email"
                                                                        value={email}
                                                                        required=""
                                                                        onChange={(e) =>
                                                                            this.setState({email: e.target.value})
                                                                        }
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-sm-12">
                                                <div className="dashboard-right register-page">
                                                    <div className="theme-card">
                                                        <form className="theme-form">
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <label >
                                                                    {(this.state.imgs && [...this.state.imgs]) ? [...this.state.imgs].map((file) => (
                                                                            <img className="img-thumbnail"
                                                                                 style={{width: 100, height: 100}}
                                                                                 src={URL.createObjectURL(file)}/>
                                                                        )) :
                                                                        <img className="img-thumbnail"
                                                                             style={{width: 100, height: 100}}
                                                                             src={image}/>
                                                                    }

                                                                    <input
                                                                        className="d-none"
                                                                        id="upload"
                                                                        ref="file"
                                                                        type="file"
                                                                        accept="image/x-png,image/gif,image/jpeg"
                                                                        onChange={this.onChange}/>
                                                                        <p htmlFor="upload" style={{cursor: 'pointer'}} className="browse_file_buttone">
                                                                            Browse
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="email">
                                                                        First Name<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="fname"
                                                                        placeholder="First Name"
                                                                        value={name}
                                                                        required=""
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                first_name: e.target.value,
                                                                                name: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="review">
                                                                        Last Name<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="lname"
                                                                        placeholder="Last Name"
                                                                        value={last_name}
                                                                        required=""
                                                                        onChange={(e) =>
                                                                            this.setState({last_name: e.target.value})
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="email">
                                                                                    Phone Number
                                                                                    <span
                                                                                        style={{color: "red"}}>*</span>
                                                                                </label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    onChange={(e) =>
                                                                                        this.handleCountryChange(e)
                                                                                    }
                                                                                    style={{
                                                                                        height: "54px",
                                                                                    }}
                                                                                    defaultValue={"+"+country_code}
                                                                                    disabled
                                                                                >
                                                                                    {countryData.map((item, i) => {
                                                                                        return (
                                                                                            <option key={i}
                                                                                                    value={item.dial_code}>
                                                                                                {item.name}&nbsp;{item.dial_code}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="form-group">
                                                                                <label htmlFor="mobileNo">&nbsp;</label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id="mobileNo"
                                                                                    placeholder="Enter phone number"
                                                                                    value={mobile_no}
                                                                                    maxLength="10"
                                                                                    required
                                                                                    onChange={(e) =>
                                                                                        this.setState({
                                                                                            mobile_no: e.target.value,
                                                                                        })
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="email">
                                                                        email<span style={{color: "red"}}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="email"
                                                                        placeholder="Email"
                                                                        value={email}
                                                                        required=""
                                                                        onChange={(e) =>
                                                                            this.setState({email: e.target.value})
                                                                        }
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="btn btn-solid"
                                                                    style={{width:210}}
                                                                    onClick={(e) => this.handleUpdateProfile(e)}
                                                                    disabled={
                                                                        this.state.mobile_no.length < 9 ||
                                                                        (this.state.name.length === 0 || this.state.name.trim().length === 0) ||
                                                                        (this.state.last_name.length === 0 || this.state.name.trim().length === 0) ||
                                                                        this.state.email.length === 0
                                                                    }
                                                                >
                                                                    UPDATE &nbsp;{" "}
                                                                    {this.state.updateProfileLoader && (
                                                                        <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    {setUserSignUpSuccess}
)(EditProfile);
