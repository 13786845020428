import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

import {getBestSeller} from "../../services";
import {addToCart, addToWishlist, addToCompare} from "../../actions";
import ProductItem from '../layouts/common/product-item';
import ProductBox from "../layouts/common/ProductBox";
import {Product4} from '../../services/script'

class RelatedProduct extends Component {
    render() {
        const {items, symbol, is_sliders,showno, addToCart, addToWishlist, addToCompare, is_short} = this.props;
        return (
            <section className="section-b-space " id="reletedCont">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 product-related">
                            <h3 className="text-dark font-weight-bold">{this.props.title}</h3>
                        </div>
                    </div>
                    {is_sliders ==1?
                    <div className="row">
                        <div className="col-sm-12">
                            <Slider {...Product4} className={is_short == 1 ? "product-4 product-m no-arrow setproducts2" : " product-4 product-m no-arrow setproducts"}>
                                {showno == 4 ?

                                        items.slice(0, 4).map((product, index) =>
                                            <div key={index}>
                                                <ProductBox
                                                    is_short={is_short}
                                                    product={product}
                                                    symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(product)}
                                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                                    onAddToCartClicked={addToCart}
                                                    key={index}
                                                    auth={this.props.auth}
                                                    isAdjustImageBox={true}
                                                    img_click={false}
                                                />
                                            </div>)

                                     :

                                        items.map((product, index) =>
                                            <div key={index}>
                                                <ProductBox
                                                    is_short={is_short}
                                                    product={product}
                                                    symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(product)}
                                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                                    onAddToCartClicked={addToCart}
                                                    key={index}
                                                    auth={this.props.auth}
                                                    isAdjustImageBox={true}
                                                    img_click={false}
                                                />
                                            </div>)


                                }
                            </Slider>
                        </div>
                    </div>:
                    <div className="row search-product">
                        <div className="col-12">
                            <div className="d-flex flex-wrap w-100">

                                {(items && items.length > 0) ? items.slice(0, 5).map((product, index) =>

                                    <div key={index} className={is_short == 1 ? "setproducts2" : "setproducts"}>

                                        <ProductBox
                                            is_short={is_short}
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={addToCart}
                                            key={index}
                                            auth={this.props.auth}
                                            isAdjustImageBox={true}
                                            img_click={false}
                                        />
                                    </div>
                                ):""}
                            </div>
                        </div>
                    </div>}
                </div>

            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        // items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(RelatedProduct);
