import React, { Component } from 'react';
import Slider from 'react-slick';
import Carousel from "react-elastic-carousel";

import {Slider115, Slider15, Slider6} from "../../../services/script";
import { Link } from "react-router-dom";
import {faqList_api,Subscribeusers_api} from "../../../api/pages";
import {toast} from "react-toastify";
import {connect} from "react-redux";

class BrandsLogo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: [],
        };
    }

    componentDidMount() {}

    Setemail(index) {
        this.setState({email: index});
    }

    Subscribeusers = async () => {
        try{
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
               email:this.state.email
            }; 

            let subscribeusers = await Subscribeusers_api(apiParams);   
            if (subscribeusers.success) {
                console.log(subscribeusers,"subscribeusers")
                toast.success(subscribeusers.message);
                this.setState({email: ""})
            }else{
                toast.error(subscribeusers.message);
            }

        }catch(error){
            console.log(error);
            toast.error(error.message);
        }   
    };

    render() {
        const { brands } = this.props
        const {store_name} = this.props.settings;

        const breakPoints = [
            { width: 1, itemsToShow: 2 ,showArrows:false ,showEmptySlots:true, pagination:false},
            { width: 550, itemsToShow: 3, showArrows:false ,showEmptySlots:true, pagination:false},
            { width: 768, itemsToShow: 4,showArrows:false,pagination:false,showEmptySlots:true},
            { width: 1200, itemsToShow: 8,showArrows:false,pagination:false,showEmptySlots:true}
        ];
        return (
            <>
                <section className="section-b-space topbrands-wrp" style={{
                    paddingTop: '20px'
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1 font-size-text-30">Top Brands</h2>
                                </div>
                            </div>
                            <div className="col-md-12 mb-5 mt-2">

                                <Carousel breakPoints={breakPoints} className="slide-6 no-arrow ">
                                    {
                                        brands.map((brand, i) => (
                                            <div key={i}>
                                                <div className="logo-block">
                                                    <Link to={`${process.env.PUBLIC_URL}/brand/${brand.id}/products`} >
                                                        <img
                                                            src={brand.manufacture_image}
                                                            alt={brand.manufacture_name} style={{
                                                            height: '120px'
                                                        }} />
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="light-layout mb-4">
                    <div className="container-fluid">
                        <section className="border-top-0" id="brandBorder">
                            <div className="row mx-5">
                                <div className="col-lg-6">
                                    <div className="subscribe text-left">
                                        <div>
                                            <h4>KNOW IT ALL FIRST!</h4>
                                            <p>Never Miss Anything From {store_name} By Signing Up To Our Newsletter. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <form className="form-inline subscribe-form" onSubmit={e=>(e.preventDefault())}>
                                    <div className="form-group mx-sm-3 ">
                                        <input onChange={(e)=>{this.Setemail(e.target.value)}} type="text" className="form-control" value={this.state.email} id="exampleFormControlInput1" placeholder="Enter your email" />
                                    </div>
                                    <button onClick={(e)=>this.Subscribeusers()}  type="submit" className="btn btn-solid">subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    settings : state.settings,
});

export default connect(
    mapStateToProps,
    {}
)(BrandsLogo);