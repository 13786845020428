import React, { Component } from 'react';
import { connect } from "react-redux";
import MultiSliderItem from "./multi-slider-item.js"
import {
    getBestSeller,
    getBestSellerProducts, getMensWear,
    getNewProducts, getWomensWear,
} from "../../../services";


class MultipleSlider extends Component {


    render() {
        const {symbol,dashboardModules } = this.props;
        return (
            <div>
                <section className="">
                    <div className="container my-4">
                        {/* <div className="title1 section-t-space">
                                        <h2 className="title-inner4">{this.props.trendTitle}</h2>
                                        <div className="line"></div>
                                    </div> */}
                        <div className="title1 section-t-space" id="specialTitle">
                            <h2 className="title-inner1">{this.props.trendTitle}</h2>
                        </div>
                        <div className="row multiple-slider">
                            <div className="col-lg-3 col-sm-6">
                                <div className="theme-card">
                                    <MultiSliderItem items={dashboardModules} NoOfProducts={1} symbol={symbol} />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    symbol: state.data.symbol,
    dashboardModules: state.home.dashboardModulesnew,
})

export default connect(
    mapStateToProps
)(MultipleSlider)