import { getUserToken } from "./storageUtils";
import {addToCart_api, getCouponList_api,} from "../api/product";

export const convertObjectToQuerystring = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
};

const optionsPriceCalculation = (item, price) => {
  let total = price;
  if (item.product_option && item.product_option.length > 0) {
    let data = item.product_option.filter(
      (fltr) => fltr.selected_options.length > 0
    );

    for (var i = 0; i < item.product_option.length; i++) {
      for (let j = 0; j < item.product_option[i].selected_options.length; j++) {
        if (item.product_option[i].selected_options[j].price_prefix === "+") {
          total += parseInt(item.product_option[i].selected_options[j].price);
        } else {
          total -= parseInt(item.product_option[i].selected_options[j].price);
        }
      }
    }
  }
  return total;
};

export const declareProductPrice = (item, display = "") => {
  let product_price =
    item.special_price !== "" ? item.special_price : item.product_price;
  let wholesale_price =
    item.wholesale_sp_price != ""
      ? item.wholesale_sp_price
      : item.wholesale_price;

  let priceDecided = 0;

  if (
    item.cart_quantity >= parseInt(item.product_min_wholesale_quantity) &&
    (item.product_val_type === 2 || item.product_val_type === 3) &&
    parseInt(wholesale_price) !== 0
  ) {
    priceDecided = parseFloat(wholesale_price).toFixed(2);
  } else {
    priceDecided = parseFloat(product_price).toFixed(2);
  }

  if (display !== "sum") {
    return priceDecided;
  } else {
    return optionsPriceCalculation(item, priceDecided);
  }
};

export const declareOrderType = (item) => {
  let wholesale_price =
      (item.wholesale_sp_price != "" && item.wholesale_sp_price != 0)
      ? item.wholesale_sp_price
      : item.wholesale_price;
  let orderType = "Retail";
  if (
    item.cart_quantity >= parseInt(item.product_min_wholesale_quantity) &&
    (item.product_val_type === 2 || item.product_val_type === 3) &&
    parseInt(wholesale_price) !== 0
  ) {
    orderType = "Wholesale";
  }

  return orderType;
};

export const declareAvailability = (item) => {

  let product_quantity = parseInt(item.product_inventory_remain_stock);
  let qty = parseInt(item.cart_quantity);
  if (product_quantity >= qty) {
    return "In Stock";
  } else {
    return "Out Of Stock";
  }
};

export const declareWishlistAvailability = (item) => {

  let product_quantity = parseInt(item.product_quantity);
  if (product_quantity >= 1) {
    return "In Stock";
  } else {
    return "Out Of Stock";
  }
};

export const declareTaxesOfProduct = (cartItems) => {
  var total = 0;
  var taxTypesSum;
  for (var i = 0; i < cartItems.length; i++) {
    taxTypesSum = 0;
    // if (declareOrderType(cartItems[i]) === "Retail") {
    //   if (cartItems[i].tax_price.length > 0) {
    //     for (var j = 0; i < cartItems[i].tax_price.length; j++) {
    //       console.log(cartItems[i].tax_price[j]);
    //       // taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
    //     }
    //     total += parseInt(cartItems[i].qty, 10) * taxTypesSum;
    //   }
    // } else {
    //   if (cartItems[i].wholesale_tax_price.length > 0) {
    //     for (var k = 0; i < cartItems[i].wholesale_tax_price.length; k++) {
    //       taxTypesSum += parseInt(
    //         cartItems[i].wholesale_tax_price[k].tax_amount
    //       );
    //     }
    //     total += parseInt(cartItems[i].qty, 10) * taxTypesSum;
    //   }
    // }
    if (cartItems[i].tax_price.length > 0) {
      for (var j = 0; j < cartItems[i].tax_price.length; j++) {
        if (cartItems[i].tax_price[j].tax_amount)
          taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
      }
      total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
    }
  }
  return total;
};

export const checkAndRedirectLogin = () => {
  const token = getUserToken();
  if (!token) {
    return false;
  } else {
    return true;
  }
};

export const offlinecartpages = () => {
  const token = getUserToken();
  if (token) {
      var data =  localStorage.getItem("cartlist");

     const cartaddlogintime = () =>{
      data = (data && JSON.parse(data).length >= 1)?JSON.parse(data):[];

      const promises = data.map(item => {
        let apiParams = {
          is_retail: 1,
          option_val: "",
          product_id: item.id,
          quantity: item.cart_quantity,
          user_id: 259
        };
            return  addToCart_api(apiParams);
      });

      Promise.all(promises).then(results => {
        console.log(results);

      })
    }
    cartaddlogintime()
    setTimeout(()=>{
      localStorage.setItem("cartlist", JSON.stringify([]));
    },500)
  }
};

export const checksinglevendo = () => {
  return true;
};