import React, {Component} from "react";
import {connect} from "react-redux";
import Link from "react-router-dom/Link";
import Breadcrumb from "../common/breadcrumb";
import {GoogleLogin} from "react-google-login";
import FacebookLogin from "react-facebook-login";
import OtpInput from "react-otp-input";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import {letCheckMobileEmail_Api, letCheckMobileNo_Api, letUserSignUp_Api,} from "../../api/auth";
import {setUserSignUpSuccess} from "../../actions";
import * as storageUtils from "../../utils/storageUtils";
import countryData from "./countries.json";
import {Helmet} from "react-helmet";

class UserRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registerPhase: true,
            otp: "",
            country_code: "+91",
            mobile_no: "",
            first_name: "",
            name: "",
            last_name: "",
            email: "",
            google_id: "",
            facebook_id: "",
            isDisplayOtp: false,
            isSentOtpLoaderEnable: false,
            isSubmitOtp: false,
            is_sign_up: 1,
            isCheckPhoneExistApiCalled: false,
            device_token: "webToken",
            device_type: "WEB",
        };
    }

    componentDidMount() {
        if (this.props.auth.isLoggedIn) {
            this.props.history.push({
                pathname: "/dashboard/account",
            });
        }

        if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: function (response) {
                    this.submitPhoneNumberAuth();
                },
            }
        );
        // this.checkAuth();
    }

    checkAuth = () => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                toast.success("User is logged In");
                console.log(user);
                firebase
                    .auth()
                    .signOut()
                    .then(function () {
                        // Sign-out successful.
                        toast.success("logout success");
                    })
                    .catch(function (error) {
                        // An error happened.
                        toast.error("error logout");
                    });
            } else {
                // No user is signed in.
                toast.error("Not logged In");
            }
        });
    };

    handleChange = (otp) => this.setState({otp});

    handleCountryChange = (e) => {
        console.log(e.target.value);
        this.setState({
            country_code: e.target.value,
        });
    };

    checkPhoneExist = async (e) => {
        e.preventDefault();
        if (this.state.mobile_no !== "") {
            this.setState({isCheckPhoneExistApiCalled: true, is_sign_up: 1});
            try {
                const checkMobileExist = await letCheckMobileNo_Api({
                    country_code: this.state.country_code.replace("+", ""),
                    mobile_no: this.state.mobile_no,
                });
                if (checkMobileExist.success) {
                    this.setState({
                        isCheckPhoneExistApiCalled: false,
                    });
                    toast.dismiss();
                    toast.error("Phone number already exist!");
                } else {
                    toast.info("Please provide some basic info to continue");
                    this.setState({
                        isCheckPhoneExistApiCalled: false,
                        registerPhase: false,
                    });
                }
            } catch (error) {
                console.log(error);
                if (error.hasOwnProperty("registered") && !error.registered) {
                    toast.info("Please provide some basic info to continue");
                    this.setState({
                        isCheckPhoneExistApiCalled: false,
                        registerPhase: false,
                    });
                } else {
                    toast.error(error.message);
                    this.setState({
                        isCheckPhoneExistApiCalled: false,
                    });
                }
            }
        } else {
            toast.error("Please enter mobile number");
        }
    };

    submitPhoneNumberAuth = async () => {
        return new Promise((resolve, reject) => {
            try {
                // We are using the test phone numbers we created before
                // var phoneNumber = document.getElementById("phoneNumber").value;
                var phoneNumber = this.state.country_code + this.state.mobile_no;
                var appVerifier = window.recaptchaVerifier;

                // console.log(phoneNumber);
                firebase
                    .auth()
                    .signInWithPhoneNumber(phoneNumber, appVerifier)
                    .then(function (confirmationResult) {
                        // console.log("confirmationResult ", confirmationResult);
                        window.confirmationResult = confirmationResult;
                        resolve(confirmationResult);
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error.message);
                    });
            } catch (error) {
                console.log(error);
                reject(error.message);
            }
        });
    };

    getOtpClick = async (e) => {
        e.preventDefault();
        if (
            this.state.mobile_no.length < 9 ||
            this.state.first_name.length === 0 ||
            this.state.last_name.length === 0 ||
            this.state.email.length === 0
        ) {
            toast.error("Please fill up the mandatory fileds");
        } else {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(this.state.email)) {
                toast.error("Please enter valid email address.");
            } else {
                try {
                    const checkMobileExist = await letCheckMobileEmail_Api({
                        country_code: this.state.country_code.replace("+", ""),
                        mobile_no: this.state.mobile_no,
                        email: this.state.email,
                    });
                    if (checkMobileExist.success) {
                        toast.dismiss();
                        toast.error(checkMobileExist.message);
                        return false;
                    }

                    this.setState({
                        isSentOtpLoaderEnable: true,
                        isCheckPhoneExistApiCalled: false,
                    });
                    const rs = await this.submitPhoneNumberAuth();
                    // console.log("RS ", rs);
                    this.setState({
                        isDisplayOtp: true,
                        isSentOtpLoaderEnable: false,
                    });
                    toast.success("OTP has been sent to the given number");
                } catch (error) {
                    console.log(error);
                    if (error.hasOwnProperty("registered") && !error.registered) {
                        this.setState({
                            isSentOtpLoaderEnable: true,
                            isCheckPhoneExistApiCalled: false,
                        });
                        const rs = await this.submitPhoneNumberAuth();
                        console.log("RS ", rs);
                        this.setState({
                            isDisplayOtp: true,
                            isSentOtpLoaderEnable: false,
                        });
                        toast.success("OTP has been sent to the given number");
                    } else {
                        toast.error(error.message);
                        this.setState({
                            isSentOtpLoaderEnable: false,
                            isCheckPhoneExistApiCalled: false,
                        });
                    }
                }
            }
        }
    };

    submitPhoneNumberAuthCode = (e) => {
        return new Promise((resolve, reject) => {
            try {
                // We are using the test code we created before
                // var code = document.getElementById("code").value;
                // console.log(this.state.otp);
                var code = this.state.otp;
                window.confirmationResult
                    .confirm(code)
                    .then(function (result) {
                        var user = result.user;
                        // console.log("=============");
                        // console.log(user);
                        resolve(user);
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error.message);
                    });
            } catch (error) {
                console.log(error);
                reject(error.message);
            }
        });
    };

    handleUserProvidedCode = async (e) => {
        e.preventDefault();
        try {
            this.setState({isSubmitOtp: true});
            const user = await this.submitPhoneNumberAuthCode();
            // console.log("USER =>", user);
            toast.success("phone number has been successfully verified");
            try {
                const userData = await letUserSignUp_Api({
                    ...this.state,
                    country_code: this.state.country_code.replace("+", ""),
                });

                if (userData.success) {
                    // Set token and login to app.
                    storageUtils.setUserData(userData.data);
                    storageUtils.setUserToken(userData.token);

                    this.props.setUserSignUpSuccess(userData.data);

                    this.setState({
                        isSubmitOtp: false,
                        otp: "",
                        mobile_no: "",
                        first_name: "",
                        last_name: "",
                        email: "",
                        isDisplayOtp: false,
                    });
                    toast.success(userData.message);

                    this.props.history.push({
                        pathname: "/",
                    });
                } else {
                    this.setState({
                        isSubmitOtp: false,
                        // isDisplayOtp: false,
                    });
                    toast.error(userData.message);
                }
            } catch (error) {
                console.log(error.message);
                toast.error(error.message);
                this.setState({isSubmitOtp: false});
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
            this.setState({isSubmitOtp: false});
        }
    };

    // responseGoogleSuccess = (response) => {
    //     // console.log(response);
    //     let user = response.profileObj;
    //     this.setState(
    //         {
    //             first_name: user.name.split(" ")[0],
    //             last_name: user.name.split(" ")[1],
    //             email: user.email,
    //             is_sign_up: 2,
    //             name: user.name.split(" ")[0],
    //             google_id: user.googleId,
    //             registerPhase: false,
    //         },
    //         () => {
    //             toast.info("Please verify your mobile number to continue");
    //         }
    //     );
    // };

    // responseGoogleError = (response) => {

    //     if (response && response.error == "idpiframe_initialization_failed") {
    //         console.log("Error ", response);
    //         toast.error(response.details);
    //     } else if (response && response.error != "popup_closed_by_user") {
    //         console.log("Error ", response);
    //         toast.error("Oops! something went wrong");
    //     }
    // };

    // responseFromFacebook = (response) => {
    //     console.log("responseFromFacebook ", response);
    //     if (response.hasOwnProperty("id") && response.hasOwnProperty("name")) {
    //         this.setState(
    //             {
    //                 first_name: response.name.split(" ")[0],
    //                 last_name: response.name.split(" ")[1],
    //                 email: response.hasOwnProperty("email") ? response.email : "",
    //                 is_sign_up: 3,
    //                 name: response.name.split(" ")[0],
    //                 facebook_id: response.id,
    //                 registerPhase: false,
    //             },
    //             () => {
    //                 toast.info("Please verify your mobile number to continue");
    //             }
    //         );
    //     } else {
    //         // toast.error("Oops! something went wrong");
    //     }
    // };

    onChangeMobile = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({mobile_no: e.target.value});
        }
    };

    render() {
        const {
            country_code,
            mobile_no,
            first_name,
            last_name,
            email,
        } = this.state;

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Register </title>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={"create account"}/>

                {/*Regsiter section*/}
                <section
                    className={`${
                        this.state.registerPhase ? "login-page" : "register-page"
                    } section-b-space`}
                >
                    <div className="container">
                        {this.state.registerPhase ? (
                            <div className="row">
                                <div className="col-lg-6">
                                    <h3>Register</h3>
                                    <div className="theme-card">
                                        <form className="theme-form">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Phone Number</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => this.handleCountryChange(e)}
                                                            style={{
                                                                height: "54px",
                                                            }}
                                                            defaultValue={this.state.country_code}
                                                        >
                                                            {countryData.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.dial_code}>
                                                                        {item.name}&nbsp;{item.dial_code}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 login-num-res">
                                                    <div className="form-group">
                                                        <label htmlFor="mobileNo">&nbsp;</label>
                                                        <input
                                                            type="tel"
                                                            className="form-control"
                                                            id="mobileNo"
                                                            placeholder="Enter phone number"
                                                            maxLength="10"
                                                            value={this.state.mobile_no}
                                                            required
                                                            onChange={this.onChangeMobile}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.isDisplayOtp ? "d-none" : ""}>
                                                <button
                                                    className="btn btn-solid"
                                                    onClick={(e) => this.checkPhoneExist(e)}
                                                    disabled={this.state.isCheckPhoneExistApiCalled}
                                                >
                                                    sign up &nbsp;{" "}
                                                    {this.state.isCheckPhoneExistApiCalled && (
                                                        <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                                    )}
                                                </button>
                                                {/* Add a container for reCaptcha */}
                                                <div id="recaptcha-container"/>
                                            </div>
                                            <div
                                                className={` OTP_IN form-group${
                                                    !this.state.isDisplayOtp ? " d-none" : ""
                                                }`}
                                            >
                                                <label htmlFor="review">Enter OTP</label>
                                                <OtpInput
                                                    value={this.state.otp}
                                                    onChange={this.handleChange}
                                                    numInputs={6}
                                                    separator={<span>&nbsp;</span>}
                                                    isInputNum={true}
                                                    // disabledStyle={true}
                                                    containerStyle={{
                                                        justifyContent: "space-between",
                                                    }}
                                                    inputStyle={{
                                                        width: "35px",
                                                        border: "1px solid #dddddd",
                                                    }}
                                                />
                                            </div>
                                        </form>
                                        {/* <div className="csLine mt-3">
                                            <h2>
                                                <span>OR</span>
                                            </h2>
                                        </div>
                                        <div className="row d-flex justify-content-between login_soc_buttons">
                                            <div className="col">
                                                <FacebookLogin
                                                    appId={firebaseConfig.facebook_appId}
                                                    // appId="302353584599517" // new  company account
                                                    // appId="3626635134028631" // company
                                                    // appId="724838841468757" // client
                                                    // autoLoad={true}
                                                    fields="name,email,picture"
                                                    callback={this.responseFromFacebook}
                                                    cssClass="fbBtnCls"
                                                    icon="fa-facebook"
                                                    textButton="&nbsp; &nbsp;SignUp With Facebook"
                                                />
                                            </div>
                                            <div className="col">
                                                <GoogleLogin
                                                    clientId={firebaseConfig.clientId}
                                                    // clientId="788880648657-3o1j5lnldosm9670lo8alheccldof4ja.apps.googleusercontent.com" // new  lino company account
                                                    // clientId="954432691949-6uqkummak31hmh081uno1f4p6oof7emc.apps.googleusercontent.com" // new  company account
                                                    // clientId="540389952777-icr1ci4ci8pbhn9gclqs07o5kd19ke8m.apps.googleusercontent.com" // client account
                                                    // clientId="565672761492-6oadma94oanrrh1aiog8iu5oapja6mt7.apps.googleusercontent.com" // company account
                                                    // clientId="328831031958-kg8odim9t1ao6qp4165recjcmr8gg95e.apps.googleusercontent.com" // my account
                                                    render={renderProps => (
                                                        <button className="fbBtnCls" onClick={renderProps.onClick}>
                                                            <i className="fa fa-google"></i>&nbsp;&nbsp;SignUp With
                                                                                                        Google</button>
                                                    )}
                                                    buttonText="SignIn With Google"
                                                    onSuccess={this.responseGoogleSuccess}
                                                    onFailure={this.responseGoogleError}
                                                    cookiePolicy={"single_host_origin"}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 right-login">
                                    <h3>Existing User</h3>
                                    <div className="theme-card authentication-right">
                                        <h6 className="title-font">Login here</h6>
                                        <p>
                                            Sign up for a free account at our store. Registration is
                                            quick and easy. It allows you to be able to order from our
                                            shop. To start shopping click register.
                                        </p>
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/login`}
                                            data-lng="en"
                                            className="btn btn-solid"
                                        >
                                            Login
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3>create account</h3>
                                    <div className="theme-card">
                                        <form className="theme-form">
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlFor="email">
                                                        First Name<span style={{color: "red"}}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fname"
                                                        placeholder="First Name"
                                                        value={first_name}
                                                        required=""
                                                        maxLength="15"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                first_name: e.target.value,
                                                                name: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="review">
                                                        Last Name<span style={{color: "red"}}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="lname"
                                                        placeholder="Last Name"
                                                        value={last_name}
                                                        maxLength="15"
                                                        required=""
                                                        onChange={(e) =>
                                                            this.setState({last_name: e.target.value})
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="email">
                                                                    Phone Number
                                                                    <span style={{color: "red"}}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => this.handleCountryChange(e)}
                                                                    style={{
                                                                        height: "54px",
                                                                    }}
                                                                    defaultValue={country_code}
                                                                >
                                                                    {countryData.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item.dial_code}>
                                                                                {item.name}&nbsp;{item.dial_code}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group">
                                                                <label htmlFor="mobileNo">&nbsp;</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="mobileNo"
                                                                    disabled={this.state.mobile_no.length ==10 ? true : false}
                                                                    placeholder="Enter phone number"
                                                                    value={mobile_no}
                                                                    maxLength="10"
                                                                    required
                                                                    onChange={this.onChangeMobile}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="email">
                                                        email<span style={{color: "red"}}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="Email"
                                                        value={email}
                                                        required=""
                                                        onChange={(e) =>
                                                            this.setState({email: e.target.value})
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className={this.state.isDisplayOtp ? "d-none" : ""}>
                                                <button
                                                    className="btn btn-solid"
                                                    onClick={(e) => this.getOtpClick(e)}
                                                    disabled={
                                                        this.state.isSentOtpLoaderEnable ||
                                                        this.state.isCheckPhoneExistApiCalled
                                                    }
                                                >
                                                    GET OTP &nbsp;{" "}
                                                    {this.state.isSentOtpLoaderEnable ||
                                                    (this.state.isCheckPhoneExistApiCalled && (
                                                        <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                                    ))}
                                                </button>
                                                {/* Add a container for reCaptcha */}
                                                <div id="recaptcha-container"/>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div
                                                        className={` OTP_IN form-group ${
                                                            !this.state.isDisplayOtp ? " d-none" : ""
                                                        }`}
                                                    >
                                                        <label htmlFor="review">Enter OTP</label>
                                                        <OtpInput
                                                            value={this.state.otp}
                                                            onChange={this.handleChange}
                                                            numInputs={6}
                                                            separator={<span>&nbsp;</span>}
                                                            isInputNum={true}
                                                            // disabledStyle={true}
                                                            containerStyle={{
                                                                justifyContent: "space-between",
                                                            }}
                                                            inputStyle={{
                                                                width: "35px",
                                                                border: "1px solid #dddddd",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={!this.state.isDisplayOtp ? "d-none" : ""}
                                                    >
                                                        <button
                                                            className="btn btn-solid"
                                                            onClick={(e) => this.handleUserProvidedCode(e)}
                                                            disabled={this.state.isSubmitOtp}
                                                        >
                                                            REGISTER NOW 
                                                            {this.state.isSubmitOtp && (
                                                                <i className="fa fa-spinner  fa-pulse "/>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {
        setUserSignUpSuccess,
    }
)(UserRegister);
