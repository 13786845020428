import React from 'react';
import Carousel from '../../layout/Carousel'
import Products from '../../Products.js'
import Display from '../../Display';

const Home = (props) => {


    return (
        <div className="Home">
            <Carousel />
             <Products />
            <Display />
        </div>
    );
};



export default Home;