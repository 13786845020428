import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { checkAndRedirectLogin } from "../../utils/helper";
import { toast } from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import { Helmet } from "react-helmet";
import Link from "react-router-dom/Link";
import {getProfileapi} from "../../api/auth";
import * as storageUtils from "../../utils/storageUtils";

class MyDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      country_code: "+91",
      mobile_no: "",
      first_name: "",
      name: "",
      last_name: "",
      email: "",
      updateProfileLoader: false,
      mode: "view",
    };
  }

  componentDidMount() {
    if (!checkAndRedirectLogin()) {
      toast.info("Please login to continue");
      this.props.history.push({
        pathname: "/login",
      });
    }
    else{
      this.getProfile();
    }
  }

  getProfile = async () => {

    try {

      let userData = await getProfileapi();
      console.log("userData", userData)
      if (userData.success) {
        storageUtils.setUserData(userData.data);
        this.setState({...userData.data});
      } else {
        toast.error(userData.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }

  };

  render() {
    const { isLoggedIn, userData } = this.props.auth;
    const {country_code, billing_address} = this.state;
    return (
      <div>
        <Helmet>
          <title>Dashboard </title>
          <meta name="description"  />
        </Helmet>

        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section className="section-b-space">
          {
            !billing_address?
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>:""
          }
          
          <div className="container dashBoardCont">

            <div className="row">

              {/*{isLoggedIn && <DahsboardSidebar {...this.props} />}*/}

              {isLoggedIn && (
                <div className="col-lg-12">
                  <div className="dashboard-right">
                    <div className="dashboard">
                      <div className="page-title">
                        <h2>My Dashboard</h2>
                      </div>
                      <div className="welcome-msg">
                        <p className="mb-2">
                          Hello, {userData.name + " " + userData.last_name} !
                        </p>
                        <p>
                          From your My Account Dashboard you have the ability to
                          view a snapshot of your recent account activity and
                          update your account information. Select a link below
                          to view or edit information.
                        </p>
                      </div>
                      <div className="box-account box-info">
                        <div className="box-head">
                          <h2>Account Information</h2>
                        </div>
                        <div className="row boxDash">
                          <div className="col-sm-12">
                            <div className="box">
                              <div className="box-title">
                                <h3>Contact Information</h3>
                                <Link
                                  to={`${
                                    process.env.PUBLIC_URL
                                  }/dashboard/profile/edit`}
                                  data-lng="en"
                                >
                                  Edit
                                </Link>
                              </div>
                              <div className="box-content">
                                <h6>
                                  {userData.name + " " + userData.last_name}
                                </h6>
                                <h6>{userData.email}</h6>
                                {/* <h6>
                                  <a href="#">Change Password</a>
                                </h6> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-6">
                            <div className="box">
                              <div className="box-title">
                                <h3>Newsletters</h3>
                                <a href="#">Edit</a>
                              </div>
                              <div className="box-content">
                                <p>
                                  You are currently not subscribed to any
                                  newsletter.
                                </p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="boxAdd">
                          <div className="box">
                            <div className="box-title">
                              <h3>Address Book</h3>
                              <Link
                                to={`${
                                  process.env.PUBLIC_URL
                                }/dashboard/address`}
                              >
                                Manage Addresses
                              </Link>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="addIN">
                                  <h6>Default Billing Address</h6>
                                  
                                  <address>
                                    {billing_address && billing_address.is_default == 1 ? <>

                                          {billing_address.address_1}  {billing_address.address_2}<br/>
                                          {billing_address.landmark}<br/>
                                          Pin code: {billing_address.pin_code}
                                          <br/> {billing_address.city_name} {billing_address.state_id} {billing_address.country_id}<br/>
                                        </> :
                                        <>You have not set a default billing address.</>
                                    }


                                    <br />
                                    {/* <a href="#">Edit Address</a> */}
                                  </address>
                                </div>
                              </div>
                              {/*<div className="col-sm-6">
                                <h6>Default Shipping Address</h6>
                                <address>
                                  You have not set a default shipping address.
                                  <br />
                                   <a href="#">Edit Address</a>
                                </address>
                              </div>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  symbol: state.data.symbol,
});

export default connect(
  mapStateToProps,
  {}
)(MyDashboard);
