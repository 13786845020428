import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

import Geocode from "react-geocode";


// Custom Components
import RealEstateHeader from "./common/headers/RealEstateHeader";
import ReaslEstateFooter from "./common/footers/ReaslEstateFooter";

// ThemeSettings
import ThemeSettings from "./common/theme-settings";
import TitleBanner from "./TitleBanner";
import {getAllSettings_api} from "../api/settings";
import {getAllSettings} from "../actions";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: [],
            id: 1,
        };
    }

    componentDidMount() {
        this.GetAllSettings()
        this.GetIP_Address()
    }

    GetAllSettings = async () => {
        let apiParams = null;
        let settings = this.props.getAllSettings(apiParams)
    };

    GetIP_Address = async () => {


        navigator.geolocation.getCurrentPosition(function (position)  {
            var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+position.coords.latitude+','+position.coords.longitude+'&key=AIzaSyAlTed1qsSl5-S2XVVDTXxGyKFiyOS4-xc';
            fetch(GEOCODING).then(response => response.json()).then(data => {
                localStorage.setItem('get_address', data.results[0].formatted_address)
                localStorage.setItem('is_active', 1)
            });
        });


        fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(data => localStorage.setItem('get_ip', data.IPv4))
            .catch(err => {
               console.log("error",JSON.stringify(err))
            })
    };

    render() {

        const {
            store_logo,
            seo_meta_title,
            seo_meta_tags,
            seo_meta_descriptions,
            store_name,
            store_phone
        } = {...this.props.settings}
        return (
            <>
                {(seo_meta_title) ? (
                    <Helmet>
                        <title>{seo_meta_title}</title>
                        <meta
                            name="description"
                            content={seo_meta_descriptions}
                        />
                        <meta
                            name="tags"
                            content={seo_meta_tags}
                        />
                        <meta name="author" content={seo_meta_title}/>
                        <meta
                            name="keywords"
                            content={seo_meta_tags}
                        />
                    </Helmet>
                ) : ''}

                {
                    (store_logo) ?
                        <>
                            <TitleBanner dashboardModulesnew={[]}/>

                            <RealEstateHeader store_phone={store_phone} store_name={store_name} logoName={store_logo}/>

                            {this.props.children}
                            <ReaslEstateFooter logoName={store_logo}/>
                            <ThemeSettings/>
                        </>
                        : ""
                }
            </>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        settings : state.settings,
    };
};

export default connect(
    mapStateToProps,
    {
        getAllSettings
    }
)(App);


