import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class Collection extends Component {

    constructor (props) {
        super (props)
    }

    render (){


        return (
            <div>    
                {/*Collection section*/}
                
                <section className="collection section-b-space">
                <div className="title1 section-t-space">
                    <h2 className="title-inner1">{this.props.productTitle}</h2>
                </div>
                    <div className="container-fluid mx-3">
                        <div className="row partition-collection ">
                            <div className="col-lg-3 col-md-6 ">
                                <div className="collection-block  ">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/collection/c1.png`} className="img-fluid" alt="" />
                                        <div className="collection-content">
                                            <h4>COMBO DEALS</h4>
                                            <h3>fashion</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry....</p>
                                            <a href="category-page.html" className="btn btn-danger">shop now !</a>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 ">
                                <div className="collection-block ">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/collection/c2.png`} className="img-fluid" alt="" />
                                        <div className="collection-content">
                                            <h4>COMBO DEALS</h4>
                                            <h3>fashion</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry....</p>
                                            <a href="category-page.html" className="btn btn-danger">shop now !</a>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 ">
                                <div className="collection-block ">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/collection/c3.png`} className="img-fluid" alt="" />
                                        <div className="collection-content">
                                            <h4>COMBO DEALS</h4>
                                            <h3>fashion</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry....</p>
                                            <a href="category-page.html" className="btn btn-danger">shop now !</a>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 ">
                                <div className="collection-block ">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/collection/c4.png`} className="img-fluid" alt="" />
                                        <div className="collection-content">
                                            <h4>COMBO DEALS</h4>
                                            <h3>fashion</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry....</p>
                                            <a href="category-page.html" className="btn btn-danger">shop now !</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Collection