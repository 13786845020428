import { combineReducers } from "redux";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";

// Import custom components
import productReducer from "./products";
import cartReducer from "./cart";
import filtersReducer from "./filters";
import wishlistReducer from "./wishlist";
import compareReducer from "./compare";
import homeReducer from "./home";
import authReducer from "./auth";
import pagesReducer from "./pages";
import settingsReducer from "./settings";
import serviceReducer from "./service";

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  data: productReducer,
  cartList: cartReducer,
  filters: filtersReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  pages: pagesReducer,
  settings: settingsReducer,
  service: serviceReducer,
  Intl,
});

export default rootReducer;
