import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class ServiceHomePageComp2 extends Component {
    render() {
        return (
            <div>
                <div className='title1 section-t-space ' id='proTitle'>
                <h2 className='title-inner1 '>{"PLUMBERS"}</h2>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/12/products`}>
                  <h6 className='new_view_all_ title-inner1 font-weight-bold'>
                    view all
                  </h6>
                </Link>
              </div>
              <section className='section-b-space'>
                <div className='container-fluid '>
                  <div className='row mx-md-2 mx-xl-5'>
                    <div className='d-flex flex-wrap w-100 justify-content-start'>
                      <div className='setproducts'>
                        <div className='product-box product-wrap mx-2 my-3'>
                          <div className='img-wrapper'>
                            <Link
                              to={`${process.env.PUBLIC_URL}/product/p/product`}
                            >
                              <div className='front'>
                                <div
                                  className='img-fluid'
                                 /* style={{
                                    backgroundImage:
                                      "url('https://realestate.on-demand-app.com/refactoring/public/product/347/9ca69a5839bb414f644b70643c8a73e8.jpg')",
                                  }}*/
                                ></div>
                              </div>
                            </Link>
                            <div className='cart-info cart-wrap'>
                                <a
                                  href='javascript:void(0)'
                                  title='Add to Wishlist'
                                >
                                  <i
                                    className='fa fa-heart'
                                    aria-hidden='true'
                                  ></i>
                                </a>
                                <a
                                  href='javascript:void(0)'
                                  data-toggle='modal'
                                  data-target='#quick-view'
                                  title='Quick View'
                                >
                                  <i
                                    className='fa fa-search'
                                    aria-hidden='true'
                                  ></i>
                                </a>
                              </div>
                            </div>
                            

                            <div className='media-body align-self-center'>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/p/product`}
                              >
                                <h5>{"Tap Fitting"}</h5>
                              </Link>

                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        )
    }
}
