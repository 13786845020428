import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import LookBook from "./pages/lookbook";

const Banner3 = (props) => {
        const {dashboardModulesnew,store_name} = props


        function setlink(bannerdetails) {

            if (bannerdetails.link_banner_to == 0) {
                return `${process.env.PUBLIC_URL}/product/` + bannerdetails.product_id;
            } else if (bannerdetails.link_banner_to == 1) {
                return `${process.env.PUBLIC_URL}/category/` + bannerdetails.category_id + `/products`
            } else if (bannerdetails.link_banner_to == 2) {
                return `${process.env.PUBLIC_URL}/offer/` + bannerdetails.offer_id + `/products`
            } else {
                return `${process.env.PUBLIC_URL}`;
            }

            return "";
        }

        return (
            <div className="Title cart-d-none">
                {(dashboardModulesnew && dashboardModulesnew.banners_top && (window.location.origin + "/" == window.location.href)) ?
                    <>
                        {(dashboardModulesnew.banners_top.banner_position === 0 ?
                            <>
                                <Link to={setlink(dashboardModulesnew.banners_top)}>
                                    <img className="d-block w-100 title_banners_header" src={dashboardModulesnew.banners_top.banner_image_url}
                                         alt="" srcSet=""/>
                                </Link>
                            </>
                            : "")}



                    </>
                    : ""}


            </div>
        );
    }
;

const mapStateToProps = (state) => ({
    dashboardModulesnew: state.home.dashboardModulesnew,
});

export default connect(mapStateToProps)(Banner3);



