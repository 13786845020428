import React, { Component } from 'react'
import ProductListing from "../collection/common/DashboardProductListing";
import ServiceProductListing from '../collection/common/ServiceProductListing';
// import nodata from "../../../access/icone/nodata.jpg"
import nodata from "../../access/icone/nodata.jpg"
import {connect} from "react-redux";
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts,
} from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import { getServiceProviderType } from '../../api/home';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const color = ["", "purple_color", "blue_color", "green_color", "yellow_color"];

class ServiceProviderTypes extends Component {
    state = {
        data : [],
        serviceId: this.props.match.params.id,
        isLoading: false

    };
    
    componentDidMount() {
        // check whether sidebar is open or not
        this.closeNav();
        this.checkPincode()
    }
    closeNav() {
        var closemyslide = document.getElementById("mySidenav");
        if (closemyslide) closemyslide.classList.remove("open-side");
    }

    checkPincode = () => {
        if(!localStorage.getItem("pincode")) {
           
            this.setState({
                isLoading: true
              })

              Swal.fire({
                title: 'Please enter pincode',
                input: 'number',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: function (pincode) {
                  return new Promise(function (resolve, reject) {
                    setTimeout(function() {
                        console.log(pincode ,"pincode")
                      if (!pincode) {
                        reject('please enter valid value')
                      } else {
                        resolve()
                      }
                    }, 2000)
                  })
                },
                allowOutsideClick: false
              }).then((pincode) =>{
                console.log("pincoide", pincode)

                if(!pincode.isConfirmed) {
                    window.history.back();
                }else {
                    this.getProviderType(pincode.value)
                    localStorage.setItem("pincode",pincode.value)
                }
              })
              return
        }else {
            this.getProviderType(localStorage.getItem("pincode"))
        }
    }

    getProviderType = async (pincode) => {        
        try {
            this.setState({
                isLoading: true
              })
              const body = {
                service_id: this.props.match.params.id,
                user_pincode: pincode
              }
            let response = await getServiceProviderType(body);

            console.log("provider type",response);

            this.setState({
                data: response.result.data
            })
         
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        } finally {
            this.setState({
                isLoading: false
              })
        
    }
  
      };
    render() {
        return (
            <div>
                <Breadcrumb title={"Choose Your Service Provider Type"} />
               
                <div class="container">
  <div className="row">
    <div className="col-sm mt-1">
    <button type="button" className="btn btn-solid btn-lg btn-block" style={{ fontSize:20 }} onClick={()=>{localStorage.removeItem("pincode"); this.checkPincode()}}>Please enter pincode where you need Service :  {localStorage.getItem("pincode")}</button>
    </div>    
  </div>
</div>

                
                {this.state.isLoading ? <div className="row">
                            <div
                                className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found">
                                <div className="loading-cls"/>
                            </div>
                        </div> : 
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        <div className="mx-2 prolistTop">
                            {/* <ServiceProductListing dashboardId={this.state.dashboardId} colSize={this.state.layoutColumns}/> */}

                            <div className='service_provider_wrapper'>
                                <div className='service_provider_wrap'>
                                    <ul className='service_provider_block'>
                                        {
                                            this.state.data.map((item, index) => {
                                                return  <li>
                                                <Link to={ item? `/service-providers/${this.state.serviceId}/${item.id}`: ""}>
                                                <div className='service_provider_sec'>
                                                    <div className={`service_title ${color[index]}`}>
                                                        <h2>{item && item.title}</h2>
                                                        <div className='service_img'>
                                                            <img src={item ? item.image_url : ""} />
                                                        </div>
                                                    </div>
                                                    <div className='service_wrap'>
                                                        {
                                                            item && item.web_type.map((type) => {
                                                                return    <div className='service_block'>
                                                                <h3>{type.subtitle}</h3>
                                                                <p>{type.text}</p>
                                                
                                                            </div>
                                                            })
                                                        }
                                                     
                                                        {/* <div className='service_block'>
                                                            <h3>EXPERIENCE</h3>
                                                            <p>3 years + experience</p>
                                                        </div>
                                                        <div className='service_block'>
                                                            <h3>TEAM</h3>
                                                            <p>1</p>
                                                        </div>
                                                        <div className='service_block'>
                                                            <h3>EXPERTIZE</h3>
                                                            <p>small works for individual homes.</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                </Link>
                                            </li>
                                            })
                                        }
                                       
                                        {/* <li>
                                        <Link to={ this.state.data[1]? `/service-providers/${this.state.serviceId}/${this.state.data[1].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title purple_color'>
                                                <h2>{this.state.data[1] && this.state.data[1].title}</h2>
                                                    
                                                    <div className='service_img'>
                                                    <img src={this.state.data[1] ? this.state.data[1].image_url : ""} />
                                                        
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[1] && this.state.data[1].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>

                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[2]? `/service-providers/${this.state.serviceId}/${this.state.data[2].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title blue_color'>
                                                    <h2>{this.state.data[2] && this.state.data[2].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[2] ? this.state.data[2].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[2] && this.state.data[2].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                              
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[3]? `/service-providers/${this.state.serviceId}/${this.state.data[3].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title green_color'>
                                                    <h2>{this.state.data[3] && this.state.data[3].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[3] ? this.state.data[3].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[3] && this.state.data[3].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                                        
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[4]? `/service-providers/${this.state.serviceId}/${this.state.data[4].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title yellow_color'>     
                                                    <h2>{this.state.data[4] && this.state.data[4].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[4] ? this.state.data[4].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[4] && this.state.data[4].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                                        
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}

                {!this.state.isLoading && this.state.data.length === 0 && <div className='d-flex justify-content-center'>
                            <img src={nodata}  height={400} width={400}/>
                        </div> }


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
});

export default connect(
    mapStateToProps,
    {
        getAllDashboardProducts,
        filterSort,
        getAllDashboardProductFilters,
        filterBrand,
        resetFilterAndfSort,
        resetDashboardProducts,
    }
)(ServiceProviderTypes);