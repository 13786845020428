import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeFromWishlist } from "../../actions";
import { getProductCartTotal } from "../../services";
import { declareOrderType } from "../../utils/helper";
import { editAddress_api} from "../../api/auth"
import EditAddress from "../pages/address/EditAddress";
import countryData from "../pages/countries.json";
import { toast } from "react-toastify";

class SelectBillingAddress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payment: "stripe",
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            country: "",
            address: "",
            city: "",
            state: "",
            pincode: "",
            sameAsDeliveryAddress: true,
            isShowEditAddressPage: false,
            editAddress: null,
            loaderForAddress: false,
        };
        // this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    };

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);

        // if (!this.validator.fieldValid(event.target.name)) {
        //   this.validator.showMessages();
        // }
    };

    checkhandle(value) {
        this.setState({
            payment: value,
        });
    }

    StripeClick = () => {
        // if (this.validator.allValid()) {
        alert("You submitted the form and stuff!");

        var handler = window.StripeCheckout.configure({
            key: "pk_test_mZ6HfljXBzO7479xfG4u3Hbl", // pk_test_glxk17KhP7poKIawsaSgKtsL
            locale: "auto",
            token: (token) => {
                console.log(token);
                this.props.history.push({
                    pathname: "/order-success",
                    state: {
                        payment: token,
                        items: this.props.cartItems,
                        orderTotal: this.props.total,
                        symbol: this.props.symbol,
                    },
                });
            },
        });
        handler.open({
            name: "",
            description: "Online Fashion Store",
            amount: this.amount * 100,
        });
        // } else {
        //   this.validator.showMessages();
        //   // rerender to show messages for the first time
        //   this.forceUpdate();
        // }
    };

    declareTaxesOfProduct = () => {
        const { cartItems } = this.props;
        var total = 0;
        var taxTypesSum;
        for (var i = 0; i < cartItems.length; i++) {
            taxTypesSum = 0;
            // if (declareOrderType(cartItems[i]) === "Retail") {
            //   if (cartItems[i].tax_price.length > 0) {
            //     for (var j = 0; j < cartItems[i].tax_price.length; j++) {
            //       if (cartItems[i].tax_price[j].tax_amount)
            //         taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // } else {
            //   if (cartItems[i].wholesale_tax_price.length > 0) {
            //     for (var k = 0; k < cartItems[i].wholesale_tax_price.length; k++) {
            //       if (cartItems[i].tax_price[k].tax_amount)
            //         taxTypesSum += parseInt(
            //           cartItems[i].wholesale_tax_price[k].tax_amount
            //         );
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // }
            if (cartItems[i].tax_price.length > 0) {
                for (var j = 0; j < cartItems[i].tax_price.length; j++) {
                    if (cartItems[i].tax_price[j].tax_amount)
                        taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
                }
                total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            }
        }
        return total;
    };

    handleNextClick = () => {
        alert("hii");
    };

    showEditPage = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isShowEditAddressPage: !prevState.isShowEditAddressPage,
        }));
    };

    handleEditClick = (e, item) => {
        e.persist();
        this.setState(
            {
                editAddress: item,
            },
            () => {
                this.showEditPage(e);
            }
        );
    };

    editAddressApi = async (data) => {
        try {
            this.setState({ loaderForAddress: true });
            data.address_id = this.state.editAddress.id;
            const newAddressAdded = await editAddress_api(data);
            this.props.setAddressList()
            this.setState((prevState) => ({
                loaderForAddress: false,
                isShowEditAddressPage: false,
                editAddress: null,
            }));
            toast.success("Address has been updated successfully");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            this.setState({ loaderForAddress: false });
        }
    };

    componentDidMount(){
        console.info("history Prop Check", this.props.history)
    }

    render() {
        const { cartItems, symbol, total, coupon_discount,cart_full } = this.props;
        const { isLoggedIn } = this.props.auth;

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: "/order-success",
                state: {
                    payment: payment,
                    items: cartItems,
                    orderTotal: total,
                    symbol: symbol,
                },
            });
        };

        const onCancel = (data) => {
            console.log("The payment was cancelled!", data);
        };

        const onError = (err) => {
            console.log("Error!", err);
        };

        const client = {
            sandbox:
                "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
            production:
                "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
        };
        console.log("delivery_address_id",this.props.delivery_address_id)
        console.log("address",this.props.address)

        let address_type;
        return (
            <section className="section-b-space address-list-checkout">

                {/* <div className="container padding-cls"> */}
                <div className="container-fluid">
                    <div className="row mx-md-5 mx-sm-1">
                        <div className="col-sm-12 ">
                            <div className="checkout-page">
                                <div className="checkout-form">
                                    {(this.props.address && this.props.address.length > 0) &&
                                        (
                                            <form>
                                                <div className="dashboard checkout row ">
                                                    {!this.state.isShowEditAddressPage ?
                                                        <>
                                                            <div className="col-lg-7 col-sm-12 col-xs-12">
                                                                <div className="box">
                                                                    <div className="box-title ">
                                                                        <h3
                                                                            className="set_color_dark_1 font-size-text-20"
                                                                            style={{
                                                                                fontWeight: "700",
                                                                                fontSize: "24PX",
                                                                            }}
                                                                        >
                                                                            DELIVERY ADDRESS
                                                                        </h3>
                                                                        <Link className="font-weight-bold" to={`${process.env.PUBLIC_URL}/dashboard/address?is_address=1`}
                                                                        >
                                                                            {/* <i className="fa fa-plus" />  */}
                                                                            ADD NEW ADDRESS
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="row check-out border-mobile-address">
                                                                    <div className="col-md-12 ">
                                                                        {this.props.address.map((item, index) => {
                                                                            address_type = "Home";
                                                                            if (item.address_type === 2) {
                                                                                address_type = "Office";
                                                                            } else if (item.address_type === 3) {
                                                                                address_type = "Site";
                                                                            }
                                                                            return (
                                                                                <div className="row mt-3 border-mobile-address_bottom" key={index}>
                                                                                    {/*<div className="col-1">
                                              <input type="radio" name="address" />

                                              <input
                                                  type="radio"
                                                  name="address"
                                                  defaultChecked={item.is_default == 1? true: false}
                                                  onClick={(e) =>
                                                      this.props.handleDeliveryAddressRadioClick(
                                                          e,
                                                          item
                                                      )
                                                  }
                                              />
                                            </div>*/}
                                                                                    <div className="col-9 address-text-set-n ">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="address"
                                                                                            className="mx-1"
                                                                                            defaultChecked={item.is_default == 1? true: false}
                                                                                            onClick={(e) =>
                                                                                                this.props.handleDeliveryAddressRadioClick(
                                                                                                    e,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        />

                                                                                        <strong className="set_color_dark_1">
                                                                                            {item.user_name} <span className="badge badge-primary  badge-custome">{address_type}</span>
                                                                                        </strong>
                                                                                        <div className=""> {item.address_1} </div>
                                                                                        <strong>Landmark:</strong>{" "}
                                                                                        <span>{item.landmark}</span>
                                                                                        <br/>
                                                                                        <strong>City:</strong>{" "}
                                                                                        <span>{item.city_name}</span>
                                                                                        <br/>
                                                                                        <strong>State:</strong>{" "}
                                                                                        <span>{item.state_id}</span>
                                                                                        <br/>
                                                                                        <strong>Country:</strong>{" "}
                                                                                        <span>{item.country_id}</span>
                                                                                        <br/>
                                                                                        <div className=""><b>Phone Number: </b> {item.mobile_no}</div>
                                                                                        <div className=""><b>Supervisor Name: </b> {item.supervision_name}</div>
                                                                                        <div className=""><b>Supervisor Number: </b> {item.supervision_mobile_no}</div>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <div className="box pull-right">
                                                                                            <a className="font-weight-bold theme_text_set"
                                                                                               href="javascript:void();"
                                                                                               onClick={(e) =>
                                                                                                   this.handleEditClick(e, item)
                                                                                               }
                                                                                            >Edit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-5 mb-md-4 mt-sm-1 mb-sm-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="sameAsDeliveryAddress"
                                                                            id="account-option"
                                                                            checked={this.props.sameAsDeliveryAddress}
                                                                            onChange={this.props.setStateFromCheckbox}
                                                                        />
                                                                        &ensp;{" "}
                                                                        <label className="font-weight-bold" htmlFor="account-option" style={{color: "#047bd5"}}>
                                                                            Same as Delivery Address
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {this.props.sameAsDeliveryAddress === false && (
                                                                    <>
                                                                        <div className="box">
                                                                            <div className="box-title">
                                                                                <h3
                                                                                    className="set_color_dark_1 font-size-text-20"
                                                                                    style={{
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                >
                                                                                    BILLING ADDRESS
                                                                                </h3>
                                                                                <Link
                                                                                    to={`${
                                                                                        process.env.PUBLIC_URL
                                                                                    }/dashboard/address`}
                                                                                    className="font-weight-bold theme_text_set"
                                                                                >
                                                                                    <i className="fa fa-plus" /> ADD ADDRESS
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row check-out border-mobile-address">
                                                                            <div className="col-md-12 ">
                                                                                {this.props.address.map((item, index) => {
                                                                                    address_type = "Home";
                                                                                    if (item.address_type === 2) {
                                                                                        address_type = "Office";
                                                                                    } else if (item.address_type === 3) {
                                                                                        address_type = "Site";
                                                                                    }
                                                                                    return (
                                                                                        <div className="row mt-3 border-mobile-address_bottom" key={index}>

                                                                                            <div className="col-9 address-text-set-n ">
                                                                                                <input
                                                                                                    className="mx-1"
                                                                                                    type="radio"
                                                                                                    name="billing"
                                                                                                    value={this.state.billing_address_id}
                                                                                                    defaultChecked={
                                                                                                        item.id ==
                                                                                                        this.props.billing_address_id
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        this.props.handleBillingAddressRadioClick(
                                                                                                            e,
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <strong className="set_color_dark_1">
                                                                                                    {item.user_name} <span className="badge badge-primary  badge-custome">{address_type}</span>
                                                                                                </strong>{" "}
                                                                                                <br />
                                                                                                {item.address_1} <br />
                                                                                                <strong>Landmark:</strong>{" "}
                                                                                                <span>{item.landmark}</span>
                                                                                                <br/>
                                                                                                <strong>City:</strong>{" "}
                                                                                                <span>{item.city_name}</span>
                                                                                                <br/>
                                                                                                <strong>State:</strong>{" "}
                                                                                                <span>{item.state_id}</span>
                                                                                                <br/>
                                                                                                <strong>Country:</strong>{" "}
                                                                                                <span>{item.country_id}</span>
                                                                                                <br/>

                                                                                                <strong>Phone Number:</strong>{" "}
                                                                                                <span>{item.mobile_no}</span> <br />
                                                                                                <strong>Supervisor Name:</strong>{" "}
                                                                                                <span>{item.supervision_name}</span>{" "}
                                                                                                <br />
                                                                                                <strong>Supervisor Number:</strong>{" "}
                                                                                                <span>
                                              {item.supervision_mobile_no}
                                            </span>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <div className="box pull-right">
                                                                                                    <Link
                                                                                                        to={`${
                                                                                                            process.env.PUBLIC_URL
                                                                                                        }/dashboard/address`}
                                                                                                        className="font-weight-bold set_color_dark_2"
                                                                                                    >Edit</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="col-lg-5 col-sm-12 col-xs-12">
                                                                {/* <div className="checkout-details"> */}
                                                                <div
                                                                    style={{
                                                                        // paddingLeft: "40px",
                                                                        paddingTop: "15px",
                                                                    }}
                                                                >
                                                                    <div className="p-2" style={{border: "1px solid #ced4da"}}>
                                                                        <h4 className="font-weight-bold my-2 set_color_dark_1">PAYMENT SUMMARY</h4>
                                                                        <table
                                                                            className='payment_summary_table'
                                                                            style={{
                                                                                // tableLayout: "fixed",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <tbody>
                                                                            <tr>
                                                                                <th  className="py-2" >Subtotal</th>
                                                                                <td  className="py-2" style={{textAlign: "right"}}>
                                                                                    {symbol} {(cart_full&&cart_full.total)?(parseFloat(cart_full.total).toFixed(2)):0}
                                                                                </td>
                                                                            </tr>
                                                                            {/*  <tr>
                                    <th  className="py-2">Grand total excl. taxes</th>
                                    <td  className="py-2"  style={{textAlign: "right"}}>
                                      {symbol} {(cart_full&&cart_full.total)?(cart_full.total):0}
                                    </td>
                                  </tr>*/}
                                                                            {/*<tr>
                                    <th  className="py-2" >Taxes</th>
                                    <td  className="py-2"  style={{textAlign: "right"}}>
                                      {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                    </td>
                                  </tr>*/}
                                                                            {cart_full && cart_full.total_coupon_price && cart_full.total_coupon_price >= 1?
                                                                                <tr>
                                                                                    <th  className="py-2" >Coupon Discount</th>
                                                                                    <td  className="py-2 text-danger"  style={{textAlign: "right"}}>
                                                                                        {symbol} {(cart_full && cart_full.total_coupon_price)?parseFloat(cart_full.total_coupon_price).toFixed(2):0}
                                                                                    </td>
                                                                                </tr>:""}
                                                                            <tr>
                                                                                <th  className="py-2" >Shipping</th>
                                                                                <td  className="py-2 text-danger"  style={{textAlign: "right"}}>
                                                                                    {symbol} {(cart_full && cart_full.total_ship_price)?parseFloat(cart_full.total_ship_price).toFixed(2):0}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className="py-2 w-70 set_color_dark_1">Grand Total</th>
                                                                                <td className="py-2 w-30 theme_text_ligblue" style={{textAlign: "right"}}>
                                                                                    {symbol}{" "}
                                                                                    {(cart_full&&cart_full.total)?(parseFloat(cart_full.total_ship_price)+parseFloat(cart_full.total)-parseFloat(cart_full.total_coupon_price)).toFixed(2):0}
                                                                                </td>
                                                                            </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <div  className="payNowClass">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-solid "
                                                                                // onClick={() => this.StripeClick()}
                                                                                onClick={() => this.props.handleNextClick()}
                                                                                style={{width: "100%"}}
                                                                            >
                                                                                NEXT
                                                                            </button>
                                                                        </div>

                                                                    </div>

                                                                    <div className="payment-box mt-3">
                                                                        {/*<Link
                                        to={`${process.env.PUBLIC_URL}/cart`}
                                        className="btn-solid btn"
                                    >
                                      BACK
                                    </Link>*/}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""}
                                                    {isLoggedIn && this.state.isShowEditAddressPage && (
                                                        <EditAddress
                                                            countryData={countryData}
                                                            editAddress={this.state.editAddress}
                                                            showEditPage={this.showEditPage}
                                                            editAddressApi={this.editAddressApi}
                                                            loaderForAddress={this.state.loaderForAddress}
                                                        />
                                                    )}
                                                </div>
                                            </form>
                                        )}

                                    {/* if no address added */}
                                    {this.props.address.length == 0 && (
                                        <div className="dashboard checkout row border-mobile-address">
                                            <div className="col-sm-12 col-xs-12">
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3
                                                            className="set_color_dark_1"
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "24PX",
                                                            }}
                                                        >
                                                            Delivery Address
                                                        </h3>

                                                    </div>
                                                </div>
                                                <div className="row check-out">
                                                    <div className="col text-center">
                                                        <p className="p-5">
                                                            No Delivery address added, please add new address to
                                                            proceed further
                                                        </p>

                                                        <Link
                                                            className=" text-center btn btn-danger"
                                                            to={`${process.env.PUBLIC_URL}/dashboard/address?is_address=1`}
                                                        >
                                                            Add Address
                                                        </Link>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart_full: state.cartList.cart_full,
    symbol: state.data.symbol,
    total: getProductCartTotal(state.cartList.cart),
    coupon_discount: state.cartList.coupon_discount,
});

export default connect(
    mapStateToProps,
    { removeFromWishlist }
)(SelectBillingAddress);
