import React, {Component} from "react";
import {connect} from "react-redux";
import {getVisibleproducts} from "../../../services";

class DashboardFilterBar extends Component {
    //List Layout View

    constructor(props) {
        super(props);

        this.state = {
            filters:false,
            sort: "A TO Z",
            Price: "Low to High",
            show: 0,
            filterClicked: false
        };
    }

    listLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:0";
        document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row")
            .childNodes;
        [].forEach.call(elems, function (el) {
            el.className = "";
            el.classList.add("col-lg-12");
        });
        setTimeout(function () {
            document.querySelector(".product-wrapper-grid").style = "opacity: 1";
        }, 500);
    }

    //Grid Layout View
    gridLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:1";
        document
            .querySelector(".product-wrapper-grid")
            .classList.remove("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row")
            .childNodes;
        [].forEach.call(elems, function (el) {
            el.className = "";
            el.classList.add("col-lg-3");
        });
    }

    // Layout Column View
    LayoutView = (colSize) => {
        if (
            !document
                .querySelector(".product-wrapper-grid")
                .classList.contains("list-view")
        ) {
            var elems = document.querySelector(".infinite-scroll-component .row")
                .childNodes;
            [].forEach.call(elems, function (el) {
                el.className = "";
                el.classList.add("col-lg-" + colSize);
            });
        }

        this.props.onLayoutViewClicked(colSize);
    };

    filteredvalue(data) {

        var id = '';
        var da = data.filters.map(word => {
            if (this.props.Filters.includes(word.filter_id.toString())) {
                id = word.filter_id
            }
        });
        return id;

    }

    onClick(index) {
        this.setState({show: index, filterClicked: true});
    }


    render() {
        var {dashboardProductFilters, fetchDashboardProductFilterLoader, dashboardProducts, Filters} = this.props;
        console.log(dashboardProducts, "product data")
        const filteredBrands = this.props.filters.brand;
        return (
            <>
                <div className="collection-filter _product_filters ">
                    <div className="collection-filter-block">
                        <div className="collection-mobile-back">
                            <span className="filter-back" >
                                <i className="fa fa-angle-left" aria-hidden="true"/> back
                            </span>
                        </div>
                        

                        <div className={this.state.show === 30?"open collection-collapse-block":"collection-collapse-block"}>
                            <h3 className="collapse-block-title" onClick={() => {this.onClick(30)}} >SORT BY : <span className="float-right mr-3" style={{color:"#999999"}}>{this.state.sort}</span></h3>
                            
                            <div className={this.state.show === 30?"show collapse":"collapse"}>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter">
                                        <ul className="category-list">
                                            <li>
                                                <a className="font-size-text-12" data-value="1" onClick={(e) => {e.preventDefault();  this.setState({ sort: "A To Z" }); this.props.onFilterSortClicked(e)}}>A To Z</a>
                                            </li>
                                            <li>
                                                <a className="font-size-text-12" data-value="2" onClick={(e) => {e.preventDefault();  this.setState({ sort: "Z To A" }); this.props.onFilterSortClicked(e)}}>Z To A</a>
                                            </li>
                                            <li>
                                                <a data-value="4" onClick={(e) => {e.preventDefault();  this.setState({ Price: "High to Low" }); this.props.onFilterSortClicked(e)}}>High to Low</a>
                                            </li>
                                            <li>
                                                <a data-value="3" onClick={(e) => {e.preventDefault();  this.setState({ Price: "Low to High" }); this.props.onFilterSortClicked(e)}}>Low to High</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="collection-filter _product_filters">
                <div className={this.state.show === 30?"open collection-collapse-block":"collection-collapse-block"}>
                        <a onClick={(e) => {window.location.reload()}}><h6 className=""  >Remove filters</h6></a></div>

                    <div className="collection-filter-block">
                        <div className="collection-mobile-back">
                            <span className="filter-back">
                                     <i className="fa fa-angle-left" aria-hidden="true"/> back
                            </span>
                        </div>

                        {!fetchDashboardProductFilterLoader &&
                        dashboardProductFilters.length > 0
                            ? dashboardProductFilters.map((item, i) => {
                                return <>
                                    <div className={this.state.show === i?"open collection-collapse-block":"collection-collapse-block"}>
                                        <h3 className="collapse-block-title" onClick={() => {this.onClick(i)}} >{item.filter_group_name}</h3>
                                        <div className={(this.state.show === i && this.state.filterClicked)?"show collapse":"collapse"}>
                                            <div className="collection-collapse-block-content">
                                                <div className="collection-brand-filter">
                                                    <ul className="category-list">
                                                        {item.filters.map((filter, index) => {
                                                            return (
                                                                <li>
                                                                    <a className="font-size-text-12" data-value={filter.filter_id} onClick={(e) => {e.preventDefault(); this.props.sidebarFilterClicked(e,filteredBrands,i)}} >{filter.filter_description}</a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            }) : ""}

                        {/* <div className={this.state.show === 50?"open collection-collapse-block":"collection-collapse-block"}>
                            <h3 className="collapse-block-title" onClick={() => {this.onClick(50)}} >Price </h3>
                            <div className={this.state.show === 50?"show collapse":"collapse"}>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter">
                                        <ul className="category-list">
                                            <li>
                                                <a data-value="4" onClick={(e) => {e.preventDefault();  this.setState({ Price: "High to Low" }); this.props.onFilterSortClicked(e)}}>High to Low</a>
                                            </li>
                                            <li>
                                                <a data-value="3" onClick={(e) => {e.preventDefault();  this.setState({ Price: "Low to High" }); this.props.onFilterSortClicked(e)}}>Low to High</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                    </div>

                </div>

                <div className="row product-filter-content d-none ">

                    <div className="col-sm-12  ">
                        <ul className="sidetitlelist">

                            <li className="product-page-filter" id="sidedrop">
                                {!fetchDashboardProductFilterLoader &&
                                dashboardProductFilters.length > 0
                                    ? dashboardProductFilters.map((item, i) => {
                                        return <>
                                            <select key={i} defaultValue={this.filteredvalue(item)}
                                                    className="col-4 font-size-text-16 font-weight-bold" onChange={(e) => this.props.sidebarFilterClicked(e,filteredBrands,i)}>
                                                <option value={0}>{item.filter_group_name}</option>
                                                {item.filters.map((filter, index) => {
                                                    return (
                                                        <option key={index} value={filter.filter_id}>{item.filter_group_name} : {filter.filter_description}</option>
                                                    );
                                                })}
                                            </select>
                                        </>

                                    }) : ""}
                            </li>
                        </ul>
                    </div>

                    <div className="col-sm-12  product-page-filter">
                         <b id="listTitle">T-SHIRTS</b>


                        <select className="col-4 float-right font-size-text-16 font-weight-bold "
                                defaultValue={this.props.filters.sortBy}
                                onChange={(e) => this.props.onFilterSortClicked(e)}
                        >
                            <option value="">Sort</option>
                            <option value="1">Sort By Name: A To Z</option>
                            <option value="2">Sort By Name: Z To A</option>
                        </select>
                        <select className="col-4 float-right font-size-text-16 font-weight-bold"
                                defaultValue={this.props.filters.sortBy}
                                onChange={(e) => this.props.onFilterSortClicked(e)}>
                            <option value="">Price</option>
                            <option value="4">Price: High to Low</option>
                            <option value="3">Price: Low to High</option>
                        </select>
                    </div>
                </div>

                <div className="row d-none-mobile_unset d-none">
                    <div className="col-sm-12">
                        <div className="d-flex flex-row bd-highlight justify-content-between">
                            <div className="p-2 bd-highlight">
                                <h6 className="h4_title_filtes_mobile mobile_border_sold">{this.props.viewlisttitle}</h6>
                            </div>
                            <div className="p-2 bd-highlight ">
                                <div className="h4_title_filtes_mobile">
                                    <samp onClick={() => this.props.changeGrideView()}><i
                                        className="fa fa-th-large  mx-1" aria-hidden="true"></i></samp>
                                    <samp><i className="fa fa-tasks  mx-1" aria-hidden="true"></i></samp>
                                    <samp onClick={() => {
                                        this.setState({filters: !this.state.filters});
                                    }}><i className="fa fa-filter  mx-1" aria-hidden="true"></i></samp>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filters-set-mobile d-none ">
                    <div className="filter">
                        <div
                            className={this.state.filters ? " filter-container-wrapper active " : "filter-container-wrapper"}>
                            <div className="filter-tabs">
                                <div className="filter-tab-list-wrapper">
                                    <div className="row product-filter-content">
                                        <div className="col-sm-12">
                                            <ul className="sidetitlelist">

                                                <li className="product-page-filter" id="sidedrop">
                                                    {!fetchDashboardProductFilterLoader &&
                                                    dashboardProductFilters.length > 0
                                                        ? dashboardProductFilters.map((item, i) => {
                                                            return <>
                                                                <select key={i} defaultValue={this.filteredvalue(item)}
                                                                        className="col-md-4 col-sm-12 font-size-text-16 font-weight-bold"
                                                                        onChange={(e) => this.props.sidebarFilterClicked(
                                                                            e,
                                                                            filteredBrands,
                                                                            i
                                                                        )}>
                                                                    <option value={0}>{item.filter_group_name}</option>
                                                                    {item.filters.map((filter, index) => {
                                                                        return (
                                                                            <option key={index}
                                                                                    value={filter.filter_id}>{item.filter_group_name} : {filter.filter_description}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </>

                                                        }) : ""}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12  product-page-filter">
                                             <b id="listTitle">T-SHIRTS</b>


                                            <select
                                                className="col-md-4 col-sm-12 float-right font-size-text-16 font-weight-bold "
                                                defaultValue={this.props.filters.sortBy}
                                                onChange={(e) => this.props.onFilterSortClicked(e)}
                                            >
                                                <option value="">Sort</option>
                                                <option value="1">Sort By Name: A To Z</option>
                                                <option value="2">Sort By Name: Z To A</option>
                                            </select>
                                            <select
                                                className="col-md-4 col-sm-12 float-right font-size-text-16 font-weight-bold"
                                                defaultValue={this.props.filters.sortBy}
                                                onChange={(e) => this.props.onFilterSortClicked(e)}>
                                                <option value="">Price</option>
                                                <option value="4">Price: High to Low</option>
                                                <option value="3">Price: Low to High</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-btn close-link" onClick={() => {
                                    this.setState({filters: false});
                                }}>X
                                </div>
                            </div>
                            <div className="filter-content owl-1">

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    filters: state.filters,
    dashboardProducts: state.data.dashboardProducts,
    dashboardProductFilters: state.data.dashboardProductFilters,
    fetchDashboardProductFilterLoader:
    state.data.fetchDashboardProductFilterLoader,
});

export default connect(
    mapStateToProps,
    {}
)(DashboardFilterBar);
