import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import main from "../../../layouts/home/main";
import buttone1 from "../../../../assets/icone/Screenshot_6.png";
import buttone from "../../../../assets/icone/button.png";
import SubMenu from "./SubMenu";
import Search_header from "../Search_header";
import { checksinglevendo, declareOrderType } from "../../../../utils/helper";
import { connect } from "react-redux";
import { getServiceMenu } from "../../../../api/product";
import { toast } from "react-toastify";

class HomeNavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "0px" },
      opensub: false,
      opensub2: false,
      serviceList: [],
      trendingService: []
    };
  }
  componentDidMount() {
    this.specialityList()
}

specialityList = async () => {

    try {

        let specialityList = await getServiceMenu();

        if (specialityList.status) {
          console.log("service_list",specialityList);
            this.setState({
              serviceList: specialityList.result.data.speciality,
              trendingService: specialityList.result.data.service
            });
        } else {
            toast.error(specialityList.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }

  };
  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-1200px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-1200px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-100%" } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function(value) {
        value && value.classList && value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function(value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  handleSubTwoMenu = (e) => {
    e.preventdefault();    
    this.setState({ opensub3: false });
  };

  handleMenu3 = (props) => {
    // if(props){
    this.setState({ opensub3: props });
    // }
    // else{
    // this.setState({opensub3: !opensub3})
    // }
  };

  handleMenu2 = (props) => {
    this.setState({ opensub2: props });
    // this.setState({ opensub2: !opensub2 });
  };

  render() {
    const { translate, mainMenu, brands, sideBarMenus } = this.props;
    const { opensub } = this.state;
    const { opensub2 } = this.state;
    const { opensub3, serviceList, trendingService } = this.state;

    const handleMenu = () => {
      this.setState({ opensub: !opensub });
    };
    return (
      <>
        <div className="main-navbar w-100">
          <div id="mainnav">
            <div
              className="toggle-nav"
              onClick={() => {
                this.props.show(this);
              }}
            >
              <i
                className="fa fa-search sidebar-bar"
                style={{ color: "#777777" }}
              />
            </div>
            <div
              className="toggle-nav toggle-navnew d-none"
              onClick={() => {
                this.openNav();
              }}
            >
              <i
                className="fa fa-ellipsis-v sidebar-bar"
                style={{ color: "#777777" }}
              />{" "}
              {/* // hide this as need to hide menu */}
            </div>
            <ul className="nav-menu w-100" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)}>
                <div className="mobile-back text-right">
                  <span>Back</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              <li>
                <Link to={this.props.isSp ?"/service-provider-home" : "/"}  className="nav-link">
                  home
                </Link>
              </li>
              {!this.props.isSp && <li
                className={`mega-menu ${
                  opensub3 ? "opensubmenu" : "closemenu"
                }`}
                onMouseOver={() => {
                    this.handleMenu3(true);
                }}
                onMouseLeave={() => this.handleMenu3(false)}
              >
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Categories
                  <span className="sub-arrow"></span>
                </Link>
                <div className={`mega-menu-container`}>
                  <div className="container-fluid">
                    <div className="row mx-md-5 mx-sm-0 megamenuIn">
                      <div className="col-sm-12 megamenuInOut">
                        <div className="grid-menu-main">
                          {sideBarMenus.map((m, i) => (
                            <div className="box-item mega-box" key={i}>
                              <div className="link-section">
                                <div className="menu-title pt-3">
                                  <h5 className="theme_text_set">
                                    <Link
                                      className="theme_text_set_a"
                                      to={`${process.env.PUBLIC_URL}/category/${m.id}/products`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // handleMenu3()
                                      }}
                                    ></Link>
                                  </h5>
                                </div>
                                <div className="menu-content">
                                  {m.subCat.length > 0 && (
                                    <>
                                      <SubMenu
                                        onClick={() => this.handleMenu3(false)}
                                        s={m.subCat}
                                        handleSubTwoMenu={(e) =>
                                          this.handleSubTwoMenu(e)
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>}

              {!this.props.isSp && <li  className={`${
                    opensub2 ? "opensubmenu" : "closemenu"
                  }`}
                  onMouseOver={() => {
                    // if (opensub2 === false) {
                      this.handleMenu2(true);
                    // }
                  }}
                  onMouseLeave={() => this.handleMenu2(false)}
                  >
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.handleMenu2();
                  }}
                  className={`nav-link`}
                 
                >
                  brands
                  <span className="sub-arrow"></span>
                </Link>
                <ul
                  className={
                    // opensub2 ? "nav-submenu opensubmenu" :
                    "nav-submenu"
                  }
                >
                  {brands &&
                    brands.map((cat, i) => (
                      <li key={i}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/brand/${cat.id}/products`}
                          className="nav-link"
                          // onClick={(e) => this.handleSubmenu(e)}
                          onClick={(e) => this.handleMenu2(false)}
                        >
                          {cat.manufacture_name}
                          <span className="sub-arrow"></span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>}

              {this.props.isSp && <li  className={`${
                    opensub3 ? "opensubmenu" : "closemenu"
                  }`}
                  onMouseOver={() => {
                    // if (opensub2 === false) {
                      this.handleMenu3(true);
                    // }
                  }}
                  onMouseLeave={() => this.handleMenu3(false)}
                  >
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.handleMenu2();
                  }}
                  className={`nav-link`}
                 
                >
                  Speciality
                  <span className="sub-arrow"></span>
                </Link>
                <ul
                  className={
                    // opensub2 ? "nav-submenu opensubmenu" :
                    "nav-submenu extra_width"
                  }
                >
                  {serviceList &&
                    serviceList.map((item, i) => (
                      <li key={i}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/services/${item.name}/${item.id}`}
                          className="nav-link"
                          // onClick={(e) => this.handleSubmenu(e)}
                          onClick={(e) => this.handleMenu3(false)}
                        >
                          {item.name}
                          {/* <span className="sub-arrow"></span> */}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>}

              {this.props.isSp && <li  className={`${
                    opensub2 ? "opensubmenu" : "closemenu"
                  }`}
                  onMouseOver={() => {
                    // if (opensub2 === false) {
                      this.handleMenu2(true);
                    // }
                  }}
                  onMouseLeave={() => this.handleMenu2(false)}
                  >
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.handleMenu2();
                  }}
                  className={`nav-link`}
                 
                >
                  TRENDING SERVICES
                  <span className="sub-arrow"></span>
                </Link>
                <ul
                  className={
                    // opensub2 ? "nav-submenu opensubmenu" :
                    "nav-submenu extra_width"
                  }
                >
                  {trendingService &&
                    trendingService.map((service, i) => {

                      return service.services.map((item) => {
                        let url = item.parent_id ? `/service-providertype/${item.id}` : `/sub-services/` + item.service_name + `/` + item.speciality_id + `/${item.id}`
                      return <li key={i}>
                        <Link
                          to={url}
                          className="nav-link"
                          // onClick={(e) => this.handleSubmenu(e)}
                          onClick={(e) => this.handleMenu2(false)}
                        >
                          {item.parent_id ?  item.sub_service_name : item.service_name}
                          {/* <span className="sub-arrow"></span> */}
                        </Link>
                      </li>}
                       )}
                    )}
                </ul>
              </li>}

              {/* {
                this.props.isSp &&  <li>
                <Link to="/speciality-listing" className="nav-link">
                  Speciality
                </Link>
              </li>
              } */}
              {/* {checksinglevendo() == true?
              <li >
                <Link to="#" className="nav-link" onClick={(e) => {e.preventDefault();}}>
                  Best selling
                </Link>
              </li>:""} */}
              <li>
                <Link to="/testimonial" className="nav-link">
                  testimonials
                </Link>
              </li>
              <li>
                <a
                  href="https://blog.infrawheel.com/"
                  className="nav-link"
                  onClick={(e) => {
                    this.setState({ opensub: 0 });
                  }}
                >
                  {translate("blog")}
                </a>
              </li>
              {/* {checksinglevendo() == true?
              <li>
                <Link to="#" className="nav-link" onClick={(e) => {e.preventDefault();}}>
                  Sell on infrawheel
                </Link>
              </li>:""}*/}
              {checksinglevendo() == true ? (
                <li>
                  <Link to="/download-app" className="nav-link">
                    Download app
                  </Link>
                </li>
              ) : (
                ""
              )}

              {/* <li>
                <Link to="/pages/contact" className="nav-link" >
                  contact us
                </Link>
              </li> */}
              {checksinglevendo() == true ? (
                <li className="float-md-right float-sm-left">
                  {(window.location.pathname.includes("sp") || window.location.pathname.includes("speciality") || window.location.pathname.includes("service"))?<Link
                    to={"/"}
                    className="nav-link p-0 ml-md-5 my-2"
                    onClick={(e) => {
                      this.setState({ opensub: 0 });
                    }}
                  >
                    <img src={buttone} alt="" />
                  </Link>:
                  <Link
                    to={"/service-provider-home"}
                    className="nav-link p-0 ml-md-5 my-2"
                    onClick={(e) => {
                      this.setState({ opensub: 0 });
                    }}
                  >
                    <img src={buttone1} alt="" />
                  </Link>}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}



// export default withTranslate(HomeNavBar);
const mapStateToProps = (state) => ({
  provider: state.settings.provider,
});

export default withRouter(connect(
  mapStateToProps,
  {
  }
)(withTranslate(HomeNavBar)));
