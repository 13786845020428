import {
    SET_ABOUTUS_PAGE,
        } from "../constants/ActionTypes";


const initialState = {
    aboutUs: {},
};

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ABOUTUS_PAGE:
            return { ...state, aboutUs: action.aboutUs };
        default:
            return state;
    }
};
export default pagesReducer;