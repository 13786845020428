import React, { Component } from "react";
import { Link } from "react-router-dom";

class DahsboardSidebar extends Component {
  render() {
    const { match } = this.props;
    return (
      <div className="col-lg-3">
        <div className="account-sidebar">
          <a className="popup-btn">my account</a>
        </div>
        <div className="dashboard-left">
          <div className="collection-mobile-back">
            <span className="filter-back">
              <i className="fa fa-angle-left" aria-hidden="true" /> back
            </span>
          </div>
          <div className="block-content">
            <ul>
              <li
                className={match.path === "/dashboard/account" ? "" : "active"}
              >
                <Link to={`${process.env.PUBLIC_URL}/dashboard/account`}>
                  Dashboard
                </Link>
              </li>
              <li
                className={
                  match.path === "/dashboard/profile/edit" ? "" : "active"
                }
              >
                <Link to={`${process.env.PUBLIC_URL}/dashboard/profile/edit`}>
                  Manage Profile
                </Link>
              </li>
              <li
                className={match.path === "/dashboard/address" ? "" : "active"}
              >
                <Link to={`${process.env.PUBLIC_URL}/dashboard/address`}>
                  Address Book
                </Link>
              </li>
              <li
                className={match.path === "/dashboard/orders" ? "" : "active"}
              >
                <Link to={`${process.env.PUBLIC_URL}/dashboard/orders`}>
                  My Orders
                </Link>
              </li>
              {/* <li>
                <a href="#">Change Password</a>
              </li> */}
              {/* <li className="last">
                <a href="#">Log Out</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default DahsboardSidebar;
