import React from 'react';

const Rating = () => {
  return (
    <>
      <div className="rating pt-4">
        <i class="fa fa-star filled" aria-hidden="true"></i>
        <i class="fa fa-star filled" aria-hidden="true"></i>
        <i class="fa fa-star filled" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>

      </div>



    </>
  );
}

export default Rating;

