import React, { Component } from "react";
import { Link } from "react-router-dom";
import { declareProductPrice } from "../../../../utils/helper";

const ProductCartHeader = ({ item, total, symbol, removeFromCart }) => (
  <li>
    <div className="media">
      <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
        <img alt="" className="mr-3 img-set-bb" src={`${item.product_image}`} />
      </Link>
      <div className="media-body">
        <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
          <><p>{item.product_name}</p></>
        </Link>
        {/* <h4>
          <span>
            {item.cart_quantity} x {symbol} {declareProductPrice(item)}
          </span>
        </h4> */}
      </div>
    </div>
    {/*<span>{cart}</span>*/}
    {/* <div className="close-circle">
      <a href={null} onClick={removeFromCart}>
        <i className="fa fa-times" aria-hidden="true" />
      </a>
    </div> */}
  </li>
);

export default ProductCartHeader;
