import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { getAboutUspage } from "../../actions/index";

class aboutUs extends Component {
  componentDidMount() {
    this.props.getAboutUspage({ content_type: 31 });
  }


  render() {
    const { aboutUs } = this.props;

    return (
      <div>
        {window.location.href.search("sp") <= 1 ? (
          <Breadcrumb title={aboutUs.page_name} />
        ) : (
          ""
        )}

        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div
                  dangerouslySetInnerHTML={{ __html: aboutUs.page_content }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutUs: state.pages.aboutUs,
});

const mapDispatchToProps = { getAboutUspage };

export default connect(mapStateToProps, mapDispatchToProps)(aboutUs);
